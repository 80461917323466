import api from "./api"

class WorksheetService {
  getPublishers(params) {
    return api.get('/worksheet/publishers/', { params })
  }

  getWorksheets(params) {
    return api.get('/view_set/worksheet/', { params })
  }

  getWorksheetList(
    pageSize,
    page,
    isOnlyMyAcademy,
    schoolType,
    schoolYear,
    subject,
    tag,
    isExcludeWrong,
    name,
    isOnlyMyFavorite,
    isShare,
  ) {
    const params = {
      'page_size': pageSize,
      'page': page,
      'is_only_my_academy': isOnlyMyAcademy,
      'school_type': schoolType,
      'school_year': schoolYear,
      'subject': subject,
      'tag': tag,
      'is_exclude_wrong': isExcludeWrong,
      'name': name,
      'is_only_my_favorite': isOnlyMyFavorite,
      'is_share': isShare,
    };
    return api.get('/worksheet/get_list', { params })
  }

  getWorksheet(id, params) {
    return api.get(`/worksheet/get/${id}/`, { params })
  }

  getAllTags(params) {
    return api.get('/worksheet/all_tags/', { params })
  }

  createWorksheet(payload) {
    return api.post('/view_set/worksheet/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  updateWorksheet(worksheetId, payload) {
    return api.put(`/view_set/worksheet/${worksheetId}/`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  patchWorksheet(worksheetId, payload) {
    return api.patch(`/view_set/worksheet/${worksheetId}/`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  deleteWorksheet(payload) {
    return api.post('/worksheet/delete_id_list/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  createFavoriteWorksheet(payload) {
    return api.post('/worksheet/create_favorite/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  deleteFavoriteWorksheet(payload) {
    return api.post('/worksheet/delete_favorite/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
}

export default new WorksheetService()
