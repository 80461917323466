const itemKey = 'selectV2';
const item = JSON.parse(localStorage.getItem(itemKey));

const initialState = item ?? {
  'isSelectV2': false,
};

export const selectV2 = {
  namespaced: true,
  state: initialState,
  actions: {
    setIsSelectV2({ commit }, isSelectV2) {
      commit('setIsSelectV2', isSelectV2);
    },
  },
  mutations: {
    setIsSelectV2(state, isSelectV2) {
      state.isSelectV2 = isSelectV2;
      localStorage.setItem(itemKey, JSON.stringify(state));
    },
  },
  getters: {
    isSelectV2: (state) => state.isSelectV2 ?? false,
  },
};
