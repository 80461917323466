export function isMobile() {
    return window.innerWidth < parseInt(process.env.VUE_APP_MIN_WIDTH);
}

export function getPdfSize() {
    const width = 597;
    const height = 844;

    const headerHeight = 169;
    const headerDividerSize = 2;
    const footerDividerSize = 4;
    const footerHeight = 40;

    return {
        'width': width,
        'height': height,
        'headerHeight': headerHeight,
        'headerDividerSize': headerDividerSize,
        'firstBodyHeight': height - (headerHeight + headerDividerSize + footerDividerSize + footerHeight),
        'bodyHeight': height - (footerDividerSize + footerHeight),
        'footerDividerSize': footerDividerSize,
        'footerHeight': footerHeight,

        'item': {
            'headerHeight': 16, // 맨 위 텍스트 라인 높이
            'gapSize': 7, // 간격
            'subTitleHeight': 8, // 두 번째 텍스트 라인 높이
            'imageWidth': 231, // 이미지 너비
            'marginY': 30, // 위/아래 마진
        },
    };
}
