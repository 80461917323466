import {getServerMediaUrl} from "@/utils/parse";
import {ProblemPatternSimpleVo} from "@/models/problem_pattern_simple_vo";
import {DividerExt} from "@/extensions/divider_ext";

export class ProblemVo {
    /** @type {number} */
    id;
    /** @type {string} */
    patternName;
    /** @type {ProblemPatternSimpleVo|null} */
    topPattern;
    /** @type {ProblemPatternSimpleVo|null} */
    middlePattern;
    /** @type {ProblemPatternSimpleVo|null} */
    unit3;
    /** @type {string|null} */
    conceptVidUrl;
    /** @type {string} */
    type;
    /** @type {string|null} */
    solutionType;
    /** @type {string} */
    difficulty;
    /** @type {string} */
    problemImgUrl;
    /** @type {number} */
    score;
    /** @type {number|null} */
    answerRate;
    /** @type {string} */
    answer;
    /** @type {string|null} */
    solutionImgUrl;
    /** @type {string|null} */
    workbookNumber;

    constructor(
        id,
        patternName,
        topPattern,
        middlePattern,
        unit3,
        conceptVidUrl,
        type,
        solutionType,
        difficulty,
        problemImgUrl,
        score,
        answerRate,
        answer,
        solutionImgUrl,
        workbookNumber,
    ) {
        this.id = id;
        this.patternName = patternName;
        this.topPattern = topPattern;
        this.middlePattern = middlePattern;
        this.unit3 = unit3;
        this.conceptVidUrl = conceptVidUrl;
        this.type = type;
        this.solutionType = solutionType;
        this.difficulty = difficulty;
        this.problemImgUrl = problemImgUrl;
        this.score = score;
        this.answerRate = answerRate;
        this.answer = answer;
        this.solutionImgUrl = solutionImgUrl;
        this.workbookNumber = workbookNumber;
    }

    static fromJson = (json) => new ProblemVo(
        json.id,
        json.pattern_name,
        json.top_pattern != null ? ProblemPatternSimpleVo.fromJson(json.top_pattern) : null,
        json.middle_pattern != null ? ProblemPatternSimpleVo.fromJson(json.middle_pattern) : null,
        json.unit_3 != null ? ProblemPatternSimpleVo.fromJson(json.unit_3) : null,
        json.concept_vid_url,
        json.type,
        json.solution_type,
        json.difficulty,
        getServerMediaUrl(json.problem_img),
        json.score,
        json.answer_rate,
        json.answer,
        json.solution_img ? getServerMediaUrl(json.solution_img) : null,
        json.workbook_number,
    );
}
