const itemKey = 'ConceptVidUrlStore';
const item = JSON.parse(sessionStorage.getItem(itemKey));

/** @type {{ items: ConceptVidItem[] }} */
const initialState = item ?? {
  items: [],
};

export const conceptVidItemStore = {
  namespaced: true,
  state: initialState,
  actions: {
    /**
     * @param commit
     * @param {ConceptVidItem} conceptVidItem
     */
    addConceptVidItem({ commit }, conceptVidItem) {
      commit('addConceptVidItem', conceptVidItem);
    },
  },
  mutations: {
    /**
     * @param state
     * @param {ConceptVidItem} conceptVidItem
     */
    addConceptVidItem(state, conceptVidItem) {
      if (state.items.length > 0) {
        state.items = state.items.filter(e => {
          const isSame = e.studentWorkId == conceptVidItem.studentWorkId
              && e.patternId == conceptVidItem.patternId;
          return !isSame;
        });
      }
      state.items.unshift(conceptVidItem);

      sessionStorage.setItem(itemKey, JSON.stringify(state));
    },
  },
  getters: {
    /** @return {ConceptVidItem[]} */
    getConceptVidUrls: (state) => state.items,
  },
};
