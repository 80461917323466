import {StudentWorkStateExt} from "@/extensions/student_work_state_ext";

export class StudentWorkByHistoryVo {
    /** @type {number} */
    id;
    /** @type {string} */
    name;
    /** @type {string} */
    subName;
    /** @type {string} */
    state;
    /** @type {string} */
    scoreStr;
    /** @type {string} */
    type;
    /** @type {boolean} */
    isWorksheet;
    /** @type {boolean} */
    isWorkbook;
    /** @type {number} */
    totalProblemCnt;
    /** @type {number} */
    totalAnswerProblemCnt;
    /** @type {number} */
    totalCorrectProblemCnt;

    constructor(
        id,
        name,
        subName,
        state,
        scoreStr,
        type,
        isWorksheet,
        isWorkbook,
        totalProblemCnt,
        totalAnswerProblemCnt,
        totalCorrectProblemCnt,
    ) {
        this.id = id;
        this.name = name;
        this.subName = subName;
        this.state = state;
        this.scoreStr = scoreStr;
        this.type = type;
        this.isWorksheet = isWorksheet;
        this.isWorkbook = isWorkbook;
        this.totalProblemCnt = totalProblemCnt;
        this.totalAnswerProblemCnt = totalAnswerProblemCnt;
        this.totalCorrectProblemCnt = totalCorrectProblemCnt;
    }

    static fromJson = (json) => new StudentWorkByHistoryVo(
        json.id,
        json.name,
        json.sub_name,
        json.h_state,
        json.h_p_state,
        json.h_title,
        json.h_is_worksheet,
        json.h_is_workbook,
        json.total_problem_cnt,
        json.total_answer_problem_cnt,
        json.total_correct_problem_cnt,
    );

    static getTotalAnswerProblemCntByList(dataList) {
        return dataList.reduce((acc, cur) => acc + cur.totalAnswerProblemCnt, 0);
    }

    static getTotalCorrectProblemCntByList(dataList) {
        return dataList.reduce((acc, cur) => acc + cur.totalCorrectProblemCnt, 0);
    }

    static getCompletedCntByList(dataList) {
        return dataList.filter(data => data.state == StudentWorkStateExt.completed).length;
    }
}
