<template>
  <div :class="['popup-wrap', 'wrong-note-add-problem-popup-wrap', { hide: !isVisible }]">
    <div class="p-shadow" @click="() => this.onCloseEvent()"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <h1>오답노트 문제 저장</h1>
        <p>저장할 오답노트를 모두 선택해주세요</p>
        <div class="pi-note-add"
             @click="() => this.isShowWrongNoteCreatePopup = true"
        >
          <img src="@/assets/images/plus-white.png" alt="">
          <span>오답노트 추가</span>
        </div>
        <div class="pi-note-list">
          <template v-if="this.errorMsg !== null">
            <ErrorMsgBox :contents="this.errorMsg"/>
          </template>
          <template v-else-if="this.isLoading">
            <div class="li-loading-wrap">
              <LoadingBox
                  size="30px"
              />
            </div>
          </template>
          <template v-else>
            <div class="li-item-list">
              <template v-for="(studentWorkFolderVo, index) in this.studentWorkFolderVos" :key="index">
                <template v-if="index > 0">
                  <div class="il-divider"></div>
                </template>
                <div :class="['il-item', {'active': this.selectFolderIds.includes(studentWorkFolderVo.id)}]"
                     @click="() => this.onClickFolder(studentWorkFolderVo.id)"
                >
                  <div class="ili-check-box"></div>
                  <div class="ili-body">
                    <p>{{ studentWorkFolderVo.name }}</p>
                    <span>{{ studentWorkFolderVo.getCreateDtStr() }}</span>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
        <div class="pi-btn-list">
          <button @click="() => this.onCloseEvent()">
            <span>취소</span>
          </button>
          <button @click="() => this.onSubmit()">
            <span>저장</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <WrongNoteCreatePopup
        :is-visible="this.isShowWrongNoteCreatePopup"
        :on-close="() => this.isShowWrongNoteCreatePopup = false"
        :on-changed="() => this.refreshList()"
    />
    <LoadingDialog
        :is-show="this.isLoadingDialog"
    />
  </Teleport>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog.vue";
import StudentWorkService from "@/services/studentWork.service";
import LoadingBox from "@/components/LoadingBox.vue";
import {StudentWorkFolderVo} from "@/models/student_work_folder_vo";
import utils from "@/utils";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import WrongNoteCreatePopup from "@/components/WrongNoteCreatePopup.vue";

export default {
  name: 'WrongNoteAddProblemPopup',
  components: {WrongNoteCreatePopup, ErrorMsgBox, LoadingBox, LoadingDialog},
  props: {
    isVisible: Boolean,
    onClose: Function,
    /** @type {number|null} */
    problemId: null,
    onChanged: Function,
  },
  watch: {
    problemId(value) {
      if (value !== null) {
        this.refreshList();
      }
    },
  },
  data() {
    return {
      /** @type {string|null} */
      errorMsg: null,
      /** @type {boolean} */
      isLoadingDialog: false,
      /** @type {boolean} */
      isLoading: true,
      /** @type {StudentWorkFolderVo[]} */
      studentWorkFolderVos: [],
      /** @type {StudentWorkFolderProblemVo[]} */
      studentWorkFolderProblemVos: [],
      /** @type {number[]} */
      selectFolderIds: [],
      /** @type {boolean} */
      isShowWrongNoteCreatePopup: false,
    };
  },
  methods: {
    onClickFolder(folderId) {
      let selectFolderIds = this.selectFolderIds;
      if (selectFolderIds.includes(folderId)) {
        selectFolderIds = selectFolderIds.filter(e => e !== folderId);
      } else {
        selectFolderIds.push(folderId);
      }

      this.selectFolderIds = Array.from(new Set(selectFolderIds));
    },
    async refreshList() {
      this.errorMsg = null;
      this.isLoading = true;
      this.selectFolderIds = [];

      const problemId = this.problemId;

      let studentWorkFolderVos;
      let studentWorkFolderProblemVos;
      await Promise.all([
          StudentWorkService.getFolders().then(res => {
            if (res !== null) {
              studentWorkFolderVos = res.map((e) => StudentWorkFolderVo.fromJson(e));
              utils.debugLog(`folder response length: ${studentWorkFolderVos.length}`);
            }
          }),
          StudentWorkService.getStudentWorkFolderProblems(problemId).then(res => {
            studentWorkFolderProblemVos = res;
          }),
      ]);
      if (studentWorkFolderVos === null) {
        this.errorMsg = '오답 노트 리스트를 받아오는 중 오류가 발생하였습니다';
        return;
      } else if (studentWorkFolderProblemVos === null) {
        this.errorMsg = '오답 노트 문제 리스트를 받아오는 중 오류가 발생하였습니다';
        return;
      }

      this.studentWorkFolderVos = studentWorkFolderVos;
      this.studentWorkFolderProblemVos = studentWorkFolderProblemVos;
      this.selectFolderIds = Array.from(new Set(studentWorkFolderProblemVos.map(e => e.folderId)));

      this.isLoading = false;
    },
    onCloseEvent() {
      this.onClose();
    },
    async onSubmit() {
      const selectFolderIds = this.selectFolderIds;
      const studentWorkProblemId = this.problemId;
      utils.debugLog(`onSubmit() - selectFolderIds: ${selectFolderIds}, studentWorkProblemId: ${studentWorkProblemId}`);

      this.isLoadingDialog = true;
      const res = await StudentWorkService.changeFolderProblem(selectFolderIds, studentWorkProblemId);
      this.isLoadingDialog = false;
      if (!res) {
        this.$toast.error('저장 중 오류가 발생하였습니다');
      } else {
        this.$toast.success('저장되었습니다');
      }

      this.onClose();
      this.onChanged();
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.wrong-note-add-problem-popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99999999;
  padding: 20px;
  &.hide {
    display: none;
  }
  .p-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .p-body {
    position: relative;
    max-width: 400px;
    width: 100%;
    max-height: 90vh;
    margin: auto;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #DBDBDB;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
    overflow-y: auto;
    border-radius: 5px;
    padding: 30px;
    @include tablet {
      max-width: 360px;
      padding: 20px;
    }
    @include mobile {
      padding: 15px;
    }
    .pb-inner-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      &>h1 {
        font-weight: 600;
        font-size: 18px;
        color: black;
        text-align: center;
        @include tablet {
          font-size: 15px;
        }
      }
      &>p {
        margin-top: 20px;
        width: 100%;
        font-size: 15px;
        text-align: center;
        word-break: keep-all;
      }
      .pi-note-add {
        margin: 10px auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #DBDBDB;
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
        padding: 8px 15px;
        background-color: #{$defaultColor};
        gap: 7px;
        @include mobile {
          padding: 8px 10px;
        }
        &>img {
          width: 15px;
          height: 15px;
          object-fit: contain;
        }
        &>span {
          text-align: center;
          font-weight: 700;
          font-size: 12px;
          color: white;
          line-height: 1;
          white-space: nowrap;
          @include tablet {
            font-size: 11px;
          }
          @include mobile {
            font-size: 10px;
          }
        }
      }
      .pi-note-list {
        margin-top: 15px;
        width: 100%;
        .li-loading-wrap {
          display: flex;
          justify-content: center;
        }
        .li-item-list {
          display: flex;
          flex-direction: column;
          .il-divider {
            width: 100%;
            height: 1px;
            background-color: #dddddd;
          }
          .il-item {
            display: flex;
            padding: 7px 16px;
            cursor: pointer;
            align-items: center;
            gap: 10px;
            border: 2px solid transparent;
            .ili-check-box {
              width: 17px;
              height: 17px;
              border: 1px solid gray;
              border-radius: 50%;
            }
            .ili-body {
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 3px;
              &>p {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
              }
              &>span {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;
              }
            }
            &:hover {
              background-color: #eeeeee;
            }
            &.active {
              border-color: #{$defaultColor};
              .ili-check-box {
                background-color: #{$defaultColor};
                border-color: #{$defaultColor};
              }
            }
          }
        }
      }
      .pi-btn-list {
        width: 100%;
        display: flex;
        gap: 15px;
        align-items: center;
        button {
          margin-top: 30px;
          width: 100%;
          border-radius: 7px;
          background-color: #{$defaultColor};
          padding: 10px 15px;
          &:first-child {
            background-color: #{$grayColor};
          }
          span {
            color: white;
          }
        }
      }
    }
  }
}
</style>
