<template>
  <div class="similar-wrap">
    <template v-if="this.isSelectedSimilarPrb === false">
      <div class="empty-content">
        <div>
          <span>오른쪽 문제 목록에서</span>
        </div>
        <div>
          <CustomButton
            :title="'유사문제'"
            :addClass="'color-text'"
            @click="() => {}"
          />
          <span>버튼을 선택해주세요.</span>
        </div>
      </div>
    </template>
    <template v-else>
      <h1>{{ this.prbNum }}번 유사문제</h1>
      <h2>유형명: <strong>{{ this.ptnNm }}</strong></h2>

      <div class="q-list-wrap">
        <div class="q-list-relative-wrap">
          <div class="q-list-inner-wrap">
            <div class="q-list">
              <draggable
                  class="vue-draggable-next"
                  tag="ul"
                  :list="this.filteredSimilarList"
                  v-bind="this.dragOptions"
                  @start="() => this.onStartDragging()"
                  @end="() => this.onEndDragging()"
                  @change="this.onChangeDragging"
              >
                <transition-group type="transition" name="flip-list">
                  <li
                    class="list-group-item list-item"
                    v-for="(trItem, trIndex) in this.filteredSimilarList"
                    :key="trItem[0].order"
                  >
                    <h1>{{ trIndex + 1 }}.</h1>
                    <div class="item-content">
                      <img :src="trItem[0].imgUrl" alt=""/>
                    </div>
                    <div class="item-pannel">
                      <CustomButton
                          :title="'추가'"
                          :addClass="'color-text'"
                          @click="() => this.onAddNewList(trItem)"
                      />
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {VueDraggableNext} from "vue-draggable-next";
import CustomButton from "@/components/CustomButton.vue";

export default {
  name: 'EditStep2SimilarPrbTab',
  components: {
    CustomButton,
    draggable: VueDraggableNext,
  },
  props: {
    similarList: Array,
    resultList: Array,
    isSelectedSimilarPrb: Boolean,
    prbNum: Number,
    ptnNm: String,
    onStartDragging: Function,
    onEndDragging: Function,
    onChangeDragging: Function,
    onAddNewList: Function,
  },
  watch: {
  },
  mounted() {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    filteredSimilarList() {
      return this.similarList.filter(i => {
        return this.resultList.findIndex(ri => ri[0].id == i[0].id) < 0
      }).map(item => Object.assign({}, item))
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";
</style>
