<template>
  <div class="paginate-wrap">
    <div class="paginate-arrow" @click="() => this.onPageChange(1)">
      <div class="img-rel">
        <img src="@/assets/images/pagination-arrow-end-left.png" alt="">
      </div>
    </div>
    <div class="paginate-arrow" @click="() => this.onPageChange(this.page > 1 ? this.page - 1 : 1)">
      <div class="img-rel">
        <img src="@/assets/images/pagination-arrow-left.png" alt="">
      </div>
    </div>

    <div class="paginate-list-wrap">
      <template v-for="(page, index) in this.pageList" :key="index">
        <div :class="['paginate-item', {
            'active': page == this.page,
          }]" @click="() => this.onPageChange(page)">
          {{ page }}
        </div>
      </template>
    </div>

    <div class="paginate-arrow" @click="() => this.onPageChange(this.page < this.totalPage ? this.page + 1 : this.totalPage)">
      <div class="img-rel">
        <img src="@/assets/images/pagination-arrow-right.png" alt="">
      </div>
    </div>
    <div class="paginate-arrow" @click="() => this.onPageChange(this.totalPage)">
      <div class="img-rel">
        <img src="@/assets/images/pagination-arrow-end-right.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: Number,
    totalPage: Number,
    maxVisiblePage: Number,
    onPageChange: Function,
  },
  computed: {
    pageList() {
      const totalPageCnt = this.totalPage;
      const page = this.page;
      const maxVisiblePage = this.maxVisiblePage;

      const pages = [page];
      let addPage = page;
      let subtractPage = page;
      while (pages.length < maxVisiblePage) {
        let stack = 0;

        addPage++;
        if (addPage <= totalPageCnt) {
          pages.push(addPage);
          stack++;
        }

        subtractPage--;
        if (subtractPage >= 1) {
          pages.push(subtractPage);
          stack++;
        }

        if (stack == 0) {
          break;
        }
      }

      pages.sort((a, b) => {
        if (a > b) return 1;
        if (a == b) return 0;
        if (a < b) return -1;
      })

      return pages;
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/values";

.paginate-wrap {
  min-height: 50px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  .paginate-arrow {
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 5px;
    .img-rel {
      width: 100%;
      height: 100%;
      position: relative;
      &>img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .paginate-list-wrap {
    display: flex;
    gap: 10px;
    .paginate-item {
      cursor: pointer;
      display: flex;
      min-width: 25px;
      min-height: 30px;
      align-items: center;
      text-align: center;
      line-height: 1;
      justify-content: center;
      font-weight: 500;
      font-size: 18px;
      &.active {
        font-weight: 700;
        color: #{$defaultColor};
      }
    }
  }
}
</style>
