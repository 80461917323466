<template>
    <select 
        :class="addClass"
        @input="changeValue($event.target.value)"
        >
        <option hidden>{{ hiddenName }}</option>
        <option 
            v-for="(option, index) in this.options" :key="index"
            :value="option.value"
            :selected="option.isSelected"
            >{{ option.name }}</option>
    </select>
</template>

<script>
export default {
    name: 'CustomSelect',
    props: {
        addClass: String,
        hiddenName: String,
        options: Array,
        onChangeValue: Function,
    },
    methods: {
      changeValue(value) {
        if (this.onChangeValue) {
          this.onChangeValue(value);
        }
      },
    },
}
</script>

<style scoped lang="scss">
$defaultColor: #008ED5;

select {
  height: 35px;
  background: #FFFFFF;
  border: 1px solid #{$defaultColor};
  border-radius: 5px;
  padding: 5px 25px 5px 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  appearance: none;
  -webkit-appearance: none;
  background: url(~@/assets/images/mypage-select.png) no-repeat right 10px center;
  background-size: 13px 10px;
  &.w-100px {
    width: 100px;
  }
  &.w-110px {
    width: 110px;
  }
  &.w-130px {
    width: 130px;
  }
}
</style>
