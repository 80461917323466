<template>
  <div :class="['popup', { hide: !isVisible }]">
    <div class="popup-shadow" @click="this.onClose"></div>
    <div :class="['popup-body']">
      <div class="popup-title">
        {{ this.isUpdate ? '학생 상세 정보' : '학생 등록' }}

        <button class="close-btn" @click="this.onClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </button>
      </div>

      <div :class="['body-contents', {
        'is-loading': this.isLoading,
      }]">
        <div class="body-section-title">학생 정보 입력 (* 초기 비밀번호 : 123456)</div>

        <div class="body-tr">
          <div class="body-item">
            <p>이름<b>* 필수입력</b></p>
            <CustomInput
                addClass="default-border"
                :value="name"
                :onChangeValue="(value) => name = value"
                placeholder="이름을 입력해주세요"
            />
          </div>
          <div class="body-item">
            <p>학년<b>* 필수입력</b></p>
            <div class="year-list">
              <CustomButton
                  title="초"
                  :isChecked="schoolType == '초등학교'"
                  @click="() => {schoolType = '초등학교'; schoolYear = '1학년'}"
              />
              <CustomButton
                  title="중"
                  :isChecked="schoolType == '중학교'"
                  @click="() => {schoolType = '중학교'; schoolYear = '1학년'}"
              />
              <CustomButton
                  title="고"
                  :isChecked="schoolType == '고등학교'"
                  @click="() => {schoolType = '고등학교'; schoolYear = '1학년'}"
              />
              <CustomSelect
                  :options="schoolYearOptions"
                  :onChangeValue="(value) => schoolYear = value"
                  :class="[{'visible': schoolType}]"
              />
            </div>
          </div>
        </div>

        <div class="body-tr">
          <div class="body-item">
            <p>연락처<b>* 필수입력</b></p>
            <CustomInput
                addClass="default-border"
                :value="phone"
                :onChangeValue="(value) => phone = value"
                placeholder="숫자만 입력해주세요"
            />
          </div>
          <div class="body-item">
            <p>상태<b>* 필수입력</b></p>
            <div class="include-list">
              <CustomButton
                  title="재원"
                  :isChecked="isInclude == true"
                  @click="() => isInclude = true"
              />
              <CustomButton
                  title="퇴원"
                  :isChecked="isInclude == false"
                  @click="() => isInclude = false"
              />
            </div>
          </div>
        </div>

        <div class="body-tr">
          <div class="body-item">
            <p>이메일<b>* 필수입력</b></p>
            <CustomInput
                addClass="default-border"
                :value="email"
                :onChangeValue="(value) => email = value"
                placeholder="예시 : sduck@sduck.com"
            />
          </div>
          <div class="body-item"></div>
        </div>

        <div class="body-section-title">선택 입력 사항</div>

        <div class="body-tr">
          <div class="body-item">
            <p>생년월일</p>
            <CustomInput
                type="date"
                addClass="default-border"
                :value="birth"
                :onChangeValue="(value) => birth = value"
                placeholder="YYYY.MM.DD"
            />
          </div>
          <div class="body-item">
            <p>학교</p>
            <CustomInput
                addClass="default-border"
                :value="schoolName"
                :onChangeValue="(value) => schoolName = value"
                placeholder="학교명을 입력해주세요"
            />
          </div>
        </div>

        <div class="body-tr">
          <div class="body-item">
            <p>학부모 연락처</p>
            <CustomInput
                addClass="default-border"
                :value="parentPhone"
                :onChangeValue="(value) => parentPhone = value"
                placeholder="숫자만 입력해주세요"
            />
          </div>
          <div class="body-item">
            <p>수업 시작일</p>
            <CustomInput
                type="date"
                addClass="default-border"
                :value="classStartDate"
                :onChangeValue="(value) => classStartDate = value"
                placeholder="YYYY.MM.DD"
            />
          </div>
        </div>

        <div class="body-tr">
          <div class="body-item">
            <p>주소</p>
            <CustomInput
                addClass="default-border"
                :value="address"
                :onChangeValue="(value) => address = value"
                placeholder="전체 주소를 입력해주세요"
            />
          </div>
          <div class="body-item"></div>
        </div>

        <div class="body-tr">
          <div class="body-item item-one">
            <p>비고</p>
            <CustomTextarea
                addClass="default-border"
                :value="etc"
                :onChangeValue="(value) => etc = value"
                placeholder="내용을 입력해주세요"
            />
          </div>
        </div>

        <template v-if="this.isLoading">
          <LoadingBox
              :is-absolute="true"
          />
        </template>
      </div>

      <div class="btn-list">
        <button @click="onSubmit">{{ this.isUpdate ? '수정하기' : '등록하기' }}</button>
      </div>
    </div>
  </div>

  <LoadingDialog
      :is-show="this.isUpdating"
  />
</template>

<script>
import academyService from '../services/academy.service'
import CustomButton from './CustomButton.vue'
import CustomInput from './CustomInput.vue'
import CustomSelect from './CustomSelect.vue'
import CustomTextarea from './CustomTextarea.vue'
import AcademyService from "../services/academy.service";
import LoadingBox from "@/components/LoadingBox.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";

export default {
  name: 'TeacherPopup',
  components: {
    LoadingDialog,
    LoadingBox,
    CustomInput,
    CustomButton,
    CustomSelect,
    CustomTextarea
  },
  props: {
    isVisible: Boolean,
    onClose: Function,
    academyUserId: Number,
    onUpdated: Function,
    onCreated: Function,
  },
  watch: {
    isVisible() {
      this.initData();
    },
  },
  data() {
    return {
      isLoading: true,
      isUpdating: false,

      name: null,
      schoolType: null,
      schoolYear: null,
      phone: null,
      isInclude: true,
      email: null,

      birth: null,
      schoolName: null,
      parentPhone: null,
      classStartDate: null,
      address: null,
      etc: null,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      this.isLoading = true;

      let academyUser;
      if (this.academyUserId) {
        const academyUserId = this.academyUserId;
        academyUser = await AcademyService.getAcademyUser(academyUserId);
      }

      this.name = academyUser?.name ?? null;
      this.schoolType = academyUser?.school_type ?? null;
      this.schoolYear = academyUser?.school_year ?? null;
      this.phone = academyUser?.user?.phone ?? null;
      this.isInclude = academyUser?.is_include ?? true;
      this.email = academyUser?.user?.email ?? null;

      this.birth = academyUser?.birth ?? null;
      this.schoolName = academyUser?.school_name ?? null;
      this.parentPhone = academyUser?.parent_phone ?? null;
      this.classStartDate = academyUser?.class_start_date ?? null;
      this.address = academyUser?.address ?? null;
      this.etc = academyUser?.etc ?? null;

      this.isLoading = false;
    },
    async onSubmit() {
      if (!this.name) { this.$toast.error("이름을 입력해주세요") }
      else if (!this.schoolType || !this.schoolYear) { this.$toast.error("학년을 입력해주세요") }
      else if (!this.phone) { this.$toast.error("연락처를 입력해주세요") }
      else if (!this.email) { this.$toast.error("이메일을 입력해주세요") }
      else {
        let res = null
        const payload = {
          name: this.name,
          school_type: this.schoolType,
          school_year: this.schoolYear,
          is_include: this.isInclude,
          phone: this.phone,
          email: this.email,
          is_student: true,

          birth: this.birth,
          school_name: this.schoolName,
          parent_phone: this.parentPhone,
          class_start_date: this.classStartDate,
          address: this.address,
          etc: this.etc,
        }

        this.isUpdating = true;
        const academyUserId = this.academyUserId;
        if (academyUserId != null) {
          res = await academyService.updateAcademyUser(academyUserId, payload)
        } else {
          res = await academyService.createAcademyUser(payload)
        }
        this.isUpdating = false;

        if (res) {
          this.$toast.success(`${this.isUpdate ? '수정' : '등록'}되었습니다`)
          if (this.isUpdate) {
            this.onUpdated();
          } else {
            this.onCreated();
          }
        } else {
          this.$toast.error(`${this.isUpdate ? '수정' : '등록'} 중 오류가 발생하였습니다`)
        }
      }
    },
  },
  computed: {
    isUpdate() {
      return !!this.academyUserId;
    },
    schoolYearOptions() {
      return Array.apply({}, Array(this.schoolType == '초등학교' ? 6 : 3)).map((_, i) => {
        const value = `${i + 1}학년`
        return {
          value: value,
          name: value,
          isSelected: this.schoolYear == value,
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99;
  &.hide {
    display: none;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    max-width: 750px;
    max-height: 100%;
    margin: auto;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #231f20;
    box-sizing: border-box;
    .popup-title {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-weight: 500;
      display: flex;
      height: 72px;
      align-items: center;
      padding: 0 24px;
      border-bottom: 1px solid rgb(224, 224, 224);
      .close-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        svg {
          width: 100%;
          color: rgb(112, 112, 112);
          path {
            fill: currentcolor;
          }
        }
      }
    }
    .body-contents {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      gap: 24px 0;
      border-bottom: 1px solid rgb(224, 224, 224);
      padding-bottom: 24px;
      overflow-y: auto;
      &.is-loading {
        position: relative;
        &>* {
          &:not(.loading-box) {
            opacity: 0;
          }
        }
      }
      .body-section-title {
        width: 100%;
        height: 32px;
        font-size: 12px;
        line-height: 18px;
        background-color: rgb(250, 250, 250);
        border-top: 1px solid rgb(232, 232, 232);
        color: rgb(112, 112, 112);
        text-align: start;
        align-items: center;
        display: flex;
        padding: 0 24px;
      }
      .body-tr {
        width: 100%;
        display: flex;
        gap: 24px;
        padding: 0 24px;
        .body-item {
          max-width: 50%;
          width: 100%;
          display: flex;
          &.item-one {
            max-width: 100%;
          }
          p {
            display: flex;
            margin-right: 12px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: rgb(51, 51, 51);
            align-items: center;
            width: 100px;
            b {
              font-size: 6px;
              color: rgb(255, 0, 0);
            }
          }
          input, textarea {
            flex: 1;
          }
          textarea {
            min-height: 100px;
          }
          button {
            padding: 9px 12px;
          }
          .year-list {
            flex: 1;
            display: flex;
            gap: 10px;
            select {
              height: 100%;
              flex: 1;
              max-width: 90px;
              opacity: 0;
              &.visible {
                opacity: 1;
              }
            }
          }
          .include-list {
            flex: 1;
            display: flex;
            gap: 10px;
          }
        }
      }
    }
    .btn-list {
      width: 100%;
      display: flex;
      gap: 8px;
      justify-content: flex-end;
      align-items: center;
      padding: 24px;
      &>button {
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
        width: 150px;
        height: 40px;
        border-radius: 4px;
        transition: opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1) 0s;
        background-color: #008ED5;
        color: white;
        font-weight: 500;
        line-height: 1;
      }
    }
  }
}
</style>
