<template>
  <div class="mypage-teacher-wrap">
    <div class="mypage-list-wrap">
      <div class="mypage-tab-body-wrap">
        <template v-if="!this.isInitialized">
          <div class="loading-wrap">
            <b-spinner
                style="width: 3rem; height: 3rem;"
                variant="primary"
                label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template v-else>
          <h1>유형 선택</h1>

          <div class="grid-list-wrap">
            <div class="list-item">
              <h5>학교 유형</h5>
              <SelectBox
                  placeholder="학교 유형을 선택해주세요"
                  :optionList="this.schoolTypeOptions"
                  :value="this.schoolType"
                  :on-change-value="onChangeSchoolType"
              />
            </div>

            <div class="list-item"
                 v-if="this.schoolYearOptions.length > 0"
            >
              <h5>학년</h5>
              <SelectBox
                  placeholder="학년을 선택해주세요"
                  :optionList="this.schoolYearOptions"
                  :value="this.schoolYear"
                  :on-change-value="onChangeSchoolYear"
              />
            </div>

            <div class="list-item"
                 v-if="this.termOptions.length > 0"
            >
              <h5>학기</h5>
              <SelectBox
                  placeholder="학기를 선택해주세요"
                  :optionList="this.termOptions"
                  :value="this.term"
                  :on-change-value="(value) => {this.term = value; this.loadProblemPatterns(0);}"
              />
            </div>

            <div class="list-item"
                 v-if="this.problemSubjectOptions.length > 0"
            >
              <h5>문제 과목</h5>
              <SelectBox
                  placeholder="문제 과목을 선택해주세요"
                  :optionList="this.problemSubjectOptions"
                  :value="this.problemSubject"
                  :on-change-value="(value) => {this.problemSubject = value; this.loadProblemPatterns(0);}"
              />
            </div>

            <template v-if="this.term || this.problemSubject">
              <div v-if="this.term" class="list-item"></div>

              <div class="list-item">
                <h5>대단원 선택</h5>
                <v-select
                    class="select-form"
                    :loading="this.problemPatterns_1 === null"
                    :options="this.problemPatterns_1 ?? []"
                    v-model="this.problemPattern_1"
                    @update:modelValue="() => {this.loadProblemPatterns(1);}"
                >
                  <template v-slot:no-options>해당되는 대단원이 없습니다</template>
                </v-select>
              </div>
            </template>

            <div class="list-item"
                 v-if="this.problemPattern_1"
            >
              <h5>중단원 선택</h5>
              <v-select
                  class="select-form"
                  :loading="this.problemPatterns_2 === null"
                  :options="this.problemPatterns_2 ?? []"
                  v-model="this.problemPattern_2"
                  @update:modelValue="() => {this.loadProblemPatterns(2);}"
              >
                <template v-slot:no-options>해당되는 중단원이 없습니다</template>
              </v-select>
            </div>

            <div class="list-item"
                 v-if="this.problemPattern_2"
            >
              <h5>소단원 선택</h5>
              <v-select
                  class="select-form"
                  :loading="this.problemPatterns_3 === null"
                  :options="this.problemPatterns_3 ?? []"
                  v-model="this.problemPattern_3"
                  @update:modelValue="() => {this.loadProblemPatterns(3);}"
              >
                <template v-slot:no-options>해당되는 소단원이 없습니다</template>
              </v-select>
            </div>

            <div class="list-item"
                 v-if="this.problemPattern_3"
            >
              <h5>유형 선택</h5>
              <v-select
                  class="select-form"
                  :loading="this.problemPatterns_4 === null"
                  :options="this.problemPatterns_4 ?? []"
                  v-model="this.problemPattern_4"
              >
                <template v-slot:no-options>해당되는 유형이 없습니다</template>
              </v-select>
            </div>
          </div>

          <h1>문제 내용 입력</h1>

          <div class="grid-list-wrap">
            <div class="list-item">
              <h5>문제 타입</h5>
              <SelectBox
                  placeholder="문제 타입을 선택해주세요"
                  :optionList="this.typeOptions"
                  :value="this.type"
                  :on-change-value="(value) => this.type = value"
              />
            </div>

            <div class="list-item">
              <h5>문제 풀이형</h5>
              <SelectBox
                  placeholder="문제 풀이형을 선택해주세요"
                  :optionList="this.solutionTypeOptions"
                  :value="this.solutionType"
                  :on-change-value="(value) => this.solutionType = value"
              />
            </div>

            <div class="list-item">
              <h5>난이도</h5>
              <SelectBox
                  placeholder="난이도를 선택해주세요"
                  :optionList="this.difficultyOptions"
                  :value="this.difficulty"
                  :on-change-value="(value) => this.difficulty = value"
              />
            </div>

            <div class="list-item">
              <h5>배점</h5>
              <SelectBox
                  placeholder="배점을 선택해주세요"
                  :optionList="this.scoreOptions"
                  :value="this.score"
                  :on-change-value="(value) => this.score = value"
              />
            </div>

            <div class="list-item">
              <h5>정답률</h5>
              <SelectBox
                  placeholder="정답률을 선택해주세요"
                  :optionList="this.answerRateOptions"
                  :value="this.answerRate"
                  :on-change-value="(value) => this.answerRate = value"
              />
            </div>

            <div class="list-item">
              <h5>정답</h5>
              <CustomInput
                  :value="this.answer"
                  :onChangeValue="(value) => this.answer = value"
              />
            </div>

            <div class="list-item">
              <h5>문제 이미지</h5>
              <div class="file-wrap">
                <FileInput
                    :accept="'image/png'"
                    :onChange="this.onChangeProblemImg"
                />
                <template v-if="this.problemImgPreview || this.problemImgPreviewUploaded">
                  <img :src="this.problemImgPreview ?? this.problemImgPreviewUploaded"
                       alt=""
                       :style="[{
                     width: '100%',
                     maxWidth: `${this.pdfSize.item.imageWidth}px`,
                   }]"
                  />
                </template>
              </div>
            </div>
            <div class="list-item"></div>

            <div class="list-item">
              <h5>문제 해설 이미지</h5>
              <div class="file-wrap">
                <FileInput
                    :accept="'image/png'"
                    :onChange="this.onChangeSolutionImg"
                />
                <template v-if="this.solutionImgPreview || this.solutionImgPreviewUploaded">
                  <img :src="this.solutionImgPreview ?? this.solutionImgPreviewUploaded"
                       alt=""
                       :style="[{
                     width: '100%',
                     maxWidth: `${this.pdfSize.item.imageWidth}px`,
                   }]"
                  />
                </template>
              </div>
            </div>
            <div class="list-item"></div>
          </div>

          <div class="submit-btn">
            <CustomButton
                :title="this.editId ? '문제 수정하기' : '문제 생성하기'"
                :addClass="'checked'"
                @click="this.submit"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
  <LoadingDialog
      :is-show="this.isLoading"
  />
</template>

<style lang="scss" scoped>
.mypage-tab-body-wrap {
  padding: 48px 34px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 142, 213, 0.5);
    border-radius: 3px;
    width: 100%;
  }
  h1 {
    display: flex;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    color: #000000;
    &:not(:first-child) {
      margin-top: 40px;
    }
  }
  .grid-list-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding-top: 20px;
    .list-item {
      width: 100%;
      display: flex;
      align-items: center;
      h5 {
        display: flex;
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        color: #000000;
        border-right: 2px solid #008ED5;
        width: 115px;
        align-self: stretch;
        align-items: center;
        margin-right: 30px;
        word-break: keep-all;
      }
      select {
        flex: 1;
      }
      .file-wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
  .submit-btn {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    button {
      padding: 15px 10px;
      font-weight: 700;
      font-size: 15px;
    }
  }
  .loading-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

<script>
import CustomInput from "@/components/CustomInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import models from "@/models";
import ProblemService from "@/services/problem.service";
import vSelect from "vue-select";
import FileInput from "@/components/FileInput.vue";
import utils from "@/utils";
import {mapGetters} from "vuex";
import CustomButton from "@/components/CustomButton.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";

export default {
  name: "MyProblemEdit",
  components: {LoadingDialog, CustomButton, FileInput, vSelect, SelectBox, CustomInput},
  mounted() {
    this.initData();
  },
  data() {
    return {
      pdfSize: utils.getPdfSize(),

      schoolType: null, // 학교 유형
      schoolYear: null, // 학년
      term: null, // 학기
      problemSubject: null, // 문제 과목

      problemPatterns_1: null, // 대단원 리스트
      problemPatterns_2: null, // 중단원 리스트
      problemPatterns_3: null, // 소단원 리스트
      problemPatterns_4: null, // 유형 리스트
      problemPattern_1: null, // 대단원
      problemPattern_2: null, // 중단원
      problemPattern_3: null, // 소단원
      problemPattern_4: null, // 유형

      type: null, // 문제 타입
      solutionType: null, // 문제 풀이형
      difficulty: null, // 난이도
      problemImg: null, // 문제 이미지
      problemImgPreview: null, // 문제 이미지 미리보기
      problemImgPreviewUploaded: null, // 업로드 된 문제 이미지 미리보기
      score: null, // 배점
      answerRate: null, // 정답률
      answer: null, // 정답
      solutionImg: null, // 문제 해설 이미지
      solutionImgPreview: null, // 문제 해설 이미지 미리보기
      solutionImgPreviewUploaded: null, // 업로드 된 문제 해설 이미지 미리보기

      isInitialized: false,
      isLoading: false,

      editId: null,
    };
  },
  methods: {
    onChangeSchoolType(value) {
      this.schoolType = value;

      this.schoolYear = null;
      this.term = null;
      this.problemSubject = null;
      this.problemPatterns_1 = null;
      this.problemPatterns_2 = null;
      this.problemPatterns_3 = null;
      this.problemPatterns_4 = null;
      this.problemPattern_1 = null;
      this.problemPattern_2 = null;
      this.problemPattern_3 = null;
      this.problemPattern_4 = null;
    },
    onChangeSchoolYear(value) {
      this.schoolYear = value;

      this.term = null;
      this.problemSubject = null;
      this.problemPatterns_1 = null;
      this.problemPatterns_2 = null;
      this.problemPatterns_3 = null;
      this.problemPatterns_4 = null;
      this.problemPattern_1 = null;
      this.problemPattern_2 = null;
      this.problemPattern_3 = null;
      this.problemPattern_4 = null;
    },
    async initData() {
      let { id } = this.$route.query;
      if (id) {
        this.editId = id;

        const res = await ProblemService.getProblemInfo({problemId: this.editId});
        if (res) {
          this.schoolType = res.school_type;
          this.schoolYear = res.school_year;
          this.term = res.term;
          this.problemSubject = res.problem_subject;

          this.problemPatterns_1 = this.parseProblemPatterns(res.problem_patterns_1);
          this.problemPatterns_2 = this.parseProblemPatterns(res.problem_patterns_2);
          this.problemPatterns_3 = this.parseProblemPatterns(res.problem_patterns_3);
          this.problemPatterns_4 = this.parseProblemPatterns(res.problem_patterns_4);
          this.problemPattern_1 = this.parseProblemPattern(res.problem_pattern_1);
          this.problemPattern_2 = this.parseProblemPattern(res.problem_pattern_2);
          this.problemPattern_3 = this.parseProblemPattern(res.problem_pattern_3);
          this.problemPattern_4 = this.parseProblemPattern(res.problem_pattern_4);

          this.type = res.type;
          this.solutionType = res.solution_type;
          this.difficulty = res.difficulty;
          this.problemImgPreviewUploaded = utils.getServerMediaPath(res.problem_img);
          this.score = res.score;
          this.answerRate = res.answer_rate;
          this.answer = res.answer;
          this.solutionImgPreviewUploaded = utils.getServerMediaPath(res.solution_img);
        } else {
          alert('해당 문제를 찾을 수 없습니다');
          utils.back();
          return;
        }
      }

      this.isInitialized = true;
    },
    async submit() {
      const payload = {};

      if (this.editId) {
        payload['id'] = this.editId;
      } else {
        payload['createUserId'] = this.getUser.id;
      }

      // 유형 선택 확인
      if (!this.schoolType) { this.$toast.error('학교 유형을 선택해주세요'); return; }
      if (this.schoolType === '초등학교' || this.schoolType === '중학교') {
        if (!this.schoolYear) { this.$toast.error('학년을 선택해주세요'); return; }
        if (!this.term) { this.$toast.error('학기를 선택해주세요'); return; }
      } else {
        if (!this.problemSubject) { this.$toast.error('문제 과목을 선택해주세요'); return; }
      }
      if (!this.problemPattern_1) { this.$toast.error('대단원을 선택해주세요'); return; }
      if (!this.problemPattern_2) { this.$toast.error('중단원을 선택해주세요'); return; }
      if (!this.problemPattern_3) { this.$toast.error('소단원을 선택해주세요'); return; }
      if (!this.problemPattern_4) { this.$toast.error('유형을 선택해주세요'); return; }
      payload['patternId'] = this.problemPattern_4.id;

      // 문제 내용 입력 확인
      if (!this.type) { this.$toast.error('문제 타입을 선택해주세요'); return; }
      payload['type'] = this.type;
      if (!this.solutionType) { this.$toast.error('문제 풀이형을 선택해주세요'); return; }
      payload['solutionType'] = this.solutionType;
      if (!this.difficulty) { this.$toast.error('난이도를 선택해주세요'); return; }
      payload['difficulty'] = this.difficulty;
      if (!this.score) { this.$toast.error('배점을 선택해주세요'); return; }
      payload['score'] = this.score;
      if (!this.answerRate) { this.$toast.error('정답률을 선택해주세요'); return; }
      payload['answerRate'] = this.answerRate;
      if (!this.answer) { this.$toast.error('정답을 입력해주세요'); return; }
      payload['answer'] = this.answer;
      if (!this.problemImg && !this.editId) { this.$toast.error('문제 이미지를 등록해주세요'); return; }
      if (this.problemImg) {
        payload['problemImg'] = this.problemImg;
      }
      if (!this.solutionImg && !this.editId) { this.$toast.error('문제 해설 이미지를 등록해주세요'); return; }
      if (this.solutionImg) {
        payload['solutionImg'] = this.solutionImg;
      }

      // 문제 생성/업데이트
      this.isLoading = true;
      let isSuccess;
      if (!this.editId) {
        isSuccess = await ProblemService.createMyProblem(payload);
      } else {
        isSuccess = await ProblemService.updateMyProblem(payload);
      }

      this.isLoading = false;
      if (isSuccess) {
        this.$toast.success(this.editId ? '문제를 수정하였습니다' : '문제를 생성하였습니다');
        this.$router.push({ name: utils.isV2Url(this) ? 'TeacherMyProblem' : 'MyProblemList' });
      } else {
        this.$toast.error(this.editId ? '문제를 수정 중 오류가 발생하였습니다' : '문제를 생성 중 오류가 발생하였습니다');
      }
    },
    async onChangeProblemImg(file) {
      this.problemImg = file;
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.problemImgPreview = e.target.result;
        }
        reader.readAsDataURL(file)
      }
    },
    async onChangeSolutionImg(file) {
      this.solutionImg = file;
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.solutionImgPreview = e.target.result;
        }
        reader.readAsDataURL(file)
      }
    },
    async loadProblemPatterns(index) {
      let upperPatternId;
      if (index <= 0) {
        this.problemPatterns_1 = null;
        this.problemPattern_1 = null;
      }
      if (index <= 1) {
        this.problemPatterns_2 = null;
        this.problemPattern_2 = null;
      }
      if (index <= 2) {
        this.problemPatterns_3 = null;
        this.problemPattern_3 = null;
      }
      if (index <= 3) {
        this.problemPatterns_4 = null;
        this.problemPattern_4 = null;
      }

      if (index === 0) {
        upperPatternId = null;
      } else if (index === 1) {
        upperPatternId = this.problemPattern_1?.id;
      } else if (index === 2) {
        upperPatternId = this.problemPattern_2?.id;
      } else if (index === 3) {
        upperPatternId = this.problemPattern_3?.id;
      } else {
        return;
      }

      const schoolType = this.schoolType;
      const schoolYear = this.schoolYear;
      const term = this.term;
      const problemSubject = this.problemSubject;
      if (!schoolType) {
        return;
      } else if ((!schoolYear || !term) && !problemSubject) {
        return;
      }

      let problemPatterns;
      if (index === 0) {
        problemPatterns = await ProblemService.getProblemPatternList({
          schoolType: schoolType,
          schoolYear: schoolYear,
          term: term,
          problemSubject: problemSubject,
        });
      } else {
        if (!upperPatternId) {
          return;
        }

        problemPatterns = await ProblemService.getProblemPatternList({
          upperPatternId: upperPatternId,
        });
      }

      problemPatterns = this.parseProblemPatterns(problemPatterns);
      if (index === 0) {
        this.problemPatterns_1 = problemPatterns;
      } else if (index === 1) {
        this.problemPatterns_2 = problemPatterns;
      } else if (index === 2) {
        this.problemPatterns_3 = problemPatterns;
      } else if (index === 3) {
        this.problemPatterns_4 = problemPatterns;
      }
    },
    parseProblemPattern(problemPattern) {
      return {
        'id': problemPattern.id,
        'label': problemPattern.name,
      };
    },
    parseProblemPatterns(problemPatterns) {
      return problemPatterns.map(problemPattern => {
        return this.parseProblemPattern(problemPattern);
      });
    },
  },
  computed: {
    schoolTypeOptions() {
      return models.schoolTypeList().map(i => {
        return {'value': i, 'name': i};
      });
    },
    schoolYearOptions() {
      if (this.schoolType === '초등학교') {
        return Array.apply({}, Array(6)).map((_, i) => {
          const value = i + 1;
          return {'value': value, 'name': `${value}학년`};
        });
      } else if (this.schoolType === '중학교') {
        return Array.apply({}, Array(3)).map((_, i) => {
          const value = i + 1;
          return {'value': value, 'name': `${value}학년`};
        });
      }

      return [];
    },
    termOptions() {
      if (this.schoolYear) {
        return Array.apply({}, Array(2)).map((_, i) => {
          return {'value': i + 1, 'name': `${i + 1}학기`};
        });
      }

      return [];
    },
    problemSubjectOptions() {
      if (this.schoolType === '고등학교') {
        return models.subjectList().map(i => {return {'value': i, 'name': i}});
      }

      return [];
    },
    typeOptions() {
      return models.problemTypeList(false).map(i => {return {'value': i, 'name': i}});
    },
    solutionTypeOptions() {
      return models.problemSolutionType(false).map(i => {return {'value': i, 'name': i}});
    },
    difficultyOptions() {
      return models.difficultyList(false).map(i => {return {'value': i, 'name': i}});
    },
    scoreOptions() {
      return Array.apply({}, Array(5)).map((_, i) => {
        return {'value': i + 1, 'name': `${i + 1}점`};
      });
    },
    answerRateOptions() {
      return Array.apply({}, Array(100)).map((_, i) => {
        return {'value': i + 1, 'name': `${i + 1}%`};
      });
    },
    ...mapGetters('auth', ['getUser'])
  },
}
</script>
