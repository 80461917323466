<template>
  <div class="worksheet-state-wrap">
    <template
        v-for="(item, index) in this.stateData.items" :key="index"
    >
      <div class="state-items">
        <div class="title-wrap">
          <h5>{{ item.title }}</h5>
          <p>{{ item.subTitle ?? '' }}</p>
        </div>
        <div :class="['btn-grid-list', item.listClass]">
          <template
              v-if="item.type == 'button'"
          >
            <CustomButton
                v-for="(cnt, index) in item.list" :key="index"
                :title="`${cnt}`"
                :isChecked="this.isCheckedCustomButton(item, cnt)"
                @click="() => item.onChange(item.id, cnt)"
            />
          </template>
        </div>
        <div
            v-if="item.slider?.isVisible"
            class="slider-wrap"
        >
          <CustomButton
              title="- 10"
              @click="() => onClickSliderButton(false, item)"
          />
          <div class="slider-inner-wrap">
            <slider
                :modelValue="this.utils.stringToInt(item.value)"
                :width="'100%'"
                :height="6"
                :handleScale="2.5"
                :min="item.slider.minValue ?? this.defaultMinValue"
                :max="item.slider.maxValue ?? this.defaultMaxValue"
                :step="1"
                :alwaysShowHandle="true"
                :color="'#008ED5'"
                :trackColor="'#80C6EA'"
                :tooltip="true"
                :tooltipColor="'#000000'"
                :sticky="true"
                :flip="false"
                @change="(value) => item.onChange(item.id, value)"
            />
            <div class="slider-values">
              <p>{{ item.slider.minValue ?? this.defaultMinValue }}</p>
              <p>{{ item.slider.maxValue ?? this.defaultMaxValue }}</p>
            </div>
          </div>
          <CustomButton
              title="+ 10"
              @click="() => onClickSliderButton(true, item)"
          />
        </div>
      </div>
    </template>

    <div class="res-wrap">
      <template
          v-for="(item, index) in this.stateData.resItems" :key="index"
      >
        <div
            v-if="item.type == 'text'"
            class="res-item"
        >
          <p>{{ item.title }}</p>
          <p>{{ item.value }}</p>
        </div>
      </template>
    </div>

    <CustomButton
        v-if="this.stateData.nextBtn"
        :title="this.stateData.nextBtn.title"
        :addClass="'next-btn checked'"
        @click="this.stateData.nextBtn.onClick"
    />
  </div>
</template>

<script>
import utils from '../utils'
import CustomButton from "@/components/CustomButton.vue";

export default {
  name: "State",
  components: {CustomButton},
  props: {
    stateData: Object,
  },
  methods: {
    isCheckedCustomButton(item, cnt) {
      if (!item.value) {
        return false
      }

      const valueType = typeof(item.value)
      if (valueType == 'string' || valueType == 'number') {
        return item.value == cnt
      } else {
        return item.value.includes(cnt)
      }
    },
    onClickSliderButton(isAdd, item) {
      const curValue = parseInt(item.value);
      let nextValue = isAdd ? curValue + 10 : curValue - 10;

      const minValue = item.slider.minValue ?? this.defaultMinValue;
      const maxValue = item.slider.maxValue ?? this.defaultMaxValue;

      if (nextValue > maxValue) nextValue = maxValue;
      else if (nextValue < minValue) nextValue = minValue;

      item.onChange(item.id, nextValue);
    },
  },
  data() {
    return {
      utils,
      defaultMinValue: 0,
      defaultMaxValue: 100,
    }
  },
}
</script>

<style lang="scss" scoped>
.worksheet-state-wrap {
  width: 350px;
  background: #FFFFFF;
  border: 1px solid #008ED5;
  border-radius: 10px;
  padding: 41px 25px 60px;
  display: flex;
  flex-direction: column;
  gap: 37px;
  align-items: center;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 142, 213, 0.5);
    border-radius: 3px;
    width: 100%;
  }
  .state-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &:first-child {
      margin-top: 0;
    }
    .title-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 5px;
      h5 {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
      }
      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
      }
    }
    .btn-grid-list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 15px;
      &>* {
        width: 100%;
      }
      &.grid-3 {
        grid-template-columns: repeat(3, 1fr);
      }
      &.grid-5 {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    .slider-wrap {
      width: 100%;
      display: flex;
      gap: 12px;
      &>button {
        padding: 5px 7px;
        font-size: 11px;
        line-height: 1.2;
      }
      .slider-inner-wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        .slider-values {
          width: 100%;
          display: flex;
          gap: 10px;
          justify-content: space-between;
          margin-top: 2px;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          text-align: center;
          color: #000000;
        }
      }
    }
  }
  .res-wrap {
    width: 100%;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #008ED5;
    .res-item {
      width: 100%;
      display: flex;
      gap: 5px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      p {
        font-weight: 700;
        font-size: 17px;
        line-height: 19px;
        color: #000000;
      }
    }
  }
  .next-btn {
    padding: 10px 32px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
    margin-left: auto;
    margin-top: -20px;
  }
}
</style>
