<template>
  <div class="mypage-tab-body-wrap">
    <Filter :filterData="this.filterData">
      <template v-slot:innerLayout>
        <template v-if="this.filterSelectBoxItems.length > 0">
          <div class="bw-ft-dv"></div>
          <div class="bw-ft-slt-lst">
            <div v-for="(filterSelectBoxItem, index) in this.filterSelectBoxItems" :key="index"
                 class="lst-item"
                 @click="() => this.onClickSelectBox(index)">
              <p>{{ filterSelectBoxItem.title }}</p>
              <span>{{ `(${filterSelectBoxItem.lowerItemCount})` }}</span>
              <img src="@/assets/images/close-3.png"
                   alt=""
                   onclick="event.stopPropagation()"
                   @click="() => this.onClickDeleteSelectBox(index)">
            </div>
          </div>
        </template>
      </template>
    </Filter>

    <TreeList
      :nodes="this.nodes"
    />
  </div>

  <State
    :stateData="this.stateData"
  />

  <Teleport to="body">
    <LoadingDialog
      :is-show="this.isShowLoadingDialog"
    />
  </Teleport>
</template>

<script>
import ProblemService from '@/services/problem.service'
import { debounce } from 'lodash'
import models from '@/models'
import Filter from "@/components/Filter.vue";
import TreeList from "@/components/TreeList.vue";
import State from "@/components/state.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";

export default {
  name: 'EditStep1Unit',
  components: {
    LoadingDialog,
    Filter,
    TreeList,
    State,
  },
  props: {
    isWorkbook: Boolean,
    onChangeProblems: Function,
    onChangeSearchProblems: Function,
    limitProblemCnt: { type: Number, default: null },
  },
  watch: {
    problems() {
      this.onChangeProblems(this.problems)
    },
    searchProblems() {
      this.onChangeSearchProblems(this.searchProblems)
    },
    limitProblemCnt() {
      const limitProblemCnt = this.limitProblemCnt;
      if (limitProblemCnt != null) {
        this.selectProblemCount = `${limitProblemCnt}`;
      }
    },
  },
  data() {
    return {
      filterData: {
        title: this.isWorkbook ? '교재 선택' : '학습지 선택',
        list: [
          {
            type: 'select',
            addClass: 'w-105px',
            name: '선택',
            options: ['중학교', '고등학교'].map(i => {return {'value': i, 'name': i}}),
            onChange: this.onChangeUnitDataSchoolType,
          },
        ],
        searchInput: {isHidden: true},
        isUnFlexList: true,
      },
      problemCounts: models.problemCountList(true),
      selectProblemCount: '250',
      maxProblemSlider: 100,
      types: models.problemTypeList(),
      selectType: ['전체'],
      solutionTypes: models.problemSolutionType(),
      selectSolutionTypes: [],
      difficulties: models.difficultyList(),
      selectDifficulty: [],
      searchProblems: [],
      isShowLoadingDialog: false,
      nodes: {},
      selectBoxes: [],
    };
  },
  mounted() {
    if (!this.isWorkbook) {
      this.selectProblemCount = '25'
      this.problemCounts = models.problemCountList()
    }

    this.maxProblemSlider = parseInt(this.problemCounts[this.problemCounts.length - 1])
  },
  methods: {
    async onClickSelectBox(index) {
      const selectBoxes = this.selectBoxes;
      if (selectBoxes.length <= index) return;

      const filterData = this.filterData;
      const selectBox = selectBoxes[index];
      const schoolType = selectBox.schoolType;
      filterData.list[0].options.forEach(i => i.isSelected = i.name == schoolType);
      await this.onChangeUnitDataSchoolType(schoolType);

      const secondOption = selectBox.secondOption;
      filterData.list[2].options.forEach(i => i.isSelected = i.name == secondOption);
      await this.reloadUnitDataNodes();
    },
    async onClickDeleteSelectBox(index) {
      const selectBoxes = this.selectBoxes;
      if (selectBoxes.length <= index) return;

      const selectBox = selectBoxes[index];
      const filterList = this.filterData.list;
      const schoolType = filterList[0].options.find(s => s.isSelected)?.name;
      const secondOption = filterList[2].options.find(s => s.isSelected)?.name;
      if (selectBox.schoolType == schoolType && selectBox.secondOption == secondOption) {
        const nodes = this.nodes;
        Object.keys(nodes).forEach(key => {
          const node = nodes[key];
          if (Object.keys(node.state).includes('checked')) {
            node.state.checked = false;
          }
          if (Object.keys(node.state).includes('indeterminate')) {
            node.state.indeterminate = false;
          }
        });
      }

      this.selectBoxes.splice(index, 1);
      await this.reloadUnitProblems(this);
    },
    onSliderOverMax: debounce(async (appContext, limitProblemCnt) => {
      appContext.$toast.error(`문항 수 ${limitProblemCnt}개까지 제한된 계정입니다`);
      appContext.selectProblemCount = 0;
      appContext.selectProblemCount = limitProblemCnt;
    }, 200),
    async reloadUnitProblems() {
      this.searchProblems = [];
      const checkedPatternIds = this.checkedPatternIds;
      this.isShowLoadingDialog = true;

      if (checkedPatternIds.length > 0) {
        try {
          const res = await ProblemService.getProblems({
            'pattern_id_list': checkedPatternIds,
            'is_order_by_random': true,
            'include_my_problems': true,
          });
          if (res) {
            this.searchProblems = res;
            this.isShowLoadingDialog = false;
            return;
          }
        } catch (e) {
          console.error(e);
          this.$toast.error('문제 리스트를 불러오는 중 오류가 발생하였습니다');
        }
      }

      this.searchProblems = [];
      this.isShowLoadingDialog = false;
    },
    onChangeUnitDataSecondSelect(value) {
      this.filterData.list[2].options.forEach(i => i.isSelected = i.value == value)

      this.reloadUnitDataNodes()
    },
    async onChangeUnitDataSchoolType(value) {
      const filterList = this.filterData.list
      const firstOptions = filterList[0].options
      firstOptions.forEach(c => c.isSelected = false)
      const schoolType = firstOptions.find(s => s.value == value)
      if (schoolType) {
        schoolType.isSelected = true
      }

      let secondOptions = []
      switch (schoolType.value) {
        case '중학교':
          secondOptions = Array.apply({}, Array(3 * 2)).map((_, index) => {
            const value = `${Math.floor(index / 2 + 1)}-${index % 2 + 1}`
            return {'value': value, 'name': value, 'isSelected': false}
          })
          break
        case '고등학교':
          secondOptions = models.subjectList().map((i, index) => {
            return {'value': index, 'name': i, 'isSelected': false}
          })
          break
      }
      const secondSelect = {
        type: 'select',
        addClass: 'w-105px',
        name: '선택',
        options: secondOptions,
        onChange: this.onChangeUnitDataSecondSelect,
      }

      if (filterList.length == 1) {
        filterList.push({type: 'select-arrow'})
        filterList.push(secondSelect)
      } else {
        filterList[2] = secondSelect
      }

      await this.reloadUnitDataNodes()
    },
    onChangeUnitDataSelectNode() {
      setTimeout(() => {
        const selectBoxes = this.selectBoxes;

        const filterList = this.filterData.list;
        const schoolType = filterList[0].options.find(s => s.isSelected)?.name;
        if (!schoolType) return;

        const secondOption = filterList[2].options.find(s => s.isSelected)?.name;
        if (!secondOption) return;

        let selectBoxIndex = selectBoxes.findIndex(e => e.schoolType == schoolType && e.secondOption == secondOption);
        if (selectBoxIndex < 0) {
          selectBoxes.push({
            'schoolType': schoolType,
            'secondOption': secondOption,
            'items': [],
          });
          selectBoxIndex = selectBoxes.length - 1;
        }

        const nodes = this.nodes;
        const selectItems = selectBoxes[selectBoxIndex].items;

        Object.entries(nodes).forEach(entry => {
          const value = entry[1];
          const patternId = value.obj.id;
          const isChecked = value.state?.checked ?? false;
          const isIndeterminate = value.state?.indeterminate ?? false;

          const foundIndex = selectItems.findIndex(k => k.patternId == patternId);
          const storeValue = {
            'state': Object.assign({}, value.state ?? {}),
            'patternId': patternId,
            'isLower': (value.children?.length ?? 0) == 0,
          };
          if (isChecked || isIndeterminate) {
            if (foundIndex >= 0) {
              selectItems[foundIndex] = storeValue;
            } else {
              selectItems.push(storeValue);
            }
          } else {
            if (foundIndex >= 0) {
              selectItems.splice(foundIndex, 1);
            }
          }
        });

        this.selectBoxes = selectBoxes.filter(e => {
          return e.items.length > 0;
        });

        this.reloadUnitProblems(this);
      }, 0);
    },
    async reloadUnitDataNodes() {
      const filterList = this.filterData.list;
      this.nodes = {};

      const schoolType = filterList[0].options.find(s => s.isSelected);
      if (!schoolType) return;
      const isHighSchool = schoolType.value == '고등학교';

      const secondOption = filterList[2].options.find(s => s.isSelected);
      if (!secondOption) return;

      let res = await ProblemService.getProblemPatterns({
        'school_type': schoolType?.value,
        'school_year': isHighSchool ? null : secondOption.value.split('-')[0],
        'term': isHighSchool ? null : secondOption.value.split('-')[1],
        'problem_subject': isHighSchool ? secondOption.name : null,
        'is_node_list': true,
      });
      if (res) {
        const selectItems = [...this.selectBoxes.map(e => e.items).flatMap(x => x)];

        Object.entries(res).forEach(entry => {
          const value = entry[1];
          const patternId = value.obj.id;

          value.onChecked = this.onChangeUnitDataSelectNode;
          value.onUnchecked = this.onChangeUnitDataSelectNode;
          value.state = Object.assign({}, selectItems.find(e => e.patternId == patternId)?.state ?? {});
        });
        this.nodes = res;
      }
    },
  },
  computed: {
    filterSelectBoxItems() {
      return this.selectBoxes.map(e => {
        return {
          'title': `${e.schoolType} - ${e.secondOption}`,
          'lowerItemCount': e.items.filter(i => i.isLower).length,
        };
      });
    },
    checkedPatternIds() {
      return [...new Set(this.selectBoxes.map(e => e.items)
          .flatMap(x => x)
          .filter(e => e.state.checked && e.isLower)
          .map(e => e.patternId))];
    },
    stateData() {
      return {
        items: [
          {
            id: 0,
            type: 'button',
            title: '문항 수',
            subTitle: `${this.selectProblemCount}`,
            list: this.problemCounts,
            value: this.selectProblemCount,
            slider: {
              isVisible: true,
              minValue: 0,
              maxValue: this.maxProblemSlider,
            },
            onChange: (id, value) => {
              if (this.selectProblemCount != value) {
                const limitProblemCnt = this.limitProblemCnt;
                if (limitProblemCnt != null && value > limitProblemCnt) {
                  this.onSliderOverMax(this, limitProblemCnt);
                  return;
                }

                this.selectProblemCount = value
              }
            },
          },
          {
            id: 1,
            type: 'button',
            title: '문제 타입',
            list: this.types,
            listClass: 'grid-3',
            value: this.selectType,
            onChange: (id, value) => {
              if (value == '전체') {
                this.selectType = ['전체']
              } else {
                const allItemIdx = this.selectType.findIndex(i => i == '전체')
                if (allItemIdx > -1) {
                  this.selectType.splice(allItemIdx, 1)
                }

                const targetItemIdx = this.selectType.findIndex(i => i == value)
                if (targetItemIdx > -1) {
                  if (this.selectType.length == 1) {
                    this.selectType = ['전체']
                  } else {
                    this.selectType.splice(targetItemIdx, 1)
                  }
                } else {
                  this.selectType.push(value)
                }
              }
            },
          },
          {
            id: 2,
            type: 'button',
            title: '풀이형',
            list: this.solutionTypes,
            listClass: 'grid-3',
            value: this.selectSolutionTypes,
            onChange: (id, value) => {
              const targetItemIdx = this.selectSolutionTypes.findIndex(i => i == value)
              if (targetItemIdx > -1) {
                this.selectSolutionTypes.splice(targetItemIdx, 1)
              } else {
                this.selectSolutionTypes.push(value)
              }
            },
          },
          {
            id: 3,
            type: 'button',
            title: '난이도',
            list: this.difficulties,
            listClass: 'grid-5',
            value: this.selectDifficulty,
            onChange: (id, value) => {
              const targetItemIdx = this.selectDifficulty.findIndex(i => i == value)
              if (targetItemIdx > -1) {
                this.selectDifficulty.splice(targetItemIdx, 1)
              } else {
                this.selectDifficulty.push(value)
              }
            },
          },
        ],
        resItems: [
          {type: 'text', title: '문제수', value: `${Number(this.problems.length)}개`},
          {type: 'text', title: '적용된 유형수', value: `${Number(this.applyProblemPatterns.length)}개`},
        ],
      }
    },
    applyProblemPatterns() {
      return this.problems.reduce(
          (acc, cur) => {
            if (!acc.includes(cur.pattern_name)) {
              acc.push(cur.pattern_name)
            }
            return acc
          },
          []
      )
    },
    problems() {
      let problems = this.searchProblems.filter(i => {
        let result = true

        const types = this.selectType.filter(i => i != '전체')
        if (result && types.length > 0) {
          result = types.includes(i.type)
        }

        if (result && this.selectSolutionTypes.length > 0) {
          result = this.selectSolutionTypes.includes(i.solution_type)
        }

        if (result && this.selectDifficulty.length > 0) {
          result = this.selectDifficulty.includes(i.difficulty)
        }

        return result
      }).map(i => Object.assign({}, i))

      let limitCount = this.selectProblemCount
      if (limitCount !== null) {
        limitCount = parseInt(limitCount)
        if (problems.length > limitCount) {
          problems.splice(limitCount, problems.length)
        }
      }

      return problems
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.mypage-tab-body-wrap {
  .bw-ft-dv {
    width: 1px;
    height: 35px;
    margin-left: 25px;
    background-color: #{$defaultColor};
  }
  .bw-ft-slt-lst {
    flex: 1;
    display: flex;
    overflow: auto;
    gap: 5px;
    padding: 5px 25px;
    &>.lst-item {
      display: flex;
      cursor: pointer;
      background-color: #FFFFFF;
      padding: 5px 7px;
      border-radius: 5px;
      gap: 3px;
      align-items: center;
      white-space: nowrap;
      &>p, &>span {
        font-size: 13px;
      }
      &>img {
        margin-left: 5px;
        width: 20px;
        object-fit: contain;
        padding: 4px;
      }
    }
  }
}
</style>
