export class ClassStoreItem {
    /** @type {number} 선택한 반 ID */
    academyClassId;

    constructor(
        academyClassId,
    ) {
        this.academyClassId = academyClassId;
    }
}
