import api from "./api"

class ProblemService {
  getProblems(params) {
    return api.get('/view_set/problem/', { params })
  }

  getProblemPatterns(params) {
    return api.get('/view_set/problem_pattern/', { params })
  }

  getMocks(params) {
    return api.get('/view_set/mock/', { params })
  }

  getWorkbookProblems(params) {
    return api.get('/problem/get_workbook_problems/', { params })
  }

  async createProblemReport(problemId, category, contents) {
    try {
      return await api.post('/problem/create_report_problem/', {
        'problem_id': problemId,
        'category': category,
        'contents': contents,
        'is_check_ext': true,
      }, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    } catch (e) {
      console.log(e);
      return null
    }
  }

  async createProblemSuggest(problemId, contents) {
    try {
      return await api.post('/problem/create_problem_suggest/', {
        'problem_id': problemId,
        'contents': contents,
      }, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    } catch (e) {
      console.log(e);
      return null
    }
  }

  createWrong(studentWorkId) {
    return api.get(`/problem/create_wrong/${studentWorkId}/`, {});
  }

  getMockUnitFilterList({
      resultType = 0, // 결과 타입 (0: 학년 리스트, 1: 년도 리스트, 2: 월 리스트)
      grade = null, // 학년
      year = null, // 년도
      month = null, // 월
  }) {
    let params = {
      'result_type': resultType,
    };
    if (grade) {
      params['grade'] = grade;
    }
    if (year) {
      params['year'] = year;
    }
    if (month) {
      params['month'] = month;
    }
    return api.get('/problem/mock_unit/filter_list', {params})
  }

  getMockUnitProblems({
    grade = null, // 학년
    year = null, // 년도
    month = null, // 월
  }) {
    let params = {
      'grade': grade,
      'year': year,
      'month': month,
    };
    return api.get('/problem/mock_unit/problems', {params})
  }

  getProblemPatternList({
    schoolType = null, // 학교 유형
    schoolYear = null, // 학년
    term = null, // 학기
    problemSubject = null, // 문제 과목
    upperPatternId = null, // 상위 유형 ID
  }) {
    let params = {
      'school_type': schoolType,
      'school_year': schoolYear,
      'term': term,
      'problem_subject': problemSubject,
      'upper_pattern_id': upperPatternId,
    };
    return api.get('/problem/problem_patterns', {params})
  }

  async createMyProblem({
    patternId, // 유형 ID
    type, // 문제 타입
    solutionType, // 문제 풀이형
    difficulty, // 난이도
    problemImg, // 문제 이미지 파일
    score, // 배점
    answerRate, // 정답률
    answer, // 정답
    solutionImg, // 문제 해설 이미지 파일
    createUserId, // 생성한 사용자 ID
  }) {
    const payload = {
      'pattern': patternId,
      'type': type,
      'solution_type': solutionType,
      'difficulty': difficulty,
      'problem_img': problemImg,
      'score': score,
      'answer_rate': answerRate,
      'answer': answer,
      'solution_img': solutionImg,
      'create_user': createUserId,
    };

    let res;
    try {
      res = await api.post('/view_set/problem/', payload, {
        headers: {"Content-Type": "multipart/form-data"},
      });
    } catch (e) {
      console.log(e);
    }

    return !!res;
  }

  async updateMyProblem({
    id, // 업데이트 할 문제 ID
    patternId = null, // 유형 ID
    type = null, // 문제 타입
    solutionType = null, // 문제 풀이형
    difficulty = null, // 난이도
    problemImg = null, // 문제 이미지 파일
    score = null, // 배점
    answerRate = null, // 정답률
    answer = null, // 정답
    solutionImg = null, // 문제 해설 이미지 파일
    createUserId = null, // 생성한 사용자 ID
  }) {
    const payload = {};
    if (patternId) { payload['pattern_id'] = patternId; }
    if (type) { payload['type'] = type; }
    if (solutionType) { payload['solution_type'] = solutionType; }
    if (difficulty) { payload['difficulty'] = difficulty; }
    if (problemImg) { payload['problem_img'] = problemImg; }
    if (score) { payload['score'] = score; }
    if (answerRate) { payload['answer_rate'] = answerRate; }
    if (answer) { payload['answer'] = answer; }
    if (solutionImg) { payload['solution_img'] = solutionImg; }
    if (createUserId) { payload['create_user_id'] = createUserId; }

    let res;
    try {
      res = await api.patch(`/view_set/problem/${id}/`, payload, {
        headers: {"Content-Type": "multipart/form-data"},
      });
    } catch (e) {
      console.log(e);
    }

    return !!res;
  }

  getMyProblems({
    userId, // 사용자 ID
  }) {
    let params = {
      'user_id': userId,
    };
    return api.get('/problem/my_problems', {params});
  }

  getProblemInfo({
    problemId, // 사용자 ID
  }) {
    return api.get(`/problem/my_problem/${problemId}`, {});
  }

  getNewProblems(
    problemIds,
    patternIds,
  ) {
    const params = {
      'problem_ids': problemIds,
      'pattern_ids': patternIds,
    };
    return api.get('/problem/new_problems', { params })
  }

  getWorksheetTabMyProblems() {
    return api.get('/problem/worksheet_tab_my_problems', {})
  }
}

export default new ProblemService()
