<template>
  <div class="student-dashboard-wrap">
    <V2Wrapper>
      <div class="sdw-inner">
        <div class="v2-base-container">
          <div class="section-1-wrap">
            <div class="sec-box-wrap">
              <h1>학습 현황</h1>
              <YearlyLearnPattern/>
            </div>
          </div>
          <div class="section-2-wrap">
            <div class="sec-left-wrap">
              <div class="sl-top-box-wrap">
                <h1>학습 참여도</h1>
                <div class="slt-chart-list-wrap">
                  <div class="cl-item">
                    <h5>학습 이수율</h5>
                    <div class="i-chart-wrap">
                      <DoughnutChart
                          :chartData="this.studyCompletionRateChartData"
                          :options="this.studyCompletionRateChartOption"
                          :style="[{'aspect-ratio': '1'}]"
                      />
                      <span>45%</span>
                    </div>
                    <p>반 상위 85%</p>
                  </div>
                  <div class="cl-item">
                    <h5>과제 제출</h5>
                    <div class="i-chart-wrap">
                      <DoughnutChart
                          :chartData="this.homeworkSubmitChartData"
                          :options="this.homeworkSubmitChartOption"
                          :style="[{'aspect-ratio': '1'}]"
                      />
                      <span>75%</span>
                    </div>
                    <p>반 상위 85%</p>
                  </div>
                  <div class="cl-item">
                    <h5>문제 풀이</h5>
                    <div class="i-chart-wrap">
                      <DoughnutChart
                          :chartData="this.problemSolvingChartData"
                          :options="this.problemSolvingChartOption"
                          :style="[{'aspect-ratio': '1'}]"
                      />
                      <span>75%</span>
                    </div>
                    <p>반 상위 85%</p>
                  </div>
                </div>
              </div>
              <div class="sl-bottom-box-wrap">
                <div class="slb-tab-list-wrap">
                  <template v-for="(tabItem, index) in this.studyStateTabItems" :key="index">
                    <div :class="['tl-item', {'active': index === this.selectStudyTabIndex}]"
                         @click="() => this.selectStudyTabIndex = index"
                    >
                      <div class="tli-title-wrap">
                        <p>{{ tabItem.title }}</p>
                        <div v-if="tabItem.newCnt > 0"
                             class="t-new-box"
                        >
                          <span>{{ tabItem.newCnt }}</span>
                        </div>
                      </div>
                      <div class="tli-border"></div>
                    </div>
                  </template>
                </div>
                <div class="slb-sub-tab-wrap">
                  <div class="st-item">
                    <p>학습지 : 1/2</p>
                  </div>
                  <div class="st-item">
                    <p>진단평가 : 1/2</p>
                  </div>
                  <div class="st-item">
                    <p>단원평가 : 1/2</p>
                  </div>
                </div>
                <div class="slb-list-wrap">
                  <template v-for="(data, index) in this.studyStateDatas" :key="index">
                    <div class="bl-item">
                      <div class="i-t-wrap">
                        <p>{{ data.type }}</p>
                        <div class="it-r-wrap">
                          <p>{{ data.deadline }}</p>
                          <p>{{ data.state }}</p>
                        </div>
                      </div>
                      <div class="i-b-wrap">
                        <p>{{ data.title }}</p>
                        <span>{{ data.studyCnt }}/{{ data.cnt }}</span>
                      </div>
                      <div class="i-divider"></div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="sec-right-wrap">
              <div class="sr-t-box-wrap">
                <div class="tb-tab-wrap">
                  <div class="t-item">
                    <img src="@/assets/images/diagnosis.png" alt="">
                    <p>진단 결과</p>
                  </div>
                  <div class="t-item">
                    <img src="@/assets/images/statistics.png" alt="">
                    <p>학급 통계</p>
                  </div>
                  <div class="t-item">
                    <img src="@/assets/images/note.png" alt="">
                    <p>오답노트</p>
                  </div>
                </div>
                <div class="tb-divider"></div>
                <div class="tb-report-wrap">
                  <h1>학습 리포트</h1>
                  <h2>‘강기복’ 학생의 학습 현황</h2>
                  <div class="lr-list-wrap">
                    <div class="list-item">
                      <div class="item-pin"></div>
                      <p>학습 이수율 : 79%</p>
                    </div>
                    <div class="list-item">
                      <div class="item-pin"></div>
                      <p>과제 제출 현황 : 10/10</p>
                    </div>
                    <div class="list-item">
                      <div class="item-pin"></div>
                      <p>학습 시간 : 200분 (3시간 20분)%</p>
                    </div>
                    <div class="list-item">
                      <div class="item-pin"></div>
                      <p>로그인 횟수 : 24</p>
                    </div>
                  </div>
                </div>
                <div class="tb-learning-chart-wrap">
                  <div class="inner-wrap">
                    <RadarChart
                        :chart-data="this.studyReportChartData"
                        :options="this.studyReportOption"
                        :style="[{'aspect-ratio': '1'}]"
                    />
                  </div>
                  <div class="data-list">
                    <div class="list-item">
                      <img src="@/assets/images/line-1.png" alt="">
                      <span>평균</span>
                    </div>
                    <div class="list-item">
                      <img src="@/assets/images/line-2.png" alt="">
                      <span>강기복</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sr-b-box-wrap">
                <div class="chat-top-item">
                  <div class="ci-icon">
                    <img src="@/assets/images/chat-icon.png" alt="">
                    <p>AI 에스덕</p>
                  </div>
                  <div class="bubble-wrap">
                    <img src="@/assets/images/pop-left.png" alt="">
                    <div class="bubble-inner">
                      <p>AI 에스덕 :<br/>미제출 된 과제가 1개 있어요! </p>
                    </div>
                  </div>
                </div>
                <div class="chat-item">
                  <p>문제는 다 풀었네요! 선생님이 추가로 추천해 준 문제가 있네요. 확인해봐요.</p>
                </div>
                <div class="chat-item">
                  <p>문제는 다 풀었네요! 선생님이 추가로 추천해 준 문제가 있네요. 확인해봐요.</p>
                </div>
                <div class="chat-input-wrap">
                  <input placeholder="입력">
                  <button>
                    <img src="@/assets/images/send.png" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
        `</div>
      </div>
    </V2Wrapper>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import YearlyLearnPattern from "@/components/YearlyLearnPattern.vue";
import { DoughnutChart, RadarChart } from 'vue-chart-3';
import {Chart, registerables} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  name: 'StudentDashboard',
  components: {
    YearlyLearnPattern,
    V2Wrapper,
    DoughnutChart,
    RadarChart,
  },
  data() {
    return {
      studyStateTabItems: [
        {title: '학습 현황', newCnt: 1},
        {title: '선생님의 과제', newCnt: 0},
        {title: '나의 과제', newCnt: 0},
      ],
      selectStudyTabIndex: 0,
      studyStateDatas: [
        {type: '학습지', title: '정수와 유리수', deadline: '마감 00일전', state: '미학습', cnt: 50, studyCnt: 0},
        {type: '학습지', title: '정수와 유리수', deadline: '마감 00일전', state: '미학습', cnt: 50, studyCnt: 0},
      ],
    };
  },
  computed: {
    studyCompletionRateChartData() {
      return {
        labels: [
          '이수완료',
          '이수미완료',
        ],
        datasets: [
          {
            data: [45, 55],
            backgroundColor: ['#008ED5', '#CFEFFF'],
          },
        ],
      };
    },
    studyCompletionRateChartOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    homeworkSubmitChartData() {
      return {
        labels: [
          '제출완료',
          '제출미완료',
        ],
        datasets: [
          {
            data: [75, 25],
            backgroundColor: ['#F8B633', '#F9D286'],
          },
        ],
      };
    },
    homeworkSubmitChartOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    problemSolvingChartData() {
      return {
        labels: [
          '풀이완료',
          '풀이미완료',
        ],
        datasets: [
          {
            data: [75, 25],
            backgroundColor: ['#65CF55', '#B6EDAE'],
          },
        ],
      };
    },
    problemSolvingChartOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    studyReportChartData() {
      return {
        labels: [
          '물리',
          '화학',
          '생명과학',
          ['지구', '과학'],
        ],
        datasets: [
          {
            label: '강기복',
            data: [
              30,
              30,
              50,
              80,
            ],
            fill: false,
            borderColor: '#008ED5',
            backgroundColor: '#008ED5',
          },
          {
            label: '평균',
            data: [
              45,
              30,
              70,
              55,
            ],
            fill: false,
            borderColor: '#F8B633',
            backgroundColor: '#F8B633',
          },
        ],
      };
    },
    studyReportOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
        scales: {
          y: {
            display: false,
          },
          r: {
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
              font: {
                size: 7,
                family: 'NanumSquare',
              },
            },
            grid: {
              circular: true,
            },
          },
        },
      };
    },
  },
}
</script>
