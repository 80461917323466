<template>
  <div class="teacher-dashboard-wrap">
    <V2Wrapper>
      <div class="tdw-inner-wrap">
        <template v-if="this.errorMsg !== null">
          <ErrorMsgBox :contents="this.errorMsg"/>
        </template>
        <template v-else-if="this.isLoading">
          <LoadingBox/>
        </template>
        <template v-else>
          <button class="ai-assistant-btn" @click="this.isVisibleAIAssistantPopup = true">
            <img src="@/assets/images/sduck-mascot.png" alt="">
          </button>
          <TeacherDashboardSection1
            :academy-class-id="this.selectedAcademyClassId"
          />
          <TeacherDashboardSection2
            :academy-class-id="this.selectedAcademyClassId"
          />
          <TeacherDashboardSection3
            :academy-class-id="this.selectedAcademyClassId"
          />
          <TeacherDashboardSection4
            :academy-class-id="this.selectedAcademyClassId"
          />
          <TeacherDashboardSection5
            :academy-class-id="this.selectedAcademyClassId"
          />
        </template>
      </div>
    </V2Wrapper>
  </div>

  <Teleport to="body">
    <AIAssistantPopup
      :is-visible="this.isVisibleAIAssistantPopup"
      :on-close="() => this.isVisibleAIAssistantPopup = false"
    />
  </Teleport>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BarChart, BubbleChart } from 'vue-chart-3';
import TitleTooltip from "@/components/TitleTooltip.vue";
import AIAssistantPopup from "@/components/AIAssistantPopup.vue";
import AnalysisDetailPopup from "@/components/AnalysisDetailPopup.vue";
import LearningStatisticsPopup from "@/components/LearningStatisticsPopup.vue";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import {mapActions, mapGetters} from "vuex";
import LoadingBox from "@/components/LoadingBox.vue";
import AcademyService from "@/services/academy.service";
import CustomSelect from "@/components/CustomSelect.vue";
import TeacherDashboardSection1 from "@/components/TeacherDashboardSection1.vue";
import TeacherDashboardSection2 from "@/components/TeacherDashboardSection2.vue";
import TeacherDashboardSection3 from "@/components/TeacherDashboardSection3.vue";
import TeacherDashboardSection4 from "@/components/TeacherDashboardSection4.vue";
import TeacherDashboardSection5 from "@/components/TeacherDashboardSection5.vue";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  name: 'TeacherDashboard',
  components: {
    TeacherDashboardSection5,
    TeacherDashboardSection4,
    TeacherDashboardSection3,
    TeacherDashboardSection2,
    TeacherDashboardSection1,
    CustomSelect,
    LoadingBox,
    ErrorMsgBox,
    LearningStatisticsPopup,
    AnalysisDetailPopup,
    AIAssistantPopup,
    BubbleChart,
    TitleTooltip,
    V2Wrapper,
    BarChart,
  },
  data() {
    return {
      errorMsg: null,
      isLoading: true,
      isVisibleAIAssistantPopup: false,
      selectedAcademyClassId: null,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.setIsSelectV2(true);

      const academyUserId = this.getUser?.academy_user?.id;
      if (academyUserId === null) { this.showErrorMsg('학원 ID를 알 수 없습니다'); return; }

      let selectedAcademyClassId = this.getSelectedAcademyClassId;
      if (selectedAcademyClassId === null) {
        const maclItemVos = await AcademyService.getMyAcademyClassList();
        if (maclItemVos === null) { this.showErrorMsg('반 리스트를 불러오는 중 오류가 발생하였습니다'); return; }
        if (maclItemVos.length === 0) { this.showErrorMsg('반 리스트를 불러오는 중 오류가 발생하였습니다'); return; }
        selectedAcademyClassId = maclItemVos[0].id;
        await this.changeAcademyClassId(selectedAcademyClassId);
      }
      this.selectedAcademyClassId = this.getSelectedAcademyClassId;

      this.isLoading = false;
    },
    showErrorMsg(errorMsg) {
      this.errorMsg = errorMsg;
      this.isLoading = false;
    },
    ...mapActions('selectV2', ['setIsSelectV2']),
    ...mapActions('classStore', ['changeAcademyClassId']),
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('classStore', ['getSelectedAcademyClassId']),
    ...mapGetters('selectV2', ['isSelectV2']),
  },
}
</script>
