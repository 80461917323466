<template>
  <div class="loading-problem-preview-wrap"
       :style="[{
         'width': `${this.pdfSize.width}px`,
         'height': `${this.pdfSize.height}px`,
       }]"
  >
    <template v-if="this.sheetData">
      <div class="lpp-sheet-wrap"
           :style="[{
             'width': `${this.pdfSize.width}px`,
             'height': `${this.pdfSize.height}px`,
           }]"
      >
        <div class="lpps-header"
             :style="[{'height': `${this.pdfSize.headerHeight}px`}]"
        >
          <div class="lppsh-top">
            <div class="sh-table">
              <div class="sht-item">
                <span>날&nbsp;&nbsp;&nbsp;&nbsp;짜</span>
                <span>{{ create_dt_str }}</span>
              </div>
              <div class="sht-item">
                <span>문제수</span>
                <span>{{ Number(problemCnt) }} 문제</span>
              </div>
              <div class="sht-item">
                <span>출제자</span>
                <span>{{ makerName }}</span>
              </div>
            </div>
            <div class="sh-logo">
              <img class="shl-img"
                   :src="get_academy_logo"
                   alt="">
            </div>
          </div>
          <div class="lppsh-mid">
            {{ nameTag }}&nbsp;{{ name }}
          </div>
          <div class="lppsh-btm">
            <h5>이름 __________</h5>
            <p>{{ subName }}</p>
            <h5>이름 __________</h5>
          </div>
        </div>
        <div class="lpps-header-divider"
             :style="[{'height': `${this.pdfSize.headerDividerSize}px`}]"
        ></div>
        <div class="lpps-body"
             :style="[{'height': `${this.pdfSize.firstBodyHeight}px`}]"
        >
          <template v-for="(pageData, pageIndex) in this.sheetData" :key="pageIndex">
            <div class="sb-divider"
                 v-if="pageIndex === 1"
            ></div>
            <div class="sb-page">
              <div v-for="(item, itemIndex) in pageData" :key="itemIndex"
                   class="sbp-item"
                   :style="[{
                     'gap': `${this.pdfSize.item.gapSize}px`,
                     'margin': `${this.pdfSize.item.marginY / 2}px 0`,
                   }]"
              >
                <div class="pi-header"
                     v-if="item.isVisibleHeader"
                     :style="[{
                       'height': `${this.pdfSize.item.headerHeight}px`,
                       }]"
                >
                  <div class="pih-pattern-name"
                       v-if="item.patternName"
                  >{{ item.patternName }}</div>
                  <div class="pih-answer-rate"
                       v-if="item.answerRate"
                  >{{ item.answerRate }}</div>
                  <div class="pih-difficulty"
                       v-if="item.difficulty"
                  >{{ item.difficulty }}</div>
                </div>
                <div class="pi-sub-title"
                     :style="[{height: `${this.pdfSize.item.subTitleHeight}px`}]"
                     v-if="item.subTitle"
                >{{ item.subTitle}}</div>
                <div class="pi-body">
                  <h1>{{ item.number }}</h1>
                  <div class="pib-contents">
                    <div class="bc-answer"
                         v-if="item.studentAnswer"
                    >
                      <p>{{ item.studentAnswer.correctAnswer }}</p>
                      <p>{{ item.studentAnswer.submitAnswer }}</p>
                    </div>
                    <div class="bc-img"
                         :style="[{
                           maxWidth: `${pdfSize.item.imageWidth}px`,
                         }]"
                    >
                      <img :src="item.imageData.localUrl"
                           alt=""
                           :width="item.imageData.width"
                           :height="item.imageData.height"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="lpps-footer-divider"
             :style="[{
                 'height': `${this.pdfSize.footerDividerSize}px`,
                 }]"
        ></div>
        <div class="lpps-footer"
             :style="[{
               'height': `${this.pdfSize.footerHeight}px`,
               }]"
        >1</div>
      </div>
    </template>
  </div>
</template>

<script>
import utils from '../utils'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'

export default {
  props: {
    logoUrl: String,
    nameTag: String,
    name: String,
    subName: String,
    create_dt: String,
    problemCnt: Number,
    makerName: String,
    problems: Array,

    isVisibleAnswerRate: {type: Boolean, default: false},
    isVisibleDate: {type: Boolean, default: false},
    isVisiblePatternName: {type: Boolean, default: false},
    isVisibleDifficulty: {type: Boolean, default: false},
    isVisibleSimilar: {type: Boolean, default: false},

    type: String,
  },
  setup() {
    utils.addScript('https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js')
    utils.addScript('https://html2canvas.hertzen.com/dist/html2canvas.js')
  },
  data() {
    return {
      pdfSize: utils.getPdfSize(),

      sheetDataList: [],

      isLoading: true,
      utils,

      processIndex: 0, // 현재 초기화 중인 작업 번호
    }
  },
  mounted() {
    this.initSheetDataList(this);
  },
  watch: {
    logoUrl() { this.initSheetDataList(this) },
    nameTag() { this.initSheetDataList(this) },
    name() { this.initSheetDataList(this) },
    subName() { this.initSheetDataList(this) },
    create_dt() { this.initSheetDataList(this) },
    problemCnt() { this.initSheetDataList(this) },
    makerName() { this.initSheetDataList(this) },
    problems() { this.initSheetDataList(this) },
  },
  methods: {
    initSheetDataList: debounce(async (appContext) => {
      appContext.processIndex += 1;
      const processIndex = appContext.processIndex;

      let sheetDataList = []; // 전체 시트 별 데이터 (페이지 두 개가 합쳐져서 시트 1장)
      let pageDataList = []; // 전체 페이지 별 데이터

      const itemDataList = await Promise.all([
        ...appContext.problems.map(async (problem, index) => await appContext.getItemDataByProblem(index, problem, processIndex)),
        ...appContext.problems.map(async (problem, index) => await appContext.getItemDataByAnswer(index, problem, processIndex)),
      ]);
      const problemItemDataList = itemDataList.slice(0, itemDataList.length / 2);
      const answerItemDataList = itemDataList.slice(itemDataList.length / 2, itemDataList.length);
      if (processIndex !== appContext.processIndex) {
        return null;
      }

      // 문제 페이지 추가
      await appContext.getCurrentPageDataList(problemItemDataList, pageDataList);

      // 정답 페이지 추가
      await appContext.getCurrentPageDataList(answerItemDataList, pageDataList);

      // 이제 시트에 2 페이지 씩 추가
      let curSheetData = [];
      for (let idx = 0; idx < pageDataList.length; idx++) {
        const pageData = pageDataList[idx];

        if (curSheetData.length >= 2) {
          sheetDataList.push(curSheetData);
          curSheetData = [];
        }
        curSheetData.push(pageData);
      }
      // 남은 페이지가 있으면 마지막 시트에 추가
      if (curSheetData.length > 0) {
        sheetDataList.push(curSheetData);
      }

      appContext.sheetDataList = sheetDataList;
    }, 300),
    getCurrentPageDataList(
        itemDataList,
        pageDataList,
    ) {
      let tempSumHeight = 0;
      let currentPageDataList = [];

      for (let idx = 0; idx < itemDataList.length; idx++) {
        const itemData = itemDataList[idx];

        tempSumHeight += itemData.height;
        const curMaxHeight = pageDataList.length < 2 ? this.pdfSize.firstBodyHeight : this.pdfSize.bodyHeight;
        if (tempSumHeight > curMaxHeight) { // 해당 이미지를 더한 높이가 전체 높이를 넘으면 다음 페이지에 표시
          pageDataList.push(currentPageDataList);
          tempSumHeight = itemData.height;
          currentPageDataList = [];
        }
        currentPageDataList.push(itemData);
      }

      // 남은 게 있으면 마지막 페이지에 추가
      if (currentPageDataList.length > 0) {
        pageDataList.push(currentPageDataList);
      }

      // 한 장에 두 페이지 씩 딱 맞추기 위해서 페이지가 짝수가 아니면 빈 페이지 넣음
      if (pageDataList.length % 2 === 1) {
        pageDataList.push([]);
      }
    },
    async getItemDataByAnswer(index, problem, processIndex) {
      const imageData = await this.getImageDataByUrl(problem.solution_img);
      let height = 0;
      height += imageData.height;
      height += this.pdfSize.item.marginY;

      return {
        'isAnswerPage': true,
        'height': height,
        'number': String(index + 1).padStart(2, '0'),
        'imageData': imageData,
      };
    },
    async getItemDataByProblem(index, problem, processIndex) {
      const imageData = await this.getImageDataByUrl(problem.problem_img);
      let height = 0;

      const patternName = this.isVisiblePatternName ? problem.pattern_name : null;
      const answerRate = this.isVisibleAnswerRate ? `정답률 ${problem.answer_rate}%${this.isVisibleDifficulty ? ', ' : ''}` : null;
      const difficulty = this.isVisibleDifficulty ? `난이도 ${problem.difficulty}` : null;
      const isVisibleHeader = patternName || answerRate || difficulty;
      if (isVisibleHeader) {
        height += this.pdfSize.item.headerHeight + this.pdfSize.item.gapSize;
      }

      const subTitle = problem.pdf_sub_title;
      if (subTitle) {
        height += this.pdfSize.item.subTitleHeight + this.pdfSize.item.gapSize;
      }

      height += imageData.height;
      height += this.pdfSize.item.marginY;

      return {
        'height': height,
        'isVisibleHeader': isVisibleHeader,
        'patternName': patternName,
        'answerRate': answerRate,
        'difficulty': difficulty,
        'number': String(index + 1).padStart(2, '0'),
        'studentAnswer': this.type === utils.PdfType.studentWork ? {
          'correctAnswer': `정답: ${problem.correct_answer}`,
          'submitAnswer': `학생이 고른 답: ${problem.answer}`,
        } : null,
        'imageData': imageData,
        'isCorrectAnswer': this.type === utils.PdfType.studentWork ? problem.is_current_answer : null,
        'subTitle': subTitle,
      };
    },
    async getImageDataByUrl(url) {
      const getMeta = (url) => new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
        img.src = url;
      });

      const problemImageUrl = utils.getServerMediaPath(url);
      const blob = await fetch(problemImageUrl).then((res) => res.blob());
      const localUrl = window.URL.createObjectURL(blob);
      const img = await getMeta(localUrl);

      const originWidth = img.naturalWidth;
      const originHeight = img.naturalHeight;
      const width = this.pdfSize.item.imageWidth;
      const aspect = img.naturalHeight / img.naturalWidth;
      const height = width * aspect;

      return {
        'localUrl': localUrl,
        'originWidth': originWidth,
        'originHeight': originHeight,
        'aspect': aspect,
        'width': width,
        'height': height,
      };
    },
  },
  computed: {
    sheetData() {
      if (this.sheetDataList.length > 0) {
        return this.sheetDataList[0];
      }
      return null;
    },
    create_dt_str() {
      try {
        return utils.getDateByServer(this.create_dt, 'yyyy.mm.dd')
      } catch (e) {
        console.log(e);
      }
      return ''
    },
    get_academy_logo() {
      const logoUrl = this.getUser?.academy_logo;
      if (logoUrl) {
        return utils.getServerMediaPath(logoUrl)
      }
      return ''
    },
    ...mapGetters('auth', ['getUser'])
  },
}
</script>
