<template>
  <div :class="['popup-wrap', 'edit-menu-popup-wrap', { hide: this.editMenu === null }]">
    <div class="p-shadow" @click="this.onCloseEvent"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <input
            ref="input"
            v-model="this.title"
            @input="changeValue($event.target.value)"
            maxlength="29"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editMenu: Object,
    onClose: Function,
    onChange: Function,
  },
  data() {
    return {
      title: '',
    };
  },
  watch: {
    editMenu() {
      this.title = this.editMenu?.title ?? '';
      console.log(this.editMenu);

      if (this.editMenu !== null) {
        setTimeout(() => {
          this.$refs.input.focus();
        }, 100);
      }
    },
  },
  mounted() {
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
    changeValue(value) {
      if (value.length > 0) {
        this.onChange(this.editMenu.id, value);
      }
    }
  },
}
</script>
