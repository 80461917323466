<template>
  <div :class="['popup', {'hide': !this.isVisible}]">
    <div class="popup-shadow" @click="this.onClose"></div>
    <div :class="['popup-body']" v-if="this.studentItem">
      <div class="popup-title">
        <h1>학생 프로필</h1>
        <button class="close-btn" @click="this.onClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </button>
      </div>
      <div class="body-contents">
        <div class="thumb-box">
          <img :src="this.studentItem.thumbnailUrl" alt="">
        </div>
        <div class="bc-bd-wrap">
          <div>
            <p>이름</p>
            <span>{{ this.studentItem.name }}</span>
          </div>
          <div>
            <p>이메일</p>
            <span>{{ this.studentItem.email }}</span>
          </div>
          <div>
            <p>학년</p>
            <span>{{ this.studentItem.schoolYear }}</span>
          </div>
          <div>
            <p>전화번호</p>
            <span>{{ this.studentItem.phone }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <LoadingDialog :is-show="this.isLoadingDialog"/>
  </Teleport>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog.vue";

export default {
  components: {LoadingDialog},
  props: {
    isVisible: {type: Boolean, default: false},
    onClose: Function,
    studentItem: Object,
  },
  watch: {
    async isVisible() {
      if (this.isVisible) {
      }
    },
  },
  data() {
    return {
      isLoadingDialog: false,
    };
  },
  methods: {
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99;
  &.hide {
    display: none;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    max-width: calc(100% - 40px - 40px);
    min-width: 600px;
    height: 100%;
    max-height: calc(100% - 24px - 24px);
    margin: auto;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #231f20;
    box-sizing: border-box;
    .popup-title {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-weight: 500;
      display: flex;
      height: 72px;
      align-items: center;
      padding: 0 24px;
      border-bottom: 1px solid rgb(224, 224, 224);
      &>h1 {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.01em;
      }
      &>.close-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        &>svg {
          width: 100%;
          color: rgb(112, 112, 112);
          &>path {
            fill: currentcolor;
          }
        }
      }
    }
    &>.body-contents {
      flex: 1;
      padding: 24px;
      width: 100%;
      height: 100%;
      display: flex;
      gap: 30px;
      overflow-y: auto;
      @include tablet {
        gap: 20px;
      }
      &>.thumb-box {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        background-color: gray;
        @include tablet {
          width: 45px;
          height: 45px;
        }
        @include mobile {
          width: 35px;
          height: 35px;
        }
        &>img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &>.bc-bd-wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 35px;
        @include tablet {
          gap: 20px;
        }
        &>div {
          width: 100%;
          display: flex;
          gap: 15px;
          &>p {
            min-width: 70px;
            font-weight: 700;
            font-size: 16px;
          }
          &>span {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
