import {ClassStoreItem} from "@/items/class_store_item";

const itemKey = 'AcademyClass';
const item = JSON.parse(localStorage.getItem(itemKey));

/** @type {ClassStoreItem} */
const initialState = item ?? new ClassStoreItem(null);

export const classStore = {
  namespaced: true,
  state: initialState,
  actions: {
    /**
     * @param commit
     * @param {number|null} academyClassId
     */
    changeAcademyClassId({ commit }, academyClassId) {
      commit('changeAcademyClassId', academyClassId);
    },
  },
  mutations: {
    /**
     * @param {ClassStoreItem} state
     * @param {number|null} academyClassId
     */
    changeAcademyClassId(state, academyClassId) {
      state.academyClassId = academyClassId;
      localStorage.setItem(itemKey, JSON.stringify(state));
    },
  },
  getters: {
    /** @return {number|null} */
    getSelectedAcademyClassId: (state) => state.academyClassId,
  },
};
