import api from "./api"

class PaymentService {
  requestUseFree(payload) {
    return api.post('/auth/request_use_free/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  requestRegister(payload) {
    return api.post('/auth/request_register/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
}

export default new PaymentService()
