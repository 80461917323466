import {FolderProblemResultVo} from "@/models/folder_problem_result_vo";

export class FolderResultVo {
    /** @type {number} */
    id;
    /** @type {number} */
    userId;
    /** @type {string} */
    name;
    /** @type {Date} */
    createDt;
    /** @type {Date} */
    updateDt;
    /** @type {FolderProblemResultVo[]} */
    folderProblemResultVos;

    constructor(
        id,
        userId,
        name,
        createDt,
        updateDt,
        folderProblemResultVos,
    ) {
        this.id = id;
        this.userId = userId;
        this.name = name;
        this.createDt = createDt;
        this.updateDt = updateDt;
        this.folderProblemResultVos = folderProblemResultVos;
    }

    static fromJson = (json) => new FolderResultVo(
        json.id,
        json.user,
        json.name,
        json.create_dt,
        json.update_dt,
        (json.student_work_folder_problem ?? []).map((e) => FolderProblemResultVo.fromJson(e)),
    );
}
