<template>
  <div class="section-1-wrap">
    <div class="v2-base-container">
      <template v-if="this.errorMsg !== null">
        <ErrorMsgBox :contents="this.errorMsg"/>
      </template>
      <template v-else-if="this.isLoading">
        <LoadingBox size="25px" style="margin: 10px auto"/>
      </template>
      <template v-else>
        <div class="sb-left-wrap">
          <div class="ai-chat-wrap"
               @click="onClickAiAssistant"
          >
            <div class="mascot-img-wrap">
              <img src="@/assets/images/sduck-mascot.png" alt="">
              <span>AI 에스덕</span>
            </div>
            <div class="chat-body">
              <img class="cb-arrow" src="@/assets/images/chat-body-arrow.png" alt=""/>
              <div class="cb-inner-wrap">
                <p>{{ `AI 에스덕의 메시지 :\n${this.aiMsg}` }}</p>
              </div>
            </div>
          </div>
          <div class="sbl-bottom-wrap">
            <div class="sblb-left-wrap">
              <div class="sblbl-tab-wrap">
                <div class="tab-item active">이름</div>
                <div class="tab-item active">과제제출</div>
                <div class="tab-item active">학습단원</div>
              </div>
              <div class="sblbl-body-wrap">
                <div class="sblbl-body-inner-wrap">
                  <template v-for="(studentItem, index) in this.studentItems" :key="index">
                    <div :class="['sblbl-body-item', {'active': studentItem.isActive}]"
                         @click="() => this.onClickStudentItem(index)"
                    >
                      <div class="bl-info">
                        <div class="bli-name">
                          <span>{{ studentItem.name }}</span>
                          <div :class="[`level-${studentItem.level}`]"></div>
                        </div>
                        <div :class="['bli-homework', `level-${studentItem.homework_level}`]">
                          <img v-if="studentItem.homework_level === -1" src="@/assets/images/homework--1.png" alt="">
                          <img v-else-if="studentItem.homework_level === 0" src="@/assets/images/homework-0.png" alt="">
                          <img v-else src="@/assets/images/homework-1.png" alt="">
                        </div>
                        <div class="bli-unit">
                          <img src="@/assets/images/location.png" alt="">
                          <span>{{ studentItem.learn_unit }}</span>
                        </div>
                      </div>
                      <div class="bi-line"></div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="sblb-right-wrap">
              <h1>학습 참여도</h1>
              <div class="learner-dist-wrap">
                <h5>학습자 분포</h5>
                <div class="ld-rate-wrap">
                  <template v-for="(ldRate, index) in this.ldRates" :key="index">
                    <div :style="[{'width': `${ldRate.percent}%`}]">
                      <template v-if="ldRate.cnt > 0">
                        <span>{{ ldRate.cnt }}명({{ ldRate.percent }}%)</span>
                      </template>
                      <img v-if="ldRate.isActive"
                           src="@/assets/images/arrow-bottom.png"
                           alt=""
                      >
                    </div>
                  </template>
                </div>
                <div class="ld-rate-info">
                  <div class="ri-item">
                    <div></div>
                    <p>빠른 학습자</p>
                  </div>
                  <div class="ri-item">
                    <div></div>
                    <p>일반 학습자</p>
                  </div>
                  <div class="ri-item">
                    <div></div>
                    <p>느린 학습자</p>
                  </div>
                </div>
              </div>
              <div class="rate-wrap">
                <div class="r-chart-item">
                  <h5>학습 이수율</h5>
                  <div class="rc-body">
                    <div class="rcb-inner-wrap">
                      <div class="rcbi-graph-wrap">
                        <DoughnutChart
                            :chartData="this.studyCompletionRateChartData"
                            :options="this.studyCompletionRateOption"
                        />
                      </div>
                    </div>
                    <p>{{ this.learnerDist.studyCompleteRate }}%</p>
                  </div>
                </div>
                <div class="r-chart-item">
                  <h5>과제 제출율</h5>
                  <div class="rc-body">
                    <div class="rcb-inner-wrap">
                      <div class="rcbi-graph-wrap">
                        <DoughnutChart
                            :chartData="this.homeworkSubmitRateChartData"
                            :options="this.homeworkSubmitRateOption"
                        />
                      </div>
                    </div>
                    <p>{{ this.learnerDist.homeworkSubmitRate }}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sb-right-wrap">
          <div class="sbr-tab">
            <div class="tab-item"
                 @click="() => this.$router.push({ name: 'TeacherSelfLearningModeResult' })">
              <img src="@/assets/images/diagnosis-result.png" alt="">
              <span>진단 결과</span>
            </div>
            <div class="tab-item"
                 @click="() => this.onClickLearnStat()">
              <img src="@/assets/images/learning-statistics.png" alt="">
              <span>학습 통계</span>
            </div>
            <div class="tab-item"
                 @click="() => this.onClickNotification()">
              <img src="@/assets/images/questions-notifications.png" alt="">
              <span>질문&알림</span>
            </div>
          </div>
          <div class="learning-report">
            <h1>학습 리포트</h1>
            <h2>{{ this.reportSubTitle }}</h2>
            <div class="lr-list-wrap">
              <div class="list-item">
                <div class="item-pin"></div>
                <p>학습 이수율 : {{ this.selectedStudentItem?.answer_rate ?? this.avgAnswerRate }}%</p>
                <template v-if="this.selectedStudentItem !== null">
                  <span>평균 {{ Math.abs(this.selectedStudentItem.answer_rate - this.avgAnswerRate) }}%</span>
                  <template v-if="this.selectedStudentItem.answer_rate - this.avgAnswerRate > 0">
                    <img src="@/assets/images/arrow-top.png" alt="">
                  </template>
                  <template v-else-if="this.selectedStudentItem.answer_rate == this.avgAnswerRate">
                    <img src="@/assets/images/arrow-bottom-gray.png" alt="">
                  </template>
                  <template v-else>
                    <img src="@/assets/images/arrow-bottom.png" alt="">
                  </template>
                </template>
                <template v-else>
                  <span>평균 0%</span>
                  <img src="@/assets/images/arrow-bottom-gray.png" alt="">
                </template>
              </div>
              <div class="list-item">
                <div class="item-pin"></div>
                <p>과제 제출 현황 : {{ this.selectedStudentItem?.recent_submitted_status ?? this.totalRecentSubmittedStatus }}/10</p>
                <template v-if="this.selectedStudentItem !== null">
                  <span>평균 {{ Math.abs(this.selectedStudentItem.recent_submitted_status - this.totalRecentSubmittedStatus) }}개</span>
                  <template v-if="this.selectedStudentItem.recent_submitted_status - this.totalRecentSubmittedStatus > 0">
                    <img src="@/assets/images/arrow-top.png" alt="">
                  </template>
                  <template v-else-if="this.selectedStudentItem.recent_submitted_status == this.totalRecentSubmittedStatus">
                    <img src="@/assets/images/arrow-bottom-gray.png" alt="">
                  </template>
                  <template v-else>
                    <img src="@/assets/images/arrow-bottom.png" alt="">
                  </template>
                </template>
                <template v-else>
                  <span>평균 0개</span>
                  <img src="@/assets/images/arrow-bottom-gray.png" alt="">
                </template>
              </div>
              <div class="list-item">
                <div class="item-pin"></div>
                <p>정답률 : {{ this.selectedStudentItem?.answer_rate ?? this.avgAnswerRate }}%</p>
                <template v-if="this.selectedStudentItem !== null">
                  <span>평균 {{ Math.abs(this.selectedStudentItem.answer_rate - this.avgAnswerRate) }}%</span>
                  <template v-if="this.selectedStudentItem.answer_rate - this.avgAnswerRate > 0">
                    <img src="@/assets/images/arrow-top.png" alt="">
                  </template>
                  <template v-else-if="this.selectedStudentItem.answer_rate == this.avgAnswerRate">
                    <img src="@/assets/images/arrow-bottom-gray.png" alt="">
                  </template>
                  <template v-else>
                    <img src="@/assets/images/arrow-bottom.png" alt="">
                  </template>
                </template>
                <template v-else>
                  <span>평균 0%</span>
                </template>
              </div>
              <div class="list-item">
                <div class="item-pin"></div>
                <p>{{ this.learnMinTitle }}</p>
                <template v-if="this.selectedStudentItem !== null">
                  <span>평균 {{ Math.abs(this.selectedStudentItem.learn_min - this.totalLearningMinute) }}분</span>
                  <template v-if="this.selectedStudentItem.learn_min - this.totalLearningMinute > 0">
                    <img src="@/assets/images/arrow-top.png" alt="">
                  </template>
                  <template v-else-if="this.selectedStudentItem.learn_min == this.totalLearningMinute">
                    <img src="@/assets/images/arrow-bottom-gray.png" alt="">
                  </template>
                  <template v-else>
                    <img src="@/assets/images/arrow-bottom.png" alt="">
                  </template>
                </template>
                <template v-else>
                  <span>평균 0분</span>
                </template>
              </div>
            </div>
          </div>
          <div class="learning-chart-wrap">
            <div class="inner-wrap">
              <RadarChart
                  :chart-data="this.studyReportChartData"
                  :options="this.studyReportOption"
                  :style="[{'max-height': '100%'}]"
              />
            </div>
            <div class="data-list">
              <div class="list-item">
                <img src="@/assets/images/line-1.png" alt="">
                <span>평균&nbsp;&nbsp;&nbsp;</span>
              </div>
              <template v-if="this.selectedStudentItem">
                <div class="list-item">
                  <img src="@/assets/images/line-2.png" alt="">
                  <span>{{ this.selectedStudentItem?.name ?? '' }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>

  <Teleport to="body">
    <LearningStatisticsPopup
      :is-visible="this.isShowLearnStatPopup"
      :on-close="() => this.isShowLearnStatPopup = false"
    />
    <AIAssistantPopup 
      :is-visible="isVisibleAIAssistantPopup" 
      :on-close="() => this.isVisibleAIAssistantPopup = false" 
    />
  </Teleport>
</template>

<script>
import AcademyService from "@/services/academy.service";
import LoadingBox from "@/components/LoadingBox.vue";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import {StudentProgressType} from "@/utils/enums";
import { DoughnutChart, RadarChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LearningStatisticsPopup from "@/components/LearningStatisticsPopup.vue";
import AnalysisDetailPopup from "@/components/AnalysisDetailPopup.vue";
import AIAssistantPopup from "@/components/AIAssistantPopup.vue";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  components: {
    AnalysisDetailPopup,
    LearningStatisticsPopup,
    ErrorMsgBox,
    LoadingBox,
    DoughnutChart,
    RadarChart,
    AIAssistantPopup,
  },
  props: {
    academyClassId: Number,
  },
  watch: {
    academyClassId() {
      if (this.academyClassId !== null) {
        this.initData();
      }
    },
  },
  data() {
    return {
      errorMsg: null,
      isLoading: true,
      aiMsg: '',
      studentItems: [],
      isShowLearnStatPopup: false,
      avgAnswerRate: 0,
      totalRecentSubmittedStatus: 0,
      totalLearningMinute: 0,
      isVisibleAIAssistantPopup: false,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    onClickLearnStat() {
      this.isShowLearnStatPopup = true;
      this.$toast.info('개발중입니다');
    },
    onClickNotification() {
      this.$toast.info('알림이 없습니다');
    },
    onClickStudentItem(selectIndex) {
      const studentItems = this.studentItems;
      for (let index = 0; index < studentItems.length; index++) {
        studentItems[index].isActive = selectIndex === index;
      }
    },
    async initData() {
      if (this.academyClassId === null) { this.errorMsg = '반을 먼저 선택해주세요'; return; }

      this.isLoading = true;

      let isError = false;
      await Promise.all([
        AcademyService.getTeacherDashboardAiMessage(this.academyClassId).then(res => {
          if (res) {
            this.aiMsg = res.msg;
          } else isError = true;
        }),
        AcademyService.getTeacherDashboardData(this.academyClassId).then(res => {
          if (res) {
            this.studentItems = res.student_items ?? [];
            this.avgAnswerRate = res.avg_answer_rate ?? 0;
            this.totalRecentSubmittedStatus = res.tot_recent_submitted_status ?? 0;
            this.totalLearningMinute = res.tot_learn_min ?? 0;
          } else isError = true;
        }),
      ]);

      if (isError) {
        this.errorMsg = '데이터를 불러오는 중 오류가 발생하였습니다';
      }
      this.isLoading = false;
    },
    onClickAiAssistant() {
      this.isVisibleAIAssistantPopup = true;
    },
  },
  computed: {
    learnerDist() {
      const studentItems = this.studentItems;
      const avgAnswerRate = this.avgAnswerRate;
      const selectedStudentItem = this.selectedStudentItem;

      const totalCnt = studentItems.length;
      const fastCnt = studentItems.filter(e => e.level === 1).length;
      const fastPer = Math.round(((fastCnt / totalCnt) * 100 * 10)) / 10;
      const generalCnt = studentItems.filter(e => e.level === 0).length;
      const generalPer = Math.round(((generalCnt / totalCnt) * 100 * 10)) / 10;
      const slowCnt = studentItems.filter(e => e.level === -1).length;
      const slowPer = 100 - fastPer - generalPer;
      const homeworkCompletedCnt = studentItems.filter(e => e.homework_level == 1).length;

      return {
        totalCnt: totalCnt,
        fastCnt: fastCnt,
        fastPer: fastPer,
        generalCnt: generalCnt,
        generalPer: generalPer,
        slowCnt: slowCnt,
        slowPer: slowPer,
        studyCompleteRate: selectedStudentItem?.answerRate ?? avgAnswerRate,
        homeworkSubmitRate: Math.floor(homeworkCompletedCnt / totalCnt * 100),
      };
    },
    ldRates() {
      const studentItems = this.studentItems;

      const totalCnt = studentItems.length;

      const fasterCnt = studentItems.filter(i => i.level === StudentProgressType.faster).length;
      const normalCnt = studentItems.filter(i => i.level === StudentProgressType.normal).length;
      const slowerCnt = studentItems.filter(i => i.level === StudentProgressType.slower).length;

      const fasterPer = Math.round(fasterCnt / totalCnt * 100);
      const normalPer = Math.round(normalCnt / totalCnt * 100);
      const slowerPer = 100 - fasterPer - normalPer;

      let isFasterActive = false;
      let isNormalActive = false;
      let isSlowerActive = false;
      for (let index = 0; index < studentItems.length; index++) {
        const studentItem = studentItems[index];
        if (studentItem.isActive) {
          if (studentItem.level === StudentProgressType.faster) {
            isFasterActive = true;
          } else if (studentItem.level === StudentProgressType.normal) {
            isNormalActive = true;
          } else if (studentItem.level === StudentProgressType.slower) {
            isSlowerActive = true;
          }
        }
      }

      return [
        {isActive: isFasterActive, percent: fasterPer, cnt: fasterCnt},
        {isActive: isNormalActive, percent: normalPer, cnt: normalCnt},
        {isActive: isSlowerActive, percent: slowerPer, cnt: slowerCnt},
      ];
    },
    homeworkSubmitRateOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    homeworkSubmitRateChartData() {
      const homeworkSubmitRate = this.learnerDist.homeworkSubmitRate;

      return {
        labels: [
          '제출완료자',
          '제출미완료자',
        ],
        datasets: [
          {
            data: [homeworkSubmitRate, 100 - homeworkSubmitRate],
            backgroundColor: ['#FEB92E', '#FC6530'],
          },
        ],
      };
    },
    studyCompletionRateOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    studyCompletionRateChartData() {
      const studyCompleteRate = this.learnerDist.studyCompleteRate;

      return {
        labels: [
          '이수율',
          '이수미완율',
        ],
        datasets: [
          {
            data: [studyCompleteRate, 100 - studyCompleteRate],
            backgroundColor: ['#5DF0D6', '#1CD7BB'],
          },
        ],
      };
    },
    selectedStudentItem() {
      const studentItems = this.studentItems;
      for (let index = 0; index < studentItems.length; index++) {
        const studentItem = studentItems[index];
        if (studentItem.isActive) {
          return studentItem;
        }
      }
      return null;
    },
    reportSubTitle() {
      const selectedStudentItem = this.selectedStudentItem;
      if (selectedStudentItem) {
        return `‘${selectedStudentItem.name}’ 학생의 학습 현황`;
      }

      return '반 전체 학생의 리포트';
    },
    learnMinTitle() {
      const targetMin = this.selectedStudentItem?.learn_min ?? this.totalLearningMinute;

      let result = '학습 시간 : ';
      result += `${targetMin}분`;
      if (targetMin >= 60) {
        const hours = targetMin / 60;
        const minutes = targetMin % 60;
        result += `${hours}시간 ${minutes.toString().padEnd(2, '0')}분`
      }
      return result;
    },
    studyReportChartData() {
      const selectedStudentItem = this.selectedStudentItem;

      return {
        labels: [
          '물리',
          '화학',
          '생명과학',
          '지구과학',
        ],
        datasets: [
          {
            label: selectedStudentItem?.name ?? '',
            data: selectedStudentItem ? [
              Math.floor(Math.random() * 101),
              Math.floor(Math.random() * 101),
              Math.floor(Math.random() * 101),
              Math.floor(Math.random() * 101),
            ] : [
              0,
              0,
              0,
              0,
            ],
            fill: false,
            borderColor: '#008ED5',
            backgroundColor: '#008ED5',
          },
          {
            label: '평균',
            data: [
              45,
              30,
              70,
              55,
            ],
            fill: false,
            borderColor: '#F8B633',
            backgroundColor: '#F8B633',
          },
        ],
      };
    },
    studyReportOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
        scales: {
          r: {
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
              font: {
                size: 7,
                family: 'NanumSquare',
              },
            },
            grid: {
              circular: true,
            },
          },
        },
      };
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/values";

.section-1-wrap {
  padding: 25px 0;
  width: 100%;
  display: flex;
  background-color: rgb(245, 245, 245);
  @include mobile {
    padding: 15px 0;
  }
  .v2-base-container {
    gap: 10px;
    @include mobile {
      flex-direction: column;
    }
    .sb-left-wrap {
      display: flex;
      flex-direction: column;
      width: 66.67%;
      gap: 15px;
      @include mobile {
        width: 100%;
      }
      .ai-chat-wrap {
        //cursor: pointer;
        width: 100%;
        display: flex;
        border: 1px solid #DBDBDB;
        box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
        border-radius: 10px;
        background-color: #FFFFFF;
        padding: 10px 10px 10px 20px;
        gap: 5px;
        @include tablet {
          gap: 20px;
        }
        @include mobile {
          gap: 5px;
        }
        .mascot-img-wrap {
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: center;
          justify-content: center;
          &>img {
            width: 65px;
            height: 65px;
            object-fit: contain;
            @include tablet {
              width: 40px;
              height: 40px;
            }
          }
          &>span {
            font-weight: 400;
            font-size: 13px;
            color: #000000;
            @include tablet {
              font-size: 10px;
            }
          }
        }
        .chat-body {
          flex: 1;
          display: flex;
          position: relative;
          padding-left: 11px;
          .cb-arrow {
            position: absolute;
            top: 20px;
            left: 0;
            width: 16px;
            height: 12px;
            object-fit: contain;
          }
          .cb-inner-wrap {
            display: flex;
            justify-content: center;
            background-color: #A4E1FF;
            border-radius: 10px;
            padding: 15px 20px;
            align-items: center;
            word-break: break-all;
            @include tablet {
              padding: 6px 10px;
            }
            &>p {
              font-weight: 400;
              font-size: 16px;
              color: #000000;
              white-space: pre-line;
              line-height: 1.5;
              @include tablet {
                font-size: 13px;
              }
              @include mobile {
                font-size: 10px;
              }
            }
          }
        }
      }
      .sbl-bottom-wrap {
        width: 100%;
        display: flex;
        gap: 10px;
        @include mobile {
          flex-direction: column;
        }
        .sblb-left-wrap {
          width: 44.28%;
          border-radius: 10px;
          background-color: #FFFFFF;
          padding: 10px;
          border: 1px solid #DBDBDB;
          box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
          gap: 5px;
          @include mobile {
            width: 100%;
          }
          .sblbl-tab-wrap {
            width: 100%;
            display: flex;
            border-radius: 5px;
            border: .5px solid #A4E1FF;
            overflow: hidden;
            .tab-item {
              flex: 1;
              text-align: center;
              padding: 10px;
              font-weight: 500;
              font-size: 16px;
              color: #000000;
              @include tablet {
                padding: 5px;
                font-size: 13px;
              }
              &:not(:last-child) {
                border-right: 1px solid #A4E1FF;
              }
              &:not(:first-child) {
                border-left: 1px solid #A4E1FF;
              }
              &.active {
                background-color: #A4E1FF;
              }
            }
          }
          .sblbl-body-wrap {
            width: 100%;
            height: 400px;
            display: flex;
            flex-direction: column;
            direction: rtl;
            overflow-y: scroll;
            @include tablet {
              height: 300px;
            }
            &::-webkit-scrollbar {
              width: 7px;
              background-color: #D9D9D9;
              border-radius: 10px;
              @include tablet {
                width: 3px;
              }
            }
            &::-webkit-scrollbar-thumb {
              background-color: #797979;
              border-radius: 10px;
              width: 100%;
            }
            .sblbl-body-inner-wrap {
              direction: ltr;
              width: 100%;
              display: flex;
              flex-direction: column;
              padding: 0 10px;
              @include tablet {
                padding: 0 7px;
              }
              .sblbl-body-item {
                width: 100%;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                transition: all .2s ease-in-out;
                &:hover {
                  background-color: rgba(0, 0, 0, .05);
                }
                &.active {
                  background-color: rgba(0, 0, 0, .1);
                }
                .bl-info {
                  width: 100%;
                  display: flex;
                  padding-top: 10px;
                  padding-bottom: 5px;
                  align-items: center;
                  .bli-name {
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: center;
                    gap: 7px;
                    @include tablet {
                      gap: 4px;
                    }
                    &>span {
                      display: flex;
                      font-weight: 400;
                      font-size: 16px;
                      color: #000000;
                      @include tablet {
                        font-size: 13px;
                      }
                    }
                    &>div {
                      width: 13px;
                      height: 13px;
                      border-radius: 50%;
                      @include tablet {
                        width: 10px;
                        height: 10px;
                      }
                      &.level--1 {
                        background-color: #F8B633;
                      }
                      &.level-0 {
                        background-color: #65CF55;
                      }
                      &.level-1 {
                        background-color: #008ED5;
                      }
                    }
                  }
                  .bli-homework {
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    &>img {
                      width: 18px;
                      height: 18px;
                      object-fit: contain;
                      @include tablet {
                        width: 14px;
                        height: 14px;
                      }
                    }
                  }
                  .bli-unit {
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    gap: 7px;
                    @include tablet {
                      gap: 5px;
                    }
                    &>img {
                      width: 20px;
                      height: 20px;
                      object-fit: contain;
                      @include tablet {
                        width: 15px;
                        height: 15px;
                      }
                    }
                    &>span {
                      font-weight: 400;
                      font-size: 17px;
                      color: #000000;
                      @include tablet {
                        font-size: 13px;
                      }
                    }
                  }
                }
                .bi-line {
                  width: 100%;
                  background-color: #666666;
                  height: .5px;
                }
              }
            }
          }
        }
        .sblb-right-wrap {
          flex: 1;
          border-radius: 10px;
          background-color: #FFFFFF;
          border: 1px solid #DBDBDB;
          box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 20px;
          @include tablet {
            padding: 10px;
          }
          @include mobile {
            flex: unset;
          }
          &>h1 {
            padding: 10px;
            width: 100%;
            font-weight: 600;
            font-size: 16px;
            color: #000000;
            @include tablet {
              font-size: 13px;
            }
          }
          .learner-dist-wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            @include mobile {
              margin-top: 10px;
            }
            &>h5 {
              margin: 0 auto;
              font-weight: 400;
              font-size: 16px;
              color: #000000;
              @include tablet {
                font-size: 13px;
              }
            }
            .ld-rate-wrap {
              display: flex;
              height: 30px;
              margin-top: 20px;
              @include tablet {
                height: 20px;
              }
              &>div {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                &:nth-child(1) {
                  background-color: #008ED5;
                }
                &:nth-child(2) {
                  background-color: #65CF55;
                }
                &:nth-child(3) {
                  background-color: #F8B633;
                }
                &>span {
                  font-weight: 400;
                  font-size: 10px;
                  color: #000000;
                  line-height: 1.5;
                  @include tablet {
                    font-size: 7px;
                  }
                }
                &>img {
                  position: absolute;
                  bottom: 100%;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 20px;
                  height: 20px;
                  object-fit: contain;
                }
              }
            }
            .ld-rate-info {
              margin-top: 10px;
              display: flex;
              gap: 10px;
              justify-content: center;
              @include tablet {
                gap: 6px;
              }
              .ri-item {
                display: flex;
                gap: 7px;
                align-items: center;
                @include tablet {
                  gap: 4px;
                }
                &>div {
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  @include tablet {
                    width: 7px;
                    height: 7px;
                  }
                }
                &>p {
                  font-weight: 400;
                  font-size: 10px;
                  color: #000000;
                  line-height: 1;
                  @include tablet {
                    font-size: 7px;
                  }
                }
                &:nth-child(1) {
                  &>div {
                    background-color: #008ED5;
                  }
                }
                &:nth-child(2) {
                  &>div {
                    background-color: #65CF55;
                  }
                }
                &:nth-child(3) {
                  &>div {
                    background-color: #F8B633;
                  }
                }
              }
            }
          }
          .rate-wrap {
            width: 100%;
            display: flex;
            gap: 20px;
            @include mobile {
              margin-top: 25px;
            }
            .r-chart-item {
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 15px;
              align-items: center;
              &>h5 {
                font-weight: 400;
                font-size: 16px;
                color: #000000;
                @include tablet {
                  font-size: 13px;
                }
              }
              .rc-body {
                width: 100%;
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                .rcb-inner-wrap {
                  width: 100%;
                  height: 0;
                  padding-bottom: 100%;
                  .rcbi-graph-wrap {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    &>div {
                      aspect-ratio: 1;
                    }
                  }
                }
                &>p {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  font-weight: 400;
                  font-size: 16px;
                  color: #000000;
                  transform: translate(-50%, -50%);
                  @include tablet {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .sb-right-wrap {
      flex: 1;
      border: 1px solid #DBDBDB;
      box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
      border-radius: 10px;
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
      padding: 25px 20px;
      gap: 20px;
      @include tablet {
        padding: 10px;
      }
      .sbr-tab {
        width: 100%;
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid #797979;
        padding-bottom: 10px;
        .tab-item {
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: center;
          cursor: pointer;
          &>img {
            width: 50px;
            height: 50px;
            object-fit: contain;
            @include tablet {
              width: 40px;
              height: 40px;
            }
          }
          &>span {
            font-weight: 400;
            font-size: 16px;
            color: #000000;
            @include tablet {
              font-size: 13px;
            }
          }
        }
      }
      .learning-report {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        &>h1 {
          font-weight: 600;
          font-size: 16px;
          color: #000000;
          @include tablet {
            font-size: 13px;
          }
        }
        &>h2 {
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          @include tablet {
            font-size: 13px;
          }
        }
        .lr-list-wrap {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 5px;
          .list-item {
            width: 100%;
            display: flex;
            align-items: center;
            .item-pin {
              width: 10px;
              height: 10px;
              background-color: #F8B633;
              border-radius: 50%;
              @include tablet {
                width: 7px;
                height: 7px;
              }
            }
            &>p {
              flex: 1;
              padding-left: 8px;
              font-weight: 500;
              font-size: 13px;
              color: #000000;
              @include tablet {
                padding-left: 5px;
                font-size: 10px;
              }
            }
            &>span {
              padding-left: 8px;
              font-weight: 500;
              font-size: 13px;
              color: #797979;
              padding-right: 5px;
              @include tablet {
                padding-left: 5px;
                font-size: 10px;
                padding-right: 2px;
              }
            }
            &>img {
              width: 13px;
              height: 13px;
              object-fit: contain;
              @include tablet {
                width: 10px;
                height: 10px;
              }
            }
          }
        }
      }
      .learning-chart-wrap {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        @include mobile {
          flex: unset;
        }
        .inner-wrap {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          @include mobile {
            position: unset;
            top: unset;
            left: unset;
            width: 250px;
            height: 250px;
            margin: 0 auto;
          }
          &>div {
            aspect-ratio: 1;
          }
        }
        .data-list {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          gap: 5px;
          @include tablet {
            gap: 2px;
          }
          .list-item {
            display: flex;
            gap: 5px;
            align-items: center;
            @include tablet {
              gap: 2px;
            }
            &>img {
              width: 33px;
              height: 9px;
              object-fit: contain;
              @include tablet {
                width: 30px;
                height: 6px;
              }
            }
            &>span {
              font-family: "Inter", sans-serif;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              line-height: 1.3;
              @include tablet {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
