<template>
  <div class="teacher-worksheet-wrap">
    <V2Wrapper>
      <MypageTeacherClassPreWorksheet/>
    </V2Wrapper>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import MypageTeacherClassPreWorksheet from "@/pages/mypage/teacher/class-pre/Worksheet.vue";

export default {
  name: 'TeacherWorksheet',
  components: {
    V2Wrapper,
    MypageTeacherClassPreWorksheet,
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {},
  computed: {},
}
</script>
