import { createStore } from "vuex"
import { auth } from "./auth.module"
import {settings} from "@/store/settings.module";
import {learningHistoryDate} from "@/store/learning_history_date.module";
import {conceptVidItemStore} from "@/store/concept_vid_url_store.module";
import {classStore} from "@/store/class_store.module";
import {selectV2} from "@/store/select_v2.module";

const store = createStore({
  modules: {
    auth,
    settings,
    learningHistoryDate,
    conceptVidItemStore,
    classStore,
    selectV2,
  },
})

export default store
