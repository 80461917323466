<template>
  <div :class="['popup-wrap', 'youtube-embed-popup-wrap', {'hide': !this.isVisible}]">
    <div class="p-shadow"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <div class="popup-title">
          <button class="close-btn" @click="this.onCloseEvent">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
          </button>
        </div>
        <template v-if="this.data != null">
          <iframe
            :src="`https://www.youtube.com/embed/${data.youtubeVideoId}?start=${this.data.startSeconds}&wmode=opaque`"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YoutubeEmbedPopup',
  props: {
    isVisible: Boolean,
    onClose: Function,
    /** @type {YoutubeEmbedPopupItem|null} */
    data: Object,
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.youtube-embed-popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99999999;
  padding: 20px;
  &.hide {
    display: none;
  }
  .p-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .p-body {
    position: relative;
    width: 100%;
    max-width: 700px;
    height: 100%;
    max-height: 600px;
    margin: auto;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #DBDBDB;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
    .pb-inner-wrap {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      .popup-title {
        width: 100%;
        display: flex;
        height: 50px;
        align-items: center;
        padding: 0 24px;
        border-bottom: 1px solid rgb(224, 224, 224);
        .close-btn {
          width: 24px;
          height: 24px;
          cursor: pointer;
          margin-left: auto;
          svg {
            width: 100%;
            color: rgb(112, 112, 112);
            path {
              fill: currentcolor;
            }
          }
        }
      }
      &>iframe {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
    }
  }
}
</style>
