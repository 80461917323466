import {V2RouterItem} from "@/items/v2_router_item";
import {V2RouterSubItem} from "@/items/v2_router_sub_item";

export const EditType = {
    preWorksheet: 'pre_ws',
    preWorkbook: 'pre_wb',
    wrongStudentWork: 'wsw',
};

export const PdfType = {
    worksheet: 'worksheet',
    workbook: 'workbook',
    academyReport: 'academy_report',
    studentWork: 'student_work',
};

export const PdfViewType = {
    preview: 'preview',
    download: 'download',
};

export const PdfTargetType = {
    all: 'all', // default
    only_problem: 'only_problem',
    only_solution: 'only_solution',
};

/** @return {V2RouterItem[]} */
export function getV2TeacherRouterItems() {
    return [
        new V2RouterItem('대시보드', require('@/assets/images/side-nav-class.png'), [
            new V2RouterSubItem('대시보드', 'TeacherDashboard', '/v2/teacher/dashboard'),
        ]),
        new V2RouterItem('수업모드', require('@/assets/images/side-nav-class.png'), [
            new V2RouterSubItem('수업모드', 'TeacherLesson', '/v2/teacher/lesson'),
        ]),
        new V2RouterItem('자가학습', require('@/assets/images/side-nav-self-study.png'), [
            new V2RouterSubItem('자가학습', 'TeacherSelfLearningMode', '/v2/teacher/self_learning_mode'),
            new V2RouterSubItem('자가 학습모드 결과', 'TeacherSelfLearningModeResult', '/v2/teacher/self_learning_result'),
        ]),
        new V2RouterItem('학습지', require('@/assets/images/side-nav-workbook.png'), [
            new V2RouterSubItem('학습지', 'TeacherWorksheet', '/v2/teacher/ws/worksheet'),
            new V2RouterSubItem('교재', 'TeacherWorkbook', '/v2/teacher/ws/workbook'),
            new V2RouterSubItem('테마별 추천학습지', 'TeacherRecTheme', '/v2/teacher/ws/recommend-by-theme'),
            new V2RouterSubItem('나만의 문제', 'TeacherMyProblem', '/v2/teacher/ws/my-problem'),
        ]),
        new V2RouterItem('보고서', require('@/assets/images/side-nav-worksheet.png'), [
            new V2RouterSubItem('학습내역', 'TeacherLearningHistory', '/v2/teacher/rp/learning-history'),
            new V2RouterSubItem('보고서', 'TeacherReport', '/v2/teacher/rp/report'),
        ]),
        new V2RouterItem('설정', require('@/assets/images/side-nav-setting.png'), [
            new V2RouterSubItem('운영 관리', 'TeacherOperation', '/v2/teacher/setting/operation'),
            new V2RouterSubItem('설정', 'TeacherSetting', '/v2/teacher/setting/setting'),
        ]),
    ];
}

/** @return {V2RouterItem[]} */
export function getV2StudentRouterItems(user) {
    const items = [];

    // items.push(new V2RouterItem('대시보드', require('@/assets/images/side-nav-class.png'), [
    //     new V2RouterSubItem('대시보드', 'StudentDashboard', '/v2/student/dashboard'),
    // ]));

    items.push(new V2RouterItem('학습기록', require('@/assets/images/side-nav-worksheet.png'), [
        new V2RouterSubItem('학습기록', 'StudentLearningHistory', '/v2/student/learning_history'),
        new V2RouterSubItem('오답노트', 'StudentWrongNote', '/v2/student/wrong_note'),
    ]));
    items.push(new V2RouterItem('수업모드', require('@/assets/images/side-nav-class.png'), [
        new V2RouterSubItem('수업모드', 'StudentLesson', '/v2/student/dashboard'),
    ]));
    items.push(new V2RouterItem('자가학습', require('@/assets/images/side-nav-self-study.png'), [
        new V2RouterSubItem('자가 학습모드', 'StudentSelfLearningModeList', '/v2/student/self_learning_mode'),
        // new V2RouterSubItem('자가 학습모드 결과', 'StudentSelfLearningModeResult', '/v2/student/self_learning_result'),
    ]));

    return items;
}

export const myPageTeacherRouterNames = [
    {
        'title': '수업준비',
        'subHeader': [
            {
                'title': '학습지',
                'routeName': 'MypageTeacherClassPreWorksheet',
                'path': '/mypage/teacher/class-pre/worksheet',
            },
            {
                'title': '교재',
                'routeName': 'MypageTeacherClassPreWorkbook',
                'path': '/mypage/teacher/class-pre/workbook',
            },
            {
                'title': '테마별 추천학습지',
                'routeName': 'MypageTeacherClassPreRecommendByTheme',
                'path': '/mypage/teacher/class-pre/recommend-by-theme',
            },
            {
                'title': '나만의 문제',
                'routeName': 'MyProblemList',
                'path': '/mypage/teacher/class-pre/my-problem',
            },
        ],
    },
    {
        'title': '수업 및 보고서',
        'subHeader': [
            {
                'title': '학습내역',
                'routeName': 'MypageTeacherLessonHistoryLearningHistory',
                'path': '/mypage/teacher/lesson-history/learning-history',
            },
            {
                'title': '보고서',
                'routeName': 'MypageTeacherLessonHistoryReport',
                'path': '/mypage/teacher/lesson-history/report',
            },
        ],
    },
    {
        'title': '관리',
        'subHeader': [
            {
                'title': '운영 관리',
                'routeName': 'MypageTeacherManageOperation',
                'path': '/mypage/teacher/manage/operation',
            },
        ],
    },
];

export const DownloadType = {
    merge: 'merge', // 하나의 PDF로 받기
    individual: 'individual', // 개별 PDF로 받기
};

export const StudentProgressType = {
    slower: -1, // 느린 학습자
    normal: 0, // 일반 학습자
    faster: 1, // 빠른 학습자
};

export const StudentHomeworkProgressType = {
    none: -1, // 숙제 미진행
    processing: 0, // 숙제 진행 중
    completed: 1, // 숙제 완료
};
