import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/home-publishing/home-publishing.scss'
import './assets/scss/base.scss'
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";
import slider from "vue3-slider"
import CustomButton from "@/components/CustomButton.vue"
import CustomCheckbox from "@/components/CustomCheckbox.vue"
import CustomRadioButton from "@/components/CustomRadioButton.vue"
import Toast, { useToast, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import store from "./store";
import BootstrapVue3 from 'bootstrap-vue-3'
import LoadScript from 'vue-plugin-load-script';

const app = createApp(App)
    .use(router)
    .use(store)
    .use(BootstrapVue3)
    .use(LoadScript)
    .component('swiper', Swiper)
    .component('swiper-slide', SwiperSlide)
    .component('slider', slider)
    .component('CustomButton', CustomButton)
    .component('CustomCheckbox', CustomCheckbox)
    .component('CustomRadioButton', CustomRadioButton)

// init toast
const toast = useToast()
app.use(Toast, {
    position: POSITION.BOTTOM_RIGHT,
})
app.provide('toast', toast)
app.config.globalProperties.$toast = toast

// mount app
app.mount('#app')
window.app = app;

export default app
