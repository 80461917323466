<template>
  <div :class="['popup', { hide: !isVisible }]">
    <div class="popup-shadow" @click="this.onClose"></div>
    <div :class="['popup-body']">
      <div class="popup-title">
        {{ academyUser ? '학부모 상세 정보' : '학부모 등록' }}

        <button class="close-btn" @click="this.onClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </button>
      </div>

      <div class="body-contents">
        <div class="body-section-title">학부모 정보 입력</div>

        <div class="body-tr">
          <div class="body-item">
            <p>이름</p>
            <CustomInput
              addClass="default-border"
              :value="name"
              placeholder="이름을 입력해주세요"
              :isReadOnly="true"
              />
          </div>
          <div class="body-item">
            <p>연락처</p>
            <CustomInput
              addClass="default-border"
              :value="phone"
              placeholder="숫자만 입력해주세요"
              :isReadOnly="true"
              />
            </div>
        </div>

        <div class="body-tr">
          <div class="body-item">
            <p>이메일</p>
            <CustomInput
              addClass="default-border"
              :value="email"
              placeholder="예시 : sduck@sduck.com"
              :isReadOnly="true"
              />
          </div>
          <div class="body-item">
            <p>연결된 학생명</p>
            <CustomInput
              addClass="default-border"
              :value="academyUser?.user.connect_student_user.name"
              placeholder="예시 : sduck@sduck.com"
              :isReadOnly="true"
              />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import academyService from '../services/academy.service'
import CustomInput from './CustomInput.vue'

  export default {
    name: 'TeacherPopup',
    components: {
      CustomInput,
    },
    props: {
      isVisible: Boolean,
      onClose: Function,
      academyUser: Object,
    },
    watch: {
      academyUser() {
        this.initData()
      },
      isVisible() {
        this.initData()
      },
    },
    data() {
      return {
        name: null,
        schoolType: null,
        schoolYear: null,
        phone: null,
        isInclude: true,
        email: null,

        birth: null,
        schoolName: null,
        parentPhone: null,
        classStartDate: null,
        address: null,
        etc: null,
      }
    },
    mounted() {
      this.initData()
    },
    methods: {
      initData() {
        this.name = this.academyUser?.name
        this.schoolType = this.academyUser?.school_type
        this.schoolYear = this.academyUser?.school_year
        this.phone = this.academyUser?.user?.phone
        this.isInclude = this.academyUser?.is_include
        this.email = this.academyUser?.user?.email

        this.birth = this.academyUser?.birth
        this.schoolName = this.academyUser?.school_name
        this.parentPhone = this.academyUser?.parent_phone
        this.classStartDate = this.academyUser?.class_start_date
        this.address = this.academyUser?.address
        this.etc = this.academyUser?.etc
      },
      async onSubmit() {
        if (!this.name) { this.$toast.error("이름을 입력해주세요") }
        else if (!this.schoolType || !this.schoolYear) { this.$toast.error("학년을 입력해주세요") }
        else if (!this.phone) { this.$toast.error("연락처를 입력해주세요") }
        else if (this.isInclude == undefined || this.isInclude == null ) { this.$toast.error("상태를 선택해주세요") }
        else if (!this.email) { this.$toast.error("이메일을 입력해주세요") }
        else {
          let res = null
          const payload = {
              name: this.name,
              school_type: this.schoolType,
              school_year: this.schoolYear,
              is_include: this.isInclude,
              phone: this.phone,
              email: this.email,
              is_student: true,

              birth: this.birth,
              school_name: this.schoolName,
              parent_phone: this.parentPhone,
              class_start_date: this.classStartDate,
              address: this.address,
              etc: this.etc,

              academy_class: this.academyUser?.academy_class?.id,
            }

          if (this.academyUser) { // update
            res = await academyService.updateAcademyUser(this.academyUser.id, payload)
          } else { // create
            res = await academyService.createAcademyUser(payload)
          }

          if (res) {
            this.$toast.success(`${this.academyUser ? '수정' : '등록'}되었습니다`)
            this.onClose()
          } else {
            this.$toast.error(`${this.academyUser ? '수정' : '등록'} 중 오류가 발생하였습니다`)
          }
        }
      },
    },
    computed: {
      schoolYearOptions() {
        return Array.apply({}, Array(this.schoolType == '초등학교' ? 6 : 3)).map((_, i) => {
          const value = `${i + 1}학년`
          return {
            value: value,
            name: value,
            isSelected: this.schoolYear == value,
          }
        })
      },
    },
  }
  </script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99;
  &.hide {
    display: none;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    max-width: 750px;
    max-height: 100%;
    margin: auto;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #231f20;
    box-sizing: border-box;
    .popup-title {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-weight: 500;
      display: flex;
      height: 72px;
      align-items: center;
      padding: 0px 24px;
      border-bottom: 1px solid rgb(224, 224, 224);
      .close-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        svg {
          width: 100%;
          color: rgb(112, 112, 112);
          path {
            fill: currentcolor;
          }
        }
      }
    }
    .body-contents {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      gap: 24px 0;
      border-bottom: 1px solid rgb(224, 224, 224);
      padding-bottom: 24px;
      overflow-y: auto;
      .body-section-title {
        width: 100%;
        height: 32px;
        font-size: 12px;
        line-height: 18px;
        background-color: rgb(250, 250, 250);
        border-top: 1px solid rgb(232, 232, 232);
        color: rgb(112, 112, 112);
        text-align: start;
        align-items: center;
        display: flex;
        padding: 0 24px;
      }
      .body-tr {
        width: 100%;
        display: flex;
        gap: 24px;
        padding: 0 24px;
        .body-item {
          max-width: 50%;
          width: 100%;
          display: flex;
          &.item-one {
            max-width: 100%;
          }
          p {
            display: flex;
            margin-right: 12px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: rgb(51, 51, 51);
            align-items: center;
            width: 100px;
            b {
              font-size: 6px;
              color: rgb(255, 0, 0);
            }
          }
          input, textarea {
            flex: 1;
          }
          textarea {
            min-height: 100px;
          }
          button {
            padding: 9px 12px;
          }
          .year-list {
            flex: 1;
            display: flex;
            gap: 10px;
            select {
              height: 100%;
              flex: 1;
              max-width: 90px;
              opacity: 0;
              &.visible { 
                opacity: 1;
              }
            }
          }
          .include-list {
            flex: 1;
            display: flex;
            gap: 10px;
          }
        }
      }
    }
    .btn-list {
        width: 100%;
        display: flex;
        gap: 8px;
        justify-content: flex-end;
        align-items: center;
        padding: 24px;
        &>button {
          align-items: center;
          justify-content: center;
          display: flex;
          cursor: pointer;
          width: 150px;
          height: 40px;
          border-radius: 4px;
          transition: opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1) 0s;
          background-color: #008ED5;
          color: white;
          font-weight: 500;
          line-height: 1;
        }
    }
  }
}
</style>
  