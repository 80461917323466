<template>
  <div :class="['popup', {'hide': !this.isVisible}]">
    <div class="popup-shadow" @click="this.onClose"></div>
    <div :class="['popup-body']">
      <div class="popup-title">
        <h1>가져오기</h1>
        <h2>{{ this.menuTitle }}</h2>
        <button class="close-btn" @click="this.onClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </button>
      </div>
      <div class="body-contents" ref="bodyContents">
        <h2>연결된 하위 페이지와 함께 가져옵니다<br/>해당 페이지를 내 개인 페이지로 가져오시겠습니까?<br/></h2>
      </div>
      <div class="btn-list">
        <button @click="onClose">취소</button>
        <button @click="onSubmit">가져오기</button>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <LoadingDialog :is-show="this.isLoadingDialog"/>
  </Teleport>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog.vue";
import BoardService from "@/services/board.service";

export default {
  components: {LoadingDialog},
  props: {
    isVisible: {type: Boolean, default: false},
    onClose: Function,
    menuId: Number,
    menuTitle: String,
  },
  watch: {
    async isVisible() {
      if (this.isVisible) {
        this.$refs.bodyContents.scrollTop = 0;
      }
    },
  },
  data() {
    return {
      isLoadingDialog: false,
    };
  },
  methods: {
    async onSubmit() {
      const menuId = this.menuId ?? null;
      if (menuId === null) {
        this.$toast.error('메뉴 ID를 확인할 수 없습니다');
        return;
      }

      this.isLoadingDialog = true;

      try {
        const res = await BoardService.menuImport(menuId);
        if (res) {
          this.$toast.success('개인 페이지로 가져왔습니다')
        } else {
          this.$toast.error('가져오기에 실패하였습니다')
        }
      } catch (e) {
        this.$toast.error('가져오기 요청 중 오류가 발생하였습니다')
      }

      this.onClose();
      this.isLoadingDialog = false;
      location.reload();
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99;
  &.hide {
    display: none;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    max-width: calc(100% - 40px - 40px);
    min-width: 600px;
    margin: auto;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #231f20;
    box-sizing: border-box;
    .popup-title {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-weight: 500;
      display: flex;
      height: 72px;
      align-items: center;
      padding: 0 24px;
      border-bottom: 1px solid rgb(224, 224, 224);
      &>h1 {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.01em;
      }
      &>h2 {
        margin-left: 12px;
        color: rgb(112, 112, 112);
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.002em;
      }
      &>.close-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        &>svg {
          width: 100%;
          color: rgb(112, 112, 112);
          &>path {
            fill: currentcolor;
          }
        }
      }
    }
    &>.body-contents {
      flex: 1;
      padding: 24px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow-y: auto;
      &>h2 {
        width: 100%;
        font-weight: 600;
        font-size: 14px;
      }
    }
    &>.btn-list {
      border-top: 1px solid rgb(224, 224, 224);
      width: 100%;
      display: flex;
      gap: 8px;
      justify-content: flex-end;
      align-items: center;
      padding: 10px 24px;
      &>button {
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
        width: 150px;
        height: 40px;
        border-radius: 4px;
        transition: opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1) 0s;
        color: white;
        font-weight: 500;
        line-height: 1;
        &:nth-child(1) {
          background-color: #{$redColor};
        }
        &:nth-child(2) {
          background-color: #{$defaultColor};
        }
      }
    }
  }
}
</style>
