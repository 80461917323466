<template>
  <div class="container-wrap">
    <template v-for="(listItem, index) in this.listItems" :key="index">
      <button @click="listItem.onClick"
      >{{ index + 1 }}. {{ listItem.title }}</button>
    </template>
  </div>
</template>

<script>
import AIAssistantPopup from "@/components/AIAssistantPopup.vue";
import AnalysisDetailPopup from "@/components/AnalysisDetailPopup.vue";
import DifficultySettingPopup from "@/components/DifficultySettingPopup.vue";
import LearningStatisticsPopup from "@/components/LearningStatisticsPopup.vue";

export default {
  name: 'V2PublishingList',
  components: {LearningStatisticsPopup, DifficultySettingPopup, AnalysisDetailPopup, AIAssistantPopup},
  data() {
    return {
      listItems: [
        {title: '선생님 대시보드', onClick: () => this.$router.push({ name: 'TeacherDashboard'})},
        {title: '학생 대시보드', onClick: () => this.$router.push({ name: 'StudentDashboard'})},
      ],
    };
  },
}
</script>

<style scoped lang="scss">
.container-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 20px;
}
</style>
