<template>
    <div class="tree-detail-wrap">
        <TreeList
            :nodes="nodes"
            />
        <CheckQuestion
            :questions="questions"
            />
    </div>
</template>

<script>
import CheckQuestion from './CheckQuestion.vue';
import TreeList from './TreeList.vue';

export default {
    name: "TreeDetail",
    props: {
        nodes: Object,
        questions: Object,
    },
    components: { TreeList, CheckQuestion },
}
</script>

<style lang="scss" scoped>
.tree-detail-wrap {
    width: 100%;
    display: flex;
    flex: 1;
    &>* {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 31px 10px 20px 38px;
        border-top: 2px solid #008ED5;
        border-bottom: 2px solid #008ED5;
        margin-top: auto;
        flex: 1;
        overflow: hidden;
        margin-top: 40px;
        &:first-child {
            border-right: 2px solid #008ED5;
            padding-left: 0;
            padding-right: 5px;
        }
    }
}
</style>