<template>
  <template v-if="isMypageTeacher">
    <button
        :class="['header-free-exp-btn', {'visible': isVisibleFreeBtn}]"
        @click="() => this.$router.push({ name: 'PriceInfoPayment', query: {'item': 'free'} })"
    >10일 무료 체험</button>
    <div :class="['header-wrap', {'header-home': isVisibleFreeBtn}]">
      <div class="base-container">
        <router-link class="h-logo-link" :to="{ name: 'home' }">
          <img class="h-logo" src="@/assets/images/logo.png" alt=""/>
        </router-link>

        <div class="h-list">
          <router-link
              v-for="(item, index) in hList"
              :key="index"
              :class="['h-link', {'active': item.isActive}]"
              :to="{ name: item.routeName }">
            {{ item.title }}
          </router-link>
        </div>

        <div class="h-pannel">
          <template v-if="isLogin">
            <template v-if="this.isMypageTeacher">
              <button class="h-link"
                      @click="() => this.onClickV2()"
              >V2 전환</button>
            </template>
            <router-link
                class="h-link link-my"
                :to="{ name: this.mypageRouteName }"
            >
              <template v-if="this.isMypageTeacher">
                <b-button class="h-tooltip" v-b-tooltip.hover.bottom title="선생님 페이지로 이동합니다">
                  <img
                      src="@/assets/images/mypage-question-mark.png"
                      alt=""
                  />
                </b-button>
              </template>
              <span>{{ this.getUser.name ?? '알수없음' }}</span>
            </router-link>
            <button :class="['h-link', {'active': isActiveRouteName('signup') || isActiveRouteName('signup-teacher') || isActiveRouteName('signup-student')}]"
                    @click="this.signOutEvent">
              로그아웃
            </button>
          </template>
          <template v-else>
            <router-link :class="['h-link', {'active': isActiveRouteName('login')}]" :to="{ name: 'login' }">
              로그인
            </router-link>
            <router-link :class="['h-link', {'active': isActiveRouteName('signup') || isActiveRouteName('signup-teacher') || isActiveRouteName('signup-student')}]" :to="{ name: 'signup' }">
              회원가입
            </router-link>
          </template>
        </div>
      </div>
    </div>
    <div class="sub-header-wrap" v-if="this.isMypageTeacher">
      <div class="base-container">
        <div class="sh-list">
          <router-link
              v-for="(item, index) in shList"
              :key="index"
              :class="['sh-link', {'active': item.isActive}]"
              :to="{ name: item.routeName }">
            {{ item.title }}
          </router-link>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div id="header">
      <div class="wrapper">
        <div class="logo_area">
          <a :href="this.$router.resolve({name: 'home'}).href"><img src="@/assets/img/assets/logo-header.svg" alt="에스덕"></a>
        </div>
        <div class="menu_area">
          <div class="gnb_wrap">
            <div class="gnb">
              <ul>
                <li><a :href="this.$router.resolve({name: 'support-workbook'}).href">지원교재</a></li>
                <li><a :href="this.$router.resolve({name: 'price-info'}).href">요금안내</a></li>
                <li><a href="https://www.notion.so/hi-s-duck/f2027538b9a04f2d84464b42799862ff?v=6bf86985dc3a497fb4629bcc8d0e8692&pvs=4">사용가이드</a></li>
                <li><a :href="this.$router.resolve({name: 'support'}).href">고객센터</a></li>
                <li><a :href="this.$router.resolve({name: 'event'}).href">이벤트</a></li>
              </ul>
            </div>
          </div>
          <template v-if="isLogin">
            <div class="sub_wrap">
              <div class="sub">
                <a :href="this.$router.resolve({name: this.mypageRouteName}).href" class="btn_link">{{ this.getUser.name }}님</a>
                <a href="#" class="btn_sub" @click="this.signOutEvent">로그아웃</a>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="sub_wrap">
              <div class="sub">
                <a :href="this.$router.resolve({name: 'login'}).href" class="btn_sub">로그인</a>
                <a :href="this.$router.resolve({name: 'signup'}).href" class="btn_basic">회원가입</a>
              </div>
            </div>
          </template>
        </div>
        <button type="button" class="btn_menu"><i>메뉴</i></button>
      </div>
    </div>
  </template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import utils from "@/utils";
import {getFirstTeacherRouteName} from "@/utils/parse";

export default {
  name: 'Header',
  props: {
    isMypageTeacher: Boolean,
    isMypageStudent: Boolean,
    isMypageParents: Boolean,
  },
  data() {
    return {
      userName: '홍길동 선생님',
      myPageTeacherRouterNames: utils.myPageTeacherRouterNames,
    };
  },
  mounted() {
  },
  methods: {
    isActiveRouteName(routeName) {
      return this.$route.name == routeName;
    },
    signOutEvent() {
      this.signout();
      this.$router.push({ name: 'login' });
    },
    onClickV2() {
      this.setIsSelectV2(true);
      this.$router.push({ name: 'TeacherDashboard' });
    },
    ...mapActions('auth', ['signout']),
    ...mapActions('selectV2', ['setIsSelectV2']),
  },
  computed: {
    mypageRouteName() {
      const isStudent = this.getUser?.academy_user?.is_student
      const isParent = this.getUser?.academy_user?.is_parents
      if (isStudent) {
        const routerItems = utils.getV2StudentRouterItems(this.getUser);
        if (routerItems.length === 0) {
          return 'RedirectStore';
        } else {
          return routerItems[0].subItems[0].routerName;
        }
      } else if (isParent) {
        return 'RedirectStore';
      } else {
        return utils.getFirstTeacherRouteName(this.isSelectV2);
      }
    },
    hList() {
      const curRouteName = this.$route.name
      const curRoutePath = this.$route.path

      if (this.isMypageTeacher) {
        const foundMyPageTeacher = this.myPageTeacherRouterNames.find(item => item.subHeader.find(subItem => subItem.routeName == curRouteName || curRoutePath.includes(subItem.path)))
        return this.myPageTeacherRouterNames.map(item => {
          const resultItem = item
          resultItem['isActive'] = item == foundMyPageTeacher
          resultItem['routeName'] = item.subHeader.length > 0 ? item.subHeader[0].routeName : ''
          return resultItem
        })
      } else {
        return []
      }
    },
    shList() {
      const curRouteName = this.$route.name
      const curRoutePath = this.$route.path

      const foundMyPageTeacher = this.myPageTeacherRouterNames.find(item => item.subHeader.find(subItem => subItem.routeName == curRouteName || curRoutePath.includes(subItem.path)))
      if (foundMyPageTeacher != null) {
        return foundMyPageTeacher.subHeader.map(subItem => {
          const resultItem = subItem
          resultItem['isActive'] = subItem.routeName == curRouteName || curRoutePath.includes(subItem.path)
          return resultItem
        })
      } else {
        return [];
      }
    },
    isVisibleFreeBtn() {
      return this.isActiveRouteName('home') && !this.getUser.is_use_free
    },
    ...mapGetters('auth', ['getUser', 'isLogin']),
    ...mapGetters('selectV2', ['isSelectV2']),
  },
}
</script>
