const item = JSON.parse(localStorage.getItem('settings'));
const initialState = item ?? [
  [
    {
      title: '빠른 학습자',
      items: [
        {name: '개념영상', isSelect: false},
        {name: '심화문제', isSelect: true},
        {name: '취약개념', isSelect: true},
        {name: '취약문제', isSelect: false},
        {name: '실험콘텐츠', isSelect: false},
        {name: '실감콘텐츠', isSelect: false},
      ],
    },
    {
      title: '일반 학습자',
      items: [
        {name: '개념영상', isSelect: true},
        {name: '심화문제', isSelect: false},
        {name: '취약개념', isSelect: true},
        {name: '취약문제', isSelect: false},
        {name: '실험콘텐츠', isSelect: true},
        {name: '실감콘텐츠', isSelect: true},
      ],
    },
    {
      title: '느린 학습자',
      items: [
        {name: '개념영상', isSelect: true},
        {name: '심화문제', isSelect: false},
        {name: '취약개념', isSelect: true},
        {name: '취약문제', isSelect: true},
        {name: '실험콘텐츠', isSelect: false},
        {name: '실감콘텐츠', isSelect: false},
      ],
    },
  ],
  [
    {
      title: '빠른 학습자',
      items: [
        {name: '개념영상', isSelect: false},
        {name: '심화문제', isSelect: true},
        {name: '취약개념', isSelect: true},
        {name: '취약문제', isSelect: false},
        {name: '실험콘텐츠', isSelect: false},
        {name: '실감콘텐츠', isSelect: false},
      ],
    },
    {
      title: '일반 학습자',
      items: [
        {name: '개념영상', isSelect: true},
        {name: '심화문제', isSelect: false},
        {name: '취약개념', isSelect: true},
        {name: '취약문제', isSelect: false},
        {name: '실험콘텐츠', isSelect: true},
        {name: '실감콘텐츠', isSelect: true},
      ],
    },
    {
      title: '느린 학습자',
      items: [
        {name: '개념영상', isSelect: true},
        {name: '심화문제', isSelect: false},
        {name: '취약개념', isSelect: true},
        {name: '취약문제', isSelect: true},
        {name: '실험콘텐츠', isSelect: false},
        {name: '실감콘텐츠', isSelect: false},
      ],
    },
  ],
];

export const settings = {
  namespaced: true,
  state: initialState,
  actions: {
    changeSettingItemSelect({ commit }, params) {
      commit('changeSettingItemSelect', params);
    },
  },
  mutations: {
    changeSettingItemSelect(state, params) {
      state[params.mainIndex][params.midIndex].items[params.subIndex].isSelect = params.isSelect;
      localStorage.setItem('settings', JSON.stringify(state));
    },
  },
  getters: {
    getSettingDiag: (state) => {
      return state[0];
    },
    getSettingUnit: (state) => {
      return state[1];
    },
  },
};
