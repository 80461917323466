<template>
  <div
      v-if="computedListData"
      :class="['list-array-wrap', {
        'grid-single': computedListData.length <= 1,
      }]"
  >
    <div
        v-for="(listData, ldIndex) in computedListData"
        :key="ldIndex"
        class="list-wrap"
    >
      <h1 v-if="listData.title">
        {{ listData.title }}
      </h1>
      <div class="list-header-wrap">
        <div class="list-tr">
          <div
              v-for="(head, index) in listData.heads" :key="index"
              :class="['list-th', head.addClass]"
          >
            {{ head.title }}
          </div>
        </div>
      </div>
      <div class="list-body-wrap">
        <template v-if="this.isLoading">
          <div class="loading-body">
            <b-spinner
                style="width: 3rem; height: 3rem;"
                variant="primary"
                label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template
            v-for="(tr, trIndex) in listData.trList"
            :key="trIndex">
          <ListArrayBodyTr
              :tdList="tr"
              :hasCPointer="this.hasCPointer(tr)"
              :tdAddClassList="this.getTdAddClassList(ldIndex)"
              :tdTypeList="this.getTdTypeList(ldIndex)"
          />
        </template>

        <slot name="listBody"></slot>
      </div>
    </div>
  </div>
  <template v-if="paginateData">
    <div class="paginate-wrap">
      <div class="paginate-arrow" @click="() => this.onPageChange(1)">
        <div class="img-rel">
          <img src="@/assets/images/pagination-arrow-end-left.png" alt="">
        </div>
      </div>
      <div class="paginate-arrow" @click="() => this.onPageChange(this.paginateData.page > 1 ? this.paginateData.page - 1 : 1)">
        <div class="img-rel">
          <img src="@/assets/images/pagination-arrow-left.png" alt="">
        </div>
      </div>

      <div class="paginate-list-wrap">
        <template v-for="(page, index) in this.paginateList" :key="index">
          <div :class="['paginate-item', {
            'active': page == this.paginateData.page,
          }]" @click="() => this.onPageChange(page)">
            {{ page }}
          </div>
        </template>
      </div>

      <div class="paginate-arrow" @click="() => this.onPageChange(this.paginateData.page < this.paginateData.totalPage ? this.paginateData.page + 1 : this.paginateData.totalPage)">
        <div class="img-rel">
          <img src="@/assets/images/pagination-arrow-right.png" alt="">
        </div>
      </div>
      <div class="paginate-arrow" @click="() => this.onPageChange(this.paginateData.totalPage)">
        <div class="img-rel">
          <img src="@/assets/images/pagination-arrow-end-right.png" alt="">
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import ListArrayBodyTr from './ListArrayBodyTr.vue'
import Image from "@/components/Image.vue";

export default {
  name: "ListArray",
  props: {
    listDatas: Array,
    listData: Object,
    paginateData: Object,
    onPageChange: Function,
    isLoading: Boolean,
  },
  computed: {
    computedListData() {
      if (this.listDatas) {
        return this.listDatas;
      }
      else {
        return [this.listData];
      }
    },
    paginateList() {
      const paginateData = this.paginateData;
      if (!paginateData) {
        return [];
      }

      const totalPageCnt = paginateData.totalPage;
      const page = paginateData.page;
      const maxVisiblePage = paginateData.maxVisiblePage;

      const pages = [page];
      let addPage = page;
      let subtractPage = page;
      while (pages.length < maxVisiblePage) {
        let stack = 0;

        addPage++;
        if (addPage <= totalPageCnt) {
          pages.push(addPage);
          stack++;
        }

        subtractPage--;
        if (subtractPage >= 1) {
          pages.push(subtractPage);
          stack++;
        }

        if (stack == 0) {
          break;
        }
      }

      pages.sort((a, b) => {
        if (a > b) return 1;
        if (a == b) return 0;
        if (a < b) return -1;
      })

      return pages;
    },
  },
  components: {Image, ListArrayBodyTr },
  methods: {
    hasCPointer(tr) {
      const findIndex = Object.values(tr).findIndex(item => item.onClick);
      return findIndex > -1;
    },
    getTdAddClassList(ldIndex) {
      return this.computedListData[ldIndex].heads.map(head => head.addClass)
    },
    getTdTypeList(ldIndex) {
      return this.computedListData[ldIndex].heads.map(head => head.type)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.paginate-wrap {
  min-height: 50px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  .paginate-arrow {
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 5px;
    .img-rel {
      width: 100%;
      height: 100%;
      position: relative;
      &>img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .paginate-list-wrap {
    display: flex;
    gap: 10px;
    .paginate-item {
      cursor: pointer;
      display: flex;
      min-width: 25px;
      min-height: 30px;
      align-items: center;
      text-align: center;
      line-height: 1;
      justify-content: center;
      font-weight: 500;
      font-size: 18px;
      &.active {
        font-weight: 700;
        color: #{$defaultColor};
      }
    }
  }
}
</style>
