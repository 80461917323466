<template>
  <div :class="['mypage-tab-body-wrap']">
    <Filter
        :filterData="filterData"
    />

    <ListArray
        :listData="this.listData"
        :isLoading="this.isLoading"
    >
    </ListArray>

    <!-- <div :class="['select-list', {'open': checkItems.length > 0}]">
        <h1>학부모 {{ checkItems.length }}명 선택됨</h1>

        <div class="btn-list">
            <div class="btn-item" @click="() => onChangeIncludeItem(false)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" data-svg-name="block" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 16px; color: white;"><path fill="#fff" d="M8 1.333C4.32 1.333 1.333 4.32 1.333 8c0 3.68 2.987 6.667 6.667 6.667 3.68 0 6.667-2.987 6.667-6.667 0-3.68-2.987-6.667-6.667-6.667zM2.667 8c0-2.947 2.386-5.333 5.333-5.333 1.233 0 2.367.42 3.267 1.126l-7.474 7.474c-.706-.9-1.126-2.034-1.126-3.267zM8 13.333c-1.233 0-2.367-.42-3.267-1.126l7.474-7.474c.706.9 1.126 2.034 1.126 3.267 0 2.947-2.386 5.333-5.333 5.333z"></path></svg>
                <span>퇴원 처리</span>
            </div>
            <div class="close-btn" @click="() => checkItems.forEach(i => i.isChecked = false)">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(159, 159, 159);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
            </div>
        </div>
    </div> -->
  </div>

  <Teleport to="body">
    <Popup
      :isTitleExclamationMark="true"
      :popupTitle="'주의'"
      :isVisible="isOpenDeletePopup"
      :onClose="() => {isOpenDeletePopup = false}"
      :onConfirm="onDeleteItems"
      :onCancel="() => {}"
    >
      <template v-slot:bodyContents>
        <div>학부모을 삭제하면 관련 내용이 모두 사라지고<br>다시 복구가 불가능합니다.<br>정말 삭제하시겠습니까?</div>
      </template>
    </Popup>
    <ParentsPopup
      :isVisible="isOpenParentsPopup"
      :academyUser="popupTargetItem"
      :onClose="() => {popupTargetItem = null; isOpenParentsPopup = false; initData()}"
    />
  </Teleport>
</template>

<script>
import Filter from './Filter.vue'
import ListArray from './ListArray.vue'
import Popup from '@/components/Popup.vue'
import academyService from '../services/academy.service'
import utils from '../utils'
import ParentsPopup from './ParentsPopup.vue'

export default {
  name: 'OperationTeacher',
  components: {
    Filter,
    Popup,
    ListArray,
    ParentsPopup,
  },
  props: {
    academyInfo: Object,
  },
  watch: {
    academyInfo() {
      this.initData()
    },
    isVisibleRequest() {
      this.initData()
    },
  },
  data() {
    return {
      isVisibleRequest: false,
      isOpenDeletePopup: false,
      requests: [],
      items: [],
      popupTargetItem: null,
      isOpenParentsPopup: false,
      isLoading: true,
    }
  },
  created() {
    this.onChangeTab(this.selectTabIndex)
    this.initData()
  },
  methods: {
    async initData() {
      if (this.academyInfo) {
        this.items = []
        this.requests = []
        this.isLoading = true;

        await Promise.all([
          academyService.getAcademyUsers({
            academy_info_id: this.academyInfo.id,
            is_parents: true,
          }).then((e) => this.items = e),
          academyService.getAcademyMemberRequests({
            academy_info_id: this.academyInfo.id,
            is_parents: true,
            is_accept: false,
            is_reject: false,
          }).then((e) => this.requests = e),
        ]);
        this.isLoading = false;
      }
    },
    onChangeTab(index) {
      this.selectTabIndex = index
    },
    onDeleteItems() {
    },
    async acceptMemberRequest(request) {
      await academyService.acceptMemberRequest(request.id)
      this.$toast.success('승인 되었습니다')

      this.initData()
    },
    async rejectMemberRequest(request) {
      await academyService.rejectMemberRequest(request.id)
      this.$toast.success('거부 되었습니다')

      this.initData()
    },
    onChangeItem(item) {
      // const findItem = this.items.find(i => i.id == item.id)
      // if (findItem) {
      //     findItem.isChecked = !findItem.isChecked
      // }
      this.popupTargetItem = item; this.isOpenParentsPopup = true
    },
  },
  computed: {
    filterData() {
      return {
        title: '목록 필터',
        list: [
          {type: 'checkbox', addClass: '', name: `요청 건(${Number(this.requests.length)}) 보기`, value: this.isVisibleRequest, onChange: (value) => this.isVisibleRequest = value},
        ],
        searchInput: {isHidden: true},
        // createBtn: {isVisible: true, onCreate: () => this.isOpenParentsPopup = true, name: '학부모 등록'}
      }
    },
    listData() {
      let trList = [];
      if (!this.isLoading) {
        if (this.isVisibleRequest) {
          trList = this.requests.map(request => {
            return [
              {value: request.user.name},
              {value: utils.getDateByServer(request.create_dt)},
              {value: '승인하기', onClickSubmitBtn: () => this.acceptMemberRequest(request), addClass: 'active-btn'},
              {value: '거부하기', onClickSubmitBtn: () => this.rejectMemberRequest(request), addClass: 'red-btn'},
            ]
          });
        } else {
          trList = this.items.map(item => {
            const result = [
              // {isChecked: item.isChecked, onChange: () => this. onChangeItem(item)},
              {value: item.name},
              {value: item.user.connect_student_user.name},
              {value: item.user.phone},
              {value: item.user.email},
              {value: '상세', onClickSubmitBtn: () => {this.popupTargetItem = item; this.isOpenParentsPopup = true}},
            ]

            result.forEach(i => {
              i.onClick = () => this.onChangeItem(item)
            })

            return result
          });
        }
      }

      return {
        title: '학부모',
        heads: this.isVisibleRequest
            ? [
              {title: '요청자명', type: 'string', addClass: 'flex-1'},
              {title: '요청일시', type: 'string', addClass: 'w-100px'},
              {title: '승인', type: 'submit-btn', addClass: 'w-110px'},
              {title: '거부', type: 'submit-btn', addClass: 'w-110px'},
            ]
            : [
              // {title: '선택', type: 'checkbox', addClass: 'w-75px'},
              {title: '학부모명', type: 'string', addClass: 'flex-1'},
              {title: '학생명', type: 'string', addClass: 'flex-1'},
              {title: '연락처', type: 'string', addClass: 'w-200px'},
              {title: '이메일', type: 'string', addClass: 'w-200px'},
              {title: '상세', type: 'submit-btn', addClass: 'w-100px'},
            ],
        trList: trList,
      };
    },
    checkItems() {
      return this.items.filter(i => i.isChecked)
    },
  },
}
</script>
