<template>
  <div class="student-wrong-note-detail-wrap">
    <V2Wrapper>
      <div class="swndw-inner">
        <template v-if="this.errorMsg !== null">
          <ErrorMsgBox :contents="this.errorMsg"/>
        </template>
        <template v-else-if="this.isLoading">
          <div class="iw-loading">
            <b-spinner
                style="width: 3rem; height: 3rem;"
                label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template v-else>
          <div class="iw-header-wrap">
            <div class="v2-base-container">
              <button class="hw-btn btn-back"
                      @click="() => this.handleBackPressed()"
              >
                <img src="@/assets/images/arrow-left-4.png" alt="">
              </button>
              <div class="hw-btn btn-back-empty"></div>
              <p>{{ this.headerTitle }}</p>
              <div class="hw-btn btn-empty"></div>
            </div>
          </div>
          <div class="iw-body-wrap">
            <div class="v2-base-container">
              <div class="bc-tab-list-wrap">
                <div class="tl-line">
                  <button :class="['tl-item', {'active': this.showType === AnswerDetailShowTypeExt.onlyWrong}]"
                          @click="() => this.onClickShowType(AnswerDetailShowTypeExt.onlyWrong)">
                    <span>틀린문제만</span>
                  </button>
                  <button :class="['tl-item', {'active': this.showType === AnswerDetailShowTypeExt.onlyAnswer}]"
                          @click="() => this.onClickShowType(AnswerDetailShowTypeExt.onlyAnswer)">
                    <span>맞춘문제만</span>
                  </button>
                </div>
                <div class="tl-line">
                  <button :class="['tl-item', {'active': this.isShowSolution}]"
                          @click="() => this.isShowSolution = !this.isShowSolution">
                    <span>해설</span>
                  </button>
                  <button :class="['tl-item', {'active': this.isShowSolvingImg}]"
                          @click="() => this.isShowSolvingImg = !this.isShowSolvingImg">
                    <span>필기</span>
                  </button>
                  <button :class="['tl-item', {'active': this.isShowFolderProblemSolvingImg}]"
                          @click="() => this.isShowFolderProblemSolvingImg = !this.isShowFolderProblemSolvingImg">
                    <span>첨삭</span>
                  </button>
                  <button :class="['tl-item', {'active': this.isShowFolderProblemMemoImg}]"
                          @click="() => this.isShowFolderProblemMemoImg = !this.isShowFolderProblemMemoImg">
                    <span>메모</span>
                  </button>
                </div>
              </div>
              <div class="bc-problem-list-wrap">
                <template v-for="(folderProblemResultVo, index) in this.filteredFolderProblemResultVos" :key="index">
                  <div class="lw-item">
                    <h1 class="lwi-header">{{ folderProblemResultVo.problemNumber }}번문제</h1>
                    <div class="lwi-sub-header-wrap">
                      <div class="hw-list">
                        <div>
                          <span>정답 :</span>
                          <span>{{ folderProblemResultVo.getCleanProblemAnswer() }}</span>
                        </div>
                        <div>
                          <span>내가 고른 답 :</span>
                          <span>{{ folderProblemResultVo.getCleanAnswer() }}</span>
                        </div>
                      </div>
                      <div class="hw-btn"
                           @click="() => this.handleClickReport(folderProblemResultVo)"
                      >
                        <span>신고</span>
                      </div>
                      <div class="hw-btn"
                           @click="() => this.deleteFolderProblemId = folderProblemResultVo.id"
                      >
                        <span>삭제</span>
                      </div>
                    </div>
                    <div class="lwi-problem-wrap" :style="[{
                      paddingBottom: this.getFolderProblemPaddingBottomPercent(folderProblemResultVo),
                    }]">
                      <div class="pw-problem-wrap">
                        <img :src="folderProblemResultVo.problemImg" alt="">
                      </div>
                      <template v-if="this.isShowSolvingImg">
                        <div class="pw-solving-wrap">
                          <img :src="folderProblemResultVo.solvingImg" alt="">
                        </div>
                      </template>
                      <template v-if="this.isShowFolderProblemSolvingImg">
                        <div class="pw-solving-wrap">
                          <img :src="folderProblemResultVo.folderSolvingImgUrl" alt="">
                        </div>
                      </template>
                    </div>
                    <template v-if="this.isShowSolution">
                      <div class="lwi-solution-wrap">
                        <img :src="folderProblemResultVo.problemSolutionImg" alt="">
                      </div>
                    </template>
                    <template v-if="this.isShowFolderProblemMemoImg">
                      <div class="lwi-memo-wrap">
                        <img :src="folderProblemResultVo.folderMemoImgUrl" alt="">
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </V2Wrapper>
  </div>

  <Teleport to="body">
    <ProblemReportPopup
      :isVisible="this.problemReportPopupItem !== null"
      :onClose="() => this.problemReportPopupItem = null"
      :problemId="this.problemReportPopupItem?.id"
      :problem-number="this.problemReportPopupItem?.number"
    />
    <WrongNoteProblemDeletePopup
      :is-visible="this.deleteFolderProblemId !== null"
      :on-close="() => this.deleteFolderProblemId = null"
      :folder-problem-id="this.deleteFolderProblemId"
      :on-changed="() => this.initData()"
    />
  </Teleport>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import StudentWorkService from "@/services/studentWork.service";
import {AnswerDetailShowTypeExt} from "@/extensions/answer_detail_show_type_ext";
import ProblemReportPopup from "@/components/ProblemReportPopup.vue";
import WrongNoteProblemDeletePopup from "@/components/WrongNoteProblemDeletePopup.vue";

export default {
  name: 'StudentWrongNoteDetail',
  components: {
    WrongNoteProblemDeletePopup,
    ProblemReportPopup,
    ErrorMsgBox,
    V2Wrapper,
  },
  data() {
    return {
      /** @type {string|null} */
      errorMsg: null,
      /** @type {boolean} */
      isLoading: true,

      /** @type {number|null} */
      folderId: null,
      /** @type {string} */
      headerTitle: '',
      /** @type {FolderProblemResultVo[]} */
      folderProblemResultVos: [],

      /** @type {string} */
      showType: AnswerDetailShowTypeExt.all,
      /** @type {boolean} */
      isShowSolution: false,
      /** @type {boolean} */
      isShowSolvingImg: false,
      /** @type {boolean} */
      isShowFolderProblemSolvingImg: false,
      /** @type {boolean} */
      isShowFolderProblemMemoImg: false,

      AnswerDetailShowTypeExt,

      problemReportPopupItem: null,
      deleteFolderProblemId: null,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    handleClickReport(folderProblemResultVo) {
      this.problemReportPopupItem = {
        id: folderProblemResultVo.problemId,
        number: folderProblemResultVo.problemNumber,
      };
    },
    getFolderProblemPaddingBottomPercent(folderProblemResultVoTemp) {
      /** @type {FolderProblemResultVo} */
      const folderProblemResultVo = folderProblemResultVoTemp;

      let percent = 0;

      const problemImgWidth = folderProblemResultVo.problemImgWidth;
      const problemImgHeight = folderProblemResultVo.problemImgHeight + 40;
      const problemImgPercent = problemImgHeight / problemImgWidth * 100;
      if (percent < problemImgPercent) {
        percent = problemImgPercent;
      }

      if (this.isShowSolvingImg) {
        const solvingImgWidth = folderProblemResultVo.solvingImgWidth;
        const solvingImgHeight = folderProblemResultVo.solvingImgHeight;
        const solvingImgPercent = solvingImgHeight / solvingImgWidth * 100;
        if (percent < solvingImgPercent) {
          percent = solvingImgPercent;
        }
      }

      if (this.isShowFolderProblemSolvingImg) {
        const folderSolvingImgWidth = folderProblemResultVo.folderSolvingImgWidth;
        const folderSolvingImgHeight = folderProblemResultVo.folderSolvingImgHeight;
        const folderSolvingImgPercent = folderSolvingImgHeight / folderSolvingImgWidth * 100;
        if (percent < folderSolvingImgPercent) {
          percent = folderSolvingImgPercent;
        }
      }

      return `${percent}%`;
    },
    handleBackPressed() {
      if (history.length <= 1) {
        window.close();
      } else {
        window.history.back();
      }
    },
    onClickShowType(showTypeExt) {
      const showType = this.showType;
      if (showType == showTypeExt) {
        this.showType = AnswerDetailShowTypeExt.all;
      } else {
        this.showType = showTypeExt;
      }
    },
    async initData() {
      this.isLoading = true;

      const {folderId} = this.$route.params;
      const folderIdNum = parseInt(folderId);
      if (isNaN(folderIdNum)) {
        this.errorMsg = '오답노트 ID를 확인할 수 없습니다.';
        return;
      }
      this.folderId = folderIdNum;

      /** @type {FolderResultVo} */
      const folderResultVo = await StudentWorkService.getFolderResult(folderIdNum);
      if (folderResultVo === null) {
        this.errorMsg = '오답노트 정보를 불러오는 중 오류가 발생하였습니다.';
        return;
      }
      this.headerTitle = folderResultVo.name;
      this.folderProblemResultVos = folderResultVo.folderProblemResultVos;

      this.isLoading = false;
    },
  },
  computed: {
    filteredFolderProblemResultVos() {
      return this.folderProblemResultVos.filter((e) => {
        switch (this.showType) {
          case AnswerDetailShowTypeExt.all:
            return true;
          case AnswerDetailShowTypeExt.onlyWrong:
            return e.isCurrentAnswer === false;
          case AnswerDetailShowTypeExt.onlyAnswer:
            return e.isCurrentAnswer === true;
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/values";

.student-wrong-note-detail-wrap {
  .swndw-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    .iw-loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      color: #{$defaultColor};
    }
    .iw-header-wrap {
      width: 100%;
      background-color: #{$defaultColor};
      height: 60px;
      display: flex;
      @include tablet {
        height: 40px;
      }
      .v2-base-container {
        gap: 5px;
        align-items: center;
        .hw-btn {
          width: 70px;
          height: 100%;
          cursor: pointer;
          @include tablet {
            width: 50px;
          }
          &>img {
            width: 100%;
            height: 25px;
            object-fit: contain;
            object-position: 5px;
            @include tablet {
              height: 20px;
            }
          }
          &.btn-back {
            display: none;
            @include tablet {
              display: unset;
            }
          }
          &.btn-back-empty {
            cursor: unset;
            @include tablet {
              display: none;
            }
          }
          &.btn-empty {
            cursor: unset;
          }
        }
        &>p {
          flex: 1;
          text-align: center;
          font-weight: 700;
          font-size: 20px;
          color: white;
          @include tablet {
            font-size: 15px;
            padding-right: 47px;
          }
          @include mobile {
            padding-right: unset;
          }
        }
      }
    }
    .iw-body-wrap {
      width: 100%;
      display: flex;
      padding-bottom: 50px;
      .v2-base-container {
        flex-direction: column;
        .bc-tab-list-wrap {
          width: 100%;
          display: flex;
          gap: 50px;
          align-items: center;
          margin-top: 30px;
          flex-wrap: wrap;
          @include tablet {
            margin-top: 20px;
          }
          @include mobile {
            margin-top: 10px;
            gap: 10px;
          }
          .tl-line {
            display: flex;
            gap: 10px;
            @include mobile {
              gap: 7px;
            }
            .tl-item {
              padding: 10px 16px;
              background-color: white;
              border: 1px solid #DBDBDB;
              box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
              border-radius: 7px;
              color: black;
              font-size: 18px;
              @include tablet {
                padding: 7px 13px;
                font-size: 16px;
              }
              @include mobile {
                padding: 5px 8px;
                font-size: 12px;
              }
              &.active {
                background-color: #{$defaultColor};
                color: white;
              }
            }
          }
        }
        .bc-problem-list-wrap {
          margin-top: 40px;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 100px 50px;
          @include tablet {
            margin-top: 20px;
            gap: 50px 25px;
          }
          @include mobile {
            grid-template-columns: repeat(1, 1fr);
          }
          .lw-item {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            @include mobile {
              max-width: 400px;
              margin: 0 auto;
            }
            &:before {
              content: '';
              position: absolute;
              top: -51px;
              width: 100%;
              height: 2px;
              background-color: #cccccc;
              @include tablet {
                top: -26px;
                height: 1px;
              }
            }
            &:nth-child(1) {
              &:before {
                display: none;
              }
            }
            &:nth-child(2) {
              &:before {
                display: none;
                @include mobile {
                  display: unset;
                }
              }
            }
            .lwi-header {
              width: 100%;
              font-weight: 500;
              font-size: 16px;
              color: black;
              @include mobile {
                font-size: 13px;
              }
            }
            .lwi-sub-header-wrap {
              margin-top: 15px;
              width: 100%;
              display: flex;
              align-items: center;
              @include tablet {
                margin-top: 10px;
              }
              .hw-list {
                flex: 1;
                display: flex;
                gap: 40px;
                align-items: center;
                flex-wrap: wrap;
                @include tablet {
                  gap: 15px;
                }
                &>div {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  @include tablet {
                    gap: 5px;
                  }
                  &>span {
                    font-weight: 500;
                    font-size: 16px;
                    color: black;
                    @include tablet {
                      font-size: 13px;
                    }
                    @include mobile {
                      font-size: 12px;
                    }
                  }
                }
              }
              .hw-btn {
                margin-left: 20px;
                cursor: pointer;
                &>span {
                  font-weight: 500;
                  font-size: 16px;
                  color: #{$redColor};
                  @include tablet {
                    font-size: 14px;
                  }
                  @include mobile {
                    font-size: 13px;
                  }
                }
              }
            }
            .lwi-problem-wrap {
              margin-top: 10px;
              width: 100%;
              height: 0;
              border-radius: 5px;
              background-color: white;
              box-shadow: 0 0 2px 0 rgba(0, 0, 0, .25);
              position: relative;
              .pw-problem-wrap {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                padding: 10px;
                &>img {
                  width: 100%;
                  object-fit: contain;
                }
              }
              .pw-solving-wrap {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                &>img {
                  width: 100%;
                  object-fit: contain;
                }
              }
            }
            .lwi-solution-wrap {
              margin-top: 15px;
              width: 100%;
              border-radius: 5px;
              background-color: white;
              box-shadow: 0 0 2px 0 rgba(0, 0, 0, .25);
              padding: 10px;
              &>img {
                width: 100%;
                object-fit: contain;
              }
            }
            .lwi-memo-wrap {
              margin-top: 15px;
              width: 100%;
              border-radius: 5px;
              background-color: white;
              box-shadow: 0 0 2px 0 rgba(0, 0, 0, .25);
              &>img {
                width: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
}
</style>
