<template>
  <div class="yearly-learn-pattern-wrap">
    <div class="ylp-inner-wrap">
      <div class="ylp-table-wrap">
        <template v-for="(monthItem, monthIndex) in this.monthItems" :key="monthIndex">
          <template v-if="monthIndex === 0">
            <div class="line-item">
              <template v-for="(dowName, index) in ['일', '월', '화', '수', '목', '금', '토']" :key="index">
                <div class="day-item">
                  <p>{{ dowName }}</p>
                </div>
              </template>
            </div>
          </template>
          <template v-for="(dayItems, dowIndex) in monthItem.dowItems" :key="dowIndex">
            <div class="line-item">
              <template v-if="dowIndex === 0">
                <div class="i-month-title-wrap">
                  <p>{{ monthItem.month }}월</p>
                </div>
              </template>
              <template v-for="(dayItem, dayIndex) in dayItems" :key="dayIndex">
                <div class="day-item">
                  <div class="d-pattern-box">
                    <div class="dp-bg"
                         :style="[{'background-color': this.patternColors[Math.floor(Math.random() * patternColors.length)]}]"
                    ></div>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </template>
      </div>
      <div class="ylp-desc-wrap">
        <p>*각 영역을 클릭하시면 해당날짜의 학습 내용을 확인해 보실 수 있습니다.</p>
        <div class="palette-wrap">
          <p>Less</p>
          <div class="p-list-wrap">
            <template v-for="(patternColor, index) in this.patternColors" :key="index">
              <div class="pl-item"
                   :style="[{'background-color': patternColor}]"
              ></div>
            </template>
          </div>
          <p>More</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YearlyLearnPattern',
  props: {
  },
  data() {
    return {
      patternColors: [
        '#EBF8FF',
        '#CFEFFF',
        '#A4E1FF',
        '#008ED5',
        '#006EA5',
      ],
    };
  },
  computed: {
    monthItems() {
      const monthItems = [];
      const nowDt = new Date();
      const nowYear = nowDt.getFullYear();

      let targetDt = new Date(nowYear, 0, 1);
      let isOverYear = false;
      while (isOverYear === false) {
        const targetDay = targetDt.getDay();

        const weekFirstDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate() - targetDay);
        const weekLastDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate() + 6 - targetDay);

        targetDt = new Date(weekLastDt.getFullYear(), weekLastDt.getMonth(), weekLastDt.getDate() + 1);
        if (targetDt.getFullYear() > nowYear) {
          isOverYear = true;
        } else {
          const dayItems = Array.from({length: 7}, (_, i) => i).map(dayIndex => {
            return new Date(weekFirstDt.getFullYear(), weekFirstDt.getMonth(), weekFirstDt.getDate() + dayIndex);
          });

          const month = weekLastDt.getMonth() + 1;
          const foundIndex = monthItems.findIndex(e => e.month === month);
          if (foundIndex >= 0) {
            monthItems[foundIndex].dowItems.push(dayItems);
          } else {
            monthItems.push({
              'month': weekLastDt.getMonth() + 1,
              'dowItems': [dayItems],
            });
          }
        }
      }

      return monthItems;
    },
  },
}
</script>

<style scoped lang="scss">
.yearly-learn-pattern-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  .ylp-inner-wrap {
    width: 100%;
    min-width: 750px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    .ylp-table-wrap {
      width: 100%;
      display: flex;
      padding-top: 16px;
      gap: 4px;
      .line-item {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 3px;
        cursor: pointer;
        transition: all .2s ease-in-out;
        .i-month-title-wrap {
          position: absolute;
          bottom: 100%;
          left: 0;
          padding-bottom: 2px;
          display: flex;
          &>p {
            font-weight: 500;
            font-size: 10px;
            color: black;
            text-align: center;
            white-space: nowrap;
          }
        }
        .day-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          &>p {
            padding-top: 3px;
            font-weight: 500;
            font-size: 10px;
            color: black;
            line-height: 1.2;
          }
          .d-pattern-box {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            .dp-bg {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 1.5px;
            }
          }
        }
      }
    }
    .ylp-desc-wrap {
      width: 100%;
      display: flex;
      margin-top: 10px;
      align-items: center;
      gap: 10px;
      &>p {
        flex: 1;
        display: flex;
        font-weight: 500;
        font-size: 10px;
        color: #797979;
      }
      .palette-wrap {
        gap: 10px;
        align-items: center;
        display: flex;
        &>p {
          font-weight: 500;
          font-size: 10px;
          color: black;
          padding-top: 3px;
        }
        .p-list-wrap {
          display: flex;
          gap: 4px;
          align-items: center;
          .pl-item {
            width: 10px;
            height: 10px;
            border-radius: 3px;
          }
        }
      }
    }
  }
}
</style>
