<template>
  <div :class="['student-learning-history-detail-bottom-popup-wrap', {'hide': !this.isVisible}]">
    <div class="popup-shadow" @click="this.onCloseEvent"></div>
    <div class="popup-body">
      <div class="pb-title">
        <button class="close-btn" @click="this.onCloseEvent">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: black;"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </button>
      </div>
      <div class="pb-contents">
        <div class="v2-base-container">
          <div class="bc-inner-wrap">
            <div class="iw-header">
              <h1>
                푼 문제는 <button></button> 로 표시 됩니다
                <br/>
                넘긴 문제는 <img src="@/assets/images/pass.png" alt=""> 로 표시 됩니다
                <br/>
                번호를 클릭하면 해당 문제로 이동합니다.
              </h1>
            </div>
            <div class="iw-body-wrap">
              <template v-for="(studentWorkProblemVo, index) in this.studentWorkProblemVos" :key="index">
                <div
                  :class="['bw-item', {
                    'type-submit': studentWorkProblemVo.isSubmitAnswer() && !studentWorkProblemVo.isPass(),
                  }]"
                  @click="() => this.onClickStudentWorkProblemVo(studentWorkProblemVo)"
                >
                  <template v-if="studentWorkProblemVo.isPass()">
                    <img src="@/assets/images/pass.png" alt="">
                  </template>
                  <span>{{ studentWorkProblemVo.order + 1 }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudentLearningHistoryDetailBottomPopup',
  props: {
    isVisible: Boolean,
    onClose: Function,
    /** @type {StudentWorkProblemVo[]} */
    studentWorkProblemVos: Array,
    onClickStudentWorkProblemVo: Function,
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.student-learning-history-detail-bottom-popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99999999;
  &.hide {
    top: 100vh;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    width: 100%;
    max-height: 90%;
    margin: auto auto 0;
    background-color: #FCE2AD;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow-y: auto;
    .pb-title {
      width: 100%;
      display: flex;
      height: 40px;
      align-items: center;
      padding: 0 24px;
      .close-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        svg {
          width: 100%;
          color: rgb(112, 112, 112);
          path {
            fill: currentcolor;
          }
        }
      }
    }
    .pb-contents {
      width: 100%;
      display: flex;
      .v2-base-container {
        .bc-inner-wrap {
          width: 100%;
          max-width: 700px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          padding-bottom: 20px;
          .iw-header {
            width: 100%;
            display: flex;
            position: relative;
            justify-content: center;
            h1 {
              font-size: 16px;
              line-height: 1.5;
              text-align: center;
              button {
                width: 12px;
                height: 12px;
                background-color: #{$studentColor};
              }
              img {
                width: 15px;
                height: 15px;
                object-fit: contain;
              }
            }
          }
          .iw-body-wrap {
            margin-top: 20px;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 10px;
            .bw-item {
              width: 100%;
              cursor: pointer;
              background-color: white;
              padding: 5px;
              min-height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 7px;
              box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .25);
              position: relative;
              &>img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
              &>span {
                width: 100%;
                text-align: center;
                color: black;
              }
              &:hover {
                box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
              }
              &.type-submit {
                background-color: #{$studentColor};
                &>span {
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
