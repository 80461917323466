<template>
  <V2Wrapper>
    <MypageTeacherClassPreRecommendByTheme/>
  </V2Wrapper>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import MypageTeacherClassPreRecommendByTheme from "@/pages/mypage/teacher/class-pre/RecommendByTheme.vue";

export default {
  name: 'TeacherRecTheme',
  components: {
    V2Wrapper,
    MypageTeacherClassPreRecommendByTheme,
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {},
  computed: {},
}
</script>
