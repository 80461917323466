<template>
  <div :class="['popup-wrap', 'delete-menu-popup-wrap', { hide: this.deleteMenu === null }]">
    <div class="p-shadow" @click="onCloseEvent"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <h2 style="text-align:center; margin-top: 5px; font-weight: bold;">삭제</h2>
        <p style="text-align:center; margin-top: 5px">삭제 하시겠습니까?</p>
        <div class="popup-buttons">
          <button class="cancel-button" @click="onClickCancelBtn">취소</button>
          <button class="delete-button" @click="emitDeleteEvent">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    deleteMenu: Object,
    onClose: Function,
  },
  data() {
    return {
      title: '',
    };
  },
  watch: {
    deleteMenu() {
      this.title = this.deleteMenu?.title ?? '';

      if (this.deleteMenu !== null) {
        setTimeout(() => {
          this.$refs.input.focus();
        }, 100);
      }
    },
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
    emitDeleteEvent() {
      this.$emit('delete', this.deleteMenu.id);
    },
    onClickCancelBtn() {
      this.onClose();
    },
  },
}
</script>

<style scoped>
@import '../assets/scss/delete-menu-popup.scss';
</style>
