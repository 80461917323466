<template>
  <div :class="['popup', { hide: !isVisible }]">
    <div class="popup-shadow" @click="this.onPressedClose"></div>
    <div :class="['popup-body']">
      <div class="popup-title">
        반 상세 정보
        <button class="close-btn" @click="this.onPressedClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </button>
      </div>

      <div :class="['body-contents', {
        'is-loading': this.isLoading,
      }]">
        <h1><strong>반 이름</strong>{{ academyClass?.name ?? '' }}</h1>

        <div class="content-list-wrap">
          <div class="list-box">
            <h2>반 선생님</h2>

            <div class="list-item-wrap">
              <div class="list-item-relative-wrap">
                <div class="list-item-inner-wrap">
                  <p
                      v-for="(item, index) in inTeachers" :key="index"
                      @click="() => changeAcademyUser(item, false)"
                  >
                    <span>{{ item.name }}</span>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"> <path d="M1310 5109 c-231 -30 -447 -114 -639 -250 -107 -75 -301 -267 -392 -389 -127 -169 -212 -359 -256 -576 -17 -83 -18 -173 -18 -1334 0 -1161 1 -1251 18 -1334 64 -313 201 -552 460 -802 185 -180 353 -284 564 -353 213 -69 134 -66 1513 -66 1161 0 1251 1 1334 18 295 60 516 179 745 402 259 251 394 489 458 801 17 83 18 173 18 1334 0 1161 -1 1251 -18 1334 -64 312 -199 550 -458 801 -224 217 -429 330 -721 398 -92 21 -106 21 -1318 23 -674 1 -1254 -2 -1290 -7z m2491 -404 c154 -27 307 -91 425 -178 82 -60 228 -206 293 -292 70 -93 145 -249 173 -360 l23 -90 0 -1225 0 -1225 -23 -89 c-27 -108 -95 -254 -159 -345 -57 -79 -223 -246 -307 -308 -118 -87 -271 -151 -425 -178 -125 -22 -2357 -22 -2482 0 -155 27 -310 92 -425 178 -84 64 -230 209 -293 292 -70 93 -145 249 -173 360 l-23 90 0 1225 0 1225 23 89 c27 108 95 254 159 345 56 78 223 246 308 309 113 83 271 150 418 177 113 21 2372 21 2488 0z"/> <path d="M2320 3541 c-60 -19 -106 -61 -502 -458 -435 -437 -438 -440 -438 -523 0 -83 3 -86 438 -523 230 -230 433 -427 452 -437 86 -44 169 -32 235 35 65 65 79 147 38 230 -8 17 -119 135 -246 263 l-232 232 758 0 c828 0 797 -2 860 58 43 41 60 81 60 142 0 61 -17 101 -60 142 -63 60 -32 58 -860 58 l-758 0 232 233 c227 228 254 260 267 323 19 88 -55 199 -146 222 -52 13 -62 14 -98 3z"/> </g></svg>
                  </p>
                </div>
              </div>
              <div class="list-item-relative-wrap">
                <div class="list-item-inner-wrap">
                  <button
                      v-for="(item, index) in outTeachers" :key="index"
                      class="teacher-btn"
                      @click="() => changeAcademyUser(item, true)"
                  >
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"> <path d="M1310 5109 c-231 -30 -447 -114 -639 -250 -107 -75 -301 -267 -392 -389 -127 -169 -212 -359 -256 -576 -17 -83 -18 -173 -18 -1334 0 -1161 1 -1251 18 -1334 64 -313 201 -552 460 -802 185 -180 353 -284 564 -353 213 -69 134 -66 1513 -66 1161 0 1251 1 1334 18 295 60 516 179 745 402 259 251 394 489 458 801 17 83 18 173 18 1334 0 1161 -1 1251 -18 1334 -64 312 -199 550 -458 801 -224 217 -429 330 -721 398 -92 21 -106 21 -1318 23 -674 1 -1254 -2 -1290 -7z m2491 -404 c154 -27 307 -91 425 -178 82 -60 228 -206 293 -292 70 -93 145 -249 173 -360 l23 -90 0 -1225 0 -1225 -23 -89 c-27 -108 -95 -254 -159 -345 -57 -79 -223 -246 -307 -308 -118 -87 -271 -151 -425 -178 -125 -22 -2357 -22 -2482 0 -155 27 -310 92 -425 178 -84 64 -230 209 -293 292 -70 93 -145 249 -173 360 l-23 90 0 1225 0 1225 23 89 c27 108 95 254 159 345 56 78 223 246 308 309 113 83 271 150 418 177 113 21 2372 21 2488 0z"/> <path d="M2320 3541 c-60 -19 -106 -61 -502 -458 -435 -437 -438 -440 -438 -523 0 -83 3 -86 438 -523 230 -230 433 -427 452 -437 86 -44 169 -32 235 35 65 65 79 147 38 230 -8 17 -119 135 -246 263 l-232 232 758 0 c828 0 797 -2 860 58 43 41 60 81 60 142 0 61 -17 101 -60 142 -63 60 -32 58 -860 58 l-758 0 232 233 c227 228 254 260 267 323 19 88 -55 199 -146 222 -52 13 -62 14 -98 3z"/> </g></svg>
                    <span>{{ item.name }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="list-box">
            <h2>반 학생</h2>

            <div class="list-item-wrap">
              <div class="list-item-relative-wrap">
                <div class="list-item-inner-wrap">
                  <template v-for="(item, index) in inStudents" :key="index">
                    <button
                        :class="['expand-btn', {
                          'is-expand': item.students.findIndex(i => i.isExpand) > -1}
                        ]"
                        @click="() => item.students.forEach(i => i.isExpand = !i.isExpand)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" data-svg-name="dropdown_arrow_up" class="is-animate degree-90 css-he2h7v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(192, 192, 192);"><path fill="#000" d="M16.586 15.5c.89 0 1.337-1.077.707-1.707l-4.586-4.586c-.39-.39-1.024-.39-1.414 0l-4.586 4.586c-.63.63-.184 1.707.707 1.707h9.172z"></path></svg>
                      <span>{{ item.schoolType }}{{ item.schoolYear }}</span>
                      <span class="light">{{ item.students.length }}명</span>
                    </button>

                    <template v-if="item.students[0].isExpand">
                      <p
                          v-for="(subItem, subIndex) in item.students" :key="subIndex"
                          @click="() => changeAcademyUser(subItem, false)"
                      >
                        <span>{{ subItem.name }}</span>
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"> <path d="M1310 5109 c-231 -30 -447 -114 -639 -250 -107 -75 -301 -267 -392 -389 -127 -169 -212 -359 -256 -576 -17 -83 -18 -173 -18 -1334 0 -1161 1 -1251 18 -1334 64 -313 201 -552 460 -802 185 -180 353 -284 564 -353 213 -69 134 -66 1513 -66 1161 0 1251 1 1334 18 295 60 516 179 745 402 259 251 394 489 458 801 17 83 18 173 18 1334 0 1161 -1 1251 -18 1334 -64 312 -199 550 -458 801 -224 217 -429 330 -721 398 -92 21 -106 21 -1318 23 -674 1 -1254 -2 -1290 -7z m2491 -404 c154 -27 307 -91 425 -178 82 -60 228 -206 293 -292 70 -93 145 -249 173 -360 l23 -90 0 -1225 0 -1225 -23 -89 c-27 -108 -95 -254 -159 -345 -57 -79 -223 -246 -307 -308 -118 -87 -271 -151 -425 -178 -125 -22 -2357 -22 -2482 0 -155 27 -310 92 -425 178 -84 64 -230 209 -293 292 -70 93 -145 249 -173 360 l-23 90 0 1225 0 1225 23 89 c27 108 95 254 159 345 56 78 223 246 308 309 113 83 271 150 418 177 113 21 2372 21 2488 0z"/> <path d="M2320 3541 c-60 -19 -106 -61 -502 -458 -435 -437 -438 -440 -438 -523 0 -83 3 -86 438 -523 230 -230 433 -427 452 -437 86 -44 169 -32 235 35 65 65 79 147 38 230 -8 17 -119 135 -246 263 l-232 232 758 0 c828 0 797 -2 860 58 43 41 60 81 60 142 0 61 -17 101 -60 142 -63 60 -32 58 -860 58 l-758 0 232 233 c227 228 254 260 267 323 19 88 -55 199 -146 222 -52 13 -62 14 -98 3z"/> </g></svg>
                      </p>
                    </template>
                  </template>
                </div>
              </div>
              <div class="list-item-relative-wrap">
                <div class="list-item-inner-wrap">
                  <template v-for="(item, index) in outStudents" :key="index">
                    <button
                        :class="['expand-btn', {
                          'is-expand': item.students.findIndex(i => i.isExpand) > -1}
                        ]"
                        @click="() => item.students.forEach(i => i.isExpand = !i.isExpand)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" data-svg-name="dropdown_arrow_up" class="is-animate degree-90 css-he2h7v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(192, 192, 192);"><path fill="#000" d="M16.586 15.5c.89 0 1.337-1.077.707-1.707l-4.586-4.586c-.39-.39-1.024-.39-1.414 0l-4.586 4.586c-.63.63-.184 1.707.707 1.707h9.172z"></path></svg>
                      <span>{{ item.schoolType }}{{ item.schoolYear }}</span>
                      <span class="light">{{ item.students.length }}명</span>
                    </button>

                    <template v-if="item.students[0].isExpand">
                      <p
                          v-for="(subItem, subIndex) in item.students" :key="subIndex"
                          @click="() => changeAcademyUser(subItem, true)"
                          class="svg-rotate"
                      >
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"> <path d="M1310 5109 c-231 -30 -447 -114 -639 -250 -107 -75 -301 -267 -392 -389 -127 -169 -212 -359 -256 -576 -17 -83 -18 -173 -18 -1334 0 -1161 1 -1251 18 -1334 64 -313 201 -552 460 -802 185 -180 353 -284 564 -353 213 -69 134 -66 1513 -66 1161 0 1251 1 1334 18 295 60 516 179 745 402 259 251 394 489 458 801 17 83 18 173 18 1334 0 1161 -1 1251 -18 1334 -64 312 -199 550 -458 801 -224 217 -429 330 -721 398 -92 21 -106 21 -1318 23 -674 1 -1254 -2 -1290 -7z m2491 -404 c154 -27 307 -91 425 -178 82 -60 228 -206 293 -292 70 -93 145 -249 173 -360 l23 -90 0 -1225 0 -1225 -23 -89 c-27 -108 -95 -254 -159 -345 -57 -79 -223 -246 -307 -308 -118 -87 -271 -151 -425 -178 -125 -22 -2357 -22 -2482 0 -155 27 -310 92 -425 178 -84 64 -230 209 -293 292 -70 93 -145 249 -173 360 l-23 90 0 1225 0 1225 23 89 c27 108 95 254 159 345 56 78 223 246 308 309 113 83 271 150 418 177 113 21 2372 21 2488 0z"/> <path d="M2320 3541 c-60 -19 -106 -61 -502 -458 -435 -437 -438 -440 -438 -523 0 -83 3 -86 438 -523 230 -230 433 -427 452 -437 86 -44 169 -32 235 35 65 65 79 147 38 230 -8 17 -119 135 -246 263 l-232 232 758 0 c828 0 797 -2 860 58 43 41 60 81 60 142 0 61 -17 101 -60 142 -63 60 -32 58 -860 58 l-758 0 232 233 c227 228 254 260 267 323 19 88 -55 199 -146 222 -52 13 -62 14 -98 3z"/> </g></svg>
                        <span>{{ subItem.name }}</span>
                      </p>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="this.isLoading">
          <LoadingBox
              :is-absolute="true"
          />
        </template>
      </div>
    </div>
  </div>

  <LoadingDialog
      :is-show="this.isUpdating"
  />
</template>
  
<script>
import academyService from '../services/academy.service'
import LoadingDialog from "@/components/LoadingDialog.vue";
import LoadingBox from "@/components/LoadingBox.vue";

export default {
  name: 'TeacherPopup',
  components: {
    LoadingBox,
    LoadingDialog
  },
  props: {
    isVisible: Boolean,
    onClose: Function,
    onUpdated: Function,
    academyClassId: Number,
    academyInfoId: Number,
    academyUsers: {default: []},
  },
  watch: {
    academyClassId() {
      this.initData();
    },
    academyInfoId() {
      this.initData();
    },
  },
  data() {
    return {
      isLoading: true,
      isUpdating: false,
      isUpdated: false,

      academyClass: null,
      includeTeacherIds: [],
      includeStudentIds: [],
    };
  },
  methods: {
    onPressedClose() {
      if (this.isUpdated) {
        this.onUpdated();
      } else {
        this.onClose();
      }
    },
    async initData() {
      const academyClassId = this.academyClassId;
      if (!academyClassId) return;

      this.academyClass = [];
      this.isLoading = true;
      this.isUpdated = false;

      const res = await academyService.getAcademyClass(academyClassId);
      this.academyClass = res;
      this.includeTeacherIds = res.teacher_ids;
      this.includeStudentIds = res.student_ids;

      this.isLoading = false;
    },
    async changeAcademyUser(academyUser, isIncludeClass) {
      this.isUpdating = true;

      await academyService.changeAcademyUserClass(
          isIncludeClass,
          academyUser.id,
          this.academyClass.id,
      );

      const academyClass = await academyService.getAcademyClass(this.academyClassId);
      this.includeTeacherIds = academyClass.teacher_ids;
      this.includeStudentIds = academyClass.student_ids;

      this.isUpdating = false;
      this.isUpdated = true;
    },
    isInclude(academyUserId, isStudent) {
      const targetIncludeIds = isStudent ? this.includeStudentIds : this.includeTeacherIds;
      return targetIncludeIds.findIndex(e => e === academyUserId) >= 0;
    },
  },
  computed: {
    teacherAcademyUsers() {
      return this.academyUsers.filter(i => i.is_teacher)
    },
    studentAcademyUsers() {
      return this.academyUsers.filter(i => i.is_student)
    },
    outTeachers() {
      return this.teacherAcademyUsers.filter(i => this.isInclude(i.id, false) == false);
    },
    inTeachers() {
      return this.teacherAcademyUsers.filter(i => this.isInclude(i.id, false));
    },
    outStudents() {
      const result = [];

      this.studentAcademyUsers
          .filter(i => this.isInclude(i.id, true) == false)
          .forEach(item => {
            const findIndex = result.findIndex(i => {
              return i.schoolType == item.school_type
                  && i.schoolYear == item.school_year;
            })
            if (findIndex >= 0) {
              result[findIndex].students.push(item)
            } else {
              result.push({
                schoolType: item.school_type,
                schoolYear: item.school_year,
                students: [item],
              })
            }
          });

      return result
    },
    inStudents() {
      const result = [];

      this.studentAcademyUsers
          .filter(i => this.isInclude(i.id, true))
          .forEach(item => {
            const findIndex = result.findIndex(i => {
              return i.schoolType == item.school_type
                  && i.schoolYear == item.school_year;
            })
            if (findIndex >= 0) {
              result[findIndex].students.push(item)
            } else {
              result.push({
                schoolType: item.school_type,
                schoolYear: item.school_year,
                students: [item],
              })
            }
          });

      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99;
  &.hide {
    display: none;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    width: 100%;
    max-width: calc(100% - 40px - 40px);
    height: calc(100vh - 48px);
    margin: auto;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #231f20;
    box-sizing: border-box;
    .popup-title {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-weight: 500;
      display: flex;
      height: 72px;
      align-items: center;
      padding: 10px 24px;
      border-bottom: 1px solid rgb(224, 224, 224);
      .close-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        svg {
          width: 100%;
          color: rgb(112, 112, 112);
          path {
            fill: currentcolor;
          }
        }
      }
    }
    .body-contents {
      width: 100%;
      flex: 1;
      padding: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      display: flex;
      gap: 24px;
      flex-direction: column;
      overflow: hidden;
      &.is-loading {
        position: relative;
        &>* {
          &:not(.loading-box) {
            opacity: 0;
          }
        }
      }
      h1 {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        strong {
          font-weight: bold;
          margin-right: 20px;
        }
      }
      .content-list-wrap {
        width: 100%;
        display: flex;
        gap: 50px;
        flex: 1;
        overflow: hidden;
        .list-box {
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          overflow: hidden;
          h2 {
            margin-bottom: 12px;
            font-size: 14px;
            line-height: 21px;
            font-weight: bold;
          }
          .list-item-wrap {
            width: 100%;
            display: flex;
            gap: 25px;
            flex: 1;
            .list-item-relative-wrap {
              position: relative;
              flex: 1;
              height: 100%;
              border: 1px solid rgb(192, 192, 192);
              border-radius: 5px;
              overflow-y: auto;
              .list-item-inner-wrap {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                p {
                  display: flex;
                  width: 100%;
                  height: 48px;
                  padding: 0 24px;
                  background-color: rgb(255, 255, 255);
                  border-bottom: 1px solid rgb(232, 232, 232);
                  transition: all .2s ease-in-out;
                  font-size: 14px;
                  line-height: 21px;
                  align-items: center;
                  cursor: pointer;
                  span {
                    display: -webkit-box;
                    overflow: hidden;
                    word-break: break-all;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                  svg {
                    width: 15px;
                    height: 15px;
                    transform: rotate(180deg);
                    margin-left: auto;
                  }
                  &:hover {
                    background-color: rgb(245, 245, 245);
                  }
                  &.svg-rotate {
                    svg {
                      transform: rotate(0);
                      margin-left: 0;
                      margin-right: 10px;
                    }
                  }
                }
                .teacher-btn {
                  width: 100%;
                  display: flex;
                  height: 48px;
                  padding: 0 24px;
                  align-items: center;
                  gap: 10px;
                  border-bottom: 1px solid rgb(232, 232, 232);
                  background-color: white;
                  transition: all .2s ease-in-out;
                  svg {
                    width: 15px;
                    height: 15px;
                    path {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  &:hover {
                    background-color: rgb(245, 245, 245);
                  }
                }
                .expand-btn {
                  width: 100%;
                  display: flex;
                  height: 48px;
                  padding: 0 24px;
                  align-items: center;
                  gap: 10px;
                  cursor: pointer;
                  background-color: rgb(250, 250, 250);
                  svg {
                    transition: all .2s ease-in-out;
                    transform: rotate(90deg);
                  }
                  span {
                    color: rgb(51, 51, 51);
                    font-size: 14px;
                  }
                  &.is-expand {
                    svg {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
              &:first-child {
                border-color: #008ED5;
                border-width: 2px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1310px) {
  .popup {
    .popup-body {
      max-width: 1230px;
    }
  }
}
</style>
