<template>
  <div :class="['right-menu-wrap', {'active': this.isVisible}]"
       @contextmenu="this.onRightClick($event)"
       @click="this.onCloseEvent">
    <div class="rm-body"
         :style="[{marginLeft: `${this.clientX}px`, marginTop: `${this.clientY}px`}]"
         onclick="event.stopPropagation()">
      <template v-for="(item, index) in  this.items" :key="index">
        <div class="rmb-item" @click="item.onClick">
          <span>{{ item.title }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightMenu',
  props: {
    isVisible: Boolean,
    onClose: Function,
    clientX: {default: 0, type: Number},
    clientY: {default: 0, type: Number},
    items: Array,
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
    onRightClick(event) {
      event.preventDefault();
      this.onCloseEvent();
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/values";

.right-menu-wrap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: none;
  &.active {
    display: flex;
  }
  .rm-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 130px;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
    border: 1px solid #DBDBDB;
    background-color: #FFFFFF;
    margin-right: auto;
    margin-bottom: auto;
    padding: 3px 0;
    border-radius: 7px;
    @include tablet {
      max-width: 120px;
    }
    @include mobile {
      max-width: 110px;
    }
    .rmb-item {
      width: 100%;
      cursor: pointer;
      padding: 3px 7px;
      background-color: #FFFFFF;
      &:hover {
        background-color: #eeeeee;
      }
      &:not(:first-child) {
        border-top: 1px solid #eeeeee;
      }
      &>span {
        font-size: 13px;
        @include tablet {
          font-size: 12px;
        }
        @include mobile {
          font-size: 10px;
        }
      }
    }
  }
}
</style>
