<template>
    <div class="min-window-info-wrap">
        <img src="@/assets/images/min-window-info-icon.png" alt=""/>
        <h1>에스덕 화면 크기 안내</h1>
        <p>에스덕은 아이패드 미니({{ minWindowWidth }}px) 이상부터 이용이 가능합니다.<br/>
최적화된 서비스 이용을 위해서 더 큰 화면의 태블릿 또는 PC로 접속해주세요.</p>

        <button
            class="logout-btn"
            @click="this.signoutEvent"
        >
          로그아웃
        </button>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'MinWindowInfo',
  data() {
    return {
      minWindowWidth: parseInt(process.env.VUE_APP_MIN_WIDTH),
    };
  },
  methods: {
    signoutEvent() {
      this.signout()
      this.$router.push({ name: 'login' })
    },
    ...mapActions('auth', ['signout']),
  },
}
</script>

<style lang="scss" scoped>
.min-window-info-wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  img {
    width: 26px;
    object-fit: contain;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px;
    color: rgb(112, 112, 112);
    text-align: center;
  }
  .logout-btn {
    margin-top: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    color: black;
  }
}
</style>
