<template>
  <div id="container">
    <div class="login-wrap">
      <div class="login-container">
        <h1>로그인</h1>

        <input v-model="email" type="text" placeholder="이메일을 입력해주세요." @keyup.enter="this.onEnterByEmail"/>
        <input v-model="password" type="password" placeholder="비밀번호를 입력해주세요." @keyup.enter="this.onLogin"/>

        <button class="login-btn" @click="onLogin">로그인</button>

        <div class="or-border">
          <div class="border"></div>
          <span>또는</span>
          <div class="border"></div>
        </div>

        <div class="sns-list">
          <button class="sns-btn" @click="this.onLoginKakao">
            <img src="@/assets/images/sns-kakaotalk.png" alt=""/>
          </button>
          <button class="sns-btn" @click="this.onLoginGoogle">
            <img src="@/assets/images/sns-google.png" alt=""/>
          </button>
          <button class="sns-btn" @click="this.onLoginNaver">
            <template v-if="isWebView">
              <img src="@/assets/images/sns-naver.png" alt=""/>
            </template>
            <template v-else>
              <img src="@/assets/images/sns-naver.png" alt="" id="naverIdLogin_loginButton"/>
            </template>
          </button>
        </div>

        <p>회원이 아니신가요?</p>

        <button class="reg-btn" @click="onClickSignUp">회원가입</button>

        <div class="find-list-wrap">
          <div class="find-item">
            <a :href="this.$router.resolve({name: 'find-email'}).href">이메일 찾기</a>
          </div>
          <div class="divider"></div>
          <div class="find-item">
            <a :href="this.$router.resolve({name: 'find-password'}).href">비밀번호 찾기</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import utils from '../utils'

export default {
  name: 'Login',
  data() {
    return {
      email: null,
      password: null,
      test: null,
      isDisableWindowWidth: false,
      minWindowWidth: parseInt(process.env.VUE_APP_MIN_WIDTH),
      isWebView: utils.isWebview(),
    }
  },
  mounted() {
    this.initKakao()
    this.initNaver()

    window.onNaverSocialLoginResponseTest = this.onNaverSocialLoginResponse
    window.showErrorToast = this.showErrorToast
    window.addEventListener("resize", this.checkWindowWidth);
    this.checkWindowWidth()

    window.loginByWebView = (type, uId) => {
      this.loginByWebView(type, uId);
    };
  },
  unmounted() {
    window.removeEventListener("resize", this.checkWindowWidth);
  },
  methods: {
    onClickSignUp() {
      this.$router.push({
        name: 'signup',
        query: this.$route.query,
      });
    },
    loginByWebView(type, uId) {
      this.signIn({
        'type': type,
        'u_id': uId,
        'redirect': this.$route.query?.redirect,
      });
    },
    checkWindowWidth() {
      this.isDisableWindowWidth = window.innerWidth < this.minWindowWidth
    },
    showErrorToast(message) {
      this.$toast.error(message)
    },
    onNaverSocialLoginResponse(response) {
      console.log('onNaverSocialLoginResponse()');
      console.log(response);

      const userId = response.id
      if (userId) {
        this.signIn({
          'type': 'b',
          'u_id': userId,
          'redirect': this.$route.query?.redirect,
        })
      } else {
        this.$toast.error('네이버 로그인 아이디를 불러오는 중 오류가 발생하였습니다')
      }
    },
    initNaver() {
      const originUrl = window.location.origin
      const naverLogin = new window.naver.LoginWithNaverId(
          {
            clientId: 'zTPyxky6y83gI6FDugj6',
            callbackUrl: originUrl + '/naver-callback.html',
            isPopup: true,
            callbackHandle: false
          }
      );
      naverLogin.init();
    },
    decodeJwtResponse(token) {
      let base64Url = token.split('.')[1]
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload)
    },
    onLoginNaver() {
      if (this.isWebView) {
        window.flutter_inappwebview.callHandler('loginChannel', 'naver');
      }
    },
    async onLoginGoogle() {
      if (this.isWebView) {
        window.flutter_inappwebview.callHandler('loginChannel', 'google');
        return;
      }

      const appContext = this

      window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: (response) => {
          console.log('handleCredentialResponse()');
          console.log(response);

          const responsePayload = this.decodeJwtResponse(response.credential)
          console.log("ID: " + responsePayload.sub);
          console.log('Full Name: ' + responsePayload.name);
          console.log('Given Name: ' + responsePayload.given_name);
          console.log('Family Name: ' + responsePayload.family_name);
          console.log("Image URL: " + responsePayload.picture);
          console.log("Email: " + responsePayload.email);

          const googleUserId = responsePayload.sub
          console.log('googleUserId => ' + googleUserId);

          appContext.signIn({
            'type': 'c',
            'u_id': googleUserId,
            'redirect': appContext.$route.query?.redirect,
          })
        },
      });

      window.google.accounts.id.prompt();
    },
    initKakao() {
      const js = document.createElement('script')
      js.setAttribute('src', '//developers.kakao.com/sdk/js/kakao.js')
      document.head.appendChild(js)
    },
    onLoginKakao() {
      if (this.isWebView) {
        window.flutter_inappwebview.callHandler('loginChannel', 'kakao');
        return;
      }

      const appContext = this

      try {
        const kakaoJsKey = process.env.VUE_APP_KAKAO_JAVASCRIPT_KEY
        window.Kakao.init(kakaoJsKey)
      } catch(e) {
        console.log(e);
      }

      window.Kakao.Auth.login({
        success: function () {
          window.Kakao.API.request({
            url: '/v2/user/me',
            data: {},
            success: async function (response) {
              const userId = response.id
              console.log('kakao userId: ' + userId);

              appContext.signIn({
                'type': 'a',
                'u_id': userId,
                'redirect': appContext.$route.query?.redirect,
              })
            },
            fail: function (error) {
              appContext.$toast.error('[카카오로그인] 내 정보를 불러오는 중 오류가 발생하였습니다')
              console.log(error)
            },
          })
        },
        fail: function (error) {
          appContext.$toast.error('[카카오로그인] 로그인 중 오류가 발생하였습니다')
          console.log(error)
        },
      })
    },
    onLogin() {
      if (!this.email) { this.$toast.error("이메일을 입력해주세요") }
      else if (!this.password) { this.$toast.error("비밀번호를 입력해주세요") }
      else {
        this.signIn({
          'email': this.email,
          'password': this.password,
          'redirect': this.$route.query?.redirect,
          'is_mobile': utils.isMobile(),
        })
      }
    },
    onEnterByEmail() {
      if (this.email && this.password) {
        this.onLogin()
      }
    },
    ...mapActions('auth', ['signIn']),
  },
}
</script>

<style lang="scss" scoped>
.login-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 108px 40px 194px;
  .login-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    h1 {
      font-weight: 700;
      font-size: 45px;
      text-align: center;
      color: black;
      margin-bottom: 46px;
    }
    input {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #999999;
      border-radius: 5px;
      padding: 17px 23px;
      font-weight: 700;
      font-size: 14px;
      color: #999999;
      margin-top: 20px;
    }
    .login-btn {
      width: 100%;
      background: #008ED5;
      border: 1px solid #008ED5;
      margin-top: 44px;
      border-radius: 5px;
      padding: 14px 10px;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #FFFFFF;
    }
    .or-border {
      width: 100%;
      display: flex;
      gap: 15px;
      margin-top: 47px;
      align-items: center;
      .border {
        flex: 1;
        height: 1px;
        background: #008ED5;
        border-radius: 5px;
      }
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #000000;
      }
    }
    .sns-list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 21px;
      align-items: center;
      .sns-btn {
        width: 50px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    p {
      margin-top: 56px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #000000;
    }
    .reg-btn {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #008ED5;
      border-radius: 5px;
      padding: 14px 10px;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #008ED5;
      margin-top: 23px;
    }
    .find-list-wrap {
      width: 100%;
      display: flex;
      gap: 10px;
      margin-top: 20px;
      .divider {
        width: 1px;
        align-self: stretch;
        background-color: #008ED5;
      }
      .find-item {
        flex: 1;
        font-size: 14px;
        color: black;
        &:first-child {
          text-align: right;
        }
        &:last-child {
          text-align: start;
        }
        &>a {
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .login-wrap {
    padding: 20px 40px;
    .login-container {
      h1 {
        font-size: 30px;
        margin-bottom: 20px;
      }
      input {
        margin-top: 15px;
        font-size: 12px;
      }
      .login-btn {
        margin-top: 30px;
      }
    }
  }
}
</style>
