<template>
  <div class="unit-learning-map-wrap">
    <div class="ulm-abs-wrap">
      <div class="m-inner-wrap">
        <OrganizationChart
          :chart-data="chartData"
          @nodeClick="this.onNodeClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationChart from "@/components/organizationChart/OrganizationChart.vue";

export default {
  components: {
    OrganizationChart,
  },
  props: {
    chartData: Object,
  },
  methods: {
    onNodeClick(event) {
      console.log(event)
    },
  },
}
</script>
