<template>
  <div class="mypage-tab-body-wrap">
    <Filter
        :filterData="this.filterData"
    />

    <TreeDetail
        :nodes="this.nodes"
        :questions="this.questions"
    />
  </div>

  <State
      :stateData="this.stateData"
  />
</template>

<script>
import Filter from './Filter.vue'
import State from './state.vue'
import ProblemService from '../services/problem.service'
import TreeDetail from "@/components/TreeDetail.vue";

export default {
  name: 'EditStep1Mock',
  components: {
    TreeDetail,
    Filter,
    State,
  },
  props: {
    isWorkbook: Boolean,
    onChangeProblems: Function,
    onChangeSearchProblems: Function,
  },
  watch: {
    problems() {
      this.onChangeProblems(this.problems)
    },
    searchProblems() {
      this.onChangeSearchProblems(this.searchProblems)
    },
    selectGrade() {
      this.initYears()
    },
    selectYear() {
      this.initMonths()
    },
    selectMonth() {
      this.initOriginNodes();
    },
  },
  data() {
    return {
      grades: [],
      selectGrade: null,
      years: [],
      selectYear: null,
      months: [],
      selectMonth: null,

      originNodes: {},

      scores: [],
      types: ['전체'],
    }
  },
  mounted() {
    this.initGrades()
  },
  methods: {
    onChangeGrades(grades) {
      this.grades = grades;
      this.selectGrade = null;
      this.years = [];
      this.selectYear = null;
      this.months = [];
      this.selectMonth = null;
      this.originNodes = {};
    },
    async initGrades() {
      this.onChangeGrades([]);
      const res = await ProblemService.getMockUnitFilterList({
        resultType: 0,
      });
      this.onChangeGrades(res?.filter_list ?? [])
    },
    onChangeYears(years) {
      this.years = years;
      this.selectYear = null;
      this.months = [];
      this.selectMonth = null;
      this.originNodes = {};
    },
    async initYears() {
      this.onChangeYears([]);
      if (this.selectGrade) {
        const res = await ProblemService.getMockUnitFilterList({
          resultType: 1,
          grade: this.selectGrade,
        });
        this.onChangeYears(res?.filter_list ?? [])
      }
    },
    onChangeMonths(months) {
      this.months = months;
      this.selectMonth = null;
      this.originNodes = {};
    },
    async initMonths() {
      this.onChangeMonths([]);
      if (this.selectYear) {
        const res = await ProblemService.getMockUnitFilterList({
          resultType: 2,
          grade: this.selectGrade,
          year: this.selectYear,
        })
        this.onChangeMonths(res?.filter_list ?? [])
      }
    },
    async initOriginNodes() {
      this.originNodes = {};
      this.originNodes = await ProblemService.getMockUnitProblems({
        grade: this.selectGrade,
        year: this.selectYear,
        month: this.selectMonth,
      });
    },
    onChangeNode(node, isChecked) {
      this.onFocusNode(node);

      const entries = Object.entries(this.originNodes);
      const changeEntry = entries.find(entry => entry[0] == node.id);
      const allItems = this.getAllItemsByNodeValue(changeEntry[1]);
      allItems.forEach(item => {
        item.isChecked = isChecked;
      });
    },
    onFocusNode(node) {
      Object.entries(this.originNodes).forEach(entry => {
        const key = entry[0]
        const value = entry[1]

        this.originNodes[key].focus = node.id == value.id;
      });
    },
    getAllItemsByNodeValue(value) {
      let result = [];
      const entries = Object.entries(this.originNodes);
      const children = value.children ?? [];

      result = [
        ...result,
        ...value.items ?? [],
      ];
      for (let index = 0; index < children.length; index++) {
        const childKey = children[index];
        entries.filter(entry => entry[0] == childKey).forEach(entry => {
          result = [
            ...result,
            ...entry[1].items ?? [],
          ];
        });
      }

      // 모의고사 문제 번호순 오름차순
      result.sort((a, b) => {
        if (a.title.includes('번') && b.title.includes('번')) {
          const aNum = parseInt(a.title.replace(/[^\d.-]+/g, '') ?? '');
          const bNum = parseInt(b.title.replace(/[^\d.-]+/g, '') ?? '');
          if (aNum > bNum) return 1;
          if (aNum < bNum) return -1;
        } else {
          if (a.title > b.title) return 1;
          if (a.title < b.title) return -1;
        }
        return 0;
      });

      return result;
    },
  },
  computed: {
    stateData() {
      let scoresStr = [];
      if (this.scores.length === 0) {
        scoresStr.push('전체');
      } else {
        this.scores.forEach(score => {
          scoresStr.push(`${score}점`);
        });
      }

      return {
        items: [
          {
            id: 0,
            type: 'button',
            title: '배점',
            list: ['전체', '2점', '3점', '4점'],
            value: scoresStr,
            onChange: (id, value) => {
              if (value == '전체') {
                this.scores = [];
              } else {
                const scoreNum = parseInt(value.replace('점', ''));

                const foundItemIdx = this.scores.findIndex(score => score === scoreNum);
                if (foundItemIdx < 0) {
                  this.scores.push(scoreNum);
                } else {
                  this.scores.splice(foundItemIdx, 1);
                }
              }
            },
          },
          {
            id: 1,
            type: 'button',
            title: '문제 타입',
            list: ['전체', '단답식', '수능형'],
            listClass: 'grid-3',
            value: this.types,
            onChange: (id, value) => {
              if (value == '전체') {
                this.types = ['전체']
              } else {
                const allItemIdx = this.types.findIndex(i => i == '전체')
                if (allItemIdx > -1) {
                  this.types.splice(allItemIdx, 1)
                }

                const targetItemIdx = this.types.findIndex(i => i == value)
                if (targetItemIdx > -1) {
                  if (this.types.length == 1) {
                    this.types = ['전체']
                  } else {
                    this.types.splice(targetItemIdx, 1)
                  }
                } else {
                  this.types.push(value)
                }
              }
            },
          },
        ],
        resItems: [
          {type: 'text', title: '문제수', value: `${Number(this.problems.length)}개`},
        ],
      }
    },
    filterData() {
      return {
        title: '모의고사 선택',
        list: [
          {type: 'select', addClass: 'w-105px', name: '학년', options: this.grades.map(i => {
              return {value: i, name: i, isSelect: this.selectGrade == i}
            }), onChange: (value) => this.selectGrade = value},
          this.selectGrade ? {type: 'select-arrow'} : {},
          this.selectGrade ? {type: 'select', addClass: 'w-105px', name: '년도', options: this.years.map(i => {
              return {value: i, name: i, isSelect: this.selectYear == i}
            }), onChange: (value) => this.selectYear = value} : {},
          this.selectGrade && this.selectYear ? {type: 'select-arrow'} : {},
          this.selectGrade && this.selectYear ? {type: 'select', addClass: 'w-105px', name: '월', options: this.months.map(i => {
              return {value: i, name: i, isSelect: this.selectMonth == i}
            }), onChange: (value) => this.selectMonth = value} : {},
        ],
        searchInput: {isHidden: true},
      }
    },
    problems() {
      let problems = [];

      // 체크 된 모든 문제들 불러오기
      Object.values(this.originNodes).forEach(value => {
        (value.items ?? []).forEach(item => {
          if (item.isChecked) {
            item.problems.forEach(problem => {
              problems.push(Object.assign({}, problem));
            });
          }
        });
      });

      // 필터링
      problems = problems.filter(problem => {
        let isPass = true;

        // 배점 필터링
        const scores = this.scores;
        if (isPass && scores.length > 0) {
          isPass = scores.includes(problem.score);
        }

        // 문제 타입 필터링
        const types = this.types.filter(i => i != '전체');
        if (isPass && types.length > 0) {
          isPass = types.includes(problem.type);
        }

        return isPass;
      });
      console.log(problems);

      return problems;
    },
    searchProblems() {
      let problems = [];

      // 체크 된 모든 문제들 불러오기
      Object.values(this.originNodes).forEach(value => {
        (value.items ?? []).forEach(item => {
          if (item.isChecked) {
            item.problems.forEach(problem => {
              problems.push(Object.assign({}, problem));
            });
          }
        });
      });

      return problems;
    },
    questions() {
      let result = [];
      const entries = Object.entries(this.originNodes);

      const focusEntry = entries.find(entry => entry[1].focus);
      if (focusEntry) {
        const value = focusEntry[1];

        result = [
            ...result,
            ...this.getAllItemsByNodeValue(value),
        ];

        result.forEach(item => {
          item.onChange = (item, isChecked) => {
            item.isChecked = isChecked;
          };
        });
      }

      return [{
        'title': '',
        'items': result,
      }];
    },
    nodes() {
      const nodes = {};
      const entries = Object.entries(this.originNodes);
      for (let index = 0; index < entries.length; index++) {
        const entry = entries[index];
        const key = entry[0];
        const value = entry[1];

        const allItems = this.getAllItemsByNodeValue(value);
        const isExistCheckedItem = allItems.findIndex(item => item.isChecked) >= 0;
        const isAllCheckedItem = allItems.findIndex(item => !item.isChecked) < 0;
        if (value.state) {
          value.state.indeterminate = isExistCheckedItem && !isAllCheckedItem;
          value.state.checked = isAllCheckedItem;
        }
        value.onChange = this.onChangeNode;
        value.onFocus = this.onFocusNode;
        nodes[key] = value;
      }
      return nodes;
    },
  },
}
</script>
