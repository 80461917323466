<template>
    <input 
        :class="addClass"
        :placeholder="placeholder"
        :type="type"
        :value="value"
        @input="changeValue($event.target.value)"
        :style="addStyle"
        :data-date="dataDate"
        ref="input"
        :disabled="isDisabled"
        :readonly="isReadOnly"
        />
</template>

<script>
export default {
    name: 'CustomInput',
    props: {
        addClass: String,
        placeholder:   { type: String,   default: '', },
        type:          { type: String,   default: 'text', },
        value:         { type: String,   default: '', },
        dataDate: String,
        onChangeValue: Function,
        isDisabled: Boolean,
        isReadOnly: Boolean,
        isRegexHyphen: Boolean,
    },
    data() {
        return {
            addStyle: null,
        };
    },
    methods: {
        changeValue(value) {
            let changedValue = value

            if (this.isRegexHyphen) {
                const regex = /-/gi
                if (regex.test(value)) {
                    changedValue = value.replace(regex, '')
                    this.$toast.warning('- 는 입력할 수 없습니다.')
                    this.$refs.input.value = changedValue
                }
            }

            if (this.isDateType) {
                this.$refs.input.setAttribute('data-date', changedValue);
            }

            if (this.onChangeValue) {
                this.onChangeValue(changedValue);
            }
        },
    },
    computed: {
        isDateType() {
            return this.type !== null
                && this.type !== undefined
                && (this.type === 'date' || this.type === 'month')
        },
    },
}
</script>

<style scoped lang="scss">
$defaultColor: #008ED5;

input {
    background: #FFFFFF;
    border: 1px solid #008ED5;
    border-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
    height: 35px;
    padding: 3px 8px;
    &.default-border {
        border: 1px solid rgb(224, 224, 224);
    }
    &:disabled {
        background: #efefef;
    }
    &:read-only {
        background: #efefef;
    }
    &::placeholder {
        color: rgb(215, 215, 215);
    }
}
</style>