export class WrongItem {
    /** @type {number} 정답률 미만 */
    max;
    /** @type {number} 오답지 생성 배수 */
    multiple;

    constructor(
        max,
        multiple,
    ) {
        this.max = max;
        this.multiple = multiple;
    }
}
