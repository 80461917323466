<template>
  <div
      :class="[
            'list-td',
            this.addClass,
            {
                'text-line-1': this.type == 'string',
                'c-pointer': td.onClick,
            }
        ]"
      @click="() => td.onClick ? td.onClick(td.id) : ''"
  >
    <CustomCheckbox
        v-if="this.type == 'checkbox'"
        class="num-cb"
        onclick="event.stopPropagation()"
        :isChecked="td.isChecked"
        :onChange="(checked) => { td.onChange ? td.onChange(checked) : '' }"
    />

    <span
        v-if="this.type == 'string'"
    >{{ td.value }}</span>

    <div
        v-if="this.type == 'image'"
        class="img-wrap"
    >
      <img :src="td.value"
           alt=""
      />
    </div>

    <button
        v-if="this.type == 'prev-btn'"
        class="img-btn"
        onclick="event.stopPropagation()"
        @click="() => {td.onClickPrevBtn ? td.onClickPrevBtn() : ''}"
    >
      <img src="@/assets/images/mypage-prev-btn.png" alt=""/>
    </button>

    <button
        v-if="this.type == 'edit-btn'"
        class="img-btn"
        onclick="event.stopPropagation()"
        @click="() => {td.onClickEditBtn ? td.onClickEditBtn() : ''}"
    >
      <img src="@/assets/images/mypage-edit-btn.png" alt=""/>
    </button>

    <button
        v-if="this.type == 'submit-btn'"
        :class="['submit-btn text-line-1', td.addClass]"
        onclick="event.stopPropagation()"
        @click="() => {td.onClickSubmitBtn ? td.onClickSubmitBtn() : ''}"
    >{{ td.value && td.value.length > 0 ? td.value : '출제하기' }}</button>

    <button
        v-if="this.type == 'r-confirm-btn'"
        :class="['submit-btn text-line-1', td.addClass]"
    >{{ td.value && td.value.length > 0 ? td.value : '수정완료' }}</button>

    <b-dropdown
        v-if="this.type == 'etc-btn'"
        dropleft
        class="etc-btn"
        onclick="event.stopPropagation()"
        ref="dropdown"
    >
      <template #button-content>
        <img src="@/assets/images/mypage-etc.png" alt=""/>
      </template>
      <b-dropdown-item
          v-if="td.onClickEctEdit"
          @click="() => {td.onClickEctEdit ? td.onClickEctEdit() : ''; this.$refs.dropdown.hide(true);}"
      >
        수정
      </b-dropdown-item>
      <b-dropdown-item
          v-if="td.onClickEctDownload"
          @click="() => {td.onClickEctDownload ? td.onClickEctDownload() : ''; this.$refs.dropdown.hide(true);}"
      >
        다운로드
      </b-dropdown-item>
      <b-dropdown-item
          v-if="td.onClickEctDelete"
          @click="() => {td.onClickEctDelete ? td.onClickEctDelete() : ''; this.$refs.dropdown.hide(true);}"
      >
        삭제
      </b-dropdown-item>
      <template v-if="td.customList">
        <template v-for="(customItem, customItemIndex) in td.customList" :key="customItemIndex">
          <b-dropdown-item
              v-if="customItem.title && customItem.onClick"
              @click="() => {customItem.onClick(); this.$refs.dropdown.hide(true);}"
          >
            {{ customItem.title }}
          </b-dropdown-item>
        </template>
      </template>
    </b-dropdown>

    <button
        v-if="this.type == 'order-btn'"
        class="order-btn"
        onclick="event.stopPropagation()"
        @click="() => {}"
    >
      <img src="@/assets/images/list-order.png" alt=""/>
    </button>
  </div>
</template>

<script>
import CustomCheckbox from './CustomCheckbox.vue';

export default {
    name: "ListArrayBodyTr",
    props: {
        td: Object,
        addClass: String,
        type: String,
    },
    components: { CustomCheckbox }
}
</script>
