import axios from "axios";
import app from '../main';
import store from "../store";
import router from "../router";

const API_URL = `${process.env.VUE_APP_IS_LOCAL === 'true' ? process.env.VUE_APP_LOCAL_SERVER_URL : process.env.VUE_APP_RELEASE_SERVER_URL}/api/`;

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    function(config) {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    function(response) {
        const { data } = response;
        return data;
    },
    function(error) {
        try {
            const res = error.response;
            if (res) {
                const status = res.status;
                const data = res.data;
                switch (status) {
                    case 400:
                        if (data) {
                            if (data.message) {
                                app.config.globalProperties.$toast.error(data.message);
                            } else if (data.length == 2 && data[0] == 'message') {
                                app.config.globalProperties.$toast.error(data[1]);
                            } else if (data.length == 2 && data[1] == 'message') {
                                app.config.globalProperties.$toast.error(data[0]);
                            }
                        }
                        break;
                    case 401:
                        app.config.globalProperties.$toast.error('서버에서 인증정보를 확인할 수 없습니다\n다시 로그인해주세요');
                        store.dispatch('auth/signout', false);
                        router.push({ name: 'login', redirect: router.currentRoute.value.fullPath });
                        break;
                }
            }            
        } catch (e) {
            console.log(e);
        }

        return Promise.reject(error);
    }
);

export default instance;
