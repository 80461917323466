<template>
  <div class="report-preview-wrap">
    <div class="wrap-abs rp-page-title">
      <div class="wrap-scroll">
        <div class="sb-cont">
          <span>{{reportData.date}}</span>
          <span>REPORT</span>
          <span>{{reportData.subject}}</span>
        </div>
        <img class="report-logo" :src="reportLogo" alt=""/>
        <div class="rp-first-header">
          <h1>원포인트</h1>
          <h2>학업분석</h2>
          <h3>보고서</h3>
        </div>
        <div class="rp-first-desc">
          <div class="desc-cont">
            <p>학년</p>
            <span>{{reportData.grade}}</span>
          </div>
          <div class="desc-cont">
            <p>이름</p>
            <span>{{reportData.name}}</span>
          </div>
          <div class="desc-cont">
            <p>보고일</p>
            <div class="desc-divider"></div>
            <span>{{reportData.report_dt}}</span>
          </div>
        </div>
        <div class="rp-logo-list">
          <img :src="academyLogoUrl" alt="">
          <img :src="reportLogoDivider" alt="">
          <img :src="defaultLogoUrl" alt="">
        </div>
      </div>
    </div>




    <div class="wrap-abs">
      <div class="wrap-scroll">
        <div class="sb-cont">
          <span>{{reportData.date}}</span>
          <span>REPORT</span>
          <span>{{reportData.subject}}</span>
        </div>
        <img class="report-logo opacity-50" :src="reportLogo" alt=""/>
        <div class="wrap-cont">
          <div class="list-title">
            <h1>목차</h1>
            <h2>&nbsp;: CONTENTS</h2>
          </div>
          <div class="list-desc">
            <p>1. 결과 요약</p>
            <p>2. 시험지 분석</p>
            <p>3. 문항별 결과</p>
            <p>4. 학업 역량 분석</p>
            <p>5. 결과 추이</p>
          </div>
          <div class="rp-title">
            <p>1. 결과요약</p>
            <h5>Summary</h5>
          </div>

          <div class="rp-1-wrap">
            <div class="line-1">
              <div class="progress-circle p30">
                <span>{{reportData.total_score}}점</span>
                <div class="left-half-clipper">
                  <div class="first50-bar"></div>
                  <div class="value-bar"></div>
                </div>
              </div>
              <div class="line-divider"></div>
              <div class="person-wrap">
                <div class="person-top">
                  <div class="arrow-wrap" :style="`left: ${reportData.top_percentage}%`">
                    <span>상위 {{reportData.top_percentage}}%</span>
                    <img :src="downArrow" alt=""/>
                  </div>
                </div>
                <div class="person-list-wrap">
                  <div class="list-bg"></div>
                  <div class="list-per" :style="`width: ${reportData.top_percentage}%`"></div>
                </div>
              </div>
            </div>

            <div class="line-2">
              <h1>전체 문항수</h1>
              <div>
                <h4>{{reportData.tot_pro_cnt}} / {{reportData.cor_pro_cnt}}</h4>
                <h5>정답 수</h5>
              </div>
            </div>

            <div class="line-3">
              <h1>취약한 문항,유형</h1>
              <template v-for="(wp, index) in reportData.weak_problems" :key="index">
                <div v-if="index > 0" class="line-divider"></div>
                <div class="line-item">
                  <p>{{wp['no']}}번</p>
                  <span>{{wp['pattern_name']}}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="rp-logo-list abs-btm">
          <img :src="academyLogoUrl" alt="">
          <img :src="reportLogoDivider" alt="">
          <img :src="defaultLogoUrl" alt="">
        </div>
      </div>
    </div>









    <div class="wrap-abs">
      <div class="wrap-scroll">
        <div class="sb-cont">
          <span>{{reportData.date}}</span>
          <span>REPORT</span>
          <span>{{reportData.subject}}</span>
        </div>
        <img class="report-logo opacity-50" :src="reportLogo" alt=""/>
        <div class="wrap-cont">
          <div class="rp-title">
            <p>2. 시험지 분석</p>
            <h5>Analyze</h5>
          </div>
          <div class="rp-title-desc">
            <p>학생의 상황을 파악하기 전 시험지의 난이도 및 유형을 먼저 이해 필요합니다.<br/>시험자의 성격의 따라 학생의 점수의 편차가 생길수 있습니다.</p>
          </div>

          <div class="rp-2-wrap">
            <div class="line-1">
              <h1 class="line-title">난이도 분포</h1>
              <div class="c-wrap">
                <PieChart
                    :width="200"
                    :height="130"
                    :chartData="{
                                        labels: reportData['diff_cnt_list'].map(i => i['name']),
                                        datasets: [
                                            {
                                                data: reportData['diff_cnt_list'].map(i => i['cnt']),
                                                backgroundColor: ['rgb(252, 82, 48)', 'rgb(253, 138, 105)', '#F9A600', 'rgb(0, 141, 213)', '#60B6DC'],
                                                borderColor: '#fff',
                                                borderWidth: 0,
                                            },
                                        ],
                                    }"
                    :options="{
                                        devicePixelRatio: this.devicePixelRatio,
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                display: true,
                                                position: 'left',
                                                labels: {
                                                    boxWidth: 12,
                                                    boxHeight: 12,
                                                    font: {
                                                        weight: '600',
                                                        size: 12,
                                                    },
                                                },
                                            },
                                            datalabels: {
                                                anchor: 'center',
                                                align: 'center',
                                                formatter: (value) => {
                                                    return value != 0 ? value : ''
                                                },
                                                font: {
                                                    size: 14,
                                                },
                                                color: '#fff',
                                                strokeStyle: '#000',
                                            }
                                        }
                                    }"
                />
                <span class="chart-desc">&#8251; 문항수 기준</span>
              </div>
            </div>

            <div class="line-2">
              <h1 class="line-title">단원별 분포</h1>
              <PieChart
                  :width="340"
                  :height="150"
                  :chartData="{
                                    labels: reportData['middle_unit_list'].map(i => i['name']),
                                    datasets: [
                                        {
                                            data: reportData['middle_unit_list'].map(i => i['cnt']),
                                            backgroundColor: ['rgb(18, 135, 207)', 'rgb(89, 203, 255)', 'rgb(23, 167, 241)', '#60B6DC', 'rgb(194, 194, 194)'],
                                            borderColor: '#fff',
                                            borderWidth: 0,
                                        },
                                    ],
                                }"
                  :options="{
                                    devicePixelRatio: this.devicePixelRatio,
                                    responsive: false,
                                    plugins: {
                                        legend: {
                                            display: true,
                                            position: 'left',
                                            labels: {
                                                boxWidth: 12,
                                                boxHeight: 12,
                                                font: {
                                                    weight: '600',
                                                    size: 12,
                                                },
                                            },
                                        },
                                        datalabels: {
                                            anchor: 'center',
                                            align: 'center',
                                            formatter: (value) => {
                                                return value != 0 ? value : ''
                                            },
                                            font: {
                                                size: 14,
                                            },
                                            color: '#fff',
                                            strokeStyle: '#000',
                                        }
                                    }
                                }"
              />
              <span class="chart-desc">&#8251; 문항수 기준</span>
            </div>

            <div class="line-3">
              <div class="line-left">
                <h1 class="line-title">유형별 분포</h1>

                <PieChart
                    :width="250"
                    :height="130"
                    :chartData="{
                                        labels: reportData['type_cnt_list'].map(i => i['name']),
                                        datasets: [
                                            {
                                                data: reportData['type_cnt_list'].map(i => i['cnt']),
                                                backgroundColor: ['rgb(18, 135, 207)', 'rgb(23, 167, 241)', 'rgb(89, 203, 255)', '#60B6DC', 'rgb(194, 194, 194)'],
                                                borderColor: '#fff',
                                                borderWidth: 0,
                                            },
                                        ],
                                    }"
                    :options="{
                                        devicePixelRatio: this.devicePixelRatio,
                                        responsive: false,
                                        plugins: {
                                            legend: {
                                                display: true,
                                                position: 'left',
                                                labels: {
                                                    boxWidth: 12,
                                                    boxHeight: 12,
                                                    font: {
                                                        weight: '600',
                                                        size: 12,
                                                    },
                                                },
                                            },
                                            datalabels: {
                                                anchor: 'center',
                                                align: 'center',
                                                formatter: (value) => {
                                                    return value != 0 ? value : ''
                                                },
                                                font: {
                                                    size: 14,
                                                },
                                                color: '#fff',
                                                strokeStyle: '#000',
                                            }
                                        }
                                    }"
                />
              </div>
              <div class="line-right">
                <h1 class="line-title">풀이형 분포</h1>

                <PieChart
                    :width="250"
                    :height="130"
                    :chartData="{
                                        labels: reportData['solution_type_cnt_list'].map(i => i['name']),
                                        datasets: [
                                            {
                                                data: reportData['solution_type_cnt_list'].map(i => i['cnt']),
                                                backgroundColor: ['#F9A600', '#DFC782'],
                                                borderColor: '#fff',
                                                borderWidth: 0,
                                            },
                                        ],
                                    }"
                    :options="{
                                        devicePixelRatio: this.devicePixelRatio,
                                        responsive: false,
                                        plugins: {
                                            legend: {
                                                display: true,
                                                position: 'right',
                                                labels: {
                                                    boxWidth: 12,
                                                    boxHeight: 12,
                                                    font: {
                                                        weight: '600',
                                                        size: 12,
                                                    },
                                                },
                                            },
                                            datalabels: {
                                                anchor: 'center',
                                                align: 'center',
                                                formatter: (value) => {
                                                    return value != 0 ? value : ''
                                                },
                                                font: {
                                                    size: 14,
                                                },
                                                color: '#fff',
                                                strokeStyle: '#000',
                                            }
                                        }
                                    }"
                />

                <span class="chart-desc">&#8251; 문항수 기준</span>
              </div>
            </div>
          </div>
        </div>
        <div class="rp-logo-list abs-btm">
          <img :src="academyLogoUrl" alt="">
          <img :src="reportLogoDivider" alt="">
          <img :src="defaultLogoUrl" alt="">
        </div>
      </div>
    </div>









    <div class="wrap-abs">
      <div class="wrap-scroll">
        <div class="sb-cont">
          <span>{{reportData.date}}</span>
          <span>REPORT</span>
          <span>{{reportData.subject}}</span>
        </div>
        <img class="report-logo opacity-50" :src="reportLogo" alt=""/>
        <div class="wrap-cont">
          <div class="rp-title mb-10px">
            <p>3. 문항별 결과</p>
            <h5>Result</h5>
          </div>
          <div class="rp-title-desc">
            <p>1번부터 끝까지 문제의 유형과 결과가 있습니다.<br>주황색 물음표는 학생이 모르거나 헷갈려서 정답을 체크하지 않거나 두 개를 체크한 문항입니다.(틀린문항)<br>틀린것과 물음표된 유형들을 집중적으로 확인해주시기 바랍니다.</p>
          </div>

          <div class="rp-3-wrap">
            <h1>취약한 문항,유형</h1>
            <div class="table-wrap">
              <template v-for="(wp, index) in reportData.weak_problems" :key="index">
                <div class="tr">
                  <p>{{wp['no']}}번</p>
                  <span>{{wp['pattern_name']}}</span>
                </div>
              </template>
            </div>

            <table>
              <thead>
              <th></th>
              <th>번호</th>
              <th>난이도</th>
              <th>유형</th>
              </thead>
              <tbody>
              <template v-if="problemList.length > 0">
                <template v-for="(problem, index) in problemList[0]" :key="index">
                  <tr :class="[{
                          'highlight': Math.floor((problem['no'] - 1) / 5) % 2 == 0,
                      }]">
                    <td>
                      <template v-if="problem['is_pass'] == 1">
                        <img :src="passAnswer" alt=""/>
                      </template>
                      <template v-else-if="problem['result'] == 1">
                        <img :src="correctAnswer" alt=""/>
                      </template>
                      <template v-else>
                        <img :src="wrongAnswer" alt=""/>
                      </template>
                    </td>
                    <td>{{problem['no']}}</td>
                    <td>{{problem['difficulty']}}</td>
                    <td>{{problem['pattern_name']}}</td>
                  </tr>
                </template>
              </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="rp-logo-list abs-btm">
          <img :src="academyLogoUrl" alt="">
          <img :src="reportLogoDivider" alt="">
          <img :src="defaultLogoUrl" alt="">
        </div>
      </div>
    </div>

    <template v-if="problemList.length > 1">
      <template v-for="(problems, listIndex) in problemList" :key="listIndex">
        <template v-if="listIndex > 0">
          <div class="wrap-abs">
            <div class="wrap-scroll">
              <div class="sb-cont">
                <span>{{reportData.date}}</span>
                <span>REPORT</span>
                <span>{{reportData.subject}}</span>
              </div>
              <img class="report-logo opacity-50" :src="reportLogo" alt=""/>
              <div class="wrap-cont">
                <div class="rp-3-wrap">
                  <table class="mt-0">
                    <thead>
                    <th></th>
                    <th>번호</th>
                    <th>난이도</th>
                    <th>유형</th>
                    </thead>
                    <tbody>
                    <template v-for="(problem, index) in problems" :key="index">
                      <tr :class="[{
                            'highlight': Math.floor((problem['no'] - 1) / 5) % 2 == 0,
                          }]">
                        <td>
                          <template v-if="problem['is_pass'] == 1">
                            <img :src="passAnswer" alt=""/>
                          </template>
                          <template v-else-if="problem['result'] == 1">
                            <img :src="correctAnswer" alt=""/>
                          </template>
                          <template v-else>
                            <img :src="wrongAnswer" alt=""/>
                          </template>
                        </td>
                        <td>{{problem['no']}}</td>
                        <td>{{problem['difficulty']}}</td>
                        <td>{{problem['pattern_name']}}</td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="rp-logo-list abs-btm">
                <img :src="academyLogoUrl" alt="">
                <img :src="reportLogoDivider" alt="">
                <img :src="defaultLogoUrl" alt="">
              </div>
            </div>
          </div>
        </template>
      </template>
    </template>






    <div class="wrap-abs">
      <div class="wrap-scroll">
        <div class="sb-cont">
          <span>{{reportData.date}}</span>
          <span>REPORT</span>
          <span>{{reportData.subject}}</span>
        </div>
        <img class="report-logo opacity-50" :src="reportLogo" alt=""/>
        <div class="wrap-cont">
          <div class="rp-title">
            <p>4. 학업 역량 분석</p>
            <h5>Ability</h5>
          </div>
          <div class="rp-title-desc">
            <p>이번 시험을 통한 학생의 역량을 수치화 한것입니다.<br>과학을 공부할때 필요한 4가지 역량중 약한부분을 확인하여서 다방면으로 대비가 필요합니다.</p>
          </div>

          <div class="rp-4-wrap">
            <div class="title-img">
              <span>기본적인 개념을 이해하고 문제를 풀고있는지 파악하는 능력<br/>오,엑스 문제와 일부 객관식 문제를 통해서 파악가능하며 4가지 역량중 가장 중요한 능력</span>
              <span>수학처럼 계산을 위한 공식이나,<br/>문제를 풀기위한 계산능력</span>
              <span>실험문제나 자료를 기반한 문제가 많은 과학에서 자료나 실험이 무엇을 파악하기 위함이며,<br/>이후에 있어질 결과는 어떤 값이 나오는지 유추하고 추론하는 능력<br/>그래프, 도표, 지도에서 말하는 의미를 분석하여 문제를 빠르게 이해 하는 능력<br/>향후 수능의 자료형 문제, 논술전형의 문제, 영제원 문제에서 필요한 능력</span>
              <span>응용문제나, 수능에서 나오는 문제의 문장을 이해하고 구분을 잘하는 능력수능에서 자주 나오는 (ex 하지 않는다.)<br/>지엽적인 과학문제를 많이 봐둬야하는 과목으로 처음보는 문제들도 응용해서 풀어내는 능력</span>
              <img :src="abilityIcon" alt=""/>
            </div>

            <div class="line-1">
              <div class="line-title">
                <h1>역량 부분</h1>
                <h5>&#8251; 한 칸 20</h5>
              </div>

              <div class="content-wrap">
                <div class="chart-wrap">
                  <PolarAreaChart
                      :width="150"
                      :height="150"
                      :chartData="{
                                            labels: ['수리력', '문해 사고력', '탐구 분석력', '이해력'],
                                            datasets: [
                                                {
                                                    data: [
                                                        reportData['comp_part_list'][1] >= 0 ? reportData['comp_part_list'][1] : 0,
                                                        reportData['comp_part_list'][3] >= 0 ? reportData['comp_part_list'][3] : 0,
                                                        reportData['comp_part_list'][2] >= 0 ? reportData['comp_part_list'][2] : 0,
                                                        reportData['comp_part_list'][0] >= 0 ? reportData['comp_part_list'][0] : 0,
                                                    ],
                                                    backgroundColor: ['rgb(0, 0, 0, .1)', 'rgb(73, 158, 237, .6)'],
                                                    borderWidth: 1,
                                                },
                                            ],
                                        }"
                      :options="{
                                            devicePixelRatio: this.devicePixelRatio,
                                            responsive: false,
                                            plugins: {
                                                legend: false,
                                                datalabels: false,
                                            },
                                            scales: {
                                                r: {
                                                    min: 0,
                                                    max: 100,
                                                    ticks: {
                                                        display: false,
                                                        stepSize: 20,
                                                    },
                                                },
                                            },
                                        }"
                  />
                  <span>수리력</span>
                  <span>문해<br/>사고력</span>
                  <span>탐구<br/>분석력</span>
                  <span>이해력</span>
                </div>
                <div class="table-wrap">
                  <table>
                    <tr>
                      <th></th>
                      <th>이해력</th>
                      <th>수리력</th>
                      <th>탐구분석력</th>
                      <th>문해사고력</th>
                    </tr>
                    <tr>
                      <td>전체 평균</td>
                      <td>{{ randValue(50, 70) }}</td> <!-- 50~70 중 랜덤 -->
                      <td>{{ randValue(50, 70) }}</td> <!-- 50~70 중 랜덤 -->
                      <td>{{ randValue(40, 60) }}</td> <!-- 40~60 중 랜덤 -->
                      <td>{{ randValue(40, 60) }}</td> <!-- 40~60 중 랜덤 -->
                    </tr>
                    <tr>
                      <td>{{ reportData.name }}</td>
                      <td :class="[{'min-value': isMinValueByCompPart(0)}]">{{reportData['comp_part_list'][0] >= 0 ? reportData['comp_part_list'][0] : '결과 없음'}}</td> <!-- 객관식 및 오엑스 중 정답률 -->
                      <td :class="[{'min-value': isMinValueByCompPart(1)}]">{{reportData['comp_part_list'][1] >= 0 ? reportData['comp_part_list'][1] : '결과 없음'}}</td> <!-- 계산식 중 정답률 -->
                      <td :class="[{'min-value': isMinValueByCompPart(2)}]">{{reportData['comp_part_list'][2] >= 0 ? reportData['comp_part_list'][2] : '결과 없음'}}</td> <!-- 실험형 중 정답률 -->
                      <td :class="[{'min-value': isMinValueByCompPart(3)}]">{{reportData['comp_part_list'][3] >= 0 ? reportData['comp_part_list'][3] : '결과 없음'}}</td> <!-- 수능형 중 정답률 -->
                      <!-- 가장 낮은 점수에 연한 빨간 셀배경처리 -->
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="line-2">
              <div class="line-title">
                <h1>중단원 부분</h1>
              </div>

              <template v-if="reportData['comp_middle_line_list'].length > 0">
                <div class="chart-wrap">
                  <BarChart
                      :height="180"
                      :chartData="{
                                            labels: reportData['comp_middle_line_list'][0].map(i => i['name']),
                                            datasets: [
                                                {
                                                    data: reportData['comp_middle_line_list'][0].map(i => i['tot_score']),
                                                    backgroundColor: ['#F9A600'],
                                                    borderWidth: 0,
                                                    label: reportData.name,
                                                },
                                                {
                                                    data: reportData['comp_middle_line_list'][0].map(i => i['avg_score']),
                                                    backgroundColor: ['rgb(194, 194, 194)'],
                                                    borderWidth: 0,
                                                    label: '전체 평균',
                                                },
                                            ],
                                        }"
                      :options="{
                                            devicePixelRatio: this.devicePixelRatio,
                                            responsive: false,
                                            plugins: {
                                                legend: {
                                                    display: true,
                                                    position: 'bottom',
                                                    labels: {
                                                        boxWidth: 12,
                                                        boxHeight: 12,
                                                        font: {
                                                            weight: '600',
                                                            size: 12,
                                                        },
                                                    },
                                                },
                                                datalabels: {
                                                    anchor: 'top',
                                                    align: 'center',
                                                    font: {
                                                        size: 10,
                                                    },
                                                    color: '#fff',
                                                    strokeStyle: '#000',
                                                },
                                            },
                                            scales: {
                                                y: {
                                                    min: 0,
                                                    max: 100,
                                                    ticks: {
                                                        stepSize: 20,
                                                    },
                                                },
                                                x: {
                                                    display: true,
                                                    grid: {
                                                        display: false,
                                                    },
                                                },
                                            },
                                        }"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="rp-logo-list abs-btm">
          <img :src="academyLogoUrl" alt="">
          <img :src="reportLogoDivider" alt="">
          <img :src="defaultLogoUrl" alt="">
        </div>
      </div>
    </div>


    <template v-if="other_page_comp_middle_line_list.length > 0">
      <template v-for="(comp_middle_line_list, index) in other_page_comp_middle_line_list" :key="index">
        <div class="wrap-abs">
          <div class="wrap-scroll">
            <div class="sb-cont">
              <span>{{reportData.date}}</span>
              <span>REPORT</span>
              <span>{{reportData.subject}}</span>
            </div>
            <img class="report-logo opacity-50" :src="reportLogo" alt=""/>
            <div class="wrap-cont">
              <div class="rp-4-wrap">
                <div class="line-2">
                  <template v-for="(comp_middle_list, index) in comp_middle_line_list" :key="index">
                    <div class="chart-wrap">
                      <BarChart
                          :height="180"
                          :chartData="{
                                                    labels: comp_middle_list.map(i => i['name']),
                                                    datasets: [
                                                        {
                                                            data: comp_middle_list.map(i => i['tot_score']),
                                                            backgroundColor: ['#F9A600'],
                                                            borderWidth: 0,
                                                            label: reportData.name,
                                                        },
                                                        {
                                                            data: comp_middle_list.map(i => i['avg_score']),
                                                            backgroundColor: ['rgb(194, 194, 194)'],
                                                            borderWidth: 0,
                                                            label: '전체 평균',
                                                        },
                                                    ],
                                                }"
                          :options="{
                                                    devicePixelRatio: this.devicePixelRatio,
                                                    responsive: false,
                                                    plugins: {
                                                        legend: {
                                                            display: true,
                                                            position: 'bottom',
                                                            labels: {
                                                                boxWidth: 12,
                                                                boxHeight: 12,
                                                                font: {
                                                                    weight: '600',
                                                                    size: 12,
                                                                },
                                                            },
                                                        },
                                                        datalabels: {
                                                            anchor: 'top',
                                                            align: 'center',
                                                            font: {
                                                                size: 10,
                                                            },
                                                            color: '#fff',
                                                            strokeStyle: '#000',
                                                        },
                                                    },
                                                    scales: {
                                                        y: {
                                                            min: 0,
                                                            max: 100,
                                                            ticks: {
                                                                stepSize: 20,
                                                            },
                                                        },
                                                        x: {
                                                            display: true,
                                                            grid: {
                                                                display: false,
                                                            },
                                                        },
                                                    },
                                                }"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="rp-logo-list abs-btm">
              <img :src="academyLogoUrl" alt="">
              <img :src="reportLogoDivider" alt="">
              <img :src="defaultLogoUrl" alt="">
            </div>
          </div>
        </div>
      </template>
    </template>









    <div class="wrap-abs">
      <div class="wrap-scroll">
        <div class="sb-cont">
          <span>{{reportData.date}}</span>
          <span>REPORT</span>
          <span>{{reportData.subject}}</span>
        </div>
        <img class="report-logo opacity-50" :src="reportLogo" alt=""/>
        <div class="wrap-cont">
          <div class="rp-title">
            <p>5. 결과 추이</p>
            <h5>History</h5>
          </div>
          <div class="rp-title-desc">
            <p>지금까지 학생이름으로 시험친 결과를 시간의 변화에 따라서 정리해둔 그래프입니다.<br>성적의 따라서 전체적인 학업 성취도를 가늠해 볼 수 있습니다.<br>그래프의 높낮이만 보는것이 아니라 시험지의 난이도를 참고하여서 확인해주시기 바랍니다.</p>
          </div>

          <div class="rp-5-wrap">
            <div class="chart-wrap">
              <p>난이도</p>
              <LineChart
                  :width="425"
                  :height="120"
                  :chartData="{
                                    labels: reportData['history_list'].map(i => i['title']),
                                    datasets: [
                                        {
                                            data: reportData['history_list'].map(i => i['diff_avg']),
                                            backgroundColor: ['black'],
                                            borderWidth: 1,
                                            borderColor: 'black',
                                            label: reportData.name,
                                        },
                                    ],
                                }"
                  :options="{
                                    devicePixelRatio: this.devicePixelRatio,
                                    responsive: false,
                                    layout: {
                                        padding: {
                                            top: 20,
                                        },
                                    },
                                    plugins: {
                                        legend: false,
                                        title: false,
                                        datalabels: false,
                                    },
                                    scales: {
                                        y: {
                                            suggestedMin: 1,
                                            suggestedMax: 5,
                                            ticks: {
                                                callback: '',
                                            },
                                            grid: {
                                                display: false,
                                            },
                                        },
                                        x: {
                                            offset: true,
                                            grid: {
                                                display: false,
                                            },
                                            ticks: {
                                                callback: '',
                                            },
                                        },
                                    },
                                }"
              />
            </div>

            <div class="chart-wrap">
              <p>평균</p>
              <LineChart
                  :width="450"
                  :height="120"
                  :chartData="{
                                    labels: reportData['history_list'].map(i => i['title']),
                                    datasets: [
                                        {
                                            data: reportData['history_list'].map(i => i['avg_avg']),
                                            backgroundColor: ['black'],
                                            borderWidth: 1,
                                            borderColor: 'black',
                                            label: reportData.name,
                                        },
                                    ],
                                }"
                  :options="{
                                    devicePixelRatio: this.devicePixelRatio,
                                    responsive: false,
                                    layout: {
                                        padding: {
                                            top: 20,
                                        },
                                    },
                                    plugins: {
                                        legend: false,
                                        title: false,
                                        datalabels: {
                                            anchor: 'start',
                                            align: 'top',
                                            font: {
                                                weight: '400',
                                                size: 10,
                                            },
                                        },
                                    },
                                    scales: {
                                        y: {
                                            min: 0,
                                            max: 100,
                                            ticks: {
                                                display: true,
                                                stepSize: 20,
                                            },
                                            grid: {
                                                display: false,
                                            },
                                        },
                                        x: {
                                            offset: true,
                                            grid: {
                                                display: false,
                                            },
                                            ticks: {
                                                callback: '',
                                            },
                                        },
                                    },
                                }"
              />
            </div>

            <div class="chart-wrap">
              <p>점수</p>
              <LineChart
                  :width="450"
                  :height="140"
                  :chartData="{
                                    labels: reportData['history_list'].map(i => i['title']),
                                    datasets: [
                                        {
                                            data: reportData['history_list'].map(i => i['score_avg']),
                                            backgroundColor: ['black'],
                                            borderWidth: 1,
                                            borderColor: 'black',
                                            label: reportData.name,
                                        },
                                    ],
                                }"
                  :options="{
                                    devicePixelRatio: this.devicePixelRatio,
                                    responsive: false,
                                    layout: {
                                        padding: {
                                            top: 20,
                                        },
                                    },
                                    plugins: {
                                        legend: false,
                                        title: false,
                                        datalabels: {
                                            anchor: 'start',
                                            align: 'top',
                                            font: {
                                                weight: '400',
                                                size: 10,
                                            },
                                        },
                                    },
                                    scales: {
                                        y: {
                                            min: 0,
                                            max: 100,
                                            ticks: {
                                                display: true,
                                                stepSize: 20,
                                            },
                                            grid: {
                                                display: false,
                                            },
                                        },
                                        x: {
                                            offset: true,
                                            grid: {
                                                display: false,
                                            },
                                        },
                                    },
                                }"
              />
            </div>

            <div class="comment-wrap">
              <div class="comment-top"></div>
              <div class="comment-content">{{reportData['comment'] ?? '코멘트 없음'}}</div>
            </div>
          </div>
        </div>
        <div class="rp-logo-list abs-btm">
          <img :src="academyLogoUrl" alt="">
          <img :src="reportLogoDivider" alt="">
          <img :src="defaultLogoUrl" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '../utils'
import { mapGetters } from 'vuex'

import { LineChart, BarChart, PieChart, PolarAreaChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import defaultLogoUrl from '@/assets/images/logo-big.png'
import reportLogo from '@/assets/images/report-logo.png'
import downArrow from '@/assets/images/down-arrow.png'
import correctAnswer from '@/assets/images/report-o.png'
import wrongAnswer from '@/assets/images/report-x.png'
import questionAnswer from '@/assets/images/report-q.png'
import abilityIcon from '@/assets/images/report-ability.png'
import reportLogoDivider from '@/assets/images/report-logo-divider.png'
import reportPersonList from '@/assets/images/report-person-list.png'
import passAnswer from '@/assets/images/report-pass.png'

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  name: 'ReportPreview',
  components: {
    LineChart,
    PieChart,
    BarChart,
    PolarAreaChart,
  },
  props: {
    reportData: Object,
  },
  setup() {
    utils.addScript('https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js')
    utils.addScript('https://html2canvas.hertzen.com/dist/html2canvas.js')
  },
  data() {
    return {
      defaultLogoUrl,
      reportLogo,
      reportLogoDivider,
      reportPersonList,
      downArrow,
      correctAnswer,
      wrongAnswer,
      questionAnswer,
      abilityIcon,
      passAnswer,
      devicePixelRatio: 10,
    };
  },
  methods: {
    randValue(minValue, maxValue) {
      return minValue + Math.floor((Math.random() * (maxValue - minValue)))
    },
    isMinValueByCompPart(index) {
      const compPartList = this.reportData['comp_part_list']
      const target = compPartList[index]

      if (target < 0) return false

      for (let idx = 0; idx < compPartList.length; idx++) {
        if (index !== idx && compPartList[idx] >= 0) {
          if (target > compPartList[idx]) {
            return false
          }
        }
      }
      return true
    },
  },
  computed: {
    other_page_comp_middle_line_list() {
      const result = []
      const targetList = this.reportData['comp_middle_line_list']
      if (targetList.length > 0) {
        let curIdx = 1
        const sliceLength = 4
        while (curIdx < targetList.length) {
          const nextIdx = curIdx + sliceLength
          result.push(targetList.slice(curIdx, nextIdx > targetList.length ? targetList.length : nextIdx))
          curIdx = nextIdx
        }
        targetList.slice
      }
      return result
    },
    academyLogoUrl() {
      const logoUrl = this.reportData?.academy_logo
      if (logoUrl) {
        return utils.getServerMediaPath(logoUrl)
      }
      return ''
    },
    problemList() {
      const problemList = []
      const problems = this.reportData.result_problems
      const firstSliceLength = 16
      const isOverFirstPage = problems.length > firstSliceLength
      problemList.push(problems.slice(0, isOverFirstPage ? firstSliceLength : problems.length)) // 첫 페이지
      if (isOverFirstPage) {
        let curSliceIndex = firstSliceLength
        let sliceLength = 24
        while (curSliceIndex < problems.length) {
          const nextSliceIndex = curSliceIndex + sliceLength
          problemList.push(problems.slice(curSliceIndex, nextSliceIndex > problems.length ? problems.length : nextSliceIndex))
          curSliceIndex = nextSliceIndex
        }
      }

      return problemList
    },
    ...mapGetters('auth', ['getUser']),
  },
}
</script>
