import {DividerExt} from "@/extensions/divider_ext";

export class StudentWorkProblemItem {
    /** @type {StudentWorkProblemVo} */
    studentWorkProblemVo;
    /** @type {string} */
    answer;

    constructor(
        studentWorkProblemVo,
        answer = null,
    ) {
        this.studentWorkProblemVo = studentWorkProblemVo;
        this.answer = answer ?? '';
    }

    /**
     * @param {StudentWorkProblemVo[]} beforeItemList
     * @param {StudentWorkProblemVo} studentWorkProblemVo
     * @return {StudentWorkProblemItem}
     */
    static fromOriginStudentWorkProblemVo(beforeItemList, studentWorkProblemVo) {
        const existItem = beforeItemList.find(e => e.studentWorkProblemVo.id == studentWorkProblemVo.id);

        let answer = existItem?.answer ?? null;
        if (answer == null) {
            answer = studentWorkProblemVo.answer;
        }

        return new StudentWorkProblemItem(
            studentWorkProblemVo,
            answer,
        );
    }

    /** @return {boolean} */
    isMultipleAnswer = () => this.studentWorkProblemVo.problemVo.answer.includes(DividerExt.dividerAnd);

    isActiveByNumber(number) {
        const answer = this.answer;
        const isMultipleAnswer = this.isMultipleAnswer();
        if (isMultipleAnswer) {
            return answer.split(DividerExt.userDivider).includes(number);
        } else {
            return number == answer;
        }
    }
}
