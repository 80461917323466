<template>
    <div class="mypage-teacher-wrap">
        <MypageList
            :selectTabIndex="this.selectTabIndex"
            :tabAddClass="'d-flex'"
            :tabs="this.tabs"
            :onTabClick="onChangeTab"
            :filterData="this.filterData"
            :listData="this.listData"
            :addTabBodyClass="'round-top-none'"
        />
    </div>
</template>

<script>
import MypageList from '../../../../components/MypageList.vue'
import WorksheetService from '@/services/worksheet.service'
import models from '../../../../models';
import utils from  '../../../../utils';
import TakeTestPopup from '@/components/TakeTestPopup.vue'
import problemService from '../../../../services/problem.service';

export default {
    name: "MypageTeacherClassPreRecommendByTheme",
    components: { 
        MypageList,
        TakeTestPopup,
     },
    data() {
        return {
            selectTabIndex: 0,
            tabs: [
                {title: '내신 대비 교과서 학습지'},
                {title: '내신 대비 추천'},
                {title: '수능/모의고사'},
                {title: '연산'},
                {title: '입학 TEST'},
                {title: '주간 TEST'},
                {title: '단원 TEST'},
            ],

            gradeList: [],
            selectGrade: null,
            
            searchKeyword: null,

            publishers: [],
            selectPublisher: null,

            tags: [],
            selectTag: null,

            worksheets: [],

            mockGrades: [],
            selectMockGrade: null,
            mockYears: [],
            selectMockYear: null,
            mockMonths: [],
            selectMockMonth: null,
        };
    },
    mounted() {
        let { tabIndex } = this.$route.query
        if (tabIndex != null) {
            tabIndex = parseInt(tabIndex)
        }

        this.onChangeTab(tabIndex ?? 0)
        this.initData()
        this.initList()
    },
    watch: {
        selectGrade() { this.initList() },
        selectTag() { this.initList() },
        selectPublisher() { this.initList() },
        selectMockGrade() {
            this.initMockYears()
            this.initList()
        },
        selectMockYear() {
            this.initMockMonths()
            this.initList()
        },
        selectMockMonth() {
            this.initList()
        },
    },
    methods: {
        async initData() {
            this.gradeList = models.gradeList()
            this.gradeList.unshift('전체')

            WorksheetService.getPublishers().then(res => {
                this.publishers = res
                this.publishers.unshift('전체')
            })

            WorksheetService.getAllTags().then(res => {
                this.tags = res
                this.tags.unshift('전체')
            })

            problemService.getMocks({
                'response_type': 'grade',
            }).then(res => {
                this.mockGrades = res.map(i => i.grade)
            })
        },
        async initMockYears() {
            const res = await problemService.getMocks({
                'response_type': 'year',
                'grade': this.selectMockGrade,
            })
            if (res) {
                this.mockYears = res.map(i => i.year)
            }
        },
        async initMockMonths() {
            const res = await problemService.getMocks({
                'response_type': 'month',
                'grade': this.selectMockGrade,
                'year': this.selectMockYear,
            })
            if (res) {
                this.mockMonths = res.map(i => i.month)
            }
        },
        onChangeTab(index) {
            this.selectTabIndex = index

            const query = Object.assign({}, this.$route.query)
            query.tabIndex = this.selectTabIndex
            this.$router.push({ query })

            this.selectPublisher = null
            this.selectTag = null
            this.searchKeyword = null
            this.selectMockGrade = null
            this.mockYears = []
            this.selectMockYear = null
            this.mockMonths = []
            this.selectMockMonth = null

            this.initList()
        },
        async initList() {
            this.worksheets = []

            let schoolType = null, schoolYear = null, subject = null
            if (this.selectGrade && this.selectGrade != '전체') {
                schoolType = utils.getSchoolTypeByGradeList(this.selectGrade)
                schoolYear = utils.getSchoolYearByGradeList(this.selectGrade)
                if (schoolYear) {
                    schoolYear = schoolYear.replace('학년', '')
                }
                subject = utils.getSubjectByGradeList(this.selectGrade)
            }

            if (schoolType == '고등학교') {
                schoolType = null
                schoolYear = null
            }

            let res = await WorksheetService.getWorksheets({
                'theme_name': this.tabs[this.selectTabIndex].title,
                'school_type': schoolType,
                'school_year': schoolYear,
                'subject': subject,
                'tag': this.selectTag != '전체' ? this.selectTag : null,
                'publisher': this.selectPublisher != '전체' ? this.selectPublisher : null,

                'mock_grade': this.selectMockGrade,
                'mock_year': this.selectMockYear,
                'mock_month': this.selectMockMonth,
            })
            if (res) {
                console.log(res);
                this.worksheets = res
            }
        },
    },
    computed: {
        filterData() {
            switch (this.selectTabIndex) {
                case 0: // 내신 대비 교과서 학습지
                    return {
                        title: '학습지 필터',
                        list: [
                            {type: 'select',    addClass: 'w-100px',    name: '학년', options: this.gradeList.map(i => {
                                return {
                                    'value': i,
                                    'name': i,
                                }
                            }), onChange: (value) => this.selectGrade = value},
                            {type: 'select',    addClass: 'w-100px',    name: '태그', options: this.tags.map(i => {
                                return {
                                    'value': i,
                                    'name': i,
                                }
                            }), onChange: (value) => this.selectTag = value},
                            {type: 'select',    addClass: 'w-100px',    name: '출판사', options: this.publishers.map(i => {
                                return {
                                    'value': i,
                                    'name': i,
                                }
                            }), onChange: (value) => this.selectPublisher = value},
                        ],
                        searchInput: {onRealtimeChange: (value) => this.searchKeyword = value},
                    }
                case 1: // 내신 대비 추천
                    return {
                        title: '학습지 필터',
                        list: [
                            {type: 'select',    addClass: 'w-100px',    name: '학년', options: this.gradeList.map(i => {
                                return {
                                    'value': i,
                                    'name': i,
                                }
                            }), onChange: (value) => this.selectGrade = value},
                        ],
                        searchInput: {onRealtimeChange: (value) => this.searchKeyword = value},
                    }
                case 2: // 수능/모의고사
                    return {
                        title: '학습지 필터',
                        list: [
                            {type: 'select',    addClass: 'w-100px',    name: '학년', options: this.mockGrades.map(i => {
                                return {
                                    'value': i,
                                    'name': i,
                                }
                            }), onChange: (value) => this.selectMockGrade = value},
                            {type: 'select',    addClass: 'w-100px',    name: '연도', options: this.mockYears.map(i => {
                                return {
                                    'value': i,
                                    'name': i,
                                }
                            }), onChange: (value) => this.selectMockYear = value},
                            // {type: 'select',    addClass: 'w-110px',    name: '계열·과목'},
                            {type: 'select',    addClass: 'w-110px',    name: '출제 월', options: this.mockMonths.map(i => {
                                return {
                                    'value': i,
                                    'name': i,
                                }
                            }), onChange: (value) => this.selectMockMonth = value},
                        ],
                        searchInput: {onRealtimeChange: (value) => this.searchKeyword = value},
                    }
                case 3: // 연산
                    return {
                        title: '학습지 필터',
                        list: [
                            {type: 'select',    addClass: 'w-100px',    name: '학년', options: this.gradeList.map(i => {
                                return {
                                    'value': i,
                                    'name': i,
                                }
                            }), onChange: (value) => this.selectGrade = value},
                            // {type: 'select-arrow'},
                            // {type: 'select',        addClass: 'w-100px',    name: '단원'},
                        ],
                        searchInput: {isHidden: true},
                    }
                case 4: // 입학 TEST
                case 5: // 주간 TEST
                case 6: // 단원 TEST
                    return {
                        title: '학습지 필터',
                        list: [
                            {type: 'select',    addClass: 'w-100px',    name: '학년', options: this.gradeList.map(i => {
                                return {
                                    'value': i,
                                    'name': i,
                                }
                            }), onChange: (value) => this.selectGrade = value},
                        ],
                        searchInput: {onRealtimeChange: (value) => this.searchKeyword = value},
                    }
                default:
                    return {}
            }
        },
        listData() {
            const filteredWorksheets = this.worksheets.filter(i => this.searchKeyword ? i.name.includes(this.searchKeyword) : true)

            switch (this.selectTabIndex) {
                case 0: // 내신 대비 교과서 학습지
                    return {
                        heads: [
                            {title: '학년', type: 'string', addClass: 'w-100px'},
                            {title: '출판사', type: 'string', addClass: 'w-120px'},
                            {title: '교재명', type: 'string', addClass: 'flex-1'},
                            {title: '문제 수', type: 'string', addClass: 'w-80px'},
                            {title: '미리보기', type: 'prev-btn', addClass: 'w-80px'},
                        ],
                        trList: filteredWorksheets.map(ws => {
                            let grade = ''
                            const schoolType = ws.school_type
                            if (schoolType) {
                                switch (schoolType) {
                                    case '초등학교':
                                        grade = `초${ws.school_year ?? ''}`
                                        break
                                    case '중학교':
                                        grade = `중${ws.school_year ?? ''}`
                                        break
                                    case '고등학교':
                                        grade = ws.subject ?? `고${ws.school_year ?? ''}`
                                        break
                                }
                            }

                            return [
                              {value: grade},
                              {value: ws.publisher ?? ''},
                              {value: ws.name},
                              {value: `${ws.theme_problem_cnt ?? 0}`},
                              {value: '', onClickPrevBtn: () => utils.previewLink(this, 'preview', utils.PdfType.worksheet, ws.id)},
                            ]
                        })
                    }
                case 1: // 내신 대비 추천
                    return {
                        heads: [
                            {title: '학년', type: 'string', addClass: 'w-100px'},
                            {title: '학습지명', type: 'string', addClass: 'flex-1'},
                            {title: '문제 수', type: 'string', addClass: 'w-80px'},
                            {title: '난이도', type: 'string', addClass: 'w-80px'},
                            {title: '미리보기', type: 'prev-btn', addClass: 'w-80px'},
                        ],
                        trList: filteredWorksheets.map(ws => {
                            let grade = ''
                            const schoolType = ws.school_type
                            if (schoolType) {
                                switch (schoolType) {
                                    case '초등학교':
                                        grade = `초${ws.school_year ?? ''}`
                                        break
                                    case '중학교':
                                        grade = `중${ws.school_year ?? ''}`
                                        break
                                    case '고등학교':
                                        grade = ws.subject ?? `고${ws.school_year ?? ''}`
                                        break
                                }
                            }

                            return [
                              {value: grade},
                              {value: ws.name},
                              {value: `${ws.theme_problem_cnt ?? 0}`},
                              {value: ws.difficulty ?? ''},
                              {value: '', onClickPrevBtn: () => utils.previewLink(this, 'preview', utils.PdfType.worksheet, ws.id)},
                            ]
                        })
                    }
                case 2: // 수능/모의고사
                    return {
                        heads: [
                            {title: '학년', type: 'string', addClass: 'w-100px'},
                            {title: '학습지명', type: 'string', addClass: 'flex-1'},
                            {title: '출제기관', type: 'string', addClass: 'w-100px'},
                            {title: '출제일', type: 'string', addClass: 'w-100px'},
                            {title: '문제 수', type: 'string', addClass: 'w-80px'},
                            {title: '미리보기', type: 'prev-btn', addClass: 'w-80px'},
                        ],
                        trList: filteredWorksheets.map(ws => {
                            let grade = ''
                            const schoolType = ws.school_type
                            if (schoolType) {
                                switch (schoolType) {
                                    case '초등학교':
                                        grade = `초${ws.school_year ?? ''}`
                                        break
                                    case '중학교':
                                        grade = `중${ws.school_year ?? ''}`
                                        break
                                    case '고등학교':
                                        grade = ws.subject ?? `고${ws.school_year ?? ''}`
                                        break
                                }
                            }

                            return [
                              {value: grade},
                              {value: ws.name},
                              {value: ws.maker_name ?? ''},
                              {value: ws.exam_date ?? ''},
                              {value: `${ws.theme_problem_cnt ?? 0}`},
                              {value: '', onClickPrevBtn: () => utils.previewLink(this, 'preview', utils.PdfType.worksheet, ws.id)},
                            ]
                        })
                    }
                case 3: // 연산
                case 4: // 입학 TEST
                case 5: // 주간 TEST
                case 6: // 단원 TEST
                    return {
                        heads: [
                            {title: '학년', type: 'string', addClass: 'w-100px'},
                            {title: '학습지명', type: 'string', addClass: 'flex-1'},
                            {title: '문제 수', type: 'string', addClass: 'w-80px'},
                            {title: '난이도', type: 'string', addClass: 'w-80px'},
                            {title: '미리보기', type: 'prev-btn', addClass: 'w-80px'},
                        ],
                        trList: filteredWorksheets.map(ws => {
                            let grade = ''
                            const schoolType = ws.school_type
                            if (schoolType) {
                                switch (schoolType) {
                                    case '초등학교':
                                        grade = `초${ws.school_year ?? ''}`
                                        break
                                    case '중학교':
                                        grade = `중${ws.school_year ?? ''}`
                                        break
                                    case '고등학교':
                                        grade = ws.subject ?? `고${ws.school_year ?? ''}`
                                        break
                                }
                            }

                            return [
                              {value: grade},
                              {value: ws.name},
                              {value: `${ws.theme_problem_cnt ?? 0}`},
                              {value: ws.difficulty ?? ''},
                              {value: '', onClickPrevBtn: () => utils.previewLink(this, 'preview', utils.PdfType.worksheet, ws.id)},
                            ]
                        })
                    }
                default:
                    return {}
            }
        },
    },
}
</script>
