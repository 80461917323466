<template>
  <div id="container">
    <div class="member-request-wrap">
      <h1>학원 소속 요청</h1>
      <h2>선생님</h2>

      <table>
        <colgroup>
          <col/>
          <col/>
        </colgroup>

        <tr>
          <th colspan="2">학원정보</th>
        </tr>
        <tr>
          <td>학원명</td>
          <td>
            <div class="td-item">
              <input
                  :value="academyInfo?.name"
                  :disabled="true"
                  :readonly="true"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>원장님 성함</td>
          <td>
            <div class="td-item">
              <input
                  id="email"
                  :value="academyInfo?.head_name"
                  :disabled="true"
                  :readonly="true"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>학원 주소</td>
          <td>
            <div class="td-item">
              <input
                  :value="academyInfo?.address"
                  :disabled="true"
                  :readonly="true"
              />
            </div>
          </td>
        </tr>
      </table>

      <button class="reg-btn" @click="this.onSubmit">요청하기</button>
    </div>
  </div>
</template>

<script>
import AcademyService from '@/services/academy.service.js'
import { mapGetters } from 'vuex'

export default {
    name: 'MemberRequest',
    data() {
        return {
            academyId: null,
            academyInfo: null,
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        async onSubmit() {
            const res = await AcademyService.createAcademyMemberRequest({
                academy_info: this.academyId,
                user: this.getUser.id,
                is_teacher: true,
            })
            if (res) {
                this.$toast.success('요청 되었습니다\n학원 관리자 승인 후 활동이 가능합니다')
            } else {
                this.$toast.error('요청에 실패하였습니다')
            }
        },
        async initData() {
            const { academyId } = this.$route.params

            if (!this.isLogin) {
                this.$toast.error('먼저 로그인 후 시도해주세요')
                this.$router.push({name: 'login', redirect: this.$router.currentRoute.value.fullPath})
            } else {
                try {
                    this.academyId = academyId
                    this.academyInfo = await AcademyService.getAcademy(this.academyId)
                } catch (e) {
                    console.log(e);
    
                    this.$toast.error('학원 정보를 불러오는 중 오류가 발생하였습니다\n홈으로 이동합니다')
                    this.$router.push({ name: 'home' })
                }
            }
        },
    },
    computed: {
        ...mapGetters('auth', ['isLogin', 'getUser'])
    },
}
</script>

<style lang="scss" scoped>
.member-request-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 108px 40px 142px;
    align-items: center;
    h1 {
        font-weight: 700;
        font-size: 45px;
        line-height: 51px;
        text-align: center;
        color: #000000;
    }
    h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        text-align: center;
        color: #F8B633;
        margin-top: 25px;
    }
    table {
        margin-top: 66px;
        width: 100%;
        max-width: 650px;
        colgroup {
            col {
                &:nth-child(1) {
                    width: 150px;
                }
            }
        }
        th {
            font-weight: 800;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            color: #008ED5;
            padding: 15px 0;
            text-align: start;
        }
        td {
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            color: #000000;
            text-align: start;
            padding: 10px 0;
            &:first-child {
                vertical-align: -webkit-baseline-middle;
                padding-top: 25px;
            }
            .td-item {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                column-gap: 20px;
                row-gap: 10px;
                input {
                    flex: 1;
                    background: #FFFFFF;
                    border: 1px solid #999999;
                    border-radius: 5px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    color: black;
                    height: 50px;
                    padding: 5px 23px;
                    &:placeholder {
                        color: #999999;
                    }
                }
                button {
                    background: #008ED5;
                    border: 1px solid #008ED5;
                    border-radius: 5px;
                    width: 130px;
                    height: 50px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    color: #FFFFFF;
                }      
                p {
                    width: 100%;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #333333;
                }   
                .input-file {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    input {
                        display: none;
                    }
                    button {
                        background: linear-gradient(180deg, rgba(206, 206, 206, 0) 0%, #CECECE 100%);
                        border: 1px solid #A3A3A3;
                        border-radius: 5px;
                        width: 130px;
                        height: 30px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #000000;
                        margin-right: 16px;
                    }
                    span {
                        max-width: 350px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: #333333;
                    }
                }                       
            }
        }
        .file-tr {
            td {
                padding-top: 20px;
            }
        }        
    }
    .reg-btn {
        margin-top: 72px;
        background: #008ED5;
        border: 1px solid #008ED5;
        border-radius: 5px;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #FFFFFF;
        padding: 14px 10px;
        width: 100%;
        max-width: 650px;
        cursor: pointer;
    }
}
</style>
