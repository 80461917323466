<template>
  <div class="v2-tree-list-wrap">
    <div class="tl-inner-wrap">
      <Tree
        :nodes="this.nodes"
        :config="this.config"
        @nodeChecked="this.onLocalNodeChecked"
        @nodeUnchecked="this.onLocalNodeUnchecked"
      />
    </div>
  </div>
</template>

<script>
import Tree from "vue3-treeview";
import "vue3-treeview/dist/style.css";
import openIcon from "@/assets/images/v2-tree-open.png";
import closeIcon from "@/assets/images/v2-tree-close.png";

export default {
  name: 'V2TreeList',
  props: {
    nodes: Object,
    //// [node example] ////
    // id1: {
    //     text: "text1",
    //     children: ["id1_1", "id1_2"],
    // },
    // id1_1: {
    //     text: "text11",
    //     children: ['id1_1_1']
    // },
    // id1_1_1: {
    //     text: "text111",
    // },
    // id1_2: {
    //     text: "text12",
    // },
    // id2: {
    //     text: "text2",
    // },
    ///////////////////////
    onNodeChecked: Function,
    onNodeUnchecked: Function,
  },
  components: {
    Tree,
  },
  methods: {
    onLocalNodeChecked(nodes) {
      if (this.onNodeChecked) {
        this.onNodeChecked();
      }
    },
    onLocalNodeUnchecked(nodes) {
      if (this.onNodeUnchecked) {
        this.onNodeUnchecked();
      }
    },
  },
  computed: {
    config() {
      const allEntries = Object.entries(this.nodes)

      return {
        roots: allEntries.filter(e => {
          const key = e[0]
          const isIncludeOther = allEntries.findIndex(e2 => e2[1].children?.includes(key)) > -1
          return !isIncludeOther
        }).map(e => e[0]),
        checkMode: 0,
        checkboxes: true,
        openedIcon: {
          type: "img",
          height: 13,
          width: 13,
          src: openIcon,
        },
        closedIcon: {
          type: "img",
          height: 13,
          width: 13,
          src: closeIcon,
        },
        keyboardNavigation: false,
        dragAndDrop: false,
        editable: false,
        disabled: false,
        padding: 25,
      };
    },
  },
}
</script>
