<template>
  <div class="problem-preview-wrap">
    <template v-for="(page, pageIndex) in pageCntArray" :key="pageIndex">
      <div
        class="wrap-abs"
        :style="[{opacity: 0}]"
      >
        <div
          class="wrap-scroll"
          :style="[{
            'width': `${width}px`,
            'height': `${height}px`,
          }]"
        >
          <div class="pp-body">
            <div class="divider"></div>
            <div :style="[{'height': `${headerHeight}px`}]"></div>

            <template v-for="(problem, problemIndex) in problems" :key="problemIndex">
              <div
                  class="preview-problem"
                  :style="[{
                    'transform': `translateX(-${pageIndex * 2}00%)`,
                    'marginTop': problemIndex === nextIncludeHeaderIndex - 1 ? `${headerHeight}px` : '0px',
                  }]"
              >
                <div class="problem-example">
                  <div class="pattern-wrapper" v-if="isVisiblePatternName || isVisibleAnswerRate || isVisibleDifficulty">
                    <p class="pattern">
                      <template v-if="isVisiblePatternName">
                        {{ problem.pattern_name }}&nbsp;
                      </template>
                      <span v-if="isVisibleAnswerRate">정답률 {{ problem.answer_rate }}%<template v-if="isVisibleDifficulty">, </template></span>
                      <span v-if="isVisibleDifficulty">난이도 {{ problem.difficulty }}</span>
                    </p>
                  </div>
                  <div class="problem-content">
                    <strong>{{ getProblemNumber(problemIndex) }}</strong>
                    <div>
                      <template v-if="isStudentWork">
                        <div class="answer-box">
                          <span>정답: {{ problem.correct_answer }}</span>
                          <span>학생이 고른 답: {{ problem.answer }}</span>
                        </div>
                      </template>
                      <div class="problem-image">
                        <img :src="getImageUrl(problem)" alt="" @load="onLoadImg(problem)">
                        <template v-if="isStudentWork">
                          <template v-if="problem.is_current_answer">
                            <img class="answer" src="@/assets/images/result-current.png" alt=""/>
                          </template>
                          <template v-else>
                            <img class="answer" src="@/assets/images/result-incurrent.png" alt=""/>
                          </template>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="pp-footer">
            <span>{{pageStr(page)}}</span>
          </div>
        </div>
      </div>
    </template>

    <!-- 정답지 -->
    <template v-if="!isStudentWork">
      <template v-for="(page, pageIndex) in answerPageCntArray" :key="pageIndex">
        <div
          class="wrap-abs"
          :style="[{
            opacity: 0,
          }]"
        >
          <div
              class="wrap-scroll"
              :style="[{
                'width': `${width}px`,
                'height': `${height}px`,
              }]"
          >
            <div class="pp-body body-answer" ref="pp-body-answer">
              <div class="divider"></div>

              <template v-for="(problem, problemIndex) in problems" :key="problemIndex">
                <div
                    class="preview-problem"
                    :style="[{
                        'transform': `translateX(-${pageIndex * 2}00%)`,
                    }]"
                    data-type="answer"
                >
                  <div class="problem-example">
                    <div class="problem-content">
                      <strong>{{ getProblemNumber(problemIndex) }}</strong>
                      <div>
                        <div class="problem-image">
                          <img :src="getAnswerImageUrl(problem)" alt="" @load="onLoadImg(problem)">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="pp-footer">
              <span>{{pageStr(page)}}</span>
            </div>
          </div>
        </div>
      </template>
    </template>

    <div class="wrap-abs">
      <div
          class="wrap-scroll"
          :style="[{
                    'width': `${width}px`,
                    'height': `${height}px`,
                }]"
      >
        <div class="pp-header" ref="ppHeader">
          <div class="header-top">
            <div class="header-table">
              <div class="table-item">
                <span>날&nbsp;&nbsp;&nbsp;&nbsp;짜</span>
                <span>{{ create_dt_str }}</span>
              </div>
              <div class="table-item">
                <span>문제수</span>
                <span>{{ Number(problemCnt) }} 문제</span>
              </div>
              <div class="table-item">
                <span>출제자</span>
                <span>{{ makerName }}</span>
              </div>
            </div>
            <div class="header-logo-wrap">
              <img class="logo-img"
                   :src="get_academy_logo"
                   alt="">
            </div>
          </div>
          <div class="header-middle">
            {{ nameTag }}&nbsp;{{ name }}
          </div>
          <div class="header-bottom">
            <div class="header-name">이름 __________</div>
            <div class="header-sub-title">{{ subName }}</div>
          </div>
        </div>
        <div class="pp-body" ref="ppBody">
          <div class="divider"></div>

          <div
              v-for="(problem, problemIndex) in problems" :key="problemIndex"
              class="preview-problem"
              data-type="problem"
          >
            <div class="problem-example">
              <div
                  v-if="isVisiblePatternName || isVisibleAnswerRate || isVisibleDifficulty"
                  class="pattern-wrapper"
              >
                <p class="pattern">
                  <template v-if="isVisiblePatternName">
                    {{ problem.pattern_name }}&nbsp;
                  </template>
                  <span v-if="isVisibleAnswerRate">정답률 {{ problem.answer_rate }}%<template v-if="isVisibleDifficulty">, </template></span>
                  <span v-if="isVisibleDifficulty">난이도 {{ problem.difficulty }}</span>
                </p>
              </div>
              <div class="problem-content">
                <strong>{{ getProblemNumber(problemIndex) }}</strong>
                <div>
                  <template v-if="isStudentWork">
                    <div class="answer-box">
                      <span>정답: {{ problem.correct_answer }}</span>
                      <span>학생이 고른 답: {{ problem.answer }}</span>
                    </div>
                  </template>
                  <div class="problem-image">
                    <img :src="getImageUrl(problem)" alt="" @load="onLoadImg(problem)">
                    <template v-if="isStudentWork">
                      <template v-if="problem.is_current_answer">
                        <img class="answer" src="@/assets/images/result-current.png" alt=""/>
                      </template>
                      <template v-else>
                        <img class="answer" src="@/assets/images/result-incurrent.png" alt=""/>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pp-footer">
          <span>1</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '../utils'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'ProblemPreview',
  props: {
    logoUrl: String,
    nameTag: String,
    name: String,
    subName: String,
    create_dt: String,
    problemCnt: Number,
    makerName: String,
    problems: Array,

    isVisibleAnswerRate: {type: Boolean, default: false},
    isVisibleDate: {type: Boolean, default: false},
    isVisiblePatternName: {type: Boolean, default: false},
    isVisibleDifficulty: {type: Boolean, default: false},
    isVisibleSimilar: {type: Boolean, default: false},

    onLoaded: Function,
    type: String,
  },
  setup() {
    utils.addScript('https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js')
    utils.addScript('https://html2canvas.hertzen.com/dist/html2canvas.js')
  },
  data() {
    return {
      width: 597,
      height: 844,

      pageCntArray: [],
      headerHeight: 0,
      nextIncludeHeaderIndex: -1,
      completeProblems: [],

      answerPageCntArray: [1],
    }
  },
  mounted() {
    this.initData(this)
  },
  watch: {
    logoUrl() { this.initData(this) },
    nameTag() { this.initData(this) },
    name() { this.initData(this) },
    subName() { this.initData(this) },
    create_dt() { this.initData(this) },
    problemCnt() { this.initData(this) },
    makerName() { this.initData(this) },
    problems() { this.initData(this) },
  },
  methods: {
    onLoadImg(problem) {
      if (this.completeProblems.filter((item) => item.id === problem.id).length === 0) {
        this.completeProblems.push(problem);
      }
      this.initData(this);
    },
    getProblemNumber(problemIndex) {
      return String(problemIndex + 1).padStart(2, '0');
    },
    getImageUrl(problem) {
      if (problem.imgUrl) {
        return problem.imgUrl
      } else if (problem.problem_img) {
        return utils.getServerMediaPath(problem.problem_img)
      } else {
        return ''
      }
    },
    getAnswerImageUrl(problem) {
      if (problem.solution_img) {
        return utils.getServerMediaPath(problem.solution_img);
      } else {
        return '';
      }
    },
    onLoadedEvent: debounce((appContext) => {
      console.log('onLoadedEvent()');
      if (appContext.onLoaded) {
        appContext.onLoaded();
      }
    }, 1000),
    initData: debounce((appContext) => {
      console.log('ProblemPreview - initData()');
      appContext.pageCntArray = appContext.getPageCntArray()
      appContext.headerHeight = appContext.$refs.ppHeader.scrollHeight
      appContext.nextIncludeHeaderIndex = appContext.getNextIncludeHeaderIndex()

      if (!appContext.isStudentWork) {
        appContext.answerPageCntArray = appContext.getAnswerPageCntArray();
        console.log(`answerPageCntArray: ${appContext.answerPageCntArray}`);
      }

      if (appContext.completeProblems.length === appContext.problems.length) {
        appContext.onLoadedEvent(appContext);
      }
    }, 300),
    pageStr(page) {
      return page.toString().padStart(1, '0');
    },
    getPageByTargetProblem(problemIndex=null) {
      const body = this.$refs.ppBody
      const header = this.$refs.ppHeader
      const style = window.getComputedStyle(body, null)
      const paddingTop = parseInt(style.getPropertyValue("padding-top").replace('px', ''))
      const firstPageHeight = parseInt(body.scrollHeight) - paddingTop
      const notFirstPageHeight = firstPageHeight + parseInt(header.scrollHeight)

      const childrens = this.$refs.ppBody.children
      let computeHeight = 0
      let curProblemIndex = 0
      let pageCnt = 1

      for (let idx = 0; idx < childrens.length; idx++) {
        const curPageHeight = pageCnt >= 2 ? notFirstPageHeight : firstPageHeight
        const el = childrens[idx]
        if (el.dataset['type'] === 'problem') {
          let elHeight = parseInt(el.offsetHeight)
          const elMarginBottom = parseInt(window.getComputedStyle(el, null).getPropertyValue("margin-bottom").replace('px', ''))
          if (computeHeight + elHeight + elMarginBottom > curPageHeight) {
            pageCnt += .5
            computeHeight = elHeight + elMarginBottom
          } else {
            computeHeight += elHeight + elMarginBottom
          }

          if (curProblemIndex === problemIndex) {
            return pageCnt
          }
          curProblemIndex++
        }
      }

      console.log(`getPageByTargetProblem() pageCnt: ${pageCnt}`);
      return pageCnt
    },
    getAnswerPageByTargetProblem() {
      const body = this.$refs["pp-body-answer"][0];
      const style = window.getComputedStyle(body, null)
      const paddingTop = parseInt(style.getPropertyValue("padding-top").replace('px', ''))
      const pageHeight = parseInt(body.scrollHeight) - paddingTop

      const childrenList = body.children
      let computeHeight = 0
      let pageCnt = 1

      for (let idx = 0; idx < childrenList.length; idx++) {
        const el = childrenList[idx]
        if (el.dataset['type'] === 'answer') {
          let elHeight = parseInt(el.offsetHeight)
          const elMarginBottom = parseInt(window.getComputedStyle(el, null).getPropertyValue("margin-bottom").replace('px', ''))
          if (computeHeight + elHeight + elMarginBottom > pageHeight) {
            pageCnt += .5
            computeHeight = elHeight + elMarginBottom
          } else {
            computeHeight += elHeight + elMarginBottom
          }
        }
      }

      console.log(`getAnswerPageByTargetProblem() pageCnt: ${pageCnt}`);
      return pageCnt;
    },
    getNextIncludeHeaderIndex() {
      const body = this.$refs.ppBody
      const style = window.getComputedStyle(body, null)
      const paddingTop = parseInt(style.getPropertyValue("padding-top").replace('px', ''))
      const bodyHeight = parseInt(body.scrollHeight) - paddingTop

      const childrens = this.$refs.ppBody.children
      let computeHeight = 0
      let problemIndex = 0
      for (let idx = 0; idx < childrens.length; idx++) {
        const el = childrens[idx]
        if (el.dataset['type'] === 'problem') {
          let elHeight = parseInt(el.offsetHeight)
          const elMarginBottom = parseInt(window.getComputedStyle(el, null).getPropertyValue("margin-bottom").replace('px', ''))
          if (computeHeight + elHeight + elMarginBottom > bodyHeight) {
            return problemIndex
          } else {
            computeHeight += elHeight + elMarginBottom
          }
        }
        problemIndex++
      }

      return -1
    },
    getPageCntArray() {
      const pageCnt = this.getPageByTargetProblem()
      return Array.apply({}, Array(Math.ceil(pageCnt))).map((_, index) => index + 1)
    },
    getAnswerPageCntArray() {
      const pageCnt = this.getAnswerPageByTargetProblem()
      const problemMaxPage = this.pageCntArray.length > 0 ? this.pageCntArray[this.pageCntArray.length - 1] : 0;
      return Array.apply({}, Array(Math.floor(pageCnt))).map((_, index) => index + problemMaxPage + 1)
    },
  },
  computed: {
    isStudentWork() {
      return this.type === utils.PdfType.studentWork;
    },
    create_dt_str() {
      try {
        return utils.getDateByServer(this.create_dt, 'yyyy.mm.dd')
      } catch (e) {
        console.log(e);
      }
      return ''
    },
    get_academy_logo() {
      const logoUrl = this.getUser?.academy_logo
      if (logoUrl) {
        return utils.getServerMediaPath(logoUrl)
      }
      return ''
    },
    ...mapGetters('auth', ['getUser'])
  },
}
</script>
