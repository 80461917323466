<template>
  <div class="student-self-learning-mode-detail-wrap">
    <V2Wrapper>
      <div class="sslmd-inner">
        <template v-if="this.errorMsg !== null">
          <ErrorMsgBox :contents="this.errorMsg"/>
        </template>
        <template v-else-if="this.isLoading">
          <LoadingBox/>
        </template>
        <template v-else>
          <div class="di-header-wrap">
            <div class="v2-base-container">
              <button class="hw-btn btn-back">
                <img src="@/assets/images/arrow-left-4.png" alt="">
              </button>
              <div class="hw-btn btn-back-empty"></div>
              <p>{{ this.headerTitle }}</p>
              <div class="hw-btn btn-back-empty"></div>
              <span></span>
            </div>
          </div>
          <div class="di-body-wrap">
            <div class="v2-base-container">
              <div class="bc-inner-wrap">
                <div class="bi-header-wrap">
                  <h5>{{ `${this.itemData?.order}번 문제` }}</h5>
                  <button class="hw-report-btn"
                          @click="() => this.onClickReport()">
                    <span>신고</span>
                  </button>
                  <span>{{ `${this.itemData?.order}/${this.itemData?.tot_prb_cnt}` }}</span>
                </div>
                <div class="bi-problem-box">
                  <div class="bipb-inner-wrap">
                    <div class="siw-problem-box">
                      <div class="img-wrap">
                        <img :src="utils.getServerMediaPath(this.itemData?.prb_img)" alt="">
                      </div>
                    </div>
                    <div class="siw-answer-box">
                      <template v-if="this.isNumberAnswerType">
                        <div class="ab-input-number-wrap">
                          <template v-for="(number, index) in ['1', '2', '3', '4', '5']" :key="index">
                            <div :class="['nw-item', {'active': this.isActiveByNumber(number)}]"
                                 @click="() => this.onChangeInputNumber(number)">
                              <span>{{ number }}</span>
                            </div>
                          </template>
                        </div>
                      </template>
                      <template v-else>
                        <div class="ab-input-text-wrap">
                          <b-button
                              class="tw-tooltip"
                              v-b-tooltip.hover.html="this.inputTextTooltip">
                            <img src="@/assets/images/student-question-mark.png" alt="">
                          </b-button>
                          <input
                              v-model="answer"
                              type="text"
                              placeholder="여기에 정답을 입력해주세요.">
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="di-nav-box">
            <div class="v2-base-container">
              <div class="nb-inner-wrap">
                <div class="nb-item btn-arrow disabled">
                  <span></span>
                </div>
                <div class="nb-item btn-arrow disabled">
                  <span></span>
                </div>
                <div class="nb-item btn-arrow"
                     @click="() => this.onSubmit()">
                  <span>채점</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </V2Wrapper>
  </div>

  <Teleport to="body">
    <ProblemReportPopup
      :isVisible="this.problemReportPopupItem !== null"
      :onClose="() => this.problemReportPopupItem = null"
      :problemId="this.problemReportPopupItem?.id"
      :problem-number="this.problemReportPopupItem?.number"
    />
    <SelfLearningSubmitPopup
      :isVisible="this.sfLnSbmPuItem !== null"
      :onClose="() => this.sfLnSbmPuItem = null"
      :is-diag-eval-type="this.isDiagEvalType"
      :eval-stu-prb-id="this.sfLnSbmPuItem?.id"
      :answer="this.sfLnSbmPuItem?.answer"
      :on-changed="() => this.refreshData()"
    />
  </Teleport>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import LoadingBox from "@/components/LoadingBox.vue";
import SelfStudyService from "@/services/selfStudy.service";
import utils from "@/utils";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import ProblemReportPopup from "@/components/ProblemReportPopup.vue";
import {ProblemTypeExtUtils} from "@/extensions/problem_type_ext";
import {DividerExt} from "@/extensions/divider_ext";
import SelfLearningSubmitPopup from "@/components/SelfLearningSubmitPopup.vue";

export default {
  name: 'StudentSelfLearningModeDetail',
  components: {SelfLearningSubmitPopup, ProblemReportPopup, ErrorMsgBox, LoadingBox, V2Wrapper},
  data() {
    return {
      errorMsg: null,
      isLoading: true,
      isDiagEvalType: true,
      evalStuId: null,

      itemData: null,
      answer: null,

      problemReportPopupItem: null,
      inputTextTooltip: { title: '* 정답 입력 예시 *<br/>3H₂O → 3H2O<br/>⅓ → 1/3<br/>10²³ → 10^23' },
      sfLnSbmPuItem: null,
      utils,
    };
  },
  mounted() {
    const { evalType, evalStuId } = this.$route.params;
    this.isDiagEvalType = evalType == 'diag';
    this.evalStuId = evalStuId;
    this.refreshData();
  },
  methods: {
    onChangeInputNumber(number) {
      const isMultipleAnswer = this.isMultipleAnswer;

      let answer = this.answer ?? '';
      if (isMultipleAnswer) {
        const beforeAnswers = answer.split(DividerExt.userDivider).filter(e => {
          return e.length > 0;
        });
        const findIndex = beforeAnswers.findIndex(e => e == number);
        if (findIndex >= 0) {
          beforeAnswers.splice(findIndex, 1);
        } else {
          beforeAnswers.push(number);
        }
        answer = beforeAnswers.join(DividerExt.userDivider);
      } else {
        answer = `${number}`;
      }
      this.answer = answer;
    },
    isActiveByNumber(number) {
      const answer = this.answer ?? '';
      const isMultipleAnswer = this.isMultipleAnswer;
      if (isMultipleAnswer) {
        return answer.split(DividerExt.userDivider).includes(number);
      } else {
        return number == answer;
      }
    },
    async onSubmit() {
      const itemData = this.itemData;
      if (itemData == null) { this.$toast.error('현재 페이지 정보를 불러올 수 없습니다'); return; }

      const answer = this.answer;
      if (answer == null || answer.length == 0) { this.$toast.error('답을 입력해주세요'); return; }

      this.sfLnSbmPuItem = {
        id: itemData.id,
        answer: answer,
      };
    },
    async refreshData() {
      const res = this.isDiagEvalType
          ? await SelfStudyService.getDiagEvalStu(this.evalStuId)
          : await SelfStudyService.getUnitEvalStu(this.evalStuId);
      if (res == null) { this.errorMsg = '초기 데이터를 불러오는 중 오류가 발생하였습니다'; return; }

      this.answer = null;
      this.itemData = res;
      this.isLoading = false;
    },
    onClickReport() {
      const itemData = this.itemData;
      if (itemData == null) {
        this.$toast.error('현재 페이지 정보를 불러올 수 없습니다');
        return;
      }

      this.problemReportPopupItem = {
        id: itemData.prb_id,
        number: itemData.order,
      };
    },
  },
  computed: {
    headerTitle() {
      return this.isDiagEvalType ? '진단평가' : '단원평가';
    },
    isNumberAnswerType() {
      return ProblemTypeExtUtils.isNumberAnswerType(this.itemData.prb_type);
    },
    isMultipleAnswer() {
      return (this.itemData?.prb_answer ?? '').includes(DividerExt.dividerAnd);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.student-self-learning-mode-detail-wrap {
  .sslmd-inner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    .di-header-wrap {
      width: 100%;
      background-color: #{$defaultColor};
      height: 60px;
      display: flex;
      @include tablet {
        height: 40px;
      }
      .v2-base-container {
        gap: 5px;
        align-items: center;
        .hw-btn {
          width: 70px;
          height: 100%;
          cursor: pointer;
          @include tablet {
            width: 50px;
          }
          &>img {
            width: 100%;
            height: 25px;
            object-fit: contain;
            object-position: 5px;
            @include tablet {
              height: 20px;
            }
          }
          &.btn-back {
            display: none;
            @include tablet {
              display: unset;
            }
          }
          &.btn-back-empty {
            cursor: unset;
            @include tablet {
              display: none;
            }
          }
        }
        &>p {
          flex: 1;
          text-align: center;
          font-weight: 700;
          font-size: 20px;
          color: white;
          @include tablet {
            font-size: 15px;
            padding-right: 47px;
          }
          @include mobile {
            padding-right: unset;
          }
        }
      }
    }
    .di-body-wrap {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      .v2-base-container {
        height: 100%;
        flex-direction: column;
        .bc-inner-wrap {
          width: 100%;
          max-width: 700px;
          height: 100%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          .bi-header-wrap {
            margin-top: 15px;
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @include tablet {
              margin-top: 10px;
            }
            &>h5 {
              font-weight: 500;
              font-size: 18px;
              line-height: 1.2;
              @include tablet {
                font-size: 15px;
              }
            }
            .hw-report-btn {
              margin-left: 15px;
              display: flex;
              align-items: center;
              border-radius: 5px;
              padding: 7px 15px;
              background-color: #{$redColor};
              box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
              cursor: pointer;
              &>span {
                color: white;
                font-weight: 500;
                line-height: 1;
                font-size: 16px;
                text-align: center;
                @include tablet {
                  font-size: 14px;
                }
              }
              &:hover {
                box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
              }
            }
            &>span {
              margin-left: auto;
              font-weight: 500;
              font-size: 15px;
              @include tablet {
                font-size: 13px;
              }
            }
          }
          .bi-problem-box {
            margin-top: 15px;
            width: 100%;
            display: flex;
            flex-direction: column;
            flex: 1;
            position: relative;
            @include tablet {
              margin-top: 10px;
            }
            .bipb-inner-wrap {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              .siw-problem-box {
                width: 100%;
                flex: 1;
                background-color: white;
                border-radius: #{$problemBorderRadius};
                border: 1px solid #{$problemBorderColor};
                padding: #{$problemPaddingSize};
                box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
                .img-wrap {
                  width: 100%;
                  height: 100%;
                  position: relative;
                  &>img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: top center;
                  }
                }
              }
              .siw-answer-box {
                margin-top: 10px;
                width: 100%;
                height: 40px;
                display: flex;
                @include mobile {
                  height: 35px;
                }
                .ab-input-number-wrap {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  gap: 15px;
                  align-items: center;
                  @include mobile {
                    gap: 10px;
                  }
                  .nw-item {
                    width: 40px;
                    height: 100%;
                    border-radius: 50%;
                    display: flex;
                    background-color: white;
                    border: 1px solid rgba(0, 0, 0, .1);
                    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .1);
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    @include mobile {
                      width: 35px;
                      height: 35px;
                    }
                    &>span {
                      font-weight: 500;
                      text-align: center;
                      color: black;
                      line-height: 1;
                    }
                    &:hover {
                      box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, .1);
                    }
                    &.active {
                      background-color: #{$defaultColor};
                      &>span {
                        color: white;
                      }
                    }
                  }
                  .nw-submit-btn {
                    display: flex;
                    min-width: 60px;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    padding: 7px 10px;
                    background-color: #{$defaultColor};
                    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
                    @include mobile {
                      min-width: 50px;
                    }
                    &>span {
                      color: white;
                      font-weight: 500;
                      line-height: 1;
                      font-size: 16px;
                      text-align: center;
                      @include tablet {
                        font-size: 14px;
                      }
                      @include mobile {
                        font-size: 12px;
                      }
                    }
                  }
                }
                .ab-input-text-wrap {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  @include mobile {
                    gap: 5px;
                  }
                  .tw-tooltip {
                    width: 30px;
                    height: 100%;
                    background-color: transparent;
                    border-color: transparent;
                    padding: 0;
                    @include mobile {
                      width: 25px;
                    }
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                  &>input {
                    flex: 1;
                    height: 100%;
                    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
                    border: 1px solid #aaaaaa;
                    border-radius: #{$problemBorderRadius};
                    padding: 5px 15px;
                    font-size: 16px;
                    @include tablet {
                      font-size: 14px;
                    }
                    @include mobile {
                      padding: 3px 10px;
                      font-size: 12px;
                    }
                    &::placeholder {
                      color: rgba(0, 0, 0, .2);
                    }
                  }
                  .tw-submit-btn {
                    display: flex;
                    min-width: 80px;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    padding: 7px 10px;
                    background-color: #{$defaultColor};
                    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
                    @include mobile {
                      min-width: 50px;
                    }
                    &>span {
                      color: white;
                      font-weight: 500;
                      line-height: 1;
                      font-size: 16px;
                      text-align: center;
                      @include tablet {
                        font-size: 14px;
                      }
                      @include mobile {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .di-nav-box {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      @include tablet {
        padding-bottom: 0;
      }
      @include mobile {
        margin-top: 5px;
      }
      .v2-base-container {
        .nb-inner-wrap {
          width: 100%;
          max-width: 700px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .nb-item {
            cursor: pointer;
            padding: 5px 2px;
            text-align: center;
            @include mobile {
              padding: 5px 0;
            }
            &.btn-arrow {
              min-width: 65px;
            }
            &.disabled {
              cursor: unset;
            }
            &>span {
              font-weight: 500;
              font-size: 15px;
              text-align: center;
              @include tablet {
                font-size: 14px;
              }
              @include mobile {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
