export class V2RouterSubItem {
    /** @type {string} */
    title;
    /** @type {string} */
    routerName;
    /** @type {string} */
    path;

    constructor(
        title,
        routerName,
        path,
    ) {
        this.title = title;
        this.routerName = routerName;
        this.path = path;
    }
}
