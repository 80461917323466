export class MACLItemVo {
    /** @type {number} */
    id;
    /** @type {number} */
    order;
    /** @type {string} */
    name;

    constructor(
        id,
        order,
        name,
    ) {
        this.id = id;
        this.order = order;
        this.name = name;
    }

    static fromJson = (json) => new MACLItemVo(
        json.id,
        json.order,
        json.name,
    );
}
