import dateFormat from 'dateformat'

export class StudentWorkFolderVo {
    /** @type {number} */
    id;
    /** @type {number} */
    userId;
    /** @type {string} */
    name;
    /** @type {string} */
    createDt;
    /** @type {string} */
    updateDt;
    /** @type {number} */
    problemCnt;

    constructor(
        id,
        userId,
        name,
        createDt,
        updateDt,
        problemCnt,
    ) {
        this.id = id;
        this.userId = userId;
        this.name = name;
        this.createDt = createDt;
        this.updateDt = updateDt;
        this.problemCnt = problemCnt;
    }

    static fromJson = (json) => new StudentWorkFolderVo(
        json.id,
        json.user,
        json.name,
        json.create_dt,
        json.update_dt,
        json.problem_cnt,
    );

    getCreateDateStr() {
        return dateFormat(this.createDt, 'yyyy-mm-dd')
    }

    getCreateDtStr() {
        return dateFormat(this.createDt, 'yyyy-mm-dd HH:mm:ss')
    }
}
