<template>
    <button 
        :class="[
            addClass, 
            {
                'checked': this.isChecked,
            },
        ]">
        {{ title }}
    </button>
</template>

<script>
export default {
    name: 'CustomButton',
    props: {
        title: String,
        isChecked: Boolean,
        addClass: String,
    },
}
</script>

<style lang="scss" scoped>
$defaultColor: #008ED5;
$grayColor: #888888;
$redColor: #EE0000;
$greenColor: #008000;

button {
    display: flex;
    padding: 10px 5px;
    background: white;
    border: 1px solid #{$defaultColor};
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #000000;
    transition: all .2s ease-in-out;
    &.color-text {
        color: #{$defaultColor};
    }
    &.checked {
        background-color: #{$defaultColor};        
        color: white;
    }
    &.gray {
        border-color: #{$grayColor};
        &.color-text {
            color: #{$grayColor};
        }
    }
    &.red {
      border-color: #{$redColor};
      &.color-text {
        color: #{$redColor};
      }
    }
    &.green {
      border-color: #{$greenColor};
      &.color-text {
        color: #{$greenColor};
      }
    }
    &.min-w-70px {
        min-width: 70px;
    }
    &.box-sm {
        padding: 3px 4px;
        border-radius: 5px;
        font-size: 10px;
        line-height: 12px;
    }
}
</style>
