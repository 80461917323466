<template>
  <div class="edit-swiper-wrap">
    <div class="swiper-wrap">
      <swiper
        slidesPerView="auto"
        :space-between="this.swiperOptions.spaceBetween"
        :modules="this.modules"
        @swiper="this.onSwiper"
        @slideChange="this.onSlideChange"
        :grabCursor="false"
        :allowTouchMove="false"
      >
        <swiper-slide v-if="this.isNewCreate">
          <div class="swiper-inner-wrap">
            <EditStep1
              :onChangeStep="this.onChangeStep"
              :onChangeProblems="this.onChangeProblems"
              :onChangeSearchProblems="this.onChangeSearchProblems"
              :isWorkbook="this.isWorkbook"
              :on-change-tab-index="this.onChangeStep1TabIndex"
              :limitProblemCnt="this.limAccPbMaxCnt"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-inner-wrap">
            <EditStep2
              :is-edit="!!this.$route.query.id"
              :onChangeStep="this.onChangeStep"
              :problems="this.problems"
              :onChangeResultProblems="this.onChangeResultProblems"
              :searchProblems="this.searchProblems"
              :isWorkbook="this.isWorkbook"
              :onOpenReportPopup="this.onOpenReportPopup"
              :onOpenSuggestPopup="this.onOpenSuggestPopup"
              :limitProblemCnt="this.limAccPbMaxCnt"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-inner-wrap">
            <EditStep3
              :onChangeStep="this.onChangeStep"
              :resultProblems="this.resultProblems"
              :editTarget="this.editTarget"
              :preTarget="this.preTarget"
              :isWorkbook="this.isWorkbook"
              :wrongStudentWork="this.wrongStudentWork"
              :changeSelectGrade="this.selectGrade"
              :selectTagOnly="this.selectTagOnly"
              :onLoading="this.onLoading"
            />
          </div>
        </swiper-slide>
      </swiper>

      <button
        v-if="this.swiperActiveIndex > 0"
        class="swiper-slide-btn slide-prev"
        @click="this.onChangeStep(this.swiper.activeIndex - 1, false)"
      >
        <img src="@/assets/images/swiper-left-arrow.png" alt=""/>
        <span>이전단계</span>
      </button>
      <button
        v-if="this.swiperActiveIndex < swiper?.slides.length - 1"
        class="swiper-slide-btn slide-next"
        @click="this.onChangeStep(this.swiper.activeIndex + 1, true)"
      >
        <img src="@/assets/images/swiper-right-arrow.png" alt=""/>
        <span>다음단계</span>
      </button>
    </div>
  </div>

  <Teleport to="body">
    <ProblemReportPopup
      :isVisible="reportItem !== null"
      :onClose="() => this.reportItem = null"
      :problemId="reportItem?.id"
    />
    <ProblemSuggestPopup
      :isVisible="this.suggestItem !== null"
      :onClose="() => this.suggestItem = null"
      :problemId="suggestItem?.id"
    />
    <LoadingDialog
      :is-show="this.isLoading"
    />
  </Teleport>
</template>

<script>
import { A11y, Navigation, Pagination } from 'swiper'
import ProblemSuggestPopup from "@/components/ProblemSuggestPopup";
import problemService from "@/services/problem.service";
import utils from "@/utils";
import LoadingDialog from "@/components/LoadingDialog.vue";
import EditStep1 from "@/components/editStep1.vue";
import EditStep2 from "@/components/editStep2.vue";
import EditStep3 from "@/components/editStep3.vue";
import WorksheetService from "@/services/worksheet.service";
import WorkbookService from "@/services/workbook.service";
import ProblemReportPopup from "@/components/ProblemReportPopup.vue";
import {EditType} from "@/utils/enums";
import CommonService from "@/services/common.service";
import TeacherWorksheet from "@/pages/teacher/TeacherWorksheet.vue";

export default {
  name: "WorksheetEdit",
  components: {
    LoadingDialog,
    ProblemSuggestPopup,
    EditStep1,
    EditStep2,
    EditStep3,
    ProblemReportPopup,
  },
  mounted() {
    window.addEventListener("resize", this.onResizeWindow);
    this.onResizeWindow()
    this.initData();
  },
  unmounted() {
    window.removeEventListener("resize", this.onResizeWindow);
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 150,
      },
      swiper: null,
      modules: [A11y, Navigation, Pagination],
      swiperActiveIndex: 0,

      problems: [],
      searchProblems: [],

      resultProblems: [],

      editTargetId: null,
      editTarget: null,

      preTargetId: null,
      preTarget: null,

      reportItem: null,
      suggestItem: null,

      wrongStudentWorkId: null, // 오답 학습지 생성 ID
      wrongStudentWork: null, // 오답 학습지 대상 숙제

      selectGrade: null,
      selectTagOnly: null,

      step1TabIndex: 0,
      isLoading: false,

      globalSetting: null,
    }
  },
  methods: {
    async initCreateData() {
      if (!this.globalSetting.is_lim_acc_ws) return;

      const isWorkbook = this.isWorkbook;
      const createdTotalCnt = isWorkbook ? this.globalSetting.wb_cnt ?? 0 : this.globalSetting.ws_cnt ?? 0;
      const maxCnt = isWorkbook ? this.globalSetting.lim_acc_wb_max_cnt ?? 0 : this.globalSetting.lim_acc_ws_max_cnt ?? 1;
      if (maxCnt <= createdTotalCnt) {
        this.$toast.error(`${isWorkbook ? '교재를' : '학습지를'} ${maxCnt}개 이상 만들 수 없도록 제한된 계정입니다`);
        window.history.back();
        return;
      }
    },
    async initData() {
      this.globalSetting = await CommonService.getGlobalSetting();

      const query = this.$route.query;
      if (!query) {
        await this.initCreateData();
        return;
      }

      const id = query.id;
      if (!id) {
        await this.initCreateData();
        return;
      }

      switch (query.type) {
        case EditType.preWorksheet:
          await this.initPreWorksheet(id);
          break;
        case EditType.preWorkbook:
          await this.initPreWorkbook(id);
          break;
        case EditType.wrongStudentWork:
          await this.initWrongStudentWork(id);
          break;
        default:
          await this.initDefaultEditData(id);
          break;
      }
    },
    onLoading(isLoading) {
      this.isLoading = isLoading;
    },
    onChangeStep1TabIndex(index) {
      this.step1TabIndex = index;
    },
    async initWrongStudentWork(id) {
      this.wrongStudentWorkId = id;
      try {
        const wrongData = await problemService.createWrong(id);
        this.wrongStudentWork = wrongData.student_work;
        this.searchProblems = wrongData.problems;
        this.problems = this.searchProblems;

        this.onChangeStep(1);
      } catch (e) {
        console.log(e);
        this.$router.go(-1);
      }
    },
    async initPreWorkbook(id) {
      this.preTargetId = id;
      try {
        const workbook = await WorkbookService.getWorkbook(this.preTargetId, {})
        if (workbook) {
          this.searchProblems = workbook.workbook_problems.map(i => i.problem)
          this.problems = this.searchProblems

          this.preTarget = workbook
        }
      } catch (e) {
        console.log(e);

        this.$toast.error('해당 교재를 찾는 중 오류가 발생하였습니다')
        this.$router.push({ name: utils.isV2Url(this) ? 'TeacherWorkbook' : 'MypageTeacherClassPreWorkbook' })
      }
    },
    async initPreWorksheet(id) {
      this.preTargetId = id;
      try {
        const worksheet = await WorksheetService.getWorksheet(this.preTargetId, {})
        if (worksheet) {
          this.searchProblems = worksheet.worksheet_problems.map(i => i.problem)
          this.problems = this.searchProblems

          this.preTarget = worksheet
        }
      } catch (e) {
        console.log(e);

        this.$toast.error('해당 학습지를 찾는 중 오류가 발생하였습니다');
        if (utils.isV2Url(this)) {
          this.$router.push({ name: 'TeacherWorksheet' });
        } else {
          this.$router.push({ name: 'MypageTeacherClassPreWorksheet' });
        }
      }
    },
    async initDefaultEditData(id) {
      this.editTargetId = id;
      const isWorkbook = this.isWorkbook;

      try {
        const res = isWorkbook
            ? await WorkbookService.getWorkbook(this.editTargetId, {})
            : await WorksheetService.getWorksheet(this.editTargetId, {});
        if (res) {
          this.searchProblems = isWorkbook
              ? res.workbook_problems.map(i => i.problem)
              : res.worksheet_problems.map(i => i.problem);
          this.problems = this.searchProblems;
          this.editTarget = res;
        }
      } catch (e) {
        console.log(e);
        this.$toast.error(isWorkbook ? '해당 교재를 찾는 중 오류가 발생하였습니다' : '해당 학습지를 찾는 중 오류가 발생하였습니다');
        if (utils.isV2Url(this)) {
          this.$router.push({ name: isWorkbook ? 'TeacherWorkbook' : 'TeacherWorksheet' });
        } else {
          this.$router.push({ name: isWorkbook ? 'MypageTeacherClassPreWorkbook' : 'MypageTeacherClassPreWorksheet' });
        }
      }
    },
    onChangeResultProblems(resultProblems) {
      this.resultProblems = resultProblems
    },
    onChangeStep(step, isNext=false) {
      if (this.wrongStudentWorkId != null && step == 0) {
        this.$toast.warning('오답 학습지 생성 시에는 2단계부터 사용하실 수 있습니다.');
        return;
      }

      if (isNext) {
        switch (step) {
          case 1:
            if (this.problems.length > 0) {
              this.swiper.slideTo(step)
            } else {
              this.$toast.error('최소 한 개 이상의 문제를 선택해주세요')
            }
            break
          case 2:
            if (this.resultProblems.length > 0) {
              this.swiper.slideTo(step)
            } else {
              this.$toast.error('최소 한 개 이상의 문제를 선택해주세요')
            }
            break
        }
      } else {
        this.swiper.slideTo(step)
      }
    },
    onResizeWindow() {
      const windowWidth = window.innerWidth
      const sideSwiperVisibleWidth = 70
      if (windowWidth > 1650) {
        this.swiperOptions.spaceBetween = (windowWidth - this.swiper.width - sideSwiperVisibleWidth) / 2
      } else {
        this.swiperOptions.spaceBetween = (windowWidth - this.swiper.width + 10) / 2
      }
    },
    onSwiper: function(swiper) {
      this.swiper = swiper
    },
    onSlideChange: function() {
      this.swiperActiveIndex = this.swiper.activeIndex
    },
    onChangeProblems(problems) {
      this.problems = problems;

      if (this.isNewCreate) { // 새로 생성하고 있으며
        if (this.swiper?.activeIndex === 0) { // 현재 STEP 1 이고
          if (this.step1TabIndex == 0 || this.step1TabIndex == 1) { // 단원 유형별이거나 시중교재인 경우
            this.selectGrade = this.getGradeByProblemsTopPattern(problems); // 문제에 따라 학년 유형 바뀜
          } else if (this.step1TabIndex == 2) { // 모의고사인 경우
            this.selectGrade = '고1';
            this.selectTagOnly = '모의고사';
          }
        }
      }
    },
    getGradeByProblemsTopPattern(problems) {
      if (problems.length > 0) {
        let topPattern = null;
        problems.forEach(problem => {
          topPattern = problem.top_pattern;
          if (topPattern) {
            return false;
          }
        });

        if (topPattern) {
          const schoolType = topPattern.school_type;
          const schoolYear = topPattern.school_year;

          let matchGrade = null;
          if (schoolType && schoolYear) {
            if (schoolType.includes('초')) {
              matchGrade = `초${schoolYear}`;
            } else if (schoolType.includes('중')) {
              matchGrade = `중${schoolYear}`;
            } else {
              matchGrade = `고${schoolYear}`;
            }
          }

          return matchGrade;
        }
      }

      return null;
    },
    onChangeSearchProblems(searchProblems) {
      this.searchProblems = searchProblems
    },
    onOpenReportPopup(item) {
      this.reportItem = item;
    },
    onOpenSuggestPopup(item) {
      this.suggestItem = item;
    },
  },
  computed: {
    isNewCreate() {
      return !this.editTargetId && !this.editTarget && !this.preTargetId && !this.preTarget;
    },
    isWorkbook() {
      return this.$route.fullPath.includes(utils.PdfType.workbook);
    },
    limAccPbMaxCnt() {
      if ((this.globalSetting?.is_lim_acc_ws ?? false) === false) return null;

      if (this.isWorkbook) {
        return this.globalSetting.lim_acc_wb_pb_max_cnt ?? 0;
      } else {
        return this.globalSetting.lim_acc_ws_pb_max_cnt ?? 10;
      }
    },
  },
}
</script>
