<template>
  <div :class="['popup-wrap', 'teacher-change-class-popup-wrap', { hide: !isVisible }]">
    <div class="p-shadow" @click="() => this.onCloseEvent()"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <h1>반 선택하기</h1>
        <p>선택한 반의 데이터를 확인할 수 있습니다.<br/>반을 선택한 후 [선택] 버튼을 눌러주세요.</p>
        <template v-if="this.isLoading">
          <LoadingBox size="2rem"/>
        </template>
        <template v-else-if="this.errorMsg !== null">
          <ErrorMsgBox
            header-title=""
            :contents="this.errorMsg"
          />
        </template>
        <template v-else>
          <CustomSelect
            hidden-name="반을 선택해주세요"
            :on-change-value="(value) => this.selectMaclItemVoId = parseInt(value)"
            :options="this.selectOptions"
          />
        </template>
        <div class="pi-btn-list">
          <button @click="() => this.onCloseEvent()">
            <span>취소</span>
          </button>
          <button @click="() => this.onSubmit()">
            <span>선택</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog.vue";
import LoadingBox from "@/components/LoadingBox.vue";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import WrongNoteCreatePopup from "@/components/WrongNoteCreatePopup.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import {mapActions, mapGetters} from "vuex";
import AcademyService from "@/services/academy.service";
import utils from "@/utils";

export default {
  name: 'TeacherChangeClassPopup',
  components: {CustomSelect, WrongNoteCreatePopup, ErrorMsgBox, LoadingBox, LoadingDialog},
  props: {
    isVisible: Boolean,
    onClose: Function,
  },
  watch: {
    isVisible(value) {
      if (value) {
        this.refreshList();
      }
    },
  },
  data() {
    return {
      /** @type {string|null} */
      errorMsg: null,
      /** @type {boolean} */
      isLoading: true,
      /** @type {MACLItemVo[]} */
      maclItemVos: [],
      /** @type {number} */
      selectMaclItemVoId: null,
    };
  },
  methods: {
    async refreshList() {
      this.errorMsg = null;
      this.isLoading = true;

      const res = await AcademyService.getMyAcademyClassList();
      if (res === null) {
        this.errorMsg = '리스트를 불러오는 중 오류가 발생하였습니다';
        this.isLoading = false;
        return;
      }
      this.maclItemVos = res;
      utils.debugLog(this.maclItemVos);

      const selectMaclItemVoId = this.getSelectedAcademyClassId;
      utils.debugLog(`selectMaclItemVoId: ${selectMaclItemVoId}`);
      this.selectMaclItemVoId = selectMaclItemVoId;

      this.isLoading = false;
    },
    onCloseEvent() {
      this.onClose();
    },
    async onSubmit() {
      const selectMaclItemVoId = this.selectMaclItemVoId;
      if (selectMaclItemVoId === null) {
        this.$toast.error('반을 먼저 선택해주세요');
        return;
      }

      await this.changeAcademyClassId(selectMaclItemVoId);
      this.onClose();
      this.$router.go(0);
    },
    ...mapActions('classStore', ['changeAcademyClassId']),
  },
  computed: {
    selectOptions() {
      const selectMaclItemVoId = this.selectMaclItemVoId;
      return this.maclItemVos.map((e) => {
        return {value: e.id, name: e.name, isSelected: e.id === selectMaclItemVoId};
      });
    },
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('classStore', ['getSelectedAcademyClassId']),
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.teacher-change-class-popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99999999;
  padding: 20px;
  &.hide {
    display: none;
  }
  .p-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .p-body {
    position: relative;
    max-width: 400px;
    width: 100%;
    max-height: 90vh;
    margin: auto;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #DBDBDB;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
    overflow-y: auto;
    border-radius: 5px;
    padding: 30px;
    @include tablet {
      max-width: 360px;
      padding: 20px;
    }
    @include mobile {
      padding: 15px;
    }
    .pb-inner-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      &>h1 {
        font-weight: 600;
        font-size: 18px;
        color: black;
        text-align: center;
        @include tablet {
          font-size: 15px;
        }
      }
      &>p {
        margin-top: 20px;
        width: 100%;
        font-size: 15px;
        text-align: center;
        word-break: keep-all;
      }
      .loading-box {
        margin-top: 20px;
      }
      &>select {
        margin-top: 20px;
      }
      .pi-btn-list {
        width: 100%;
        display: flex;
        gap: 15px;
        align-items: center;
        button {
          margin-top: 30px;
          width: 100%;
          border-radius: 5px;
          background-color: #{$defaultColor};
          padding: 10px 15px;
          &:first-child {
            background-color: #{$grayColor};
          }
          span {
            color: white;
          }
        }
      }
    }
  }
}
</style>
