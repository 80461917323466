<template>
    <div class="img-wrap">
        <div class="img-relative" :style="`padding-bottom: ${paddingBottomPercent}%;background-color:${backgroundColor};`">
            <img v-if="src && src.length > 0" :src="src" alt=""/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Image',
    props: ['imageWidth', 'imageHeight', 'src', 'defaultBgColor'],
    computed: {
        paddingBottomPercent() {
            return (this.imageHeight / this.imageWidth) * 100
        },
        backgroundColor() {
            return this.defaultBgColor ?? '#D9D9D9'
        },
    },
}
</script>

<style scoped lang="scss">
.img-wrap {
    width: 100%;
    display: flex;
    .img-relative {
        width: 100%;
        display: flex;
        height: 0;
        position: relative;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
</style>