<template>
  <div :class="['popup-wrap', 'popup-component-wrap', { hide: !isVisible }]">
    <div class="p-shadow" @click="this.onCloseEvent"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <slot name="popupContents"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    onClose: Function,
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-component-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99999999;
  padding: 20px;
  &.hide {
    display: none;
  }
  .p-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .p-body {
    position: relative;
    max-width: 550px;
    width: 100%;
    max-height: 90vh;
    margin: auto;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #DBDBDB;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
    overflow-y: auto;
    border-radius: 5px;
    padding: 20px;
    .pb-inner-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
