import api from "./api"

class BoardService {
  getBoardMenus() {
    return api.get('/board/menus');
  }
  getBoardSharedMenus() {
    return api.get('/board/shared_menus');
  }

  createMenu(
    upperMenuId,
    title,
  ) {
    return api.post('/board/menu/create', {
      upper_menu_id: upperMenuId,
      title: title,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  deleteMenu(
    menuId,
  ) {
    return api.post(`/board/menu/delete/${menuId}`, {}, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  editMenu(
    menuId,
    title,
  ) {
    return api.post(`/board/menu/edit/${menuId}`, {
      title: title,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  getBoardContents(menuId) {
    return api.get(`/board/contents/get/${menuId}`);
  }

  editBoardContents(
    boardContentsId,
    contents,
  ) {
    return api.post(`/board/contents/edit/${boardContentsId}`, {
      contents: contents,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  menuShare(menuId, academyUserIds) {
    return api.post(`/board/menu/share/${menuId}`, {
      academy_user_ids: academyUserIds,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  menuImport(menuId) {
    return api.post(`/board/menu/import/${menuId}`, {}, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}

export default new BoardService()
