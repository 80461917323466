import {getServerMediaUrl} from "@/utils/parse";
import {DividerExtUtils} from "@/extensions/divider_ext";

export class FolderProblemResultVo {
    /** @type {number} */
    id;
    /** @type {number} */
    folderId;
    /** @type {number} */
    swProblemId;
    /** @type {Date} */
    createDt;
    /** @type {Date} */
    updateDt;
    /** @type {string|null} */
    folderSolvingJson;
    /** @type {string|null} */
    folderSolvingImgUrl;
    /** @type {number|null} */
    folderSolvingImgWidth;
    /** @type {number|null} */
    folderSolvingImgHeight;
    /** @type {string|null} */
    folderMemoJson;
    /** @type {string|null} */
    folderMemoImgUrl;
    /** @type {number|null} */
    folderMemoImgWidth;
    /** @type {number|null} */
    folderMemoImgHeight;

    /** @type {number} */
    problemNumber;
    /** @type {number} */
    problemId;
    /** @type {string} */
    problemAnswer;
    /** @type {string|null} */
    answer;
    /** @type {string} */
    problemImg;
    /** @type {number} */
    problemImgWidth;
    /** @type {number} */
    problemImgHeight;
    /** @type {string|null} */
    teacherSolvingImg;
    /** @type {string|null} */
    solvingImg;
    /** @type {number|null} */
    solvingImgWidth;
    /** @type {number|null} */
    solvingImgHeight;
    /** @type {boolean|null} */
    isCurrentAnswer;
    /** @type {string} */
    problemSolutionImg;

    constructor(
        id,
        folderId,
        swProblemId,
        createDt,
        updateDt,
        folderSolvingJson,
        folderSolvingImgUrl,
        folderSolvingImgWidth,
        folderSolvingImgHeight,
        folderMemoJson,
        folderMemoImgUrl,
        folderMemoImgWidth,
        folderMemoImgHeight,

        problemNumber,
        problemId,
        problemAnswer,
        answer,
        problemImg,
        problemImgWidth,
        problemImgHeight,
        teacherSolvingImg,
        solvingImg,
        solvingImgWidth,
        solvingImgHeight,
        isCurrentAnswer,
        problemSolutionImg,
    ) {
        this.id = id;
        this.folderId = folderId;
        this.swProblemId = swProblemId;
        this.createDt = createDt;
        this.updateDt = updateDt;
        this.folderSolvingJson = folderSolvingJson;
        this.folderSolvingImgUrl = folderSolvingImgUrl;
        this.folderSolvingImgWidth = folderSolvingImgWidth;
        this.folderSolvingImgHeight = folderSolvingImgHeight;
        this.folderMemoJson = folderMemoJson;
        this.folderMemoImgUrl = folderMemoImgUrl;
        this.folderMemoImgWidth = folderMemoImgWidth;
        this.folderMemoImgHeight = folderMemoImgHeight;

        this.problemNumber = problemNumber;
        this.problemId = problemId;
        this.problemAnswer = problemAnswer;
        this.answer = answer;
        this.problemImg = problemImg;
        this.problemImgWidth = problemImgWidth;
        this.problemImgHeight = problemImgHeight;
        this.teacherSolvingImg = teacherSolvingImg;
        this.solvingImg = solvingImg;
        this.solvingImgWidth = solvingImgWidth;
        this.solvingImgHeight = solvingImgHeight;
        this.isCurrentAnswer = isCurrentAnswer;
        this.problemSolutionImg = problemSolutionImg;
    }

    static fromJson = (json) => new FolderProblemResultVo(
        json.id,
        json.folder,
        json.problem,
        new Date(json.create_dt),
        new Date(json.update_dt),
        json.solving_json,
        json.folder_solving_img !== null ? getServerMediaUrl(json.folder_solving_img) : null,
        json.folder_solving_img_width,
        json.folder_solving_img_height,
        json.memo_json,
        json.folder_memo_img !== null ? getServerMediaUrl(json.folder_memo_img) : null,
        json.folder_memo_img_width,
        json.folder_memo_img_height,

        json.problem_number,
        json.problem_id,
        json.problem_answer,
        json.answer,
        getServerMediaUrl(json.problem_img),
        json.problem_img_width,
        json.problem_img_height,
        json.teacher_solving_img !== null ? getServerMediaUrl(json.teacher_solving_img) : null,
        json.solving_img !== null ? getServerMediaUrl(json.solving_img) : null,
        json.solving_img_width,
        json.solving_img_height,
        json.is_current_answer,
        getServerMediaUrl(json.problem_solution_img),
    );

    getCleanProblemAnswer = () => DividerExtUtils.getCleanAnswer(this.problemAnswer);
    getCleanAnswer() {
        const answer = this.answer;
        if (answer == null) return '[ 풀이 중 ]';
        if (answer == '') return '[ 넘김 ]';

        return DividerExtUtils.getCleanAnswer(this.answer);
    }
}
