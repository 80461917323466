<template>
    <textarea
        :class="[addClass]"
        :placeholder="placeholder"
        :value="value"
        @input="changeValue($event.target.value)"
        ref="input"
        :disabled="isDisabled"
        :readonly="isReadOnly"
        >
    </textarea>
</template>

<script>
export default {
    name: 'CustomTextarea',
    props: {
        addClass: String,
        placeholder:   { type: String,   default: '', },
        value:         { type: String,   default: '', },
        onChangeValue: Function,
        isDisabled: Boolean,
        isReadOnly: Boolean,
        isRegexHyphen: Boolean,
    },
    methods: {
        changeValue(value) {
            let changedValue = value

            if (this.isRegexHyphen) {
                const regex = /-/gi
                if (regex.test(value)) {
                    changedValue = value.replace(regex, '')
                    this.$toast.warning('- 는 입력할 수 없습니다.')
                    this.$refs.input.value = changedValue
                }
            }

            if (this.onChangeValue) {
                this.onChangeValue(changedValue);
            }
        },
    },
}
</script>

<style scoped lang="scss">
$defaultColor: #008ED5;

textarea {
    background: #FFFFFF;
    border: 1px solid #008ED5;
    border-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
    height: 35px;
    padding: 3px 8px;
    resize: none;
    &.default-border {
        border: 1px solid rgb(224, 224, 224);
    }
    &:disabled {
        background: #efefef;
    }
    &:read-only {
        background: #efefef;
    }
    &::placeholder {
        color: rgb(215, 215, 215);
    }
}
</style>