<template>
  <div :class="['popup-wrap', 'ai-assistant-popup-wrap', { hide: !this.isVisible }]">
    <div class="p-shadow" @click="this.onCloseEvent"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <!-- 상단 버튼 3개 삭제 -->
        <h5 style="text-align: center; font-weight: bold">AI 에스덕</h5>
        <div class="pb-divider"></div>
        <div class="pb-chat-list">
          <div v-for="(message, index) in messages" :key="index" class="cl-item">
            <div v-if="!message.isUser" class="mascot-img-wrap">
              <img src="@/assets/images/sduck-mascot.png" alt="">
            </div>
            <div :class="['chat-box', { 'user-message': message.isUser }]">
              <p>{{ message.text }}</p>
            </div>
          </div>
        </div>
        <div class="pb-input-list">
          <div class="recommend-list">
          <button class="recommend-item">
            메시지 추천 1
          </button>
          <button class="recommend-item">
            메시지 추천 2
          </button>
          <button class="recommend-item">
            메시지 추천 3
          </button>
        </div>
        <input 
          v-model="newMessage"
          @keyup.enter="sendMessage"
          class="input-box"
          type="text"
          placeholder="메시지를 입력해주세요."
        />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
  },
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    onClose: Function,
  },
  data() {
    return {
      newMessage: '',
      messages: [
        { text: 'AI 에스덕 : 선생님, 과제 미제출 학생을 확인해주세요.', isUser: false },
        { text: '미제출 학생에게 메시지를 보낼까요?', isUser: false },
        { text: '학습이 부족한 학생들이 있습니다. 확인해주세요.', isUser: false },
      ],
    };
  },
  watch: {
    isVisible() {
    },
  },
  mounted() {
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        this.messages.push({ text: this.newMessage, isUser: true });
        this.newMessage = '';
      }
    },
    onCloseEvent() {
      this.onClose();
    },
  },
}
</script>
