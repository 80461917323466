<template>
    <label>
        <input 
            type="radio"
            @input="(value) => { value == true || value == false ? $emit('input', !this.isChecked) : '' }"
            :name="this.name"
            :checked="this.checked"
            />
        <span>{{ this.title }}</span>
    </label>
</template>

<script>
export default {
    name: 'CustomRadioButton',
    props: {
        title: String,
        checked: Boolean,
        name: String,
    },
}
</script>

<style lang="scss" scoped>
label {
    display: flex;
    input {
        display: none;
    }
    input + span {
        padding: 10px 5px;
        background: #FFFFFF;
        border: 1px solid #008ED5;
        border-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: #000000;
        transition: all .2s ease-in-out;
        cursor: pointer;
        flex: 1;
    }
    input:checked + span {
        background-color: #008ED5;        
        color: white;
    }
}
</style>
