export class V2RouterItem {
    /** @type {string} */
    title;
    /** @type {string} */
    iconUrl;
    /** @type {V2RouterSubItem[]} */
    subItems;

    constructor(
        title,
        iconUrl,
        subItems,
    ) {
        this.title = title;
        this.iconUrl = iconUrl;
        this.subItems = subItems;
    }
}
