<template>
  <div :class="['popup-wrap', 'student-ai-assistant-popup-wrap', { hide: !this.isVisible }]">
    <div class="p-body">
      <template v-if="this.isLoading">
            <div class="pb-loading">
              <b-spinner style="width: 3rem; height: 3rem;"
                        variant="primary"
                        label="Spinning"/>
            </div>
          </template>
      <div class="pb-inner-wrap">
        <div class="iw-top-bar">
          <h5 style="text-align: center; font-weight: bold">AI 에스덕</h5>
          <div class="iw-close-btn" @click="this.onCloseEvent">
          <img src="@/assets/images/close.png" alt="">
        </div>
        
        </div> 
        <div class="pb-chat-list">
          <div v-for="(message, index) in messages" :key="index" class="cl-item">
            <div v-if="!message.isUser" class="mascot-img-wrap">
              <img src="@/assets/images/sduck-mascot.png" alt="">
            </div>
            <div :class="['chat-box', { 'user-message': message.isUser }]">
              <p>{{ message.text }}</p>
            </div>
          </div>
        </div>
        <div class="pb-input-list">
          <div class="recommend-list">
          <button class="recommend-item">
             
          </button>
          <button class="recommend-item">
             
          </button>
          <button class="recommend-item">
             
          </button>
        </div>
        <input 
          v-model="message"
          @keyup.enter="sendMessage"
          class="input-box"
          type="text"
          placeholder="메시지를 입력해주세요."
        />
        </div>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <LoadingDialog :is-show="this.isLoadingDialog"/>
  </Teleport>
</template>

<script>
import axios from "axios";
import LoadingDialog from "@/components/LoadingDialog.vue";

export default {
  computed: {
  },
  components: {
    LoadingDialog,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    onClose: Function,
  },
  data() {
    return {
      messages: [
        { text: '안녕하세요, AI 에스덕입니다. 궁금한 점을 질문해주세요.', isUser: false },
      ],
      message: '',
      message_id: '',
      run_id: '',
      reset_flag: true,
      isLoading: false,
      isLoadingDialog: false,
      isLoadingContents: false,
    };
  },
  watch: {
    isVisible() {
    },
    
  },
  mounted() {
  },
  methods: {
    async sendMessage() {
      if (this.message.trim() === '') return;

      // 유저 메시지 추가
      this.messages.push({ text: this.message, isUser: true });
      const userMessage = this.message;
      this.message = '';

      this.isLoading = true;

      try {
        // AI 메시지 요청
        const response = await axios.post("https://sduck-llm.rest.univa.co.kr/llm_call", {
          message: userMessage,
          message_id: this.message_id,
          run_id: this.run_id,
          reset_flag: this.reset_flag
        }, {
          headers: {
            "Content-Type": "application/json"
          }
        });
        this.messages.push({ text: response.data.text, isUser: false });
      } catch (error) {
        console.error("Error:", error);
        this.messages.push({ text: "Error: Unable to get response", isUser: false });
      }

      this.isLoading = false;
    },
    onCloseEvent() {
      this.onClose();
    },
  },
}
</script>
