<template>
  <div class="section-2-wrap">
    <div class="v2-base-container">
      <div class="s-body-wrap">
        <TitleTooltip
            title="평가 리포트"
            :tooltip="'진단평가 - 학생이 진도 나가기 전에 이전 학년 교육과정을 이해했는지 파악합니다.<br/>단원평가(AI평가) – 수업 후 학생이 이해했는지 확인합니다.'"
        />
        <template v-if="this.errorMsg !== null">
          <ErrorMsgBox :contents="this.errorMsg"/>
        </template>
        <template v-else-if="this.isLoading">
          <LoadingBox size="25px" style="margin: 10px auto"/>
        </template>
        <template v-else>
          <div class="sb-list-wrap">
            <template v-for="(reportItem, index) in this.reportItems" :key="index">
              <div class="list-item">
                <div class="li-title-wrap">
                  <h5>{{ reportItem.title }}</h5>
                  <button @click="() => this.showAnalysisDetailPopup(index === 0, reportItem.evalId)">
                    <span>결과 보기</span>
                    <img src="@/assets/images/arrow-right-3.png" alt="">
                  </button>
                </div>
                <div class="li-divider"></div>
                <div class="li-body-wrap">
                  <div class="lib-title">
                    <span>{{ reportItem.subTitle }}</span>
                  </div>
                  <div class="lib-progress-wrap">
                    <span>{{ reportItem.submitCnt }}/{{ reportItem.totalCnt }}명</span>
                    <div class="p-bar">
                      <div class="inner-bar" :style="[{'width': `${(reportItem.submitCnt / reportItem.totalCnt) * 100}%`}]"></div>
                    </div>
                  </div>
                  <div class="lib-complete-rate-wrap">
                    <p>완료율</p>
                    <span>{{ reportItem.totalCnt == 0 ? 0 : Math.floor((reportItem.submitCnt / reportItem.totalCnt) * 100 * 10) / 10 }}%</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
      <button :class="['s-button', {'opened': this.isOpenLearningStatus}]"
              @click="() => this.toggleLearningStatus()">
        <span>학생별 학습 현황 열기</span>
        <img src="@/assets/images/arrow-bottom-2.png" alt="">
      </button>
      <div :class="['learning-status-wrap', {'active': this.isOpenLearningStatus}]">
        <div class="ls-title-wrap">
          <div class="lst-tab-list">
            <div v-for="(tabName, index) in this.learningStatusTabs" :key="index"
                 @click="() => this.onClickLearningStatusTab(index)"
                 :class="['tl-item', {'active': this.selectLearningStatusTabIndex == index}]">
              <span>{{ tabName }}</span>
            </div>
          </div>
          <div class="lst-dt-wrap">
            <button class="dt-btn active"
                    @click="() => this.onChangeLearningStatusDt(true)">
              <img src="@/assets/images/arrow-left-2.png" alt="">
            </button>
            <span>{{ this.learningStatusDtStr }}</span>
            <button :class="['dt-btn', {'active': this.isLearningStatusDtOverWeekNumber === false}]"
                    @click="() => this.onChangeLearningStatusDt(false)">
              <img src="@/assets/images/arrow-right-8.png" alt="">
            </button>
          </div>
        </div>
        <template v-if="this.isLoadingLearningStatusData">
          <LoadingBox size="30px"/>
        </template>
        <template v-else>
          <div class="ls-table-wrap">
            <template v-for="(learningStatusDataListSplitItem, index) in this.learningStatusDataListSplitItems" :key="index">
              <div class="st-item">
                <div class="ti-tr tr-header">
                  <div class="ti-td"></div>
                  <div v-for="(headerItem, index) in this.learningStatusHeaderItems" :key="index"
                       class="ti-td">
                    <p>{{ headerItem.weekday_simple_name }}<br/>{{ headerItem.date_title }}</p>
                  </div>
                </div>
                <template v-for="(studentItem, index) in learningStatusDataListSplitItem" :key="index">
                  <div class="ti-tr">
                    <div class="ti-td">
                      <img class="td-smile" src="@/assets/images/smile.png" alt="">
                      <span>{{ studentItem.student_name }}</span>
                    </div>
                    <template v-for="(dayItem, dayIndex) in studentItem.day_items" :key="dayIndex">
                      <div class="ti-td">
                        <img v-if="dayItem.is_checked"
                             class="td-check-img"
                             src="@/assets/images/check.png" alt="">
                        <img v-else
                             class="td-check-img"
                             src="@/assets/images/close.png" alt="">
                        <div class="td-hover">
                          <h5>{{ dayItem.dt }}</h5>
                          <div class="tdh-item">
                            <p>문제</p>
                            <span>{{ dayItem.problem_count }}개</span>
                          </div>
                          <div class="tdh-item">
                            <p>동영상</p>
                            <span>{{ dayItem.video_learning_min }}분</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </template>
        <div class="ls-desc-wrap">
          <div class="d-title-wrap">
            <div class="dt-item">
              <img src="@/assets/images/check.png" alt="">
              <span>학습함</span>
            </div>
            <div class="dt-item">
              <img src="@/assets/images/close.png" alt="">
              <span>학습 안함</span>
            </div>
          </div>
          <p>학습 활동 기준 : 영상 시청, 단원별 문제 풀이, 단원평가<br>(진단평가는 학습에 포함되지 않습니다.)</p>
        </div>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <AnalysisDetailPopup
      :is-visible="this.analysisDetailPopupItem !== null"
      :on-close="() => this.analysisDetailPopupItem = null"
      :eval-data="this.analysisDetailPopupItem"
    />
  </Teleport>
</template>

<script>
import TitleTooltip from "@/components/TitleTooltip.vue";
import LoadingBox from "@/components/LoadingBox.vue";
import AcademyService from "@/services/academy.service";
import AIAssistantPopup from "@/components/AIAssistantPopup.vue";
import AnalysisDetailPopup from "@/components/AnalysisDetailPopup.vue";
import StudentProfilePopup from "@/components/StudentProfilePopup.vue";
import utils from "@/utils";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";

export default {
  components: {
    ErrorMsgBox,
    StudentProfilePopup,
    AnalysisDetailPopup, AIAssistantPopup,
    LoadingBox,
    TitleTooltip,
  },
  props: {
    academyClassId: Number,
  },
  watch: {
    academyClassId() {
      if (this.academyClassId !== null) {
        this.initData();
      }
    },
  },
  data() {
    return {
      errorMsg: null,
      isLoading: true,
      reportItems: [
        {title: '진단평가', evalId: null, subTitle: '', submitCnt: 0, totalCnt: 0},
        {title: 'AI 평가', evalId: null, subTitle: '', submitCnt: 0, totalCnt: 0},
      ],
      analysisDetailPopupItem: null,
      isOpenLearningStatus: false,
      learningStatusTabs: [
        '진단평가',
        '단원평가',
        // '자가학습', // TODO:
      ],
      selectLearningStatusTabIndex: 0,
      learningStatusDt: new Date(),
      isLoadingLearningStatusData: true,
      learningStatusDataList: [],
      learningStatusHeaderItems: [],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    onChangeLearningStatusDt(isPrev) {
      const learningStatusDt = this.learningStatusDt;

      if (isPrev) {
        this.learningStatusDt = new Date(learningStatusDt.setDate(learningStatusDt.getDate() - 7));
        this.reloadLearningStatusDataList();
      } else {
        if (this.isLearningStatusDtOverWeekNumber === false) {
          this.learningStatusDt = new Date(learningStatusDt.setDate(learningStatusDt.getDate() + 7));
          this.reloadLearningStatusDataList();
        }
      }
    },
    async reloadLearningStatusDataList() {
      const selectTabName = this.learningStatusTabs[this.selectLearningStatusTabIndex];
      const selectDt = this.learningStatusDt;

      const startDtStr = utils.parseYmd(utils.getWeekFirstDateByDt(selectDt));
      const endDtStr = utils.parseYmd(utils.getWeekLastDateByDt(selectDt));

      this.isLoadingLearningStatusData = true;

      const res = await AcademyService.getTeacherDashboardLearningStatus(this.academyClassId, selectTabName, startDtStr, endDtStr);
      if (res) {
        this.learningStatusHeaderItems = res.header_items;
        this.learningStatusDataList = res.result_list;
      }

      this.isLoadingLearningStatusData = false;
    },
    async onClickLearningStatusTab(index) {
      this.selectLearningStatusTabIndex = index;
      await this.reloadLearningStatusDataList();
    },
    toggleLearningStatus() {
      this.isOpenLearningStatus = !this.isOpenLearningStatus;
      if (this.isOpenLearningStatus) {
        this.reloadLearningStatusDataList();
      }
    },
    showAnalysisDetailPopup(isDiag, evalId) {
      this.analysisDetailPopupItem = {
        'id': evalId,
        'isDiag': isDiag,
      };
    },
    async initData() {
      if (this.academyClassId === null) { this.errorMsg = '반을 먼저 선택해주세요'; return; }

      this.isLoading = true;

      let isError = false;
      await Promise.all([
        AcademyService.getTeacherDashboardEvalReportDiagEval(this.academyClassId).then(res => {
          if (res) {
            this.reportItems[0].evalId = res.eval_id;
            this.reportItems[0].subTitle = res.pattern_name;
            this.reportItems[0].submitCnt = res.completed_student_cnt;
            this.reportItems[0].totalCnt = res.total_student_cnt;
          } else isError = true;
        }),
        AcademyService.getTeacherDashboardEvalReportUnitEval(this.academyClassId).then(res => {
          if (res) {
            this.reportItems[1].evalId = res.eval_id;
            this.reportItems[1].subTitle = res.pattern_name;
            this.reportItems[1].submitCnt = res.completed_student_cnt;
            this.reportItems[1].totalCnt = res.total_student_cnt;
          } else isError = true;
        }),
      ]);

      if (isError) {
        this.errorMsg = '데이터를 불러오는 중 오류가 발생하였습니다';
      }
      this.isLoading = false;
    },
  },
  computed: {
    learningStatusDataListSplitItems() {
      const chunkSize = 5;
      const learningStatusDataList = this.learningStatusDataList;

      const resultList = [];
      for (let i = 0; i < learningStatusDataList.length; i += chunkSize) {
        const chunk = learningStatusDataList.slice(i, i + chunkSize);
        resultList.push(chunk);
      }

      return resultList;
    },
    isLearningStatusDtOverWeekNumber() {
      const learningStatusDt = this.learningStatusDt;
      const year = learningStatusDt.getFullYear();
      const month = learningStatusDt.getMonth();
      const weekNumber = utils.getWeekNumberOfMonth(learningStatusDt);

      const nowDt = new Date();
      const nowYear = nowDt.getFullYear();
      const nowMonth = nowDt.getMonth();
      const nowWeekNumber = utils.getWeekNumberOfMonth(nowDt);

      return year >= nowYear
          && month >= nowMonth
          && weekNumber >= nowWeekNumber;
    },
    learningStatusDtStr() {
      const learningStatusDt = this.learningStatusDt;
      return `${learningStatusDt.getMonth() + 1}월 ${utils.getWeekNumberOfMonth(learningStatusDt)}주차`;
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/values";

.section-2-wrap {
  width: 100%;
  display: flex;
  padding: 50px 0;
  background-color: rgb(245, 245, 245);
  @include mobile {
    padding: 15px 0;
  }
  .v2-base-container {
    flex-direction: column;
    padding: 0 100px;
    @include tablet {
      padding: 0 30px;
    }
    @include mobile {
      padding: 0 15px;
    }
    .s-body-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      background-color: #A4E1FF;
      border: 1px solid #DBDBDB;
      box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
      border-radius: 5px;
      .sb-list-wrap {
        width: 100%;
        display: flex;
        gap: 18px;
        margin-top: 20px;
        @include mobile {
          flex-direction: column;
          gap: 10px;
          margin-top: 15px;
        }
        .list-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          background-color: white;
          border-radius: 15px;
          padding: 15px;
          .li-title-wrap {
            width: 100%;
            display: flex;
            align-items: center;
            &>h5 {
              flex: 1;
              padding: 5px;
              font-weight: 600;
              font-size: 16px;
              color: #008ED5;
              @include tablet {
                font-size: 13px;
              }
            }
            &>button {
              background-color: #A4E1FF;
              display: flex;
              border-radius: 15px;
              padding: 5px 10px;
              gap: 10px;
              align-items: center;
              cursor: pointer;
              &>span {
                font-weight: 500;
                font-size: 13px;
                color: #008ED5;
                @include tablet {
                  font-size: 10px;
                }
              }
              &>img {
                width: 9px;
                height: 15px;
                object-fit: contain;
                @include tablet {
                  width: 6px;
                  height: 12px;
                }
              }
            }
          }
          .li-divider {
            margin-top: 13px;
            background-color: #797979;
            width: 100%;
            height: .5px;
            @include mobile {
              margin-top: 5px;
            }
          }
          .li-body-wrap {
            margin-top: 15px;
            width: 100%;
            display: flex;
            padding: 10px;
            align-items: center;
            @include mobile {
              flex-wrap: wrap;
              gap: 5px;
              margin-top: 5px;
            }
            .lib-title {
              flex: 1.5;
              display: flex;
              align-items: center;
              @include mobile {
                min-width: 100%;
                width: 100%;
              }
              &>span {
                font-weight: 500;
                font-size: 13px;
                color: #008ED5;
                @include tablet {
                  font-size: 10px;
                }
              }
            }
            .lib-progress-wrap {
              flex: 1;
              display: flex;
              gap: 10px;
              align-items: center;
              &>span {
                font-weight: 500;
                font-size: 10px;
                color: #008ED5;
                @include tablet {
                  font-size: 7px;
                }
              }
              .p-bar {
                width: 50px;
                background-color: #DBDBDB;
                height: 10px;
                border-radius: 15px;
                overflow: hidden;
                @include tablet {
                  width: 45px;
                  height: 6px;
                }
                .inner-bar {
                  height: 100%;
                  background-color: #008ED5;
                  border-radius: 15px;
                }
              }
            }
            .lib-complete-rate-wrap {
              display: flex;
              gap: 5px;
              align-items: center;
              @include tablet {
                gap: 2px;
              }
              &>p {
                font-weight: 500;
                font-size: 13px;
                color: #797979;
                @include tablet {
                  font-size: 10px;
                }
              }
              &>span {
                font-weight: 500;
                font-size: 13px;
                color: #008ED5;
                @include tablet {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
    .s-button {
      margin: 50px auto 0;
      display: flex;
      align-items: center;
      border: 1px solid #797979;
      border-radius: 15px;
      padding: 5px 10px;
      gap: 10px;
      &>span {
        font-weight: 600;
        font-size: 13px;
        color: #000000;
        line-height: 1.5;
        @include tablet {
          font-size: 10px;
        }
      }
      &>img {
        width: 15px;
        height: 10px;
        object-fit: contain;
        @include tablet {
          width: 10px;
          height: 6px;
        }
      }
      &.opened {
        &>img {
          transform: rotate(-180deg);
        }
      }
    }
    .learning-status-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 0;
      padding: 0;
      margin-top: 0;
      &.active {
        margin-top: 18px;
        height: unset;
        padding: 0 35px;
        overflow: visible;
        @include mobile {
          padding: 0;
        }
      }
      .ls-title-wrap {
        width: 100%;
        display: flex;
        gap: 5px;
        align-items: center;
        .lst-tab-list {
          flex: 1;
          display: flex;
          gap: 5px;
          align-items: center;
          @include tablet {
            gap: 2px;
          }
          .tl-item {
            display: flex;
            align-items: center;
            border: 1px solid #DBDBDB;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
            &>span {
              font-weight: 500;
              font-size: 13px;
              color: black;
              @include tablet {
                font-size: 10px;
              }
            }
            &.active {
              border-color: #008ED5;
            }
          }
        }
        .lst-dt-wrap {
          display: flex;
          align-items: center;
          gap: 5px;
          @include tablet {
            gap: 2px;
          }
          .dt-btn {
            width: 20px;
            height: 20px;
            cursor: unset;
            opacity: 0;
            &.active {
              opacity: 1;
              cursor: pointer;
            }
            @include tablet {
              width: 15px;
              height: 15px;
            }
            &>img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          &>span {
            font-weight: 500;
            font-size: 10px;
            color: #8225D4;
            @include tablet {
              font-size: 7px;
            }
          }
        }
      }
      .ls-table-wrap {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        gap: 40px;
        @include mobile {
          grid-template-columns: repeat(1, 1fr);
        }
        .st-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          .ti-tr {
            width: 100%;
            display: flex;
            border-bottom: 1px solid #DBDBDB;
            &.tr-header {
              border-bottom-color: #797979;
            }
            .ti-td {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
              padding: 5px;
              position: relative;
              .td-smile {
                width: 25px;
                height: 25px;
                object-fit: contain;
                @include tablet {
                  width: 20px;
                  height: 20px;
                }
              }
              .td-check-img {
                width: 15px;
                height: 15px;
                object-fit: contain;
                @include tablet {
                  width: 10px;
                  height: 10px;
                }
              }
              &>span {
                font-weight: 600;
                font-size: 13px;
                color: black;
                text-align: center;
                @include tablet {
                  font-size: 10px;
                }
              }
              &>p {
                font-weight: 500;
                font-size: 13px;
                color: black;
                text-align: center;
                @include tablet {
                  font-size: 10px;
                }
              }
              &:nth-child(1) {
                flex: 1;
                justify-content: start;
              }
              &:not(:nth-child(1)) {
                width: 40px;
                @include tablet {
                  width: 30px;
                }
              }
              &:nth-child(2) {
                &>p {
                  color: #D8260E;
                }
              }
              &:last-child {
                &>p {
                  color: #008ED5;
                }
              }
              .td-hover {
                position: absolute;
                top: 100%;
                left: 0;
                width: 0;
                height: 0;
                overflow: hidden;
                background-color: white;
                border-radius: 5px;
                padding: 0;
                z-index: 1;
              }
              &:hover {
                .td-hover {
                  width: unset;
                  height: unset;
                  padding: 10px;
                  overflow: visible;
                  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
                  gap: 10px;
                  display: flex;
                  flex-direction: column;
                  min-width: 150px;
                  @include tablet {
                    min-width: 100px;
                  }
                  &>h5 {
                    font-weight: 500;
                    font-size: 16px;
                    color: black;
                    @include tablet {
                      font-size: 13px;
                    }
                  }
                  .tdh-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &>p {
                      font-weight: 500;
                      font-size: 13px;
                      color: #666666;
                      @include tablet {
                        font-size: 10px;
                      }
                    }
                    &>span {
                      font-weight: 600;
                      font-size: 13px;
                      color: black;
                      @include tablet {
                        font-size: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .ls-desc-wrap {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 10px;
        .d-title-wrap {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 10px;
          .dt-item {
            display: flex;
            gap: 5px;
            align-items: center;
            @include tablet {
              gap: 2px;
            }
            &>img {
              width: 15px;
              height: 15px;
              object-fit: contain;
              @include tablet {
                width: 10px;
                height: 10px;
              }
            }
            &>span {
              font-weight: 600;
              font-size: 13px;
              color: black;
              @include tablet {
                font-size: 10px;
              }
            }
          }
        }
        &>p {
          font-weight: 500;
          font-size: 13px;
          color: #797979;
          @include tablet {
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>
