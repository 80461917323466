<template>
  <router-link
      :class="this.linkClass"
      :to="{ name: 'home' }"
  >
    <img src="@/assets/images/logo.png" alt=""/>
  </router-link>
</template>

<script>
export default {
  name: 'WhLogoLink',
  props: {
    linkClass: String,
  },
}
</script>
