<template>
  <div id="container">
    <div class="event-wrap">
      <div class="base-container">
        <h1>이벤트</h1>

        <div class="body-list">
          <template v-for="(event, index) in events" :key="index">
            <template v-if="event.url">
              <a
                  class="list-item c-pointer"
                  :href="event.url"
                  target="_blank"
              >
                <div class="img-relative">
                  <img :src="event.img"/>
                </div>
                <p>{{event.title}}</p>
              </a>
            </template>
            <template v-else>
              <div
                  class="list-item"
              >
                <div class="img-relative">
                  <img :src="event.img"/>
                </div>
                <p>{{event.title}}</p>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonService from '@/services/common.service'

export default {
    name: 'Event',
    data() {
        return {
            events: [],
            selectEvent: null,
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            CommonService.getEvents().then(res => {this.events = res; console.log(res);})
        },
    },
}
</script>

<style lang="scss" scoped>
.event-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 104px 0 150px;
    .base-container {
        flex-direction: column;
        h1 {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 35px;
            color: #000000;
        }
        .body-list {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 40px;
            margin-top: 70px;
            .list-item {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                &.c-pointer {
                    cursor: pointer;
                }
                .img-relative {
                    width: 100%;
                    padding-bottom: 100%;
                    height: 0;
                    position: relative;
                    background-color: #D9D9D9;
                    overflow: hidden;
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;
                    color: #000000; 
                }
            }
        }
    }
}
</style>
