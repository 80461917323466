<template>
  <div class="section-4-wrap">
    <div class="v2-base-container">
      <div class="s-title-wrap">
        <TitleTooltip
            title="학생 분포도"
            tooltip="각 과목의 대단원 별 학생들의 학습량과 정답률 분포를 한눈에 볼 수 있습니다."
        />
      </div>
      <template v-if="this.errorMsg !== null">
        <ErrorMsgBox :contents="this.errorMsg"/>
      </template>
      <template v-else-if="this.isLoading">
        <LoadingBox size="25px" style="margin: 10px auto"/>
      </template>
      <template v-else>
        <div class="s-tab-list-wrap">
          <div class="stl-inner-wrap">
            <template v-for="(studentDistChartTab, index) in this.studentDistChartTabs" :key="index">
              <div :class="['tab-item', {'active': index === this.selectStudentDistChartTabIndex}]"
                   @click="() => this.selectStudentDistChartTabIndex = index"
              >
                <p>{{ studentDistChartTab }}</p>
                <div></div>
              </div>
            </template>
          </div>
        </div>
        <template v-if="this.selectStudentDistChartTabIndex !== 0">
          <select v-model="this.selectStudentDistChartSubTabIndex">
            <option value="null" hidden>-</option>
            <option v-for="(name, index) in this.studentDistChartSubTabs" :key="index" :value="index">{{ name }}</option>
          </select>
        </template>
        <div class="s-chart-wrap">
          <div class="scw-inner">
            <div class="chart-title title-y">
              <p>정답률</p>
            </div>
            <div class="chart-title title-x">
              <p>학습량 (문제 풀이)</p>
            </div>
            <div class="chart-body">
              <BubbleChart
                  :key="this.studentDistChartReloadKey"
                  :chart-data="this.studentDistChartData"
                  :options="this.studentDistChartOption"
                  :style="[{'aspect-ratio': '2.5375'}]"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AcademyService from "@/services/academy.service";
import TitleTooltip from "@/components/TitleTooltip.vue";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BubbleChart } from 'vue-chart-3';
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import LoadingBox from "@/components/LoadingBox.vue";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  components: {
    LoadingBox, ErrorMsgBox,
    TitleTooltip,
    BubbleChart,
  },
  props: {
    academyClassId: Number,
  },
  watch: {
    academyClassId() {
      if (this.academyClassId !== null) {
        this.initData();
      }
    },
    selectStudentDistChartTabIndex() {
      this.studentDistChartReloadKey += 1;
      this.selectStudentDistChartSubTabIndex = 0;
    },
    studentDistChartData() {
      this.studentDistChartReloadKey += 1;
    },
  },
  data() {
    return {
      errorMsg: null,
      isLoading: true,
      studentDistChartTabs: [],
      selectStudentDistChartTabIndex: 0,
      studentDistDatas: [],
      studentDistChartReloadKey: 0,
      selectStudentDistChartSubTabIndex: 0,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      if (this.academyClassId === null) { this.errorMsg = '반을 먼저 선택해주세요'; return; }

      this.isLoading = true;

      let isError = false;
      await Promise.all([
        AcademyService.getTeacherDashboardStudentDistribution(this.academyClassId).then(res => {
          if (res) {
            this.studentDistChartTabs = res.branches;
            this.selectStudentDistChartTabIndex = 0;
            this.studentDistDatas = res.result_list;
          } else isError = true;
        }),
      ]);

      if (isError) {
        this.errorMsg = '데이터를 불러오는 중 오류가 발생하였습니다';
      }
      this.isLoading = false;
    },
  },
  computed: {
    studentDistChartOption() {
      const filteredStudentDistDatas = this.filteredStudentDistDatas;
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            enabled: false,
            external: function (context) {
              let tooltipEl = document.getElementById('chartjs-tooltip');

              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                document.body.appendChild(tooltipEl);
              }

              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.top = '50%';
                tooltipEl.style.left = '50%';
                tooltipEl.style.opacity = 0;
                tooltipEl.style.padding = '0';
                return;
              }

              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              const dataPoints = tooltipModel.dataPoints;
              if (dataPoints.length === 0) return;

              const dataPoint = dataPoints[0];
              const dataset = dataPoint.dataset;
              if (!dataset) return;

              const label = dataset.label;
              const datas = dataset.data;
              if (!label || !datas || datas.length === 0) return;

              const data = datas[0];
              if (!data) return;

              const dataId = label;
              const studentDistData = filteredStudentDistDatas.find(e => e.student_user_id === dataId);
              if (!studentDistData) return;

              tooltipEl.innerHTML = `
<div class="teacher-dashboard-sdc-tooltip-wrap">
    <div class="t-name-wrap">
        <img src="https://firebasestorage.googleapis.com/v0/b/sduck-368307.appspot.com/o/smile.png?alt=media&token=f04d26f4-87d6-4080-9cf8-adb543c3e3e6" alt="">
        <p>${studentDistData.student_user_name}</p>
    </div>
    <div class="t-divider"></div>
    <div class="t-body-wrap">
        <div class="tb-item">
            <p>문제 풀이</p>
            <span class="active">${studentDistData.total_problem_count}개</span>
        </div>
        <div class="tb-item">
            <p>정답률</p>
            <span>${studentDistData.answer_rate}%</span>
        </div>
        <div class="tb-item">
            <p>최근 학습</p>
            <span>${studentDistData.recent_answer_dt}</span>
        </div>
    </div>
</div>
`;

              const position = context.chart.canvas.getBoundingClientRect();
              const resPosTop = position.top + window.pageYOffset + tooltipModel.caretY;
              const resPosLeft = position.left + window.pageXOffset + tooltipModel.caretX;
              tooltipEl.style.top = tooltipModel.caretY > (position.height / 2)
                  ? `${resPosTop - tooltipEl.clientHeight}px`
                  : `${resPosTop}px`;
              tooltipEl.style.left = tooltipModel.caretX > (position.width / 2)
                  ? `${resPosLeft - tooltipEl.clientWidth}px`
                  : `${resPosLeft}px`;
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.padding = '0';
              tooltipEl.style.pointerEvents = 'none';
            },
          },
        },
        scales: {
          y: {
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
              font: {
                size: 11,
                family: 'Inter',
              },
              callback: function(value, index, ticks) {
                return `${value}%`;
              },
              color: '#008ED5',
            },
          },
          x: {
            min: 0,
            ticks: {
              stepSize: 15,
              font: {
                size: 11,
                family: 'Inter',
              },
              callback: function(value, index, ticks) {
                return `${value} 개`;
              },
              color: '#333333',
            },
          },
        },
      };
    },
    studentDistChartData() {
      return {
        datasets: this.filteredStudentDistDatas.map((data) => {
          return {
            label: data.student_user_id,
            data: [
              {
                y: data.answer_rate,
                x: data.total_problem_count,
                r: 9,
              },
            ],
            backgroundColor: '#F9D286',
            borderColor: '#F8B633',
          };
        }),
      };
    },
    filteredStudentDistDatas() {
      if (this.studentDistChartTabs.length <= this.selectStudentDistChartTabIndex) return [];

      const selectTabName = this.studentDistChartTabs[this.selectStudentDistChartTabIndex];
      const mainTabList = this.studentDistDatas.filter(e => {
        return e.branches.includes(selectTabName);
      });
      if (this.selectStudentDistChartTabIndex === 0) return mainTabList;

      const studentDistChartSubTabs = this.studentDistChartSubTabs;
      if (studentDistChartSubTabs.length <= 0) return [];

      const selectSubTabName = studentDistChartSubTabs[this.selectStudentDistChartSubTabIndex];
      return mainTabList.filter(e => {
        return e.pattern_name == selectSubTabName;
      });
    },
    studentDistChartSubTabs() {
      let filteredStudentDistDatas = [];
      if (this.studentDistChartTabs.length > this.selectStudentDistChartTabIndex) {
        const selectTabName = this.studentDistChartTabs[this.selectStudentDistChartTabIndex];
        filteredStudentDistDatas = this.studentDistDatas.filter(e => {
          return e.branches.includes(selectTabName);
        });
      }

      return filteredStudentDistDatas.reduce((acc, cur) => {
        const patternName = cur.pattern_name;
        if (!acc.includes(patternName)) {
          acc.push(patternName);
        }
        return acc;
      }, []);
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/values";

</style>
