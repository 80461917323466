export const DividerExt = {
    dividerAnd: '_|AND|_',
    dividerOr: '_|OR|_',
    dividerOrder: '_|O|_',
    typeLike: '_|L|_',
    userDivider: ',',
}

export class DividerExtUtils {
    /**
     * @param {string|null} answer
     * @return {string}
     */
    static getCleanAnswer(answer) {
        if (answer == null) return '';
        let result = answer;
        result = result.split(DividerExt.dividerAnd).join(', ');
        result = result.split(DividerExt.dividerOrder).join(' -> ');
        result = result.split(DividerExt.typeLike).join('');

        const orValues = result.split(DividerExt.dividerOr);
        result = orValues.map(e => {
            if (orValues.length > 1) {
                return `( ${e} )`;
            }
            return e;
        }).join(' 혹은 ');

        return result;
    }
}
