<template>
    <div class="check-question-wrap">
        <div class="check-question-relative-wrap">
            <div class="check-question-inner-wrap">
                <div
                    v-for="(question, qIndex) in questions" :key="qIndex" 
                    class="questions-wrap"
                    >
                    <div class="q-title" v-if="question.title">
                        <CustomButtonLabel
                            :title="question.title"
                            :isChecked="this.isCheckedTitle(question)"
                            @click="this.onChangeTitle(question)"
                            />
                    </div>
                    <div class="cb-list">
                        <CustomButtonLabel
                            v-for="(item, iIndex) in question.items" :key="iIndex"
                            :title="item.title"
                            :isChecked="item.isChecked"
                            @click="() => item.onChange(item, !item.isChecked)"
                            />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomButtonLabel from './CustomButtonLabel.vue';

export default {
    name: "CheckQuestion",
    props: {
        questions: Array,
    },
    components: { 
        CustomButtonLabel
    },
    methods: {
        isCheckedTitle(question) {
            if (question.items.length > 0) {
                return question.items.filter(i => i.isChecked).length == question.items.length
            }
            return false
        },
        onChangeTitle(question) {
            if (question.items.length > 0) {
                const isChecked = question.items.filter(i => i.isChecked).length == question.items.length
                question.items.forEach(i => {
                    i.isChecked = !isChecked
                })
            } else {
                this.$toast.error('하위 문제가 없습니다.')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.check-question-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 31px 10px 20px 38px;
    .check-question-relative-wrap {
        position: relative;
        width: 100%;
        flex: 1;
        .check-question-inner-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            overflow-y: auto;
            flex-direction: column;
            gap: 30px;
            &::-webkit-scrollbar {
                width: 4px;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba(0, 142, 213, 0.5);
                border-radius: 3px;
                width: 100%;
            }
            .questions-wrap {
                width: 100%;
                display: flex;
                flex-direction: column;
                h1 {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 15px;
                    color: #000000;
                }
                .cb-list {
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    margin-top: 14px;
                    gap: 16px 20px;
                }
            }
        }
    }
}
</style>
