<template>
    <div class="mypage-teacher-wrap">
        <div class="container-1200px">
            <MypageSideBar
                :sidebarData="this.sidebarData"
                :onTabClick="this.onChangeSidebar"
                :onExpandItem="this.onExpandSidebar"
            />
            <MypageList
                :selectTabIndex="this.selectContentDataTabIndex"
                :tabAddClass="''"
                :tabs="[
                  {title: '학습지'},
                  ...this.selectStudent ? [
                    {title: '숙제'},
                  ] : [],
                ]"
                :onTabClick="this.onChangeContentTab"
                :filterData="{
                  searchInput: {isHidden: true},
                }"
                :listDatas="this.listData"
                :addTabBodyClass="''"
            />
        </div>
    </div>
</template>

<script>
import MypageSideBar from '../../../../components/MypageSideBar.vue';
import MypageList from '../../../../components/MypageList.vue';
import academyService from '../../../../services/academy.service';
import utils from '../../../../utils';
import {EditType} from "@/utils/enums";

export default {
  name: 'MypageTeacherLessonHistoryLearningHistory',
  components: {
    MypageSideBar,
    MypageList,
  },
  data() {
    return {
      selectSideBarTabIndex: 0,
      selectContentDataTabIndex: 0,

      students: [],
      selectStudent: null,

      dataList: [],
      selectDataId: null,
    }
  },
  mounted() {
    this.initData()
  },
  watch: {
    selectStudent() { this.initStudentWorks() },
  },
  methods: {
    async onClickWorksheetHomework(academyUserId, worksheetId) {
      await utils.onClickWorksheetHomework(academyUserId, worksheetId, this)
      await this.initStudentWorks()
    },
    async initData() {
      this.students = await academyService.getAcademyUsers({
        get_my_students: true,
      })
      await this.initStudentWorks()
    },
    async initStudentWorks() {
      this.dataList = []
      this.selectDataId = null
      this.selectContentDataTabIndex = 0

      let includeStudentIds = []
      if (this.selectStudent) {
        includeStudentIds.push(this.selectStudent.id)
      } else {
        includeStudentIds = this.students.reduce(
            (acc, cur) => {
              if (!acc.includes(cur.id)) {
                acc.push(cur.id)
              }
              return acc
            },
            []
        )
      }

      this.dataList = await academyService.getLearningHistory({
        'include_student_id': includeStudentIds,
      });
    },
    onChangeContentTab(index) {
      this.selectContentDataTabIndex = index
    },
    onChangeSidebar(index) {
      this.selectSideBarTabIndex = index
    },
    onExpandSidebar(index, isExpand) {
      if (this.sidebarData.tabBodyList.length > index) {
        this.sidebarData.tabBodyList[index].isExpand = isExpand
      }
    }
  },
  computed: {
    computedStudents() {
      const result = []

      this.students
          .forEach(student => {
            if (this.selectSideBarTabIndex == 0) {
              const schoolType = student.school_type?.substr(0, 1)
              const schoolYear = student.school_year?.substr(0, 1)

              const findIndex = result.findIndex(i => i.schoolType == schoolType && i.schoolYear == schoolYear)
              if (findIndex > -1) {
                result[findIndex].students.push(student)
              } else {
                result.push({
                  schoolType: schoolType,
                  schoolYear: schoolYear,
                  title: `${schoolType}${schoolYear}`,
                  students: [student],
                })
              }
            } else {
              const academyClasses = student.academy_class_list;
              for (let index = 0; index < academyClasses.length; index++) {
                const academyClass = academyClasses[index];
                
                const findIndex = result.findIndex(i => i.class.id == academyClass.id)
                if (findIndex > -1) {
                  result[findIndex].students.push(student)
                } else {
                  result.push({
                    class: academyClass,
                    title: academyClass.name,
                    students: [student],
                  })
                }
              }
            }

          })

      return result
    },
    sidebarData() {
      return {
        selectTabIndex: this.selectSideBarTabIndex,
        tabAddClass: 'd-flex',
        tabs: [
          {title: '학년'},
          {title: '반'},
        ],
        tabBodyList: this.computedStudents.map(i => {
          return {
            headTitle: `${i.title}`,
            headSubTitle: `${i.students.length}명`,
            isExpand: true,
            items: [
              {
                value: '전체',
                isSelect: this.selectStudent == null,
                onClick: () => this.selectStudent = null
              },
              ...i.students.map(student => {
                return {
                  value: student.name,
                  isSelect: student.id == this.selectStudent?.id,
                  onClick: () => this.selectStudent = student,
                }
              })
            ]
          }
        })
      }
    },
    listData() {
      if (this.selectContentDataTabIndex == 0) {
        const studentWorksBySelectedWorksheet = this.selectDataId != null
            ? this.dataList.filter(i => i.id == this.selectDataId)[0].student_works
            : [];

        return [
          {
            title: '학습지',
            heads: [
              {title: '학습지명', type: 'string', addClass: 'flex-1'},
              {title: '채점', type: 'string', addClass: 'w-100px'},
              {title: '인원수', type: 'string', addClass: 'w-100px'},
            ],
            trList: this.dataList.map(data => {
              const result = [
                {value: data.name, isChecked: data.id == this.selectDataId},
                {value: data.method},
                {value: data.cnt},
              ];

              result.forEach(i => {
                i.onClick = () => this.selectDataId = this.selectDataId == data.id ? null : data.id;
              });

              return result;
            }),
          },
          {
            title: '학생',
            heads: [
              {title: '학생명', type: 'string', addClass: 'flex-1'},
              {title: '채점문항수', type: 'string', addClass: 'w-100px'},
              {title: '정답률', type: 'string', addClass: 'w-100px'},
              {title: '', type: 'etc-btn', addClass: 'w-50px'},
            ],
            trList: studentWorksBySelectedWorksheet.map(studentWork => {
              const result = [
                {value: studentWork.s_name},
                {value: studentWork.cnt},
                {value: studentWork.rate},
                {
                  value: '',
                  customList: [
                    {
                      title: '미리보기',
                      onClick: () => utils.previewLink(this, 'preview', utils.PdfType.studentWork, studentWork.id),
                    },
                    {
                      title: '다운로드',
                      onClick: () => utils.previewLink(this, 'download', utils.PdfType.studentWork, studentWork.id),
                    },
                    {
                      title: '공유하기',
                      onClick: () => {
                        const shareHref = utils.getPreviewUrl(this, 'preview', utils.PdfType.studentWork, studentWork.id);
                        const shareFullLink = `${window.location.origin}${shareHref}`;
                        utils.writeClipboardText(shareFullLink);
                        this.$toast.success('공유 링크가 클립보드에 복사되었습니다.');
                      },
                    },
                  ],
                },
              ];

              return result;
            }),
          },
        ];
      } else {
        const studentWorks = this.dataList.map(data => {
          return data.student_works.map(i => {
            return {
              ...i,
              'wsName': data.name, // 학습지 명
              'wsId': data.id, // 학습지 ID
            };
          });
        }).reduce((acc, cur) => {
          return [
            ...acc,
            ...cur,
          ];
        }, []).sort((a, b) => {
          if (a.id > b.id) return -1;
          if (a.id == b.id) return 0;
          if (a.id < b.id) return 1;
        });

        return [
          {
            title: '학습지',
            heads: [
              {title: '학습지명', type: 'string', addClass: 'flex-1'},
              {title: '숙제내기', type: 'prev-btn', addClass: 'w-80px'},
              {title: '미리보기', type: 'prev-btn', addClass: 'w-80px'},
              {title: '오답학습지 생성', type: 'prev-btn', addClass: 'w-100px'},
              {title: '정답률', type: 'string', addClass: 'w-100px'},
            ],
            trList: studentWorks.map(studentWork => {
              return [
                {value: studentWork.wsName},
                {value: '', onClickPrevBtn: () => this.onClickWorksheetHomework(studentWork.s_id, studentWork.wsId)},
                {value: '', onClickPrevBtn: () => utils.previewLink(this, 'preview', utils.PdfType.worksheet, studentWork.wsId)},
                {value: '', onClickPrevBtn: () => {
                  if (studentWork.rate === '미완') {
                    this.$toast.warning('학생이 해당 숙제를 제출하면 생성할 수 있습니다.');
                  } else if (studentWork.rate === '100%') {
                    this.$toast.warning('정답률이 100%인 숙제는 오답학습지를 생성 할 수 없습니다.');
                  } else {
                    this.$router.push({ name: utils.isV2Url(this) ? 'TeacherWorksheetEdit' : 'WorksheetEdit', query: {'id': studentWork.id, 'type': EditType.wrongStudentWork} });
                  }
                }},
                {value: studentWork.rate},
              ]
            })
          }
        ];
      }
    },
  },
}
</script>
