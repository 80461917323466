<template>
    <div id="container">
        <div class="title-wrap">개인정보처리방침</div>

        <div class="body-wrap">
            <div> 
                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제1조 총칙<br/></strong></h5>
                <ul class="list_indent2 mgt10">
                    <p class="ls2">① 개인정보란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다.</p>
                    <p class="ls2">② 에스덕(이하 ‘회사’라 함)은 회원의 개인정보를 매우 중요시하고, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보보호법』 등의 관련 법률을 준수하고 있으며, 그에 의한 개인정보 처리방침을 정하여 이용자의 권익 보호에 최선을 다하고 있습니다.</p>
                    <p class="ls2">③ 회사는 개인정보 처리방침을 통하여 회원께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.</p>
                    <p class="ls2">④ 회사는 개인정보 처리방침을 홈페이지 첫 화면에 공개함으로써 회원께서 언제나 용이하게 보실 수 있도록 조치하고 있습니다.</p><br/>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제2조 (개인정보의 처리 항목 및 목적)<br/></strong></h5>
                <ul class='list_indent2 mgt10'>
                    <p class="ls2">① 회사는 다음의 목적을 위하여 필요 최소한의 범위에서 개인정보를 수집・이용합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
                    <p class="ls2">② 회사는 「개인정보 보호법」 제15조 제1항 제4호에 근거하여 정보주체와의 원활한 계약의 이행을 위하여 아래의 개인정보를 정보주체의 동의 없이 수집할 수 있습니다.</p>
                    <table>
                        <tr>
                            <th>법적 근거</th>
                            <th>구분</th>
                            <th>수집 목적</th>
                            <th>수집항목</th>
                            <th>보유 및 이용 기간</th>
                        </tr>
                        <tr>
                            <td>개인정보 보호법 제15조 제1항 제4호 (계약의 이행)</td>
                            <td>회원 서비스 운영</td>
                            <td>회원제 서비스 제공에 따른 본인 식별・인증, 회원자격 유지・관리, 서비스 부정이용 방지</td>
                            <td>이메일, 비밀번호, 이름, 전화번호</td>
                            <td>회원 탈퇴 시 또는 계약 만료 시</td>
                        </tr>
                    </table><br/>
                    <p class="ls2">③ 회사는 다음의 개인정보 항목을 「개인정보 보호법」 제15조 제1항 제1호 및 제22조 제1항 제7호에 따라 정보주체의 동의를 받아 처리하고 있습니다.</p>
                    <table>
                        <tr>
                            <th>유형</th>
                            <th>구분</th>
                            <th>수집/이용 목적</th>
                            <th>수집/이용 항목</th>
                            <th>보유 및 이용 기간</th>
                        </tr>
                        <tr>
                            <td rowspan='4'>회원가입</td>
                            <td>일반 회원가입<br/>(선생님)</td>
                            <td rowspan='4'>- 회원제 서비스를 위한 본인확인 및 가입 의사 확인, 중복 가입 방지, 만14세 미만 여부 확인<br/>- 법령상 의무 이행, 법령 및 약관위반 회원에 대한 이용제한 및 서비스부정이용 행위 제재<br/>- 회원에 대한 고지 사항 전달 및 불만처리 등을 위한 원활한 의사소통의 경로 확보<br/>- 가입 및 탈퇴 의사 확인, 계약의 체결/유지</td>
                            <td>[필수] 이메일, 비밀번호, 이름, 연락처<br/>[선택] 학원/학교 정보(학교/학원 이름, 원장님/관리자 성함, 주소)</td>
                            <td>회원 탈퇴 또는 동의 철회 시</td>
                        </tr>
                        <tr>
                            <td>일반 회원가입<br/>(학생)</td>
                            <td>[필수] 이메일, 비밀번호, 이름, 연락처, 학원/학교명</td>
                            <td>회원 탈퇴 또는 동의 철회 시</td>
                        </tr>
                        <tr>
                            <td>일반 회원가입<br/>(학부모)</td>
                            <td>[필수] 이메일, 비밀번호, 이름, 연락처, 학원/학교명</td>
                            <td>회원 탈퇴 또는 동의 철회 시</td>
                        </tr>
                        <tr>
                            <td>간편 회원가입</td>
                            <td>[카카오]<br/>[네이버]</td>
                            <td>회원 탈퇴 또는 동의 철회 시</td>
                        </tr>
                        <tr>
                            <td>상품 구매</td>
                            <td>카드 결제</td>
                            <td>- 대금 결제, 재화 혹은 용역 구매 시 결제정보 수집, 환불 업무</td>
                            <td>[필수] 카드번호, 생년월일(6자리)</td>
                            <td>5년(전자상거래법 제6조)<br/>단, 정상회원의 경우 탈퇴 시 까지</td>
                        </tr>
                    </table>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제3조 (개인정보의 처리 및 보유 기간)<br/></strong></h5><ul class='list_indent2 mgt10'>
                    <p class="ls2">① 회사는 법령에 따른 개인정보 보유・이용 기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유・이용 기간 내에서 개인정보를 처리・보유합니다</p>
                    <p class="ls2">② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
                    <blockquote class="custom-blockquote"><p class="ls2">1. 온라인 회원가입 및 관리: 회원 탈퇴 시</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2">2. 재화 또는 서비스 제공: 서비스 공급 완료 또는 요금 결제 · 물품 배송 완료 시</p></blockquote>
                    <p class="ls2">③ 단, 아래와 같이 관련 법령의 규정에 의하여 거래 관련 권리 의무 관계의 확인 등을 이유로 일정 기간 보유하여야 할 필요가 있을 경우에는 일정 기간 보유합니다.</p>
                    <blockquote class="custom-blockquote"><p class="ls2">- 법에 의해 보관이 의무화된 개인정보는 요청이 있더라도 보관기간 내에 수정 · 삭제할 수 없습니다.</p></blockquote>
                    <table>
                        <tr>
                            <th>보유 정보</th>
                            <th>보유 기간</th>
                            <th>근거거 법령</th>
                        </tr>
                        <tr>
                            <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                            <td>5년 보관</td>
                            <td rowspan='3'>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                        </tr>
                        <tr>
                            <td>계약 또는 청약철회 등에 관한 기록</td>
                            <td>5년 보관</td>
                        </tr>
                        <tr>
                            <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                            <td>3년 보관</td>
                        </tr>
                        <tr>
                            <td>세법이 규정하는 모든 거래에 관한 장부 및 증거 서류</td>
                            <td>5년 보관</td>
                            <td>국세기본법</td>
                        </tr>
                        <tr>
                            <td>웹사이트/앱 방문 기록, 로그인 기록</td>
                            <td>3개월</td>
                            <td>통신비밀보호법</td>
                        </tr>
                    </table><br/>
                    <p class="ls2">④ 회원 탈퇴 시 부정이용 및 서비스 이용 혼란 방지를 위하여 30일간 보관 후 삭제하며, 거래가 있는 경우에는 전항 법률에 의거하여 별도 보관합니다.</p>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제4조 (개인정보의 제3자 제공에 관한 사항)<br/></strong></h5><ul class='list_indent2 mgt10'>
                    <p class="ls2">① 회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.</p>
                    <p class="ls2">② 다만, 다음의 경우에는 개인정보를 이용 및 제공할 수 있습니다.</p>
                    <blockquote class="custom-blockquote"><p class="ls2">1) 정보주체로부터 별도의 동의를 받은 경우</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2">2) 다른 법률에 특별한 규정이 있는 경우</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2">3) 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요한 경우</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2">4) 공중위생 등 공공의 안전과 안녕을 위하여 긴급히 필요한 경우</p></blockquote>
                    <p class="ls2">③ 회사는 현재 개인정보 제3자 제공에 대한 별도의 제공사항이 없음을 알려드립니다. 향후 회사가 제3자에게 개인정보를 제공하게 될 경우에는 다음의 항목을 정보주체에게 알린 후 동의를 받겠습니다. </p>
                    <blockquote class="custom-blockquote"><p class="ls2">1. 개인정보를 제공받는 자</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2">2. 개인정보의 이용 목적(제공 시에는 제공받는 자의 이용 목적을 말한다)</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2">3. 이용 또는 제공하는 개인정보의 항목</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2">4. 개인정보의 보유 및 이용 기간(제공 시에는 제공받는 자의 보유 및 이용 기간을 말한다)</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2">5. 동의를 거부할 권리가 있다는 사실 및 동의 거부에 따른 불이익이 있는 경우에는 그 불이익의 내용</p></blockquote>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제5조 (개인정보 처리 업무의 위탁에 관한 사항)<br/></strong></h5><ul class='list_indent2 mgt10'>
                    <p class="ls2">① 회사는 원활한 개인정보 업무 처리를 위하여 다음과 같이 개인정보 처리 업무를 위탁하고 있습니다. </p>
                    <table>
                        <tr>
                            <th>위탁받는 자(수탁자) </th>
                            <th>위탁업무 내용</th>
                        </tr>
                        <tr>
                            <td>네이버 클라우드 플랫폼</td>
                            <td>서비스 제공을 위한 시스템 개발 및 운영, 데이터 보관</td>
                        </tr>
                        <tr>
                            <td>페이앱</td>
                            <td>결제대행 서비스(PG)</td>
                        </tr>
                        <tr>
                            <td>NHN KCP</td>
                            <td>결제대행 서비스(PG)</td>
                        </tr>
                        <tr>
                            <td>채널코퍼레이션</td>
                            <td>고객 상담 채널톡 서비스</td>
                        </tr>
                        <tr>
                            <td>SMS 인증</td>
                            <td>본인 인증</td>
                        </tr>
                    </table><br/>
                    <p class="ls2">② 회사는 위탁 계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁 업무 수행 목적 외 개인정보 처리금지, 기술적・관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리・감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. </p>
                    <p class="ls2">③ 「개인정보 보호법」 제26조 제6항에 따라 수탁자가 당사의 개인정보 처리 업무를 재위탁하는 경우 동의를 받고 있습니다.</p>
                    <p class="ls2">④ 위탁 업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제6조 (개인정보의 파기 절차 및 방법)<br/></strong></h5><ul class='list_indent2 mgt10'>
                    <p class="ls2">① 회사는 개인정보 보유 기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.</p>
                    <p class="ls2">② 정보주체로부터 동의받은 개인정보 보유 기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.</p>
                    <p class="ls2">③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
                    <blockquote class="custom-blockquote"><p class="ls2">1. 파기 절차<br/>회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2">2. 파기 방법<br/>회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</p></blockquote>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제7조 (만 14세 미만 아동의 개인정보 처리에 관한 사항)<br/></strong></h5><ul class='list_indent2 mgt10'>
                    <p class="ls2">① 회사는 개인정보 보호법 제22조의2에 따라 만 14세 미만의 아동의 개인정보를 처리하기 위하여 동의를 받아야 할 때에는 해당 아동의 법정대리인으로부터 동의를 받습니다. </p>
                    <p class="ls2">② 회사는 만 14세 미만 아동의 개인정보 처리에 관하여 그 법정대리인의 동의를 받을 때에는 아동에게 법정대리인의 성명, 연락처와 같이 최소한의 정보를 요구할 수 있으며, 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고, 그 동의 표시를 확인하였음을 법정대리인의 휴대전화 문자메시지로 알리는 방법으로 그 사실을 확인합니다.</p>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제8조 (정보주체와 법정대리인의 권리∙의무 및 행사방법에 관한 사항)<br/></strong></h5><ul class='list_indent2 mgt10'>
                    <p class="ls2">① 정보주체는 정보주체의 개인정보에 대해 언제든지 개인정보 열람・정정・ 삭제・처리정지 및 철회 요구, 자동화된 결정에 대한 거부 또는 설명 요구 등의 권리를 행사(이하 “권리 행사”라 함)할 수 있습니다.<br/>※ 만 14세 미만 아동에 관한 개인정보의 열람 등의 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의 개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.</p>
                    <blockquote class="custom-blockquote"><p class="ls2" style='font-weight: bold; font-size: 14px'>① 개인정보 열람 요구</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2" style='font-weight: bold; font-size: 14px'>② 오류 등이 있을 경우 정정 요구</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2" style='font-weight: bold; font-size: 14px'>③ 삭제 요구</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2" style='font-weight: bold; font-size: 14px'>④ 처리 정지 요구</p></blockquote>
                    <p class="ls2">② 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라서 서면, 전자 우편, 모사 전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체 없이 조치하겠습니다.</p>
                    <p class="ls2">③ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</p>
                    <p class="ls2">④ 정보주체가 개인정보 열람 및 처리 정지를 요구할 권리는 「개인정보 보호법」 제35조 제4항 및 제37조 제2항에 의하여 제한될 수 있습니다.</p>
                    <p class="ls2">⑤ 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 해당 개인정보의 삭제를 요구할 수 없습니다.</p>
                    <p class="ls2">⑥ 자동화된 결정이 이루어진다는 사실에 대해 정보주체의 동의를 받았거나, 계약 등을 통해 미리 알린 경우, 법률에 명확히 규정이 있는 경우에는 자동화된 결정에 대한 거부는 인정되지 않으며 설명 및 검토 요구만 가능합니다.</p>
                    <blockquote class="custom-blockquote"><p class="ls2">- 또한 자동화된 결정에 대한 거부・설명 요구는 다른 사람의 생명・신체・재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 등 정당한 사유가 있는 경우에는 그 요구가 거절될 수 있습니다.</p></blockquote>
                    <p class="ls2">⑦ 회사는 권리 행사를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</p>
                    <p class="ls2">⑧ 회사는 권리 행사를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 권리 행사가 신속하게 처리되도록 노력하겠습니다.</p>
                    <blockquote class="custom-blockquote"><p class="ls2" style='font-weight: bold'>▶ 개인정보 열람 등 청구 접수・처리 부서</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2">부서명: 경영지원팀<br/>주  소: 대구시 북구 대학로 99-4, 203호<br/>연락처: H.P: 010-4977-2966 이메일: mingi021316@naver.com</p></blockquote>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제9조 (개인정보의 안전성 확보조치에 관한 사항)<br/></strong></h5><ul class='list_indent2 mgt10'>
                    <p class="ls2">① 회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
                    <blockquote class="custom-blockquote">1. 정기적인 자체 감사 실시<br/>개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.<p class="ls2"></p></blockquote>
                    <blockquote class="custom-blockquote">2. 개인정보 취급 직원의 최소화 및 교육<br/>개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.<p class="ls2"></p></blockquote>
                    <blockquote class="custom-blockquote">3. 내부 관리 계획의 수립 및 시행<br/>개인정보의 안전한 처리를 위하여 내부 관리 계획을 수립하고 시행하고 있습니다.<p class="ls2"></p></blockquote>
                    <blockquote class="custom-blockquote">4. 해킹 등에 대비한 기술적 대책<br/>회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적∙물리적으로 감시 및 차단하고 있습니다.<p class="ls2"></p></blockquote>
                    <blockquote class="custom-blockquote">5. 개인정보의 암호화<br/>이용자의 개인정보 및 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.<p class="ls2"></p></blockquote>
                    <blockquote class="custom-blockquote">6. 접속 기록의 보관 및 위∙변조 방지<br/>개인정보 처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며, 다만, 5만 명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이 상 보관, 관리하고 있습니다. 또한 접속 기록이 위∙변조 및 도난, 분실되지 않도록 보안 기능을 사용하고 있습니다.<p class="ls2"></p></blockquote>
                    <blockquote class="custom-blockquote">7. 개인정보에 대한 접근 제한<br/>개인정보를 처리하는 데이터베이스시스템에 대한 접근 권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근 통제를 위하여 필요한 조치를 하고 있으며 침입 차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<p class="ls2"></p></blockquote>
                    <blockquote class="custom-blockquote">8. 문서 보안을 위한 잠금장치 사용<br/>개인정보가 포함된 서류, 보조 저장 매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<p class="ls2"></p></blockquote>
                    <blockquote class="custom-blockquote">9. 비인가자에 대한 출입 통제<br/>개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입 통제 절차를 수립, 운영하고 있습니다.<p class="ls2"></p></blockquote>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제10조 (개인정보의 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)<br/></strong></h5><ul class='list_indent2 mgt10'>
                    <blockquote class="custom-blockquote"><p class="ls2" style="font-weight: bold">{{startPart}}설치・운영하는 개인정보 자동 수집 장치{{endPart}}</p></blockquote>
                    <p class="ls2">① 회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</p>
                    <p class="ls2">② 쿠키는 웹사이트 운영에 이용되는 서버(http)가 정보주체의 브라우저에 보내는 소량의 정보이며 정보주체의 PC 또는 모바일에 저장됩니다.</p>
                    <p class="ls2">③ 정보주체는 웹 브라우저 옵션 설정을 통해 쿠키 허용, 차단 등의 설정을 할 수 있습니다. 다만, 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</p>
                    <blockquote class="custom-blockquote"><p class="ls2">1. 쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2">2. 쿠키의 설치·운영 및 거부: 웹 브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.</p></blockquote>
                    <blockquote class="custom-blockquote2"><p class="ls2" style="font-size: 14px">● Chrome: [설정 메뉴 선택 > 고급 설정 표시 선택 > 개인정보 및 보안 > 콘텐츠 설정 선택 > 쿠키 설정]</p></blockquote>
                    <blockquote class="custom-blockquote2"><p class="ls2" style="font-size: 14px">● Microsoft Edge: [설정 메뉴 선택 > 쿠키 및 사이트 권한 탭 선택 > 쿠키 및 사이트 데이터 관리 및 삭제 설정]</p></blockquote>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제11조 (개인정보 보호책임자 및 열람 청구를 접수∙처리하는 부서)<br/></strong></h5><ul class='list_indent2 mgt10'>
                    <p class="ls2">① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만 처리 및 피해 구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
                    <table>
                        <tr>
                            <th colspan='2'>개인정보 보호책임자</th>
                            <th colspan='2'>개인정보보호 담당 부서</th>
                        </tr>
                        <tr>
                            <td>성명 및 직위</td>
                            <td>양장엽 대표</td>
                            <td>부서명</td>
                            <td>경영지원팀</td>
                        </tr>
                        <tr>
                            <td>연락처</td>
                            <td>sduck0712@gmail.com</td>
                            <td>연락처</td>
                            <td>mingi021316@naver.com</td>
                        </tr>
                    </table><br/>
                    <p class="ls2">② 정보주체는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보보호 관련 문의, 불만 처리, 피해 구제 등에 관한 사항을 개인정보 보호책임자 및 담당 부서로 문의할 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</p>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제12조 (권익 침해 구제 방법)<br/></strong></h5><ul class='list_indent2 mgt10'>
                    <p class="ls2">① 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁 해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.</p>
                    <table>
                        <tr>
                            <th>기관</th>
                            <th>홈페이지</th>
                            <th>전화번호호</th>
                        </tr>
                        <tr>
                            <td>개인정보분쟁조정위원회</td>
                            <td>www.kopico.go.kr</td>
                            <td>(국번없이) 1833-6972</td>
                        </tr>
                        <tr>
                            <td>개인정보침해신고센터</td>
                            <td>privacy.kisa.or.kr</td>
                            <td>(국번없이) 118</td>
                        </tr>
                        <tr>
                            <td>대검찰청</td>
                            <td>www.spo.go.kr</td>
                            <td>(국번없이) 1301</td>
                        </tr>
                        <tr>
                            <td>경찰청</td>
                            <td>ecrm.cyber.go.kr</td>
                            <td>(국번없이) 182</td>
                        </tr>
                    </table><br/>
                    <p class="ls2">② 회사는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 아래의 담당 부서로 연락해 주시기 바랍니다.</p>
                    <blockquote class="custom-blockquote"><p class="ls2" style="font-weight: bold;">▶ 개인정보보호 관련 고객 상담 및 신고</p></blockquote>
                    <blockquote class="custom-blockquote"><p class="ls2">부서명: 경영지원팀<br/>연락처: H.P: 010-4977-2966, mingi021316@naver.com</p></blockquote>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제13조 (고정형 영상정보처리기기 설치·운영)<br/></strong></h5><ul class='list_indent2 mgt10'>
                    <p class="ls2">① 회사는 대구광역시 북구청이 조성한 청년 창업공간에 입주한 업체로서 고정형 영상정보처리기기를 직접 운영하고 있지 않습니다. </p>
                    <p class="ls2">② 회사의 고정형 영상정보처리기기 운영관리방침은 대구광역시 북구청 웹사이트에 게재된 관리방침을 통해 확인할 수 있습니다. </p>
                </ul><br/><br/>

                <h5 class='lh6 bs4' style='font-weight: bold'><strong>제14조 (개인정보 처리방침의 변경)<br/></strong></h5><ul class='list_indent2 mgt10'>
                    <p class="ls2">① 이 개인정보 처리방침은 2025.2.11부터 적용됩니다.</p>
                    <p class="ls2">② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.</p>
                    <blockquote class="custom-blockquote"><p class="ls2" style="font-size: 14px; cursor: pointer;" @click="onClickExPersonalTerms">- 2023.7.1 ~ 2025.2.10 적용 (클릭)</p></blockquote>
                </ul><br/><br/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PersonalTerms',
    data() {
        const appName = '에스덕';
        const companyName = '에스덕';
        const domain = window.location.origin;

        return {
            baseName: `< ${companyName} >('${domain}'이하  '${appName}')`,
            appName: appName,
            companyName: companyName,
            startPart: '<',
            endPart: '>',
            domain: domain,
            email: 'sduck0712@gmail.com',
            userName: '양장엽',
            address: '대구광역시 남구 명덕로 104, 비사관동 6층 B호 비사관 6층 청년창업사관학교 B 사무실 에스덕(대명동, 계명대학교)',
            date: '2023년 7월 1',
        };
    },
    methods: {
        onClickExPersonalTerms() {
            this.$router.push({ name: 'ExPersonalTerms' });
        },
    }
}
</script>

<style lang="scss" scoped>
.title-wrap {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 40px;
    font-weight: 900;
}
.body-wrap {
    width: 100%;
    margin: 0 auto 50px;
    max-width: 1200px;
    padding: 0 20px;
}
td, th {
    padding: 6px;
    border: 1px solid #000000;
    font-size: 14px;
}
th {
    background-color: #ccc;
    font-weight: bold;
}
.custom-blockquote {
  margin-left: 15px;
}
.custom-blockquote2 {
  margin-left: 30px;
}
</style>
