<template>
  <div class="student-problem-solving-wrap">
    <div class="sw-header-wrap">
      <div class="v2-base-container">
        <button class="hw-btn btn-back"
        >
          <img src="@/assets/images/arrow-left-4.png" alt="">
        </button>
        <div class="hw-btn btn-back-empty"></div>
        <p>{{ this.headerTitle }}</p>
        <button class="hw-btn"
                @click="() => this.handleClickPass()"
        >
          <span>넘기기</span>
        </button>
      </div>
    </div>
    <div class="sw-body-wrap">
      <div class="v2-base-container">
        <div class="iwb-inner-wrap">
          <div class="bi-header-wrap">
            <h5>{{ this.problemNumberHeader }}</h5>
            <button class="hw-report-btn"
                    @click="() => this.handleClickReport()"
            >
              <span>신고</span>
            </button>
            <span>{{ this.pageNumberHeader }}</span>
          </div>
          <div class="bi-problem-box">
            <div class="bipb-inner-wrap">
              <swiper
                  @swiper="this.onSwiperCreated"
                  :modules="modules"
                  effect="cube"
                  :cube-effect="this.cubeEffect"
                  :grabCursor="false"
                  :allowTouchMove="false"
                  :initialSlide="this.initialPage"
              >
                <template v-for="(studentWorkProblemItem, index) in this.studentWorkProblemItems" :key="index">
                  <swiper-slide>
                    <div class="pbiw-slide-inner-wrap">
                      <div class="siw-problem-box">
                        <div class="img-wrap">
                          <img :src="studentWorkProblemItem.studentWorkProblemVo.problemVo.problemImgUrl" alt="">
                        </div>
                      </div>
                      <div class="siw-answer-box">
                        <template v-if="this.isNumberAnswerType(studentWorkProblemItem)">
                          <div class="ab-input-number-wrap">
                            <template v-for="(number, index) in ['1', '2', '3', '4', '5']" :key="index">
                              <div :class="['nw-item', {'active': studentWorkProblemItem.isActiveByNumber(number)}]"
                                   @click="() => this.onChangeInputNumber(number, studentWorkProblemItem)"
                              >
                                <span>{{ number }}</span>
                              </div>
                            </template>
                            <template v-if="studentWorkProblemItem.isMultipleAnswer()">
                              <button class="nw-submit-btn"
                                      @click="() => this.handleSubmitAnswer(studentWorkProblemItem, false)"
                              >
                                <span>확인</span>
                              </button>
                            </template>
                          </div>
                        </template>
                        <template v-else>
                          <div class="ab-input-text-wrap">
                            <b-button
                                class="tw-tooltip"
                                v-b-tooltip.hover.html="this.inputTextTooltip"
                            >
                              <img src="@/assets/images/student-question-mark.png" alt="">
                            </b-button>
                            <input
                                v-model="studentWorkProblemItem.answer"
                                type="text"
                                placeholder="여기에 정답을 입력해주세요."
                                @keyup="(event) => this.onChangeInputText(event, studentWorkProblemItem)"
                            >
                            <button class="tw-submit-btn"
                                    @click="() => this.handleSubmitAnswer(studentWorkProblemItem, false)"
                            >
                              <span>확인</span>
                            </button>
                          </div>
                        </template>
                      </div>
                    </div>
                  </swiper-slide>
                </template>
              </swiper>
            </div>
          </div>
          <div class="bi-nav-box">
            <template v-if="this.isFirstPage">
              <div class="nb-item btn-arrow disabled">
                <span></span>
              </div>
            </template>
            <template v-else>
              <div class="nb-item btn-arrow"
                   @click="() => this.handlePagePrevious()"
              >
                <span>이전 문제</span>
              </div>
            </template>
            <div class="nb-item"
                 @click="() => this.isShowBottomSheet = true"
            >
              <span>문제 전체 확인</span>
            </div>
            <template v-if="this.isLastPage">
              <div class="nb-item btn-arrow disabled">
                <span></span>
              </div>
            </template>
            <template v-else>
              <div class="nb-item btn-arrow"
                   @click="() => this.handlePageNext()"
              >
                <span>다음 문제</span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <ProblemReportPopup
        :isVisible="this.problemReportPopupItem !== null"
        :onClose="() => this.problemReportPopupItem = null"
        :problemId="this.problemReportPopupItem?.id"
        :problem-number="this.problemReportPopupItem?.number"
    />
    <StudentLearningHistoryDetailBottomPopup
        :isVisible="this.isShowBottomSheet"
        :onClose="() => this.isShowBottomSheet = false"
        :studentWorkProblemVos="this.studentWorkProblemVos"
        :onClickStudentWorkProblemVo="(studentWorkProblemVo) => this.handleClickBottomPopup(studentWorkProblemVo)"
    />
    <YoutubeEmbedPopup
        :is-visible="this.youtubeEmbedPopupItem != null"
        :on-close="() => {this.youtubeEmbedPopupItem = null}"
        :data="this.youtubeEmbedPopupItem"
    />
    <LoadingDialog
        :is-show="this.isLoadingDialog"
    />
  </Teleport>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog.vue";
import YoutubeEmbedPopup from "@/components/YoutubeEmbedPopup.vue";
import ProblemReportPopup from "@/components/ProblemReportPopup.vue";
import StudentLearningHistoryDetailBottomPopup from "@/components/StudentLearningHistoryDetailBottomPopup.vue";
import {EffectCube} from "swiper";
import {StudentWorkProblemItem} from "@/items/student_work_problem_item";
import {ProblemTypeExtUtils} from "@/extensions/problem_type_ext";
import {DividerExt} from "@/extensions/divider_ext";
import utils from "@/utils";
import {ConceptVidItem} from "@/items/concept_vid_item";
import {YoutubeEmbedPopupItem} from "@/items/youtube_embed_item";
import StudentWorkService from "@/services/studentWork.service";
import {StudentWorkProblemVo} from "@/models/student_work_problem_vo";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'StudentProblemSolving',
  components: {
    StudentLearningHistoryDetailBottomPopup,
    ProblemReportPopup,
    YoutubeEmbedPopup,
    LoadingDialog,
  },
  props: {
    studentWorkId: String,
    isShowConceptVid: Boolean,
    initStudentWorkProblemVos: Array,
    headerTitle: String,
  },
  watch: {
    pageIndex(now, before) {
      this.handleSlideChange(now);
    },
    studentWorkProblemVos(now, before) {
      if (now.length != before.length) {
        this.handleSlideChange(this.pageIndex);
      }
    },
  },
  mounted() {
    this.refreshStudentWorkProblemItems(this.initStudentWorkProblemVos);
    const pageByNoAnswered = this.getFirstPageByNoAnswered();
    this.initialPage = pageByNoAnswered < 0 ? 0 : pageByNoAnswered;
  },
  data() {
    return {
      isLoadingDialog: false,
      problemReportPopupItem: null,
      studentWorkProblemItems: [],
      swiper: null,
      modules: [EffectCube],
      cubeEffect: {
        slideShadows: false,
        shadow: false,
      },
      initialPage: 0,
      inputTextTooltip: {
        title: '* 정답 입력 예시 *' +
            '<br/>' +
            '3H₂O → 3H2O' +
            '<br/>' +
            '⅓ → 1/3' +
            '<br/>' +
            '10²³ → 10^23',
      },
      isShowBottomSheet: false,

      /** @type {YoutubeEmbedPopupItem|null} */
      youtubeEmbedPopupItem: null,
    };
  },
  methods: {
    /**
     * @return {number}
     */
    getFirstPageByNoAnswered() {
      return this.studentWorkProblemItems.findIndex(e => e.studentWorkProblemVo.answer == null);
    },
    handleClickPass() {
      const item = this.selectStudentWorkProblemItem;
      if (item == null) {
        this.$toast.error('현재 페이지의 문제 정보를 불러올 수 없습니다');
        return;
      }
      this.handleSubmitAnswer(item, true);
    },
    refreshStudentWorkProblemItems(studentWorkProblemVos) {
      const beforeItemList = [...this.studentWorkProblemItems];

      this.studentWorkProblemItems = studentWorkProblemVos.map(studentWorkProblemVo => {
        return StudentWorkProblemItem.fromOriginStudentWorkProblemVo(
            beforeItemList,
            studentWorkProblemVo,
        );
      });
    },
    onSwiperCreated: function (swiper) {
      this.swiper = swiper;
    },
    handlePagePrevious() {
      if (this.isFirstPage) {
        this.$toast.warning('첫 번째 페이지입니다');
        return;
      }

      const swiper = this.swiper;
      if (!swiper) {
        this.$toast.error('페이지가 아직 초기화되지 않았습니다');
        return;
      }

      const pageIndex = this.pageIndex;
      swiper.slideTo(pageIndex - 1);
    },
    handlePageNext() {
      if (this.isLastPage) {
        this.$toast.warning('마지막 페이지입니다');
        return;
      }

      const swiper = this.swiper;
      if (!swiper) {
        this.$toast.error('페이지가 아직 초기화되지 않았습니다');
        return;
      }

      const pageIndex = this.pageIndex;
      swiper.slideTo(pageIndex + 1);
    },
    isNumberAnswerType(studentWorkProblemItem) {
      return ProblemTypeExtUtils.isNumberAnswerType(studentWorkProblemItem.studentWorkProblemVo.problemVo.type);
    },
    /**
     * @param event
     * @param {StudentWorkProblemItem} studentWorkProblemItem
     */
    onChangeInputText(event, studentWorkProblemItem) {
      studentWorkProblemItem.answer = event.target.value;
    },
    /**
     * @param {number} number
     * @param {StudentWorkProblemItem} item
     */
    onChangeInputNumber(number, item) {
      /** @type {StudentWorkProblemItem} */
      const studentWorkProblemItem = item;
      const isMultipleAnswer = studentWorkProblemItem.isMultipleAnswer();

      if (isMultipleAnswer) {
        const beforeAnswers = item.answer.split(DividerExt.userDivider).filter(e => {
          return e.length > 0;
        });
        const findIndex = beforeAnswers.findIndex(e => e == number);
        if (findIndex >= 0) {
          beforeAnswers.splice(findIndex, 1);
        } else {
          beforeAnswers.push(number);
        }
        studentWorkProblemItem.answer = beforeAnswers.join(DividerExt.userDivider);
      } else {
        studentWorkProblemItem.answer = `${number}`;
        this.handleSubmitAnswer(studentWorkProblemItem, false);
      }
    },
    handleClickBottomPopup(studentWorkProblemVo) {
      const studentWorkProblemVos = this.studentWorkProblemVos;
      const pageIndex = studentWorkProblemVos.findIndex(e => e.id == studentWorkProblemVo.id);
      if (pageIndex < 0) {
        this.$toast.error('페이지 정보를 찾을 수 없습니다');
        return;
      }

      this.isShowBottomSheet = false;
      this.jumpToPage(pageIndex);
    },
    jumpToPage(pageIndex) {
      const swiper = this.swiper;
      if (!swiper) {
        this.$toast.error('페이지가 아직 초기화되지 않았습니다');
        return;
      }

      swiper.slideTo(pageIndex);
    },
    handleSlideChange(nowPageIndex) {
      /** @type {StudentWorkProblemVo[]} */
      const studentWorkProblemVos = this.studentWorkProblemVos;
      if (studentWorkProblemVos.length <= nowPageIndex) {
        console.log('handleSlideChange() - error 1');
        return;
      }

      const isShowConceptVid = this.isShowConceptVid;
      if (!isShowConceptVid) {
        utils.debugLog('handleSlideChange() - isShowConceptVid is false');
        return;
      }

      /** @type {StudentWorkProblemVo} */
      const studentWorkProblemVo = studentWorkProblemVos[nowPageIndex];
      /** @type {ProblemPatternSimpleVo} */
      const unit3 = studentWorkProblemVo.problemVo.unit3;
      console.log(unit3);
      if (unit3 == null) {
        utils.debugLog('handleSlideChange() - unit3 == null');
        return;
      }

      const studentWorkId = this.studentWorkId;
      const existConceptVidUrls = this.getConceptVidUrls;
      const conceptVidItem = new ConceptVidItem(
          studentWorkId,
          unit3.id,
          unit3.conceptVidUrl,
      );
      const isExist = conceptVidItem.isExist(existConceptVidUrls);
      if (isExist) {
        // utils.debugLog('handleSlideChange() - isExist');
        return;
      }
      const conceptVidUrl = conceptVidItem.conceptVidUrl;
      if (conceptVidUrl == null) {
        utils.debugLog('handleSlideChange() - conceptVidUrl is null');
        return;
      }
      utils.debugLog(`conceptVidUrl: ${conceptVidUrl}`);

      const url = new URL(conceptVidUrl);
      const host = url.host;
      let youtubeVideoId;
      switch (host) {
        case 'youtu.be':
          const pathNames = url.pathname.split('/').filter(e => e.length > 0);
          youtubeVideoId = pathNames[0];
          break;
        case 'www.youtube.com':
          const urlParams = new URLSearchParams(url.search);
          youtubeVideoId = urlParams.get('v');
          break;
        default:
          this.$toast.warning('정해진 영상 정보가 아닙니다');
          break;
      }
      utils.debugLog(`youtubeVideoId: ${youtubeVideoId}`);
      if (youtubeVideoId == null) {
        this.$toast.warning('Youtube 영상 ID를 읽을 수 없습니다');
        return;
      }

      const urlParams = new URLSearchParams(url.search);
      const timeParam = urlParams.get('t')?.replace(/s/g, '');
      let startSeconds = parseInt(timeParam);
      if (isNaN(startSeconds)) startSeconds = null;
      console.log(startSeconds);

      this.youtubeEmbedPopupItem = new YoutubeEmbedPopupItem(
          youtubeVideoId,
          startSeconds,
      );
      this.addConceptVidItem(conceptVidItem);
    },
    /**
     * @param {StudentWorkProblemItem} item
     * @param {boolean} isPass
     */
    async handleSubmitAnswer(item, isPass) {
      /** @type {StudentWorkProblemItem} */
      const studentWorkProblemItem = item;
      let answer = studentWorkProblemItem.answer;

      if (isPass) {
        answer = '';
      } else if (isPass == false && answer.length == 0) {
        this.$toast.error('답을 입력해주세요');
        return;
      }

      this.isLoadingDialog = true;

      let res;
      try {
        res = await StudentWorkService.submitAnswer(
            studentWorkProblemItem.studentWorkProblemVo.id,
            answer,
            isPass,
        );
      } catch (e) {
        console.log(e);
        this.$toast.error('문제 제출 준비 중 오류가 발생하였습니다');
        return;
      }

      // 기존 리스트 갱신
      const studentWorkProblemVos = (res.student_work_problems ?? []).map(e => StudentWorkProblemVo.fromJson(e));
      this.refreshStudentWorkProblemItems(studentWorkProblemVos);

      // 모든 문제를 다 풀었으면 메인 페이지로 이동
      const pageByNoAnswered = this.getFirstPageByNoAnswered();
      if (pageByNoAnswered < 0) {
        this.$toast.success('모든 문제를 제출하였습니다\n홈 화면으로 이동합니다');
        this.$router.push({ name: 'StudentLearningHistory' });
        return;
      } else {
        // 안 푼 문제 중 가장 첫 번째 문제로 이동
        this.jumpToPage(pageByNoAnswered);
      }

      this.isLoadingDialog = false;
    },
    handleClickReport() {
      const item = this.selectStudentWorkProblemItem;
      if (item == null) {
        this.$toast.error('현재 페이지의 문제 정보를 불러올 수 없습니다');
        return;
      }
      /** @type {StudentWorkProblemItem} */
      const studentWorkProblemItem = item;
      this.problemReportPopupItem = {
        id: studentWorkProblemItem.studentWorkProblemVo.problemVo.id,
        number: studentWorkProblemItem.studentWorkProblemVo.order + 1,
      };
    },
    ...mapActions('conceptVidItemStore', [
      'addConceptVidItem',
    ]),
  },
  computed: {
    /** @return {StudentWorkProblemItem[]} */
    studentWorkProblemVos() {
      return (this.studentWorkProblemItems ?? []).map(e => e.studentWorkProblemVo);
    },
    /** @type {StudentWorkProblemItem|null} */
    selectStudentWorkProblemItem() {
      const studentWorkProblemItems = this.studentWorkProblemItems;
      const pageIndex = this.pageIndex;
      if (studentWorkProblemItems && studentWorkProblemItems.length > pageIndex) {
        return studentWorkProblemItems[pageIndex];
      }

      return null;
    },
    selectStudentWorkProblemVo() {
      return this.selectStudentWorkProblemItem?.studentWorkProblemVo ?? null;
    },
    problemNumberHeader() {
      const studentWorkProblemVo = this.selectStudentWorkProblemVo;
      if (!studentWorkProblemVo) return '';

      return `${studentWorkProblemVo.order + 1}번 문제`;
    },
    pageNumberHeader() {
      const studentWorkProblemVo = this.selectStudentWorkProblemVo;
      if (!studentWorkProblemVo) return '';

      const studentWorkProblemItems = this.studentWorkProblemItems;
      if (!studentWorkProblemItems) return '';

      return `${studentWorkProblemVo.order + 1}/${studentWorkProblemItems.length}`;
    },
    isFirstPage() {
      return this.pageIndex < 1;
    },
    isLastPage() {
      const studentWorkProblemItems = this.studentWorkProblemItems ?? [];
      return this.pageIndex >= studentWorkProblemItems.length - 1;
    },
    pageIndex() {
      return this.swiper?.activeIndex ?? 0;
    },
    ...mapGetters('conceptVidItemStore', [
      'getConceptVidUrls',
    ]),
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/values";

.student-problem-solving-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .sw-header-wrap {
    width: 100%;
    background-color: #{$defaultColor};
    height: 60px;
    display: flex;
    @include tablet {
      height: 40px;
    }
    .v2-base-container {
      gap: 5px;
      align-items: center;
      .hw-btn {
        width: 70px;
        height: 100%;
        cursor: pointer;
        @include tablet {
          width: 50px;
        }
        &>img {
          width: 100%;
          height: 25px;
          object-fit: contain;
          object-position: 5px;
          @include tablet {
            height: 20px;
          }
        }
        &>span {
          font-weight: 600;
          color: white;
          font-size: 17px;
          @include tablet {
            font-size: 15px;
          }
          @include mobile {
            font-size: 12px;
          }
        }
        &.btn-back {
          display: none;
          @include tablet {
            display: unset;
          }
        }
        &.btn-back-empty {
          cursor: unset;
          @include tablet {
            display: none;
          }
        }
      }
      &>p {
        flex: 1;
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        color: white;
        @include tablet {
          font-size: 15px;
          padding-right: 47px;
        }
        @include mobile {
          padding-right: unset;
        }
      }
    }
  }
  .sw-body-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    .v2-base-container {
      height: 100%;
      flex-direction: column;
      .iwb-inner-wrap {
        width: 100%;
        max-width: 700px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .bi-header-wrap {
          margin-top: 15px;
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          @include tablet {
            margin-top: 10px;
          }
          &>h5 {
            font-weight: 500;
            font-size: 18px;
            line-height: 1.2;
            @include tablet {
              font-size: 15px;
            }
          }
          .hw-report-btn {
            margin-left: 15px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            padding: 7px 15px;
            background-color: #{$redColor};
            box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
            cursor: pointer;
            &>span {
              color: white;
              font-weight: 500;
              line-height: 1;
              font-size: 16px;
              text-align: center;
              @include tablet {
                font-size: 14px;
              }
            }
            &:hover {
              box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
            }
          }
          &>span {
            margin-left: auto;
            font-weight: 500;
            font-size: 15px;
            @include tablet {
              font-size: 13px;
            }
          }
        }
        .bi-problem-box {
          margin-top: 15px;
          width: 100%;
          display: flex;
          flex-direction: column;
          flex: 1;
          position: relative;
          @include tablet {
            margin-top: 10px;
          }
          .bipb-inner-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .swiper {
              width: 100%;
              height: 100%;
              .swiper-wrapper {
                .swiper-slide {
                  width: 100%;
                  height: 100%;
                  .pbiw-slide-inner-wrap {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    .siw-problem-box {
                      width: 100%;
                      flex: 1;
                      background-color: white;
                      border-radius: #{$problemBorderRadius};
                      border: 1px solid #{$problemBorderColor};
                      padding: #{$problemPaddingSize};
                      box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
                      .img-wrap {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        &>img {
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          object-fit: contain;
                          object-position: top center;
                        }
                      }
                    }
                    .siw-answer-box {
                      margin-top: 10px;
                      width: 100%;
                      height: 40px;
                      display: flex;
                      @include mobile {
                        height: 35px;
                      }
                      .ab-input-number-wrap {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        gap: 15px;
                        align-items: center;
                        @include mobile {
                          gap: 10px;
                        }
                        .nw-item {
                          width: 40px;
                          height: 100%;
                          border-radius: 50%;
                          display: flex;
                          background-color: white;
                          border: 1px solid rgba(0, 0, 0, .1);
                          box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .1);
                          justify-content: center;
                          align-items: center;
                          cursor: pointer;
                          @include mobile {
                            width: 35px;
                            height: 35px;
                          }
                          &>span {
                            font-weight: 500;
                            text-align: center;
                            color: black;
                            line-height: 1;
                          }
                          &:hover {
                            box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, .1);
                          }
                          &.active {
                            background-color: #{$defaultColor};
                            &>span {
                              color: white;
                            }
                          }
                        }
                        .nw-submit-btn {
                          display: flex;
                          min-width: 60px;
                          height: 100%;
                          justify-content: center;
                          align-items: center;
                          border-radius: 5px;
                          padding: 7px 10px;
                          background-color: #{$defaultColor};
                          box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
                          @include mobile {
                            min-width: 50px;
                          }
                          &>span {
                            color: white;
                            font-weight: 500;
                            line-height: 1;
                            font-size: 16px;
                            text-align: center;
                            @include tablet {
                              font-size: 14px;
                            }
                            @include mobile {
                              font-size: 12px;
                            }
                          }
                        }
                      }
                      .ab-input-text-wrap {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        @include mobile {
                          gap: 5px;
                        }
                        .tw-tooltip {
                          width: 30px;
                          height: 100%;
                          background-color: transparent;
                          border-color: transparent;
                          padding: 0;
                          @include mobile {
                            width: 25px;
                          }
                          img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                          }
                        }
                        &>input {
                          flex: 1;
                          height: 100%;
                          box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
                          border: 1px solid #aaaaaa;
                          border-radius: #{$problemBorderRadius};
                          padding: 5px 15px;
                          font-size: 16px;
                          @include tablet {
                            font-size: 14px;
                          }
                          @include mobile {
                            padding: 3px 10px;
                            font-size: 12px;
                          }
                          &::placeholder {
                            color: rgba(0, 0, 0, .2);
                          }
                        }
                        .tw-submit-btn {
                          display: flex;
                          min-width: 80px;
                          height: 100%;
                          justify-content: center;
                          align-items: center;
                          border-radius: 5px;
                          padding: 7px 10px;
                          background-color: #{$defaultColor};
                          box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
                          @include mobile {
                            min-width: 50px;
                          }
                          &>span {
                            color: white;
                            font-weight: 500;
                            line-height: 1;
                            font-size: 16px;
                            text-align: center;
                            @include tablet {
                              font-size: 14px;
                            }
                            @include mobile {
                              font-size: 12px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .bi-nav-box {
          margin-top: 10px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          @include tablet {
            padding-bottom: 0;
          }
          @include mobile {
            margin-top: 5px;
          }
          .nb-item {
            cursor: pointer;
            padding: 5px 2px;
            @include mobile {
              padding: 5px 0;
            }
            &.btn-arrow {
              min-width: 65px;
            }
            &.disabled {
              cursor: unset;
            }
            &>span {
              font-weight: 500;
              font-size: 15px;
              @include tablet {
                font-size: 14px;
              }
              @include mobile {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
